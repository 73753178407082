import { ForgotPassword } from '../modules/authentication/pages/ForgotPassword';
import { ResetPassword } from '../modules/authentication/pages/ResetPassword';
import { SignIn } from '../modules/authentication/pages/SignIn';
import { SignUp } from '../modules/authentication/pages/SignUp';
import { ShowBudget } from '../modules/budgets/pages/Budget/showBudget';
import { Home } from '../modules/home/pages/Home';
import { NotFound } from '../modules/home/pages/NotFound';
import { Messages } from '../modules/messages/pages';
import { BecomePremium } from '../modules/premium/pages/BecomePremium';
import { SignaturePremium } from '../modules/premium/pages/SignaturePremium';
import { Me } from '../modules/profile/pages/Me';
import { budgetsRoutes } from './budgets.routes';
import { informationRoutes } from './information.routes';
import { recordsRoutes } from './records.routes';
import { salesRoutes } from './sales.routes';
import { technicalSheetsRoutes } from './technicalSheets.routes';
import { IRoutes } from './types.routes';
import { utilityRoutes } from './utility.routes';

export const publicRoutes: IRoutes[] = [
  {
    path: '/sign-in',
    children: <SignIn />,
  },
  {
    path: '/sign-up',
    children: <SignUp />,
  },
  {
    path: '/forgot-password',
    children: <ForgotPassword />,
  },
  {
    path: '/reset-password/:token',
    children: <ResetPassword />,
  },
  {
    path: '/orcamento/:id/:file',
    children: <ShowBudget />,
    pageName: 'Orçamentos',
  },
];

export const protectedRoutes: IRoutes[] = [
  {
    path: '/',
    children: <Home />,
    isPrivate: true,
    exact: true,
    pageName: 'Início',
  },
  {
    path: '/profile',
    children: <Me />,
    isPrivate: true,
    exact: true,
    pageName: 'Perfil',
  },
  {
    path: '/become-premium',
    children: <BecomePremium />,
    isPrivate: true,
    exact: true,
    pageName: 'Seja premium',
  },
  {
    path: '/signature/premium',
    children: <SignaturePremium />,
    isPrivate: true,
    exact: true,
    pageName: 'Assinatura Premium',
  },
  {
    path: '/messages',
    children: <Messages />,
    isPrivate: true,
    exact: true,
    pageName: 'Mensagens',
  },
  ...recordsRoutes,
  ...technicalSheetsRoutes,
  ...salesRoutes,
  ...informationRoutes,
  ...utilityRoutes,
  ...budgetsRoutes,
];

export const routes: IRoutes[] = [
  ...publicRoutes,
  ...protectedRoutes,
  {
    path: '/*',
    children: <NotFound />,
    isPrivate: true,
  },
];
