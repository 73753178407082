import { Box, Chip, makeStyles, Theme, Typography } from '@material-ui/core';
import { Star } from '@material-ui/icons';
import { AnimatePresence } from 'framer-motion';
import { FC, ReactNode, useContext } from 'react';

import { ENVIRONMENT } from '../../../config/environment';
import { background } from '../../assets/backgroundBase64';
import { logoPageAuth } from '../../assets/logoBase64';
import { ButtonToggleTheme } from '../../components/ButtonToggleTheme';
import { Footer } from '../../components/Footer';
import { ThemeCustomContext } from '../../contexts/ThemeCustomContext';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    backgroundImage: theme.background.gradient.radial,
    color: theme.background.gradient.contrastText,
  },
  headerText: {
    color: theme.palette.primary.contrastText,
  },
  headerTextSubtitle: {
    color: theme.palette.error.main,
  },
}));

interface AuthLayoutProps {
  children?: ReactNode;
}

const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const { toggleTheme, isDark } = useContext(ThemeCustomContext);
  return (
    <Box
      height="100%"
      minHeight="100vh"
      width="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        className={classes.header}
        position="relative"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        textAlign="center"
        minHeight={['260px', '350px']}
      >
        <Box
          position="absolute"
          display="block"
          width="100%"
          height="100%"
          style={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'contain',
            backgroundPosition: 'top left',
            opacity: 0.5,
          }}
          zIndex={1}
        />
        <Box
          zIndex={2}
          marginX="auto"
          width="130px"
          height="130px"
          borderRadius="50%"
          bgcolor="#fff"
          boxShadow="0px 8px 4px rgba(0, 0, 0, 0.1)"
          display="flex"
          justifyContent="center"
          alignItems="center"
          mb={1}
          mt={1}
        >
          <img
            src={logoPageAuth}
            alt="Logo"
            style={{
              height: '120px',
              width: '120px',
              objectFit: 'contain',
              objectPosition: 'center',
            }}
          />
        </Box>

        <Typography
          className={classes.headerText}
          variant="h5"
          style={{ zIndex: 2, fontWeight: 'bold', marginBottom: '5px' }}
        >
          BOLO NO BOLSO 2.0
        </Typography>
        <Typography
          className={classes.headerTextSubtitle}
          variant="body1"
          style={{ zIndex: 2, marginBottom: 8 }}
        >
          Nova Versão 100% online!
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        padding="48px 32px 32px"
        width="100%"
        maxWidth="400px"
        margin="0 auto"
        position="relative"
      >
        <Box position="absolute" right="32px" top="12px">
          <ButtonToggleTheme onClick={toggleTheme} isThemeDark={isDark} />
        </Box>

        <AnimatePresence>{children}</AnimatePresence>

        <Footer mt={3} version={ENVIRONMENT.REACT_APP_VERSION} />
      </Box>
    </Box>
  );
};

export { AuthLayout };
