import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function HomeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0)">
        <path d="M12.2995 16.7667C9.84445 16.7667 7.83575 14.7867 7.83575 12.3667V8.70001H16.7633V12.3667C16.7633 14.7867 14.7546 16.7667 12.2995 16.7667ZM8.57972 9.43335V12.3667C8.57972 14.3833 10.2536 16.0333 12.2995 16.0333C14.3454 16.0333 16.0193 14.3833 16.0193 12.3667V9.43335H8.57972Z" />
        <path d="M8.24493 11.7433L8.17053 11.01C9.28647 10.9366 10.514 10.1667 10.514 9.06665H11.258C11.258 10.6067 9.73285 11.6333 8.24493 11.7433Z" />
        <path d="M16.3913 11.6333H15.0894C12.3367 11.6333 11.9275 10.24 11.9275 9.43332V9.06665H12.6715V9.43332C12.6715 10.4233 13.4898 10.9 15.0894 10.9H16.3913V11.6333Z" />
        <path d="M12.2995 15.3C11.2952 15.3 10.4024 14.8233 9.84444 14.0167C9.77004 13.87 9.69565 13.7233 9.69565 13.54C9.69565 13.1 10.0676 12.7333 10.514 12.7333H14.085C14.5314 12.7333 14.9034 13.1 14.9034 13.54C14.9034 13.7233 14.8662 13.87 14.7546 14.0167C14.1966 14.8233 13.3039 15.3 12.2995 15.3ZM10.514 13.4667C10.4768 13.4667 10.4396 13.5033 10.4396 13.54C10.4396 13.5767 10.4396 13.5767 10.4396 13.5767C10.8488 14.2 11.5556 14.53 12.2995 14.53C13.0435 14.53 13.7502 14.1633 14.1594 13.5767V13.54C14.1594 13.5033 14.1222 13.4667 14.085 13.4667H10.514Z" />
        <path d="M19.7391 23H18.9952V21.5333C18.9952 19.92 17.656 18.6 16.0193 18.6H8.5797C6.94299 18.6 5.60386 19.92 5.60386 21.5333V23H4.85989V21.5333C4.85989 19.5167 6.53381 17.8667 8.5797 17.8667H16.0193C18.0652 17.8667 19.7391 19.5167 19.7391 21.5333V23Z" />
        <path d="M15.6473 23H14.9034V20.7267C14.9034 20.3967 14.7546 20.1034 14.5314 19.92L12.9319 18.5267L13.4155 17.9767L15.015 19.37C15.4242 19.7 15.6473 20.2134 15.6473 20.7267V23Z" />
        <path d="M12.6715 19.7H11.9275V20.4333H12.6715V19.7Z" />
        <path d="M12.6715 21.1667H11.9275V21.9H12.6715V21.1667Z" />
        <path d="M20.1111 9.79999H19.3672V10.5333H20.1111V9.79999Z" />
        <path d="M20.1111 11.2667H19.3672V12H20.1111V11.2667Z" />
        <path d="M20.1111 12.7333H19.3672V13.4667H20.1111V12.7333Z" />
        <path d="M20.1111 14.2H19.3672V14.9333H20.1111V14.2Z" />
        <path d="M3.74396 2.46667H3V3.20001H3.74396V2.46667Z" />
        <path d="M3.74396 3.93335H3V4.66668H3.74396V3.93335Z" />
        <path d="M3.74396 5.39999H3V6.13333H3.74396V5.39999Z" />
        <path d="M3.74396 6.86667H3V7.6H3.74396V6.86667Z" />
        <path d="M5.60386 9.79999H4.85989V10.5333H5.60386V9.79999Z" />
        <path d="M5.60386 11.2667H4.85989V12H5.60386V11.2667Z" />
        <path d="M5.60386 12.7333H4.85989V13.4667H5.60386V12.7333Z" />
        <path d="M20.1111 15.6667H19.3672V16.4H20.1111V15.6667Z" />
        <path d="M10.7 18.4533L10.1048 18.05L10.6256 17.3167C10.7744 17.1333 10.8116 16.9133 10.8116 16.6933V16.2533H11.5556V16.6933C11.5556 17.06 11.444 17.4267 11.2208 17.7567L10.7 18.4533Z" />
        <path d="M13.899 18.4533L13.3783 17.72C13.1551 17.4267 13.0435 17.06 13.0435 16.6567V16.2167H13.7874V16.6567C13.7874 16.8767 13.8618 17.0967 13.9734 17.28L14.4942 18.0133L13.899 18.4533Z" />
        <path d="M16.7633 9.06667H16.0193V7.30667L16.3169 7.23333C17.2096 7.01333 17.8792 6.17 17.8792 5.21667C17.8792 4.11667 16.9493 3.2 15.8333 3.2C15.4985 3.2 15.2009 3.27333 14.9034 3.42L14.5314 3.60333L14.4198 3.2C14.1222 2.32 13.2667 1.73333 12.2995 1.73333C11.3324 1.73333 10.514 2.32 10.1792 3.2L10.0676 3.60333L9.69564 3.42C9.39805 3.27333 9.10047 3.2 8.76568 3.2C7.64974 3.2 6.71979 4.11667 6.71979 5.21667C6.71979 6.17 7.38936 7.05 8.28211 7.23333L8.57969 7.30667V9.06667H7.83573V7.85667C6.75699 7.45333 5.97583 6.39 5.97583 5.21667C5.97583 3.71333 7.24056 2.46667 8.76568 2.46667C9.06327 2.46667 9.36085 2.50333 9.65844 2.61333C10.142 1.62333 11.1836 1 12.2995 1C13.4154 1 14.457 1.62333 14.9406 2.61333C15.2381 2.50333 15.5357 2.46667 15.8333 2.46667C17.3584 2.46667 18.6232 3.71333 18.6232 5.21667C18.6232 6.42667 17.842 7.49 16.7633 7.85667V9.06667Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="17.1111" height="22" transform="translate(3 1)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
