import { useSnackbar } from 'notistack';

export const useSnackMessages = () => {
  const { enqueueSnackbar } = useSnackbar();

  return {
    msgSuccess: (msg?: string) =>
      enqueueSnackbar(msg || 'Successo!', { variant: `success` }),
    msgWarning: (msg?: string) =>
      enqueueSnackbar(msg || 'Alerta!', { variant: `warning` }),
    msgError: (msg?: string) =>
      enqueueSnackbar(
        msg ||
          'Ocorreu um erro inesperado. Tente novamente! Se o erro persistir entre em contato com o suporte.',
        { variant: `error` }
      ),
  };
};
