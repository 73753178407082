import { makeStyles } from '@material-ui/core/styles';

import { background } from '../../../assets/backgroundBase64';

export const useStylesMenu = makeStyles((theme) => ({
  title: {
    color: '#B54D54',
  },
  list: {
    width: 250,
  },
  listIconSelect: {
    color: theme.palette.primary.main,
    minWidth: theme.spacing(4),
  },
  colorSignOut: {
    color: theme.palette.secondary.dark,
  },
  shadow: {
    color: theme.text.primary,
    boxShadow: theme.shadows[5],
    background: `url(${background}), ${theme.background.gradient.radial}`,
    backgroundSize: 'contain',
    backgroundPosition: 'top left',
  },
  text: {
    '& .MuiTypography-body1': {
      fontWeight: 300,
    },
  },
  textColor: {
    color: theme.palette.primary.main,
    '& .MuiTypography-body1': {
      fontWeight: 400,
      color: theme.palette.primary.main,
    },
  },
  premiumColor: {
    color: theme.color.premium.main,
  },
  listIcon: {
    minWidth: theme.spacing(4),
  },
}));
