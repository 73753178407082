import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  DialogActions,
  DialogContent,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { AddOutlined, Close } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { Button } from '../../../../shared/components/Button';
import { ButtonLarge } from '../../../../shared/components/Button/ButtonLarge';
import { Input } from '../../../../shared/components/Form/Input';
import { InputNumber } from '../../../../shared/components/Form/Input/InputNumber';
import { InputSlider } from '../../../../shared/components/Form/Input/InputSlider';
import { SearchForm } from '../../../../shared/components/Form/SearchForm';
import { Select } from '../../../../shared/components/Form/Select';
import { Header } from '../../../../shared/components/Header';
import { Modal } from '../../../../shared/components/Modal';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { useSalesAdjustments } from '../../../../shared/hooks/reactQuery/useSalesAdjustments';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { useUpload } from '../../../../shared/hooks/useUpload';
import { api } from '../../../../shared/services/apiClient';
import { formatPrice } from '../../../../shared/utils/formatPrice';
import { CreateProductModal } from '../../../records/components/CreateProductModal';
import { ImageUploadTechnicalSheet } from '../../components/ImageUploadTechnicalSheet';
import { ProductTableTechnicalSheet } from '../../components/ProductTableTechnicalSheet';
import {
  IRecipeRequest,
  Product,
  RecipeType,
  SelectedProduct,
} from '../TechnicalSheet/types';
import { marksSliceQuantity } from './marksSliceQuantity';
import { IRecipe, IRecipeResponse } from './types';

const schema = yup.object().shape({
  title: yup.string().required('Defina o nome da ficha técnica.'),
  description: yup.string(),
  quantity: yup.string().required('Campo obrigatório.'),
  unit: yup.string().nullable(),
  type: yup
    .object({
      id: yup.string(),
      name: yup.string(),
    })
    .required('Selecione o tipo da ficha técnica.'),
  profit_margin: yup.string(),
  contribution: yup.string(),
});

type FormValues = {
  title: string;
  description: string;
  quantity: number;
  unit: string;
  profit_margin: number;
  contribution: number;
  type: {
    id: number;
    name: string;
  };
};

export function EditTechnicalSheet() {
  const { msgSuccess, msgError } = useSnackMessages();
  const history = useHistory();
  const params = useParams<{ id: string; slug?: string }>();

  const [searchValue, setSearchValue] = useState('');
  const [defaultSearchValue, setDefaultSearchValue] = useState('');

  const {
    imageUploaded,
    setImageUploaded,
    isSubmitting: isSubmittingImage,
    setUrlPreview,
  } = useUpload();

  const [openProductModal, setOpenProductModal] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>(
    []
  );
  const [selectedRecipeTypeCake, setSelectedRecipeTypeCake] = useState(true);

  const [recipeTypes, setRecipeTypes] = useState<RecipeType[]>([]);

  const [recipe, setRecipe] = useState<IRecipe>({} as IRecipe);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isErrorValues, setIsErrorValues] = useState(false);

  const { data: salesAdjustment } = useSalesAdjustments();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      title: '',
      description: '',
      quantity: 10,
      unit: '',
      profit_margin: '',
      contribution: '',
    },
    mode: 'onChange',
  });

  const loadProducts = useCallback(() => {
    api.get<{ results: Product[] }>('/products').then((response) => {
      const formatProducts = response.data.results.map((product) => ({
        ...product,
        price: product.price,
        selected: !!selectedProducts.find(
          (currentProduct) => currentProduct.product_id === product.id
        ),
      }));

      setProducts(formatProducts);
    });
  }, [selectedProducts]);

  useEffect(() => {
    if (!params?.id) {
      history.push(`/technical-sheets/${params?.slug || ''}`);
    }
  }, [params?.id, params?.slug, history]);

  useEffect(() => {
    api.get<IRecipeResponse>(`/recipes/${params.id}`).then((response) => {
      console.log(response.data);
      setRecipe(response.data);

      setSelectedRecipeTypeCake(
        response.data.recipeType.slug?.includes('cakes')
      );

      setValue('title', response.data.title);
      setValue('description', response.data.description);
      setValue('profit_margin', response.data.profit_margin);
      setValue('contribution', response.data.contribution);
      setValue('quantity', response.data.quantity);
      setValue('unit', response.data.unit);
      setValue('type', response.data.recipeType);
      setValue('type.id', response.data.recipeType.id);
      setValue('type.name', response.data.recipeType.name);

      if (setUrlPreview) {
        if (response.data?.image_url) {
          setUrlPreview(response.data.image_url);
        } else {
          setUrlPreview('');
        }
      }

      if (setImageUploaded) {
        if (response.data?.image_url) {
          setImageUploaded(undefined);
        } else {
          setImageUploaded(undefined);
        }
      }

      if (response.data?.recipeHasProducts?.length) {
        setSelectedProducts((state) => [
          ...state,
          ...response.data.recipeHasProducts.map((currentProduct) => {
            return {
              product_id: currentProduct.product_id,
              quantity: currentProduct.product.quantity,
              product_name: currentProduct.product.product_name,
              price: currentProduct.product.price,
              unitMeasurement: currentProduct.product.unitMeasurement,
              newQuantity: currentProduct.quantity,
              formattedPrice: formatPrice(
                (currentProduct.product.price * currentProduct.quantity) /
                  currentProduct.product.quantity
              ),
            };
          }),
        ]);
      }

      api.get<{ results: Product[] }>('/products').then((responseProduct) => {
        const formatProducts = responseProduct.data.results.map((product) => ({
          ...product,
          price: product.price,
          selected: !!response.data.recipeHasProducts.find(
            (currentProduct) => currentProduct.product_id === product.id
          ),
        }));

        setProducts(formatProducts);
      });

      api
        .get<RecipeType[]>('/recipes/recipe-types')
        .then((responseRecipeTypes) => {
          setRecipeTypes(responseRecipeTypes.data);
          setValue(
            'type',
            responseRecipeTypes.data?.find(
              (item) => item?.id === response.data.recipe_type_id
            ) || responseRecipeTypes.data?.[0]
          );
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, setValue]);

  const onSubmit = useCallback(
    async ({
      title,
      description,
      contribution,
      profit_margin,
      quantity,
      unit,
      type,
    }: FormValues) => {
      setIsErrorValues(false);
      const applicationFee = Number(
        salesAdjustment?.result?.application_fee || 0
      );
      const saleRateWithCards = Number(
        salesAdjustment?.result?.sale_rate_with_cards || 0
      );
      const salesFeePerMe = Number(
        salesAdjustment?.result?.sales_fee_per_me || 0
      );

      const total =
        applicationFee +
        saleRateWithCards +
        salesFeePerMe +
        Number(contribution || 0) +
        Number(profit_margin || 0);

      console.log(
        {
          application_fee: applicationFee,
          contribution,
          profit_margin,
          sale_rate_with_cards: saleRateWithCards,
          sales_fee_per_me: salesFeePerMe,
        },
        total
      );
      if (total >= 100) {
        setIsErrorValues(true);
        msgError(
          'Valores inválidos! Margem de Lucro e Margem de contribuição.'
        );
        return;
      }

      const formattedData: IRecipeRequest = {
        id: Number(params.id),
        title,
        description,
        contribution,
        profit_margin,
        quantity,
        unit,
        image: imageUploaded || recipe?.image,
        old_image: imageUploaded && recipe.image ? recipe.image : undefined,
        recipe_type_id: type.id,
        products: selectedProducts?.map((product) => ({
          product_id: product.product_id,
          quantity: product.newQuantity || product.quantity,
        })),
      };

      try {
        await api.post('/recipes', formattedData);

        msgSuccess('Ficha técnica atualizada com sucesso.');

        if (setUrlPreview) {
          setUrlPreview('');
        }

        history.push(
          `/technical-sheets/details/${params.id}/${params?.slug || ''}`
        );
      } catch (err: any) {
        console.log(err);
        msgError(err?.response?.data?.message || err?.response?.data?.error);
      }
    },
    [
      imageUploaded,
      history,
      params.id,
      selectedProducts,
      msgSuccess,
      msgError,
      recipe?.image,
      params?.slug,
      setUrlPreview,
      salesAdjustment?.result?.application_fee,
      salesAdjustment?.result?.sale_rate_with_cards,
      salesAdjustment?.result?.sales_fee_per_me,
    ]
  );

  const handleClickOpenProductModal = () => {
    setOpenProductModal(true);
  };

  const handleCloseProductModal = useCallback(() => {
    loadProducts();
    setOpenProductModal(false);
  }, [loadProducts]);

  const handleSelectRecipeType = useCallback(
    (value) => {
      if (!value?.id) return;

      setSelectedRecipeTypeCake(
        recipeTypes.find((recipeType) => recipeType.id === value?.id)?.type ===
          'slices' || false
      );
    },
    [recipeTypes]
  );

  const handleSelectProduct = useCallback((product: Product) => {
    setProducts((state) =>
      state.map((currentProduct) => {
        if (currentProduct.id === product.id) {
          return {
            ...currentProduct,
            selected: true,
          };
        }
        return currentProduct;
      })
    );

    setSelectedProducts((state) => [
      ...state,
      {
        ...product,
        product_id: product.id,
        formattedPrice: product.price
          .toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          .replace('.', ','),
      },
    ]);
  }, []);

  const handleChangeQuantityProduct = useCallback(
    (product: SelectedProduct) => {
      setSelectedProducts((state) =>
        state.map((currentProduct) => {
          if (currentProduct.product_id === product.product_id) {
            return product;
          }
          return currentProduct;
        })
      );
    },
    []
  );

  const handleRemoveProduct = useCallback((id: number) => {
    setSelectedProducts((state) =>
      state.filter((product) => product.product_id !== id)
    );
    setProducts((state) =>
      state.map((product) =>
        product.id === id ? { ...product, selected: false } : product
      )
    );
  }, []);

  const handleSearch = useCallback(async (searchValue: string) => {
    setSearchValue(searchValue);
    setDefaultSearchValue(searchValue);
  }, []);

  const handleSearchClear = useCallback(() => {
    setSearchValue('');
    setDefaultSearchValue('');
  }, []);

  return (
    <>
      <PageContainer
        display="flex"
        flexDirection="column"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Header title="Atualizar Ficha Técnica" goBack={!isSubmitting} />

        <Box
          display="grid"
          gridTemplateColumns="1fr"
          gridAutoRows="auto"
          gridGap={16}
        >
          <ImageUploadTechnicalSheet disabled={isSubmitting} />

          <Input
            name="title"
            type="text"
            control={control}
            error={errors}
            variant="outlined"
            label="Nome da ficha técnica"
            fullWidth
            required
            disabled={isSubmitting}
          />

          <Input
            name="description"
            type="text"
            control={control}
            error={errors}
            variant="outlined"
            label="Sobre esta ficha técnica"
            multiline
            minRows={3}
            fullWidth
            disabled={isSubmitting}
            inputProps={{ maxLength: 500 }}
            placeholder="500 caracteres"
          />

          {isErrorValues && (
            <Alert severity="error">
              <AlertTitle>Valores inválidos</AlertTitle>
              A soma de todas as porcentagens não devem ser maior ou igual a
              100%. <br />
              Lembre-se de considerar os valores que foram informados no ajuste
              de vendas (Taxa de venda com cartões, Taxa de venda por ME (Micro
              Empresa) e Taxa do aplicativo) .<br />
              <strong>- Taxa de venda com cartões: </strong>
              {salesAdjustment?.result?.sale_rate_with_cards}%
              <br />
              <strong>- Taxa de venda por ME (Micro Empresa): </strong>
              {salesAdjustment?.result?.sales_fee_per_me}%
              <br />
              <strong>- Taxa do aplicativo: </strong>
              {salesAdjustment?.result?.application_fee}%
            </Alert>
          )}

          <InputNumber
            name="profit_margin"
            control={control}
            error={errors}
            variant="outlined"
            label="Margem de lucro (%)"
            fullWidth
            disabled={isSubmitting}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
          />

          <InputNumber
            name="contribution"
            control={control}
            error={errors}
            variant="outlined"
            label="Margem de Contribuição (%)"
            fullWidth
            disabled={isSubmitting}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
          />

          <Box pb={1} />

          <Select
            options={recipeTypes}
            name="type"
            control={control}
            error={errors}
            variant="outlined"
            label="Tipo de fixa técnica"
            fullWidth
            required
            disabled={isSubmitting}
            getSelectValue={(value) => handleSelectRecipeType(value)}
          />

          <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap={24} mb={3}>
            <InputNumber
              name="quantity"
              type="number"
              control={control}
              error={errors}
              variant="outlined"
              label="Rendimento/quantidade"
              fullWidth
              required
              disabled={isSubmitting}
              isInteger
            />
            <Input
              name="unit"
              type="text"
              control={control}
              error={errors}
              variant="outlined"
              label="Unidade Rendimento"
              fullWidth
              disabled={isSubmitting}
              placeholder="Fatias"
            />
          </Box>
          {/* )} */}
          <Box pb={1} />
          <Box display="flex" flexDirection="column" width="100%">
            <Box
              fontSize="1rem"
              width="100%"
              bgcolor="primary.light"
              padding={1}
              color="secondary.dark"
              textAlign="center"
              borderRadius="4px 4px 0 0"
            >
              Produtos
            </Box>

            <Box display="flex" flexDirection="column" width="100%">
              <ProductTableTechnicalSheet
                products={selectedProducts}
                onChangeInput={(product) =>
                  handleChangeQuantityProduct(product)
                }
                onRemove={(productId) => handleRemoveProduct(productId)}
              />
            </Box>

            <Box display="flex" justifyContent="flex-end" my={1}>
              <Button
                startIcon={<AddOutlined />}
                size="small"
                style={{ paddingLeft: 16, paddingRight: 16 }}
                onClick={() => setIsOpenModal(true)}
              >
                Adicionar Produto
              </Button>
            </Box>
          </Box>
        </Box>

        <ButtonLarge
          type="submit"
          mt={6}
          isDisabled={isSubmittingImage || isSubmitting}
        >
          Salvar
        </ButtonLarge>
      </PageContainer>

      <Modal
        title="Adicionar Produtos"
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="center"
          mb={1}
        >
          <Box>Não encontrou o produto que procurava?</Box>
          <Button
            size="small"
            style={{ width: '100%', paddingLeft: 16, paddingRight: 16 }}
            variant="outlined"
            color="secondary"
            onClick={handleClickOpenProductModal}
          >
            Cadastrar novo produto
          </Button>
        </Box>
        <DialogContent dividers style={{ paddingLeft: 8, paddingRight: 8 }}>
          <Table>
            <TableBody>
              {!products.length && (
                <TableRow>
                  <TableCell>
                    <Box
                      display="flex"
                      flexDirection="column"
                      p={2}
                      width="100%"
                      borderRadius={8}
                    >
                      <Box
                        component="strong"
                        fontWeight="normal"
                        fontSize="0.88rem"
                      >
                        Nenhum produto encontrado.
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell colSpan={2}>
                  <Box>
                    <Box color="secondary.main">
                      Pesquise pelo nome do produto:
                    </Box>
                    <SearchForm
                      defaultValue={defaultSearchValue}
                      loading={false}
                      searchValue={(value) => handleSearch(value)}
                      searchClear={handleSearchClear}
                      // disabled={loading || !clients.length}
                    />
                  </Box>
                </TableCell>
              </TableRow>

              {!!products.length &&
                products.map((product) => {
                  return searchValue?.length ? (
                    <React.Fragment
                      key={`search-p-${product.id}-${searchValue}`}
                    >
                      {product.product_name
                        .toLowerCase()
                        .search(searchValue.toLowerCase()) === 0 && (
                        <TableRow>
                          <TableCell>
                            <Box display="flex" justifyContent="flex-start">
                              <Box fontWeight="normal" fontSize="0.88rem">
                                {product.product_name}
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box display="flex" justifyContent="flex-end">
                              <Button
                                size="small"
                                endIcon={
                                  product.selected ? undefined : <AddOutlined />
                                }
                                style={
                                  product.selected
                                    ? {
                                        paddingRight: 16,
                                        paddingLeft: 16,
                                        border: 'none',
                                        boxShadow: 'none',
                                      }
                                    : { paddingRight: 16, paddingLeft: 16 }
                                }
                                disabled={product.selected}
                                onClick={() => handleSelectProduct(product)}
                                variant={
                                  product.selected ? 'outlined' : 'contained'
                                }
                                color={
                                  product.selected ? 'secondary' : 'primary'
                                }
                              >
                                {product.selected ? 'Adicionado' : 'Adicionar'}
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ) : (
                    <TableRow key={`product-${product.id}`}>
                      <TableCell>
                        <Box display="flex" justifyContent="flex-start">
                          <Box fontWeight="normal" fontSize="0.88rem">
                            {product.product_name}
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box display="flex" justifyContent="flex-end">
                          <Button
                            size="small"
                            endIcon={
                              product.selected ? undefined : <AddOutlined />
                            }
                            style={
                              product.selected
                                ? {
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    border: 'none',
                                    boxShadow: 'none',
                                  }
                                : { paddingRight: 16, paddingLeft: 16 }
                            }
                            disabled={product.selected}
                            onClick={() => handleSelectProduct(product)}
                            variant={
                              product.selected ? 'outlined' : 'contained'
                            }
                            color={product.selected ? 'secondary' : 'primary'}
                          >
                            {product.selected ? 'Adicionado' : 'Adicionar'}
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ width: '100%', height: 56 }}
            onClick={() => setIsOpenModal(false)}
            variant="outlined"
          >
            Fechar
          </Button>
        </DialogActions>
      </Modal>

      <CreateProductModal
        onClose={handleCloseProductModal}
        isOpen={openProductModal}
      />
    </>
  );
}
