import { Box } from '@material-ui/core';

type TableItemScheduleData = {
  title: string;
  value?: string | number | React.ReactNode;
};

export function TableItemSchedule({ title, value }: TableItemScheduleData) {
  if (!value) return <></>;

  return (
    <Box display="flex" flexDirection="row" alignItems="center" width="100%">
      <Box fontSize="0.88rem" fontWeight="bold" color="secondary.dark">
        {title}:
      </Box>
      <Box ml={1}>{value}</Box>
    </Box>
  );
}
