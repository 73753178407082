import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  table: {
    width: '100%',
    textAlign: 'left',
    borderCollapse: 'collapse',
    border: `1px solid ${theme.palette.secondary.main}`,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },

    '& thead tr': {
      color: theme.palette.primary.dark,
      fontWeight: 'normal',
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    '& thead th': {
      fontSize: '0.95rem',
      padding: '6px 14px',
      textAlign: 'left',
      verticalAlign: 'top',
      border: `1px solid ${theme.palette.secondary.main}`,
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    '& tbody th, tbody td, tfoot td': {
      fontSize: '0.95rem',
      padding: '6px 14px',
      border: `1px solid ${theme.palette.secondary.main}`,
      textAlign: 'left',
    },
    '& thead th:first-child': {
      borderTopLeftRadius: theme.spacing(1),
    },
    '& thead th:last-child': {
      borderTopRightRadius: theme.spacing(1),
    },
  },
}));
