import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import {
  CrownIcon,
  PeoplePremiumIcon,
} from '../../../../shared/components/Icons';
import { usePremium } from '../../../../shared/hooks/usePremium';

const useStyles = makeStyles((theme: Theme) => ({
  becomePremium: {
    cursor: 'pointer',
    border: 'none',
    backgroundColor: '#DF7664',
    boxShadow: theme.shadows[5],
    [theme.breakpoints.up(460)]: {
      maxWidth: '600px',
    },
    [theme.breakpoints.down(460)]: {
      maxWidth: '400px',
    },
  },
}));

export function BePremium() {
  const classes = useStyles();
  const history = useHistory();
  const isPremium = usePremium();

  if (isPremium === undefined || isPremium) return <></>;

  return (
    <Box
      component="button"
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      className={classes.becomePremium}
      borderRadius="6px"
      textAlign="center"
      minWidth="250px"
      position="relative"
      mb={2}
      mx="auto"
      onClick={() => history.push('/become-premium')}
    >
      <Box display="flex" alignItems="flex-start" ml={1} color="#FFFFFF">
        <PeoplePremiumIcon style={{ fontSize: '3.1rem' }} />
      </Box>
      <Box mb={1} mt={1} ml={2} mr={1} color="#FFFFFF">
        <Typography
          style={{ fontSize: '20px', fontWeight: 'bold' }}
          component="div"
        >
          TORNE-SE PREMIUM
          <CrownIcon
            style={{
              marginLeft: 8,
              color: '#FFDF6F',
            }}
          />
        </Typography>
        <small style={{ fontSize: '13px' }}>
          Contrate agora por R$ 9,90/mês ou R$ 99,00/anual <br />
          Tenha acesso a todas funcionalidades! <br />
          Clique aqui
        </small>
      </Box>
    </Box>
  );
}
