import { Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { ENVIRONMENT } from '../../../../config/environment';
import { logoPageAuth } from '../../../../shared/assets/logoBase64';
import { Footer } from '../../../../shared/components/Footer';
import { Header } from '../../../../shared/components/Header';
import { PageContainer } from '../../../../shared/components/PageContainer';

export function AboutApp() {
  return (
    <PageContainer display="flex" flexDirection="column">
      <Header goPush="/" title="Sobre o APP" />
      <Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            src={logoPageAuth}
            alt="Logo"
            width="150px"
            style={{ marginRight: 8 }}
          />
          <Typography
            variant="caption"
            style={{
              fontSize: '17px',
              marginBottom: '13px',
              marginTop: '13px',
            }}
          >
            <p>Bem-vindos ao Bolo no Bolso!</p>
            Este é um aplicativo cuidadosamente elaborado para profissionais da
            confeitaria que, assim como eu precisam de todos os seus controles
            em um só lugar: o celular!
          </Typography>
          <img
            style={{
              width: '300px',
              borderRadius: '4px',
              marginBottom: '10px',
              marginTop: '25px',
            }}
            src="/vivi-costa.jpeg"
            alt="vivi costa"
          />
          <Typography
            variant="caption"
            style={{ fontSize: '17px', marginBottom: '13px' }}
          >
            O Bolo no bolso foi pensado em agosto de 2018 , em uma mesa de
            jantar com minha família, ao conversarmos sobre todas as planilhas
            de vendas, agendamentos, elaboração de preços e controles
            financeiros que eu já tinha desenvolvido ao longo da minha vida de
            confeiteira e o quanto eu queria tudo em um único dispositivo ou
            ferramenta. Pois bem, aqui está! Nele você vai cadastar suas
            próprias receitas, aprender a fazer ficha técnica; preço de venda de
            cada bolo doce ou salgado, incluindo o calculo de impostos, custos
            com cartões de crédito e taxas de aplicativo de entrega.
          </Typography>
          <Typography
            variant="caption"
            style={{ fontSize: '17px', marginBottom: '13px' }}
          >
            Nele, você também terá acesso a &nbsp;
            <Link style={{ color: '#FF9988' }} to="/information/vivi-tv">
              vídeos do meu canal
            </Link>
            , receitas e todas as &nbsp;
            <Link style={{ color: '#FF9988' }} to="/information/blog">
              novidades
            </Link>
            , inclusive atualizações que já estão em desenvolvimento para fazer
            a sua vida de confeiteira empresária mais prática! Como eu sempre
            digo: otimização de tempo é tudo !!
          </Typography>
        </Box>
      </Box>
      <Footer mt={3} version={ENVIRONMENT.REACT_APP_VERSION} />
    </PageContainer>
  );
}
