import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function SweetsAndDessertsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M11.3658 15.2059C7.18111 15.2059 1 14.4764 1 12.5183C1 11.2129 3.64905 10.5218 5.87578 10.2147L5.99096 10.9825C2.9196 11.4433 1.76784 12.1728 1.76784 12.5183C1.76784 13.171 5.06955 14.438 11.3658 14.438C17.6621 14.438 20.9638 13.171 20.9638 12.5183C20.9638 12.1728 19.8121 11.4433 16.7407 10.9825L16.8559 10.2147C19.0826 10.5602 21.7317 11.2129 21.7317 12.5183C21.7317 14.4764 15.5506 15.2059 11.3658 15.2059Z" />
      <path d="M11.3659 17.1257C11.2507 17.1257 11.0971 17.1257 10.9819 17.1257V16.3578C11.3659 16.3578 11.7114 16.3578 12.0953 16.3578V17.1257C11.8649 17.1257 11.5962 17.1257 11.3659 17.1257Z" />
      <path d="M10.1373 17.0873C8.67841 17.0489 7.29629 16.8953 5.99097 16.6649L6.10614 15.897C7.37308 16.1274 8.75519 16.281 10.1373 16.3194V17.0873Z" />
      <path d="M11.3658 22.8848C8.14091 22.8848 4.41689 22.1553 3.8794 20.5811L1.07678 12.8255L1.80623 12.5567L4.60885 20.3124C4.91598 21.1571 7.60343 22.0785 11.4042 22.0785C15.205 22.0785 17.8541 21.1571 18.1996 20.3124L21.0022 12.5567L21.7317 12.8255L18.8523 20.5811C18.3148 22.1553 14.5908 22.8848 11.3658 22.8848Z" />
      <path d="M18.9291 23L18.5451 22.3089C19.0826 22.0017 19.4665 21.6562 19.5817 21.349L20.4264 18.9686L21.1558 19.2373L20.3112 21.6178C20.1576 22.1169 19.6969 22.5776 18.9291 23Z" />
      <path d="M21.4654 15.9377L20.8478 17.7148L21.5731 17.9669L22.1907 16.1898L21.4654 15.9377Z" />
      <path d="M13.6694 10.2146C12.3256 10.2146 10.2141 9.25478 10.2141 8.10295C10.2141 7.64222 10.4828 7.18148 10.8667 6.95112L16.8943 3.34204L17.2782 3.99475L11.2507 7.60382C11.0587 7.719 10.9819 7.91098 10.9819 8.10295C10.9819 8.56368 12.4408 9.44675 13.6694 9.44675C15.3586 9.44675 16.7407 8.06455 16.7407 6.3752C16.7407 6.22162 16.7407 6.10644 16.7023 5.95286L17.4702 5.83768C17.5086 6.02965 17.5086 6.18323 17.5086 6.3752C17.5086 8.48689 15.7809 10.2146 13.6694 10.2146Z" />
      <path d="M15.4738 7.29668L15.0899 6.64398L21.4245 2.84293C21.6165 2.72775 21.6933 2.53578 21.6933 2.3438C21.6933 2.03665 21.4245 1.76789 21.1174 1.76789C21.0022 1.76789 20.9255 1.80628 20.8103 1.84468L16.7407 4.30192L16.3568 3.64921L20.4264 1.19197C20.6183 1.07679 20.8871 1 21.1174 1C21.8469 1 22.4611 1.61431 22.4611 2.3438C22.4611 2.80454 22.1924 3.26527 21.8085 3.49564L15.4738 7.29668Z" />
      <path d="M11.5578 7.25827L10.79 7.14308L11.1355 5.18497C11.2507 4.57066 11.7882 4.10992 12.4408 4.10992C12.6328 4.10992 12.7864 4.14832 12.9399 4.22511L14.5908 4.91621L14.2836 5.60731L12.6328 4.91621C12.556 4.87781 12.4792 4.87781 12.4024 4.87781C12.1337 4.87781 11.9033 5.06978 11.865 5.33855L11.5578 7.25827Z" />
      <path d="M9.44624 7.52704H8.67841V8.29493H9.44624V7.52704Z" />
      <path d="M9.44624 9.06281H8.67841V9.83069H9.44624V9.06281Z" />
      <path d="M8.6784 10.5986H7.91055V11.3665H8.6784V10.5986Z" />
      <path d="M10.598 10.2147H9.83017V10.9825H10.598V10.2147Z" />
      <path d="M9.83016 11.7504H9.06232V12.5183H9.83016V11.7504Z" />
      <path d="M11.7498 11.3665H10.9819V12.1344H11.7498V11.3665Z" />
      <path d="M8.29448 12.9023H7.52664V13.6702H8.29448V12.9023Z" />
      <path d="M10.9819 12.9023H10.2141V13.6702H10.9819V12.9023Z" />
      <path d="M12.9015 12.5183H12.1337V13.2862H12.9015V12.5183Z" />
    </SvgIcon>
  );
}
