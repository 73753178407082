import {
  Box,
  Checkbox,
  FormControlLabel,
  makeStyles,
  Theme,
} from '@material-ui/core';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('xs')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: 'auto',
    },
  },
  card: {
    border: `1px solid ${theme.palette.secondary.main}12`,
  },
  cardSelected: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

export type IMonth = {
  id: number;
  month: string;
  checked?: boolean;
};

interface CardsMonthsProps {
  getSelectedMonths: (months: IMonth[]) => void;
}

function CardsMonths({ getSelectedMonths }: CardsMonthsProps) {
  const classes = useStyles();

  const [allSelectedMonths, setAllSelectedMonths] = useState(false);

  const [months, setMonths] = useState<IMonth[]>([
    { id: 1, month: 'Janeiro' },
    { id: 2, month: 'Fevereiro' },
    { id: 3, month: 'Março' },
    { id: 4, month: 'Abril' },
    { id: 5, month: 'Maio' },
    { id: 6, month: 'Junho' },
    { id: 7, month: 'Julho' },
    { id: 8, month: 'Agosto' },
    { id: 9, month: 'Setembro' },
    { id: 10, month: 'Outubro' },
    { id: 11, month: 'Novembro' },
    { id: 12, month: 'Dezembro' },
  ]);

  const handleChangeCheckbox = (selectedMonth: IMonth, checked: boolean) => {
    if (checked) {
      const selectedMonths = months.filter(
        (currentMonth) =>
          currentMonth.checked === true || currentMonth.id === selectedMonth.id
      );

      getSelectedMonths(selectedMonths);
    }
    setMonths((state) =>
      state.map((currentMonth) => {
        if (currentMonth.id === selectedMonth.id) {
          return {
            ...currentMonth,
            checked,
          };
        }
        return currentMonth;
      })
    );
  };

  const handleSelectedAllMonths = (checked: boolean) => {
    setAllSelectedMonths(checked);

    if (checked) {
      const selectedMonths = months.map((month) => ({
        ...month,
        checked: true,
      }));

      setMonths(selectedMonths);
      getSelectedMonths(selectedMonths);
      return;
    }

    setMonths((state) => state.map((month) => ({ ...month, checked: false })));
    getSelectedMonths([]);
  };

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      gridGap={16}
      className={classes.container}
    >
      {months.map((item) => (
        <Box
          key={item.id}
          pl={1}
          borderRadius={4}
          className={clsx(item?.checked ? classes.cardSelected : classes.card)}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={item?.checked || false}
                onChange={(_, checked) => handleChangeCheckbox(item, checked)}
                name={item.month}
                color={item.checked ? 'primary' : 'secondary'}
              />
            }
            label={item.month}
          />
        </Box>
      ))}
      <Box
        pl={1}
        borderRadius={4}
        className={clsx(
          allSelectedMonths ? classes.cardSelected : classes.card
        )}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={allSelectedMonths}
              onChange={(_, checked) => handleSelectedAllMonths(checked)}
              name="Todos os meses"
              color={allSelectedMonths ? 'primary' : 'secondary'}
            />
          }
          label="Todos os meses"
        />
      </Box>
    </Box>
  );
}

export { CardsMonths };
