import {
  Box,
  Chip,
  Theme,
  makeStyles,
  Typography,
  Divider,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { background } from '../../../../shared/assets/backgroundBase64';
import { logoPageAuth as logoBase64 } from '../../../../shared/assets/logoBase64';
import { Button } from '../../../../shared/components/Button';
import { Flex } from '../../../../shared/components/Flex';
import { useAuth } from '../../../../shared/hooks/useAuth';
import { usePremium } from '../../../../shared/hooks/usePremium';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { CancelSubscription } from '../../components/CancelSubscription';
import { SignaturePremiumItem } from '../../components/SignaturePremiumItem';

const useStyles = makeStyles((theme: Theme) => ({
  plan: {
    background: 'transparent',
    color: '#FFF',
    fontWeight: 500,
    borderColor: '#FFF',
  },
  logo: {
    width: 80,
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  planPrice: {
    '& strong': {
      fontSize: '2rem',
    },
    '& sup, & sub': {
      fontSize: '1rem',
    },
    '& sup': {
      fontSize: '1.2rem',
    },
  },
  divider: {
    borderColor: theme.border.color,
  },
  shadow: {
    color: theme.text.primary,
    boxShadow: theme.shadows[5],
    background: `url(${background}), ${theme.palette.primary.dark}`,
    backgroundSize: '60%',
    backgroundPosition: 'top left',
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    marginTop: -theme.spacing(2),
    [theme.breakpoints.up(560)]: {
      backgroundSize: '50%',
    },
    [theme.breakpoints.up(760)]: {
      backgroundSize: '30%',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: -theme.spacing(3),
      marginRight: -theme.spacing(3),
    },
  },
  title: {
    color: '#FFF',
  },
}));

export function SignaturePremium() {
  const classes = useStyles();

  const history = useHistory();
  const { signOutRedirect } = useAuth();
  const isPremium = usePremium();

  const { msgSuccess, msgError } = useSnackMessages();
  const [loading, setLoading] = useState(false);
  const [openModalCancelSubscription, setOpenModalCancelSubscription] =
    useState(false);

  useEffect(() => {
    if (!isPremium) {
      history.push('/');
    }
  }, [isPremium, history]);

  const handleClickOpenModalCancelSubscription = () => {
    setOpenModalCancelSubscription(true);
  };

  const handleCloseModalCancelSubscription = useCallback(() => {
    setOpenModalCancelSubscription(false);
  }, []);

  const handleCancelSubscription = async () => {
    setLoading(true);

    try {
      await api.delete('/signature/stripe/cancel-subscription');
      msgSuccess(
        'Assinatura cancelada com sucesso! Faça login novamente para acessar os recursos gratuitos.'
      );

      signOutRedirect();
    } catch (err: any) {
      setLoading(false);

      msgError(err?.response?.data?.message);
    }
  };

  return (
    <>
      <Flex
        flexDirection="column"
        p={2}
        borderRadius="0 0 20px 20px"
        color="primary.contrastText"
        className={classes.shadow}
      >
        <Box mb={2} textAlign="center">
          <Chip
            variant="outlined"
            size="small"
            label="Plano Premium"
            className={classes.plan}
          />
        </Box>

        <Flex
          marginX="auto"
          width="80px"
          height="80px"
          borderRadius="50%"
          bgcolor="#fff"
          boxShadow="0px 8px 4px rgba(0, 0, 0, 0.1)"
          mb={1}
          mt={1}
        >
          <img src={logoBase64} alt="Logo" width="60px" />
        </Flex>
        <Typography
          variant="h6"
          style={{ fontWeight: 'bold' }}
          className={classes.title}
        >
          Assinatura Ativa
        </Typography>
      </Flex>
      <Box display="flex" flexDirection="column" mt={3}>
        <Typography component="div">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <Box fontSize="1.4rem" color="secondary.dark" fontWeight="bold">
              Parabéns!
            </Box>
            <Box fontSize="0.85rem" color="secondary.main">
              Agora você tem acesso à todas as funcionalidades do Aplicativo!
            </Box>
          </Box>
        </Typography>

        <Box py={2}>
          <Divider className={classes.divider} />
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          mt={1}
          alignItems={['flex-start', 'center']}
        >
          <SignaturePremiumItem isBlocked={false}>
            Cadastro ilimitado de Clientes, Produtos e Fichas Técnicas.
          </SignaturePremiumItem>
          <SignaturePremiumItem isBlocked={false}>
            Calculadora de compras.
          </SignaturePremiumItem>
          <SignaturePremiumItem isBlocked={false}>
            Ajuste de lucro e contribuição.
          </SignaturePremiumItem>
          <SignaturePremiumItem isBlocked={false}>
            Agenda para gerenciar pagamentos.
          </SignaturePremiumItem>
          <SignaturePremiumItem isBlocked={false}>
            Sem propagandas no aplicativo.
          </SignaturePremiumItem>

          <SignaturePremiumItem isFree>
            Cadastro de clientes.
          </SignaturePremiumItem>
          <SignaturePremiumItem isFree>Gerar orçamentos.</SignaturePremiumItem>
          <SignaturePremiumItem isFree>FOFA.</SignaturePremiumItem>
          <SignaturePremiumItem isFree>
            Controle de estoque.
          </SignaturePremiumItem>
        </Box>

        <Box
          mt={4}
          display="flex"
          width={['100%', '300px']}
          justifyContent="flex-start"
          marginX="auto"
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClickOpenModalCancelSubscription}
            style={{ width: '100%', textTransform: 'uppercase', height: 56 }}
            styleMotion={{ width: '100%' }}
            startIcon={<Close />}
          >
            Cancelar assinatura
          </Button>
        </Box>
      </Box>

      <CancelSubscription
        isOpen={openModalCancelSubscription}
        isLoading={loading}
        onClose={handleCloseModalCancelSubscription}
        onClick={handleCancelSubscription}
      />
    </>
  );
}
