import moment from 'moment';
import { useInfiniteQuery } from 'react-query';

import { api } from '../../../services/apiClient';
import { assertIsDataResponse } from '../assertIsDataResponse';
import { Budget } from './types';

type IReactQueryResponse = {
  results: Budget[];
  next: number | undefined;
};

type IQuery = {
  pageParam: number;
  searchParam?: string;
  recipeType?: number;
};

async function getData({ pageParam = 1 }: IQuery) {
  const response = await api.get<{ results: Budget[]; last_page: number }>(
    `budgets/?page=${pageParam}`
  );

  const data: IReactQueryResponse = {
    results: response.data.results?.map((result) => {
      return {
        ...result,
        formattedCreatedAt: moment(result.created_at).format(
          'DD [de] MMMM [de] YYYY'
        ),
        formattedDate: moment(result.created_at).format('DD-MM-YYYY'),
      };
    }),
    next:
      response.data.last_page === 0 ||
      response.data.results.length === 0 ||
      response.data.last_page === pageParam
        ? undefined
        : pageParam + 1,
  };
  return data;
}

function useBudgets() {
  return useInfiniteQuery<IReactQueryResponse, Error>(
    ['budgets'],
    (queryKey) => getData({ pageParam: queryKey.pageParam }),
    {
      getNextPageParam: (nextPage) => nextPage.next,
    }
  );
}

export { useBudgets };
