import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  showButton: {
    display: 'flex',
    height: '28px',
    borderRadius: '6px',
    marginTop: '7px',
    [theme.breakpoints.down(390)]: {
      marginTop: '10px',
      height: '30px',
      fontSize: '12px',
    },
  },
  hideOnPrint: {
    '@media print': {
      display: 'none',
    },
  },

  cardTitle: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: 1.2,
    [theme.breakpoints.down(390)]: {
      fontSize: '11px',
      textAlign: 'center',
    },
  },
  cardContent: {
    fontSize: '1rem',
    fontWeight: 'bold',
    margin: 0,
    color: theme.palette.primary.main,

    '& strong': {
      fontSize: '0.78rem',
      color: theme.palette.secondary.main,
      fontWeight: 'normal',
    },
  },
  premiumColor: {
    color: theme.color.premium.main,
  },
}));
