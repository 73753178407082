import { useHistory } from 'react-router-dom';

import { cardButtonsData } from '../../../../config/cardButtonsData';
import { CardButtons } from '../../../../shared/components/CardButtons';
import { CardButtonItem } from '../../../../shared/components/CardButtons/CardButtonItem';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { useModalYourRegistration } from '../../../../shared/hooks/useModalYourRegistration';
import { usePremium } from '../../../../shared/hooks/usePremium';
import { Ads } from '../../../ads';
import { FullPageAdModal } from '../../../ads/fullPageAdModal';
import { BePremium } from '../../components/BePremium';

function Home() {
  const history = useHistory();
  const isPremium = usePremium();
  const { onOpen: onOpenYourRegistration } = useModalYourRegistration();

  function handlePushOrOpenModal(
    route: string,
    onOpenModal?: 'yourRegistration' | 'otherModal'
  ) {
    if (onOpenModal) {
      switch (onOpenModal) {
        case 'yourRegistration':
          onOpenYourRegistration();
          break;

        default:
          break;
      }
    } else {
      history.push(route);
    }
  }

  return (
    <>
      <PageContainer display="flex" flexDirection="column">
        <BePremium />

        {cardButtonsData.map((content) => {
          return (
            <CardButtons key={content.sectionName} title={content.sectionName}>
              {content.buttons.map((button) => {
                return (
                  <CardButtonItem
                    key={`${button.name}-${content.sectionName}`}
                    name={button.name}
                    premiumContent={!!button?.isPremiumRequired}
                    isDisabled={
                      button?.isPremiumRequired === true && !isPremium
                    }
                    isNew={button.isNew}
                    icon={button.icon}
                    isLoading={isPremium === undefined}
                    onClick={() =>
                      handlePushOrOpenModal(
                        `${button.toPage}`,
                        button.onOpenModal
                      )
                    }
                  />
                );
              })}
            </CardButtons>
          );
        })}
        <Ads />
        <FullPageAdModal />
      </PageContainer>
    </>
  );
}

export { Home };
