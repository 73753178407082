import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '../../../../shared/components/Button';
import { Input } from '../../../../shared/components/Form/Input';
import { InputCurrency } from '../../../../shared/components/Form/Input/InputCurrency';
import { InputDatePicker } from '../../../../shared/components/Form/Input/InputDatePicker';
import { InputRadio } from '../../../../shared/components/Form/Input/InputRadio';
import { Modal } from '../../../../shared/components/Modal';
import { ICashflowDetail } from '../../../../shared/hooks/reactQuery/useCashFlowDetail/types';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';

type FormValues = {
  title: string;
  description?: string;
  value: number;
  date: string;
  cash_flow_type_id: string;
  cash_flow_type_output_id?: string;
};

const schema = yup.object().shape({
  title: yup
    .string()
    .required('Título é obrigatório.')
    .max(254, 'No máximo 40 digitos.'),
  description: yup.string(),
  value: yup.string(),
  date: yup.string().nullable(),
  cash_flow_type_id: yup.string().required('Selecione o tipo do fluxo.'),
  cash_flow_type_output_id: yup.string(),
});

interface ModalEditCashFlowProps {
  data: ICashflowDetail;
  onClose: () => void;
  onCloseSuccess: () => void;
  isOpen: boolean;
}

export function ModalEditCashFlow({
  data,
  onClose,
  onCloseSuccess,
  isOpen,
}: ModalEditCashFlowProps) {
  const { msgSuccess, msgError } = useSnackMessages();

  const [loading, setLoading] = useState(false);

  const [selectedCashFlowType, setSelectedCashFlowType] = useState<string>(
    String(data.cash_flow_type_id)
  );

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      title: data.title,
      description: data.description || '',
      date: moment(data.date).format(),
      value: Number(data.value),
      cash_flow_type_id: String(data.cash_flow_type_id),
      cash_flow_type_output_id: data.cash_flow_type_id
        ? String(data.cash_flow_type_output_id)
        : '',
    },
  });

  const onSubmit = useCallback(
    async ({
      title,
      description,
      value,
      date,
      cash_flow_type_id,
      cash_flow_type_output_id,
    }: FormValues) => {
      setLoading(true);

      if (cash_flow_type_id === '2' && !cash_flow_type_output_id) {
        msgError('Ops! Você deve selecionar o tipo de fluxo de saída');
        setLoading(false);
        return;
      }

      try {
        const formatData = {
          title,
          description,
          value: Number(value.toString().replace('R$', '').replace(',', '.')),
          cash_flow_type_id,
          cash_flow_type_output_id:
            cash_flow_type_id === '2' && cash_flow_type_output_id
              ? cash_flow_type_output_id
              : undefined,
          date: moment(date).format('YYYY-MM-DDTHH:mm:ssZ'),
        };

        await api.put(`/sales/cash-flow/${data.id}`, formatData);

        msgSuccess('Fluxo de caixa atualizado com sucesso!');

        reset();
        setLoading(false);
        onCloseSuccess();
      } catch (err: any) {
        setLoading(false);
        msgError(err?.response?.data?.message || err?.response?.data?.error);
      }
    },
    [msgError, msgSuccess, reset, onCloseSuccess, data.id]
  );

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal title="Atualizar Fluxo de Caixa" open={isOpen} onClose={handleClose}>
      <DialogContent dividers>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column" width="100%">
            <Input
              name="title"
              type="text"
              control={control}
              error={errors}
              variant="outlined"
              label="Título"
              fullWidth
              required
              disabled={loading}
            />

            <Box mb={2} />

            <Input
              name="description"
              control={control}
              error={errors}
              variant="outlined"
              label="Descrição"
              fullWidth
              disabled={loading}
              inputProps={{ maxLength: 500 }}
              placeholder="500 caracteres"
            />
            <Box mb={2} />

            <InputCurrency
              name="value"
              control={control}
              error={errors}
              variant="outlined"
              label="Valor total"
              fullWidth
              required
              disabled={loading}
            />

            <InputDatePicker
              name="date"
              label="Data de entrada ou saída"
              variant="outlined"
              control={control}
              error={errors}
              disabled={loading}
              required
            />
            <Box mb={2} />

            {data.cash_flow_type_id && (
              <InputRadio
                name="cash_flow_type_id"
                control={control}
                error={errors}
                required
                label="Tipo do fluxo"
                options={[
                  { id: 1, name: 'Entrada' },
                  { id: 2, name: 'Saída' },
                ]}
                getSelectValue={(value) => setSelectedCashFlowType(value)}
              />
            )}
            <Box mb={2} />

            {selectedCashFlowType === '2' && (
              <>
                <InputRadio
                  name="cash_flow_type_output_id"
                  control={control}
                  error={errors}
                  required
                  label="Tipo do fluxo de saída"
                  options={[
                    { id: 1, name: 'Variável' },
                    { id: 2, name: 'Fixo' },
                  ]}
                />
                <Box mb={2} />
              </>
            )}
          </Box>

          <DialogActions style={{ padding: 0 }}>
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              gridGap={8}
              mt={4}
              alignItems="stretch"
            >
              <Button
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
                style={{ width: '100%' }}
              >
                {isSubmitting ? 'Salvando...' : 'Salvar'}
              </Button>
              <Button
                onClick={handleClose}
                color="primary"
                variant="outlined"
                size="large"
                style={{ width: '100%' }}
                disabled={isSubmitting}
              >
                Cancelar
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </DialogContent>
    </Modal>
  );
}
