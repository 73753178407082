import { Box, makeStyles, Theme } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';

import { usePremium } from '../../shared/hooks/usePremium';
import { api } from '../../shared/services/apiClient';

interface AdProps {
  id: number;
  image: string;
  url_redirect: string;
  image_url: string;
  title: string;
  type: string;
  status: boolean;
}

export function Ads() {
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      width: '100%',
      display: 'flex',
      maxWidth: '100%',
      bottom: 100,
      color: theme.palette.primary.contrastText,
    },
  }));

  const classes = useStyles();
  const isPremium = usePremium();
  const [footerAd, setFooterAd] = useState<AdProps[]>([]);
  const [selectedAds, setSelectedAds] = useState<AdProps>();

  const sortRandom = useCallback(() => {
    setSelectedAds(
      (state) => footerAd[Math.floor(Math.random() * footerAd.length)] || state
    );
  }, [footerAd]);

  useEffect(() => {
    if (footerAd.length) {
      setInterval(sortRandom, 30000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortRandom]);

  useEffect(() => {
    api.get<{ results: AdProps[] }>('/ads').then((response) => {
      const adsFooter = response.data.results.filter(
        (item) => item.type === 'footer' && item.status === true
      );

      if (adsFooter.length) {
        setFooterAd(adsFooter);
        setSelectedAds(adsFooter[Math.floor(Math.random() * adsFooter.length)]);
      }
    });
  }, []);

  return (
    <Box>
      {!isPremium && selectedAds && (
        <Box width="100%" display="flex">
          <Box width="100%" className={classes.root} key={selectedAds.id}>
            <a
              target="_blank"
              href={selectedAds.url_redirect}
              rel="noreferrer"
              style={{ width: '100%' }}
            >
              <img
                src={selectedAds.image_url}
                alt={selectedAds.title}
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '100%',
                  objectFit: 'contain',
                  objectPosition: 'center',
                  borderRadius: 4,
                }}
              />
            </a>
          </Box>
        </Box>
      )}
    </Box>
  );
}
