import { Box, useMediaQuery } from '@material-ui/core';
import { ApexOptions } from 'apexcharts';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useParams, useHistory } from 'react-router-dom';

import { Header } from '../../../../shared/components/Header';
import { Loading } from '../../../../shared/components/Loading';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { useBalancePoint } from '../../../../shared/hooks/reactQuery/useBalancePoint';
import { useSalesAdjustments } from '../../../../shared/hooks/reactQuery/useSalesAdjustments';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { useTheme } from '../../../../shared/hooks/useTheme';
import { formatPrice } from '../../../../shared/utils/formatPrice';
import { CardsTopCashFlow } from '../../components/CardsTopCashFlow';
import { DateNavigation } from '../../components/DateNavigation';
import { TableMonthsBalancePoint } from '../../components/TableMonthsBalancePoint';

type SeriesData = {
  name: string;
  data: number[];
};

type MonthBalancePoint = {
  month: string;
  balance_point: string;
  fixed_cost: string | number;
  profit_margin?: string | number;
  contribution?: string | number;
};

type CardsTop = {
  input: string;
  output: string;
  balance: string;
  isNegativeBalance?: boolean;
};

export function CashFlowBalancePoint() {
  const isNotMobile = useMediaQuery('(min-width:600px)');
  const { isDark } = useTheme();
  const { year } = useParams<{ year: string }>();
  const history = useHistory();
  const { msgError } = useSnackMessages();

  const [selectedDate] = useState(
    moment(
      new Date(new Date().setFullYear(Number(year) || new Date().getFullYear()))
    )
  );

  const { data, status, isFetching } = useBalancePoint(
    selectedDate.format('YYYY')
  );

  const { data: salesAdjustments, error: errorSalesAdjustments } =
    useSalesAdjustments();

  if (errorSalesAdjustments) {
    msgError(errorSalesAdjustments?.message);
  }

  const [cardsTop, setCardsTop] = useState<CardsTop>(() => {
    return {
      input: '0',
      output: '0',
      balance: '0',
    };
  });
  const [options, setOptions] = useState<ApexOptions>(() => {
    return {
      chart: {
        height: 380,
        width: '100%',
        type: 'bar',
        foreColor: isDark ? '#FFFFFF' : '#666666',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          s̶t̶a̶r̶t̶i̶n̶g̶S̶h̶a̶p̶e̶: 'flat',
          e̶n̶d̶i̶n̶g̶S̶h̶a̶p̶e̶: 'flat',
          borderRadius: 0,
          columnWidth: '70%',
          barHeight: '70%',
          distributed: false,
          rangeBarOverlap: true,
          rangeBarGroupRows: false,
          colors: {
            ranges: [
              {
                from: 0,
                to: 0,
                color: undefined,
              },
            ],
            backgroundBarColors: [],
            backgroundBarOpacity: 1,
            backgroundBarRadius: 0,
          },
          dataLabels: {
            position: 'top',
            maxItems: 100,
            hideOverflowingLabels: true,
            orientation: 'vertical',
          },
        },
      },
      colors: ['#39b33f', '#B54D54'],
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        theme: isDark ? 'dark' : 'light',
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      yaxis: {
        labels: {
          formatter: (value): string => {
            return `R$ ${formatPrice(value)}`;
          },
        },
      },
      xaxis: {
        categories: [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro',
        ],
      },
      legend: {
        position: 'top',
        verticalAlign: 'top',
        fontSize: '14px',
        containerMargin: {
          bottom: 35,
          left: 60,
        },
      },
      fill: {
        opacity: 1,
        type: 'solid',
      },
    };
  });
  const [series, setSeries] = useState<SeriesData[]>(() => {
    return [
      {
        name: 'Entradas',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: 'Saídas',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ];
  });

  const [monthsBalancePoint, setMonthsBalancePoint] = useState<
    MonthBalancePoint[]
  >([]);

  useEffect(() => {
    setOptions((state) => ({
      ...state,
      chart: {
        ...state.chart,
        foreColor: isDark ? '#FFFFFF' : '#666666',
      },
      tooltip: {
        ...state.tooltip,
        theme: isDark ? 'dark' : 'light',
      },
    }));

    if (data?.results) {
      const inputs = data.results.map((result) => {
        return Number(result.input);
      });
      const outputs = data.results.map((result) => {
        return Number(result.output);
      });

      setSeries([
        {
          name: 'Entradas',
          data: inputs,
        },
        {
          name: 'Saídas',
          data: outputs,
        },
      ]);

      const inputTotal =
        inputs.reduce((accumulator, currentValue) => {
          return accumulator + Number(currentValue);
        }, 0) || 0;

      const outputTotal =
        outputs.reduce((accumulator, currentValue) => {
          return accumulator + Number(currentValue);
        }, 0) || 0;

      const balanceTotal =
        inputTotal > outputTotal
          ? inputTotal - outputTotal
          : outputTotal - inputTotal;

      setCardsTop({
        input: formatPrice(inputTotal),
        output: formatPrice(outputTotal),
        balance: formatPrice(balanceTotal),
        isNegativeBalance: inputTotal < outputTotal,
      });

      const margin = salesAdjustments?.result
        ? (Number(salesAdjustments?.result?.profit_margin) +
            Number(salesAdjustments?.result?.contribution)) /
          100
        : 1;
      setMonthsBalancePoint(
        data.results.map((item) => {
          return {
            month: moment()
              .set('month', item.month - 1)
              .format('MMMM'),
            balance_point:
              margin > 0
                ? formatPrice(
                    Number((Number(item.fixed_cost) / margin).toFixed(2))
                  )
                : formatPrice(Number(Number(item.fixed_cost).toFixed(2))),
            fixed_cost: item.fixed_cost,
            profit_margin: salesAdjustments?.result.profit_margin,
            contribution: salesAdjustments?.result.contribution,
          };
        })
      );
    }
  }, [isDark, data?.results, salesAdjustments?.result]);

  const handlePrevNewDate = useCallback(
    (momentDate: Moment) => {
      const newPrevDate = momentDate.set('year', momentDate.year() - 1);
      history.push(
        `/sales/cash-flow/balance-point/${newPrevDate.format('YYYY')}`
      );
    },
    [history]
  );

  const handleNextNewDate = useCallback(
    (momentDate: Moment) => {
      const newNextDate = momentDate.set('year', momentDate.year() + 1);
      history.push(
        `/sales/cash-flow/balance-point/${newNextDate.format('YYYY')}`
      );
    },
    [history]
  );

  return (
    <PageContainer display="flex" flexDirection="column">
      <Header
        title="Voltar"
        goBack
        goPush="/sales/cash-flow"
        isLoading={isFetching}
      />

      {status === 'loading' && <Loading />}

      <DateNavigation
        format="YYYY"
        currentDate={selectedDate}
        prevDate={() => handlePrevNewDate(selectedDate)}
        nextDate={() => handleNextNewDate(selectedDate)}
      />

      <CardsTopCashFlow
        input={cardsTop.input}
        output={cardsTop.output}
        balance={cardsTop.balance}
        isNegativeBalance={cardsTop.isNegativeBalance}
      />

      <Box
        maxWidth="100%"
        style={{ overflowX: 'auto' }}
        my={3}
        py={2}
        borderRadius={8}
        bgcolor="background.paper"
      >
        <Chart
          options={options}
          series={series}
          type="bar"
          width={isNotMobile ? '100%' : 700}
          height={320}
        />
      </Box>

      <TableMonthsBalancePoint data={monthsBalancePoint} />
    </PageContainer>
  );
}
