import {
  Box,
  Slider,
  SliderProps,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ReportProblemOutlined as Error } from '@material-ui/icons';
import { Controller } from 'react-hook-form';

const PrettoSlider = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  mark: {
    height: 4,
    width: 1,
  },
}))(Slider);

type Props = SliderProps & {
  label?: string;
  control: any;
  name: string;
  error?: any;
  help?: string;
  required?: boolean;
};

export function InputSlider({
  label,
  control,
  name,
  error = undefined,
  help,
  required,
  ...rest
}: Props) {
  const valuetext = (value: number) => {
    return `${value}°C`;
  };

  return (
    <Box display="flex" flexDirection="column">
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <>
            {label && (
              <Typography component="div">
                <Box color="text.secondary">{label}</Box>
              </Typography>
            )}
            <Box px={2}>
              <PrettoSlider
                getAriaValueText={valuetext}
                aria-labelledby={`${name}-discrete-slider`}
                valueLabelDisplay="auto"
                marks
                value={field.value || 0}
                defaultValue={field.value || 0}
                onChangeCommitted={(_, value) => field.onChange(value)}
                {...rest}
              />
              {/* <PrettoSlider
                defaultValue={field.value}
                getAriaValueText={valuetext}
                onChangeCommitted={(_, value) => field.onChange(value)}
                aria-labelledby={`${name}-discrete-slider`}
                valueLabelDisplay="auto"
                step={2}
                marks
                min={0}
                max={30}
                {...rest}
              /> */}
            </Box>
          </>
        )}
      />
      {name && error?.[name]?.message && (
        <Typography
          component="p"
          variant="subtitle2"
          color="error"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Error color="error" style={{ width: 18, marginRight: 4 }} />
          {error[name]?.message}
        </Typography>
      )}
    </Box>
  );
}
