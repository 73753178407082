import { createContext, ReactNode, useState, useEffect } from 'react';
import { IBudgetProps } from '../hooks/reactQuery/useBudgets/types';

interface BudgetProviderProps {
  children: ReactNode;
}

interface BudgetContextData {
  budgetData: IBudgetProps | null;
  updateBudgetData: (value: IBudgetProps | null) => void;
}

export const BudgetContext = createContext({} as BudgetContextData);

export function BudgetProvider({ children }: BudgetProviderProps) {
  const [budgetData, setBudgetData] = useState<IBudgetProps | null>(null);

  const updateBudgetData = (newData: IBudgetProps | null) => {
    setBudgetData(newData);
  };

  return (
    <BudgetContext.Provider
      value={{
        budgetData,
        updateBudgetData,
      }}
    >
      {children}
    </BudgetContext.Provider>
  );
}
