import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function ClientIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M21.4827 12.599H20.7241V7.17347C20.7241 6.03525 19.7379 5.4282 18.7896 5.27644C17.7276 5.12467 16.8552 4.66939 16.3621 4.32792C16.2862 4.55556 16.2103 4.89703 16.2103 5.27644C16.2103 6.56642 17.1965 7.17347 18.1069 7.17347V7.93229C16.5517 7.93229 15.4517 6.83201 15.4517 5.27644C15.4517 4.36586 15.869 3.60705 15.869 3.5691L16.0965 3.1897L16.4379 3.45528C16.4379 3.45528 17.5379 4.32792 18.9034 4.51762C20.4586 4.74527 21.5207 5.80761 21.5207 7.17347V12.599H21.4827Z" />
      <path d="M18.8276 11.347C17.3483 11.347 16.1724 10.1708 16.1724 8.69112V7.02173H16.931V8.69112C16.931 9.75346 17.7655 10.5882 18.8276 10.5882C19.0172 10.5882 19.2069 10.5502 19.3966 10.5123L19.6241 11.2332C19.3586 11.309 19.0931 11.347 18.8276 11.347Z" />
      <path d="M13.5172 18.5557C13.0241 18.5557 12.569 18.3281 12.3034 17.9107L9.61033 14.1926C9.45861 13.9649 9.34482 13.6993 9.34482 13.3958C9.34482 12.6749 9.95171 12.0679 10.6724 12.0679C10.8241 12.0679 10.9759 12.1058 11.0896 12.1438L10.8241 12.8646C10.7483 12.8267 10.7103 12.8267 10.6345 12.8267C10.331 12.8267 10.0655 13.0923 10.0655 13.3958C10.0655 13.5096 10.1034 13.6234 10.1793 13.7373L12.8724 17.4555C13.1 17.759 13.5931 17.8728 13.8965 17.6452L17.3483 15.4067L17.7655 16.0516L14.3138 18.2902C14.0862 18.4799 13.8207 18.5557 13.5172 18.5557Z" />
      <path d="M23 22.3498H22.2414V14.7616C22.2414 13.6993 21.4069 12.8646 20.3448 12.8646H18.3724C17.7276 12.8646 17.1207 13.0923 16.6276 13.4717L13.5931 15.9758L11.7345 13.8131L12.3034 13.3199L13.7069 14.9514L16.1724 12.9405C16.7793 12.4473 17.5758 12.1437 18.3724 12.1437H20.3448C21.8241 12.1437 23 13.3199 23 14.7996V22.3498Z" />
      <path d="M18.8276 10.9296H18.069V12.4852H18.8276V10.9296Z" />
      <path d="M17.3103 16.1654H16.5517V22.3497H17.3103V16.1654Z" />
      <path d="M11.7345 14.0028C11.6207 14.0028 11.4689 13.9649 11.3552 13.9269C11.1276 13.8131 10.9379 13.6234 10.8621 13.3958L9.76205 10.4364C9.5724 9.94316 9.83791 9.41199 10.2931 9.26022L11.5069 8.76699C11.7345 8.69111 12 8.69111 12.1896 8.76699C12.4172 8.88082 12.6069 9.07052 12.6827 9.29816L13.7827 12.2575C13.9724 12.7508 13.7069 13.2819 13.2517 13.4337L12.0379 13.9269C11.9621 13.9649 11.8483 14.0028 11.7345 14.0028ZM11.8862 9.44993H11.8483L10.6345 9.94316C10.5586 9.9811 10.5207 10.0949 10.5586 10.1708L11.6586 13.1302C11.6965 13.2061 11.7345 13.2061 11.7345 13.2061C11.7724 13.2061 11.8103 13.244 11.8483 13.2061L13.0621 12.7128C13.1379 12.6749 13.1758 12.5611 13.1379 12.4852L12.0379 9.52581C12 9.44993 11.962 9.44993 11.962 9.44993C11.9241 9.44993 11.8862 9.44993 11.8862 9.44993Z" />
      <path d="M12.7586 22.3498H2.13794V15.8998H2.89656V21.5909H12V17.4175H12.7586V22.3498Z" />
      <path d="M7.44828 16.2793H1.75862C1.34138 16.2793 1 15.9378 1 15.5204V14.7616C1 14.3443 1.34138 14.0028 1.75862 14.0028H10.1034V14.7616H1.75862V15.5204H7.44828V16.2793Z" />
      <path d="M3.65517 14.3822H2.89655V11.7264C2.89655 10.8917 3.5793 10.2087 4.41379 10.2087H10.2552V10.9676H4.41379C3.99654 10.9676 3.65517 11.309 3.65517 11.7264V14.3822Z" />
      <path d="M4.79311 10.5882H4.03448V7.9323C4.03448 7.09761 4.71724 6.41467 5.55173 6.41467H9.34484C10.1793 6.41467 10.8621 7.09761 10.8621 7.9323V9.56376H10.1035V7.9323C10.1035 7.51495 9.76208 7.17349 9.34484 7.17349H5.55173C5.13449 7.17349 4.79311 7.51495 4.79311 7.9323V10.5882Z" />
      <path d="M9.72412 6.7941H8.9655V5.27647C8.9655 5.04882 8.81378 4.89706 8.58619 4.89706H6.31033C6.08274 4.89706 5.93102 5.04882 5.93102 5.27647V6.7941H5.17239V5.27647C5.17239 4.63147 5.6655 4.13824 6.31033 4.13824H8.58619C9.23102 4.13824 9.72412 4.63147 9.72412 5.27647V6.7941Z" />
      <path d="M7.44827 13.244C6.95517 13.244 6.5 13.0922 6.12069 12.8266C5.74138 13.0922 5.28621 13.244 4.7931 13.244C4.07241 13.244 3.38965 12.9025 2.97241 12.3334L3.57931 11.8781C3.88276 12.2575 4.3 12.4852 4.7931 12.4852C5.21034 12.4852 5.55172 12.3334 5.85517 12.0678L6.12069 11.8022L6.3862 12.0678C6.95517 12.6369 7.94138 12.6369 8.51034 12.0678L8.77586 11.8022L9.04138 12.0678C9.26896 12.2955 9.61034 12.4472 9.95172 12.4852L9.87586 13.244C9.45862 13.206 9.07931 13.0543 8.77586 12.8266C8.39655 13.0922 7.94138 13.244 7.44827 13.244Z" />
      <path d="M8.9655 15.5204H8.20688V16.2793H8.9655V15.5204Z" />
      <path d="M2.51724 21.5909H1V22.3498H2.51724V21.5909Z" />
      <path d="M13.8966 21.5909H12.3793V22.3498H13.8966V21.5909Z" />
      <path d="M15.4138 21.5909H14.6552V22.3498H15.4138V21.5909Z" />
      <path d="M8.9655 7.93231H8.20688V8.69113H8.9655V7.93231Z" />
      <path d="M7.44827 7.93231H4.41379V8.69113H7.44827V7.93231Z" />
      <path d="M7.82759 3H7.06897V4.51763H7.82759V3Z" />
    </SvgIcon>
  );
}
