import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function NotFoundIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M10.8704 17.2C8.27319 17.2 6.14819 15.04 6.14819 12.4V8.39999H15.5926V12.4C15.5926 15.04 13.4676 17.2 10.8704 17.2ZM6.93523 9.19999V12.4C6.93523 14.6 8.70606 16.4 10.8704 16.4C13.0348 16.4 14.8056 14.6 14.8056 12.4V9.19999H6.93523Z" />
      <path d="M6.58115 11.72L6.50244 10.92C7.683 10.84 8.98161 9.99999 8.98161 8.79999H9.76865C9.76865 10.48 8.15522 11.6 6.58115 11.72Z" />
      <path d="M15.199 11.6H13.8217C10.9097 11.6 10.4768 10.08 10.4768 9.19999V8.79999H11.2638V9.19999C11.2638 10.28 12.1296 10.8 13.8217 10.8H15.199V11.6Z" />
      <path d="M18.7407 24H17.9537V22.4C17.9537 20.64 16.537 19.2 14.8056 19.2H6.93519C5.2037 19.2 3.78704 20.64 3.78704 22.4V24H3V22.4C3 20.2 4.77083 18.4 6.93519 18.4H14.8056C16.9699 18.4 18.7407 20.2 18.7407 22.4V24Z" />
      <path d="M14.412 24H13.625V21.52C13.625 21.16 13.4675 20.84 13.2314 20.64L11.5393 19.12L12.0509 18.52L13.743 20.04C14.1759 20.4 14.412 20.96 14.412 21.52V24Z" />
      <path d="M11.2638 20.4H10.4768V21.2H11.2638V20.4Z" />
      <path d="M11.2638 22H10.4768V22.8H11.2638V22Z" />
      <path d="M9.17821 19.04L8.54858 18.6L9.09951 17.8C9.25692 17.6 9.29627 17.36 9.29627 17.12V16.64H10.0833V17.12C10.0833 17.52 9.96525 17.92 9.72914 18.28L9.17821 19.04Z" />
      <path d="M12.5623 19.04L12.0114 18.24C11.7753 17.92 11.6572 17.52 11.6572 17.08V16.6H12.4443V17.08C12.4443 17.32 12.523 17.56 12.641 17.76L13.1919 18.56L12.5623 19.04Z" />
      <path d="M15.5927 8.8H14.8057V6.88L15.1205 6.8C16.0649 6.56 16.7733 5.64 16.7733 4.6C16.7733 3.4 15.7895 2.4 14.6089 2.4C14.2547 2.4 13.9399 2.48 13.6251 2.64L13.2316 2.84L13.1135 2.4C12.7987 1.44 11.8936 0.8 10.8705 0.8C9.84733 0.8 8.98159 1.44 8.62742 2.4L8.50937 2.84L8.11585 2.64C7.80103 2.48 7.48622 2.4 7.13205 2.4C5.9515 2.4 4.9677 3.4 4.9677 4.6C4.9677 5.64 5.67603 6.6 6.62048 6.8L6.93529 6.88V8.8H6.14826V7.48C5.00705 7.04 4.18066 5.88 4.18066 4.6C4.18066 2.96 5.51863 1.6 7.13205 1.6C7.44687 1.6 7.76168 1.64 8.0765 1.76C8.58807 0.68 9.68992 0 10.8705 0C12.051 0 13.1529 0.68 13.6645 1.76C13.9793 1.64 14.2941 1.6 14.6089 1.6C16.2223 1.6 17.5603 2.96 17.5603 4.6C17.5603 5.92 16.7339 7.08 15.5927 7.48V8.8Z" />
      <path d="M10.8705 15.6C10.2015 15.6 9.68994 15.08 9.68994 14.4C9.68994 13.72 10.2015 13.2 10.8705 13.2C11.5395 13.2 12.0511 13.72 12.0511 14.4C12.0511 15.08 11.5395 15.6 10.8705 15.6ZM10.8705 14C10.6344 14 10.477 14.16 10.477 14.4C10.477 14.64 10.6344 14.8 10.8705 14.8C11.1066 14.8 11.264 14.64 11.264 14.4C11.264 14.16 11.1066 14 10.8705 14Z" />
      <path d="M19.0557 9.47999H19.9608V10.4H19.0557V9.47999ZM19.095 4.07999H19.9608V8.79999H19.095V4.07999Z" />
    </SvgIcon>
  );
}
