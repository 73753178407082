export const ENVIRONMENT = {
  REACT_APP_VERSION: process.env.REACT_APP_VERSION || '',
  REACT_APP_NAME: 'Bolo No Bolso',
  REACT_APP_FULL_NAME: 'Que seja doce o dia, a vida e a alma.',
  REACT_APP_STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY || '',
  ...(process.env.NODE_ENV === 'development'
    ? {
        REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL,
        REACT_APP_URL: 'http://localhost:3000',
        REACT_APP_BASENAME: null,
      }
    : {}),
  ...(process.env.NODE_ENV === 'test'
    ? {
        REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL,
        REACT_APP_URL: process.env.REACT_APP_URL,
        REACT_APP_BASENAME: null,
      }
    : {}),
  ...(process.env.NODE_ENV === 'production'
    ? {
        REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL,
        REACT_APP_URL: process.env.REACT_APP_URL,
        REACT_APP_BASENAME: null,
      }
    : {}),
};
