import { Box, Typography } from '@material-ui/core';
import { ReactNode } from 'react';

interface BackupButtonProps {
  onClick: () => void;
  icon: ReactNode;
  title: string;
  disabled?: boolean;
}

export function BackupButton({
  onClick,
  icon,
  title,
  disabled = false,
}: BackupButtonProps) {
  return (
    <Box
      p={2}
      m={2}
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      bgcolor="background.paper"
      borderRadius={8}
      onClick={!disabled ? onClick : undefined}
      maxWidth={130}
      width="100%"
      style={{
        cursor: disabled ? 'auto' : 'pointer',
        opacity: disabled ? 0.4 : 1,
      }}
    >
      {icon}
      <Box mt={1} textAlign="center">
        <Typography style={{ width: '100%' }}>{title}</Typography>
      </Box>
    </Box>
  );
}
