import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControlLabel, Switch } from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';
import { Alert, Skeleton } from '@material-ui/lab';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '../../../../shared/components/Button';
import { ButtonLarge } from '../../../../shared/components/Button/ButtonLarge';
import { Header } from '../../../../shared/components/Header';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { useSalesAdjustments } from '../../../../shared/hooks/reactQuery/useSalesAdjustments';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { useUserAuthenticated } from '../../../../shared/hooks/useUserAuthenticated';
import { api } from '../../../../shared/services/apiClient';
import { SalesAdjustmentsCard } from '../../components/SalesAdjustmentsCard';
import { SalesAdjustmentsSubCard } from '../../components/SalesAdjustmentsCard/SalesAdjustmentsSubCard';

const schemaValidate = yup.object().shape({
  profit_margin: yup.string(),
  contribution: yup.string(),
  sale_rate_with_cards: yup.string(),
  sales_fee_per_me: yup.string(),
  application_fee: yup.string(),
});

type FormValues = {
  profit_margin: string | number;
  contribution: string | number;
  sale_rate_with_cards: string | number;
  sales_fee_per_me: string | number;
  application_fee: string | number;
};

export function SalesAdjustments() {
  const { user } = useUserAuthenticated();
  const { msgError, msgSuccess } = useSnackMessages();

  const [isMEI, setIsMEI] = useState(true);
  const [onEdit, setOnEdit] = useState(false);
  const [salesOnTheCard, setSalesOnTheCard] = useState(false);
  const [useDeliveryApps, setUseDeliveryApps] = useState(false);

  const { data, error, refetch, status, isFetching } = useSalesAdjustments();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(schemaValidate),
    defaultValues: {
      profit_margin: '',
      contribution: '',
      sale_rate_with_cards: '',
      sales_fee_per_me: '',
      application_fee: '',
    },
    mode: 'onChange',
  });

  const setDefaultValues = useCallback(
    ({
      application_fee,
      contribution,
      profit_margin,
      sale_rate_with_cards,
      sales_fee_per_me,
    }: FormValues) => {
      setValue('application_fee', application_fee);
      setValue('contribution', contribution);
      setValue('profit_margin', profit_margin);
      setValue('sale_rate_with_cards', sale_rate_with_cards);
      setValue('sales_fee_per_me', sales_fee_per_me);

      setIsMEI(!sales_fee_per_me || sales_fee_per_me === '0.0');

      setSalesOnTheCard(
        !!sale_rate_with_cards && sale_rate_with_cards !== '0.0'
      );

      setUseDeliveryApps(!!application_fee && application_fee !== '0.0');
    },
    [setValue]
  );

  useEffect(() => {
    if (user?.email) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email]);

  useEffect(() => {
    if (data?.result) {
      setDefaultValues(data.result);
    }
  }, [data?.result, setDefaultValues]);

  const onSubmit = async ({
    application_fee,
    contribution,
    profit_margin,
    sale_rate_with_cards,
    sales_fee_per_me,
  }: FormValues) => {
    const applicationFee = useDeliveryApps ? Number(application_fee) : 0;
    const saleRateWithCards = salesOnTheCard ? Number(sale_rate_with_cards) : 0;
    const salesFeePerMe = !isMEI ? Number(sales_fee_per_me) : 0;

    const total =
      applicationFee +
      saleRateWithCards +
      salesFeePerMe +
      Number(contribution || 0) +
      Number(profit_margin || 0);

    console.log(
      'test',
      {
        application_fee: applicationFee,
        contribution,
        profit_margin,
        sale_rate_with_cards: saleRateWithCards,
        sales_fee_per_me: salesFeePerMe,
      },
      total
    );
    if (total >= 100) {
      msgError(
        'Valores inválidos! A soma de todas as porcentagens não devem ser maior ou igual a 100%.'
      );
      return;
    }

    const formatData = {
      application_fee: applicationFee,
      contribution,
      profit_margin,
      sale_rate_with_cards: saleRateWithCards,
      sales_fee_per_me: salesFeePerMe,
    };

    api
      .post('sales/sales-adjustment', formatData)
      .then(() => {
        msgSuccess('Ajuste de vendas atualizado com sucesso!');
      })
      .catch((err: any) => {
        msgError(err?.response?.data?.message || err?.response?.data?.error);
      })
      .finally(() => {
        setOnEdit(false);

        refetch();
      });
  };

  return (
    <PageContainer display="flex" flexDirection="column">
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Header
          goPush="/"
          title="Ajustes de preço de venda"
          isLoading={isFetching}
        >
          {onEdit && (
            <Button
              size="medium"
              variant="contained"
              color="primary"
              startIcon={<AddOutlined />}
              type="submit"
              disabled={isSubmitting}
            >
              Salvar
            </Button>
          )}
          {!onEdit && (
            <Button
              size="medium"
              variant="contained"
              color="primary"
              startIcon={<AddOutlined />}
              onClick={() => setOnEdit(true)}
            >
              Editar
            </Button>
          )}
        </Header>

        {error && <Alert severity="error">{error.message}</Alert>}

        {status !== 'loading' && (
          <>
            {/* INPUTs */}
            <SalesAdjustmentsCard
              title="Margem de lucro (usado para o cálculo do Ponto de Equilíbrio)"
              value={data?.result?.profit_margin}
              name="profit_margin"
              control={control}
              isEditing={onEdit}
              error={errors?.profit_margin?.message as string}
              help="Margem de lucro é receita (tudo o que vendeu) menos o que saiu (custos totas + impostos). Não coloque uma porcentagem muito alta, para que o preço de venda não fique exagerado em relação ao mercado."
            />

            <SalesAdjustmentsCard
              title="Margem de Contribuição (usado para o cálculo do Ponto de Equilíbrio)"
              value={data?.result?.contribution}
              name="contribution"
              control={control}
              isEditing={onEdit}
              error={errors?.contribution?.message as string}
              help="Margem de contribuição é uma porcentagem destinada do que se vendeu para Contribuir com os custos fixos. No aplicativo ela é somada com a margem de lucro que deseja para formação de preço. Assim cada produto que vender dali sairá uma porcentagem para ajudar os custos fixos."
            />

            <SalesAdjustmentsCard
              title="Faço vendas no cartão?"
              control={control}
              customInput={
                <FormControlLabel
                  control={<Switch color="primary" />}
                  label={salesOnTheCard ? 'SIM' : 'NÃO'}
                  labelPlacement="start"
                  checked={salesOnTheCard}
                  onChange={(_, checked) => setSalesOnTheCard(checked)}
                  name="salesOnTheCard"
                  disabled={!onEdit}
                />
              }
            >
              {salesOnTheCard && (
                <SalesAdjustmentsSubCard
                  title="Taxa de venda com cartões"
                  value={data?.result?.sale_rate_with_cards}
                  name="sale_rate_with_cards"
                  control={control}
                  isEditing={onEdit}
                  error={errors?.sale_rate_with_cards?.message as string}
                  required
                />
              )}
            </SalesAdjustmentsCard>

            <SalesAdjustmentsCard
              title="Sou MEI (Micro Empreendedor Individual)?"
              control={control}
              customInput={
                <FormControlLabel
                  control={<Switch color="primary" />}
                  label={isMEI ? 'SIM' : 'NÃO'}
                  labelPlacement="start"
                  checked={isMEI}
                  onChange={(_, checked) => setIsMEI(checked)}
                  name="isMEI"
                  disabled={!onEdit}
                />
              }
            >
              {!isMEI && (
                <SalesAdjustmentsSubCard
                  title="Taxa de venda por ME (Micro Empresa)"
                  value={data?.result?.sales_fee_per_me}
                  name="sales_fee_per_me"
                  control={control}
                  isEditing={onEdit}
                  error={errors?.sales_fee_per_me?.message as string}
                  required
                />
              )}
            </SalesAdjustmentsCard>

            <SalesAdjustmentsCard
              title="Usa aplicativos de entrega?"
              control={control}
              customInput={
                <FormControlLabel
                  control={<Switch color="primary" />}
                  label={useDeliveryApps ? 'SIM' : 'NÃO'}
                  labelPlacement="start"
                  checked={useDeliveryApps}
                  onChange={(_, checked) => setUseDeliveryApps(checked)}
                  name="useDeliveryApps "
                  disabled={!onEdit}
                />
              }
            >
              {useDeliveryApps && (
                <SalesAdjustmentsSubCard
                  title="Taxa do aplicativo"
                  value={data?.result?.application_fee}
                  name="application_fee"
                  control={control}
                  isEditing={onEdit}
                  error={errors?.application_fee?.message as string}
                  required
                />
              )}
            </SalesAdjustmentsCard>

            {onEdit && (
              <>
                <ButtonLarge
                  startIcon={<AddOutlined />}
                  type="submit"
                  mt={1}
                  isDisabled={isSubmitting}
                >
                  Salvar
                </ButtonLarge>

                <Button
                  disabled={isSubmitting}
                  variant="outlined"
                  color="primary"
                  style={{ width: '100%', height: 56, marginTop: 16 }}
                  onClick={() => setOnEdit(false)}
                >
                  Cancelar
                </Button>
              </>
            )}
          </>
        )}

        {status === 'loading' && (
          <Box>
            <Skeleton
              variant="rect"
              width="100%"
              height={62}
              style={{ borderRadius: 1, marginBottom: 16 }}
            />
            <Skeleton
              variant="rect"
              width="100%"
              height={62}
              style={{ borderRadius: 1, marginBottom: 16 }}
            />
            <Skeleton
              variant="rect"
              width="100%"
              height={124}
              style={{ borderRadius: 1, marginBottom: 16 }}
            />
            <Skeleton
              variant="rect"
              width="100%"
              height={124}
              style={{ borderRadius: 1, marginBottom: 16 }}
            />
            <Skeleton
              variant="rect"
              width="100%"
              height={124}
              style={{ borderRadius: 1, marginBottom: 16 }}
            />
            <Skeleton
              variant="rect"
              width="100%"
              height={124}
              style={{ borderRadius: 1, marginBottom: 16 }}
            />
          </Box>
        )}
      </Box>
    </PageContainer>
  );
}
