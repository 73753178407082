import { Box, BoxProps, makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { ReactElement, ReactNode } from 'react';

import { Button } from '../index';

const useStyles = makeStyles(() => ({
  root: {
    background: 'linear-gradient(45deg, #F9836F 30%, #F9836F 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 56,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(249, 131, 111, .3)',
  },
  label: {
    textTransform: 'inherit',
  },
}));

interface ButtonLargeProps extends BoxProps {
  startIcon?: ReactElement;
  children: ReactNode;
  onClick?: () => void;
  isDisabled?: boolean;
  styleButton?: CSSProperties;
  type?: 'button' | 'submit' | 'reset';
}

export function ButtonLarge({
  startIcon,
  children,
  onClick,
  isDisabled,
  styleButton,
  type,
  ...rest
}: ButtonLargeProps) {
  const classes = useStyles();

  return (
    <Box display="flex" width="100%" mt={3} {...rest}>
      <Button
        onClick={onClick}
        type={type}
        classes={{
          root: classes.root, // class name, e.g. `classes-nesting-root-x`
          label: classes.label, // class name, e.g. `classes-nesting-label-x`
        }}
        startIcon={startIcon}
        style={{ width: '100%', ...styleButton }}
        styleMotion={{ flex: 1 }}
        disabled={isDisabled}
      >
        {children}
      </Button>
    </Box>
  );
}
