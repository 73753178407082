import { Box } from '@material-ui/core';

export const marksSliceQuantity = [
  {
    value: 10,
    label: (
      <Box
        borderRadius={4}
        width={24}
        height={24}
        bgcolor="primary.light"
        color="primary.contrastText"
        fontSize="12px"
        fontWeight="bold"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        10
      </Box>
    ),
  },
  {
    value: 20,
    label: (
      <Box
        borderRadius={4}
        width={24}
        height={24}
        bgcolor="primary.light"
        color="primary.contrastText"
        fontSize="12px"
        fontWeight="bold"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        20
      </Box>
    ),
  },
  {
    value: 30,
    label: (
      <Box
        borderRadius={4}
        width={24}
        height={24}
        bgcolor="primary.light"
        color="primary.contrastText"
        fontSize="12px"
        fontWeight="bold"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        30
      </Box>
    ),
  },
  {
    value: 40,
    label: (
      <Box
        borderRadius={4}
        width={24}
        height={24}
        bgcolor="primary.light"
        color="primary.contrastText"
        fontSize="12px"
        fontWeight="bold"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        40
      </Box>
    ),
  },
  {
    value: 50,
    label: (
      <Box
        borderRadius={4}
        width={24}
        height={24}
        bgcolor="primary.light"
        color="primary.contrastText"
        fontSize="12px"
        fontWeight="bold"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        50
      </Box>
    ),
  },
  {
    value: 60,
    label: (
      <Box
        borderRadius={4}
        width={24}
        height={24}
        bgcolor="primary.light"
        color="primary.contrastText"
        fontSize="12px"
        fontWeight="bold"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        60
      </Box>
    ),
  },
  {
    value: 70,
    label: (
      <Box
        borderRadius={4}
        width={24}
        height={24}
        bgcolor="primary.light"
        color="primary.contrastText"
        fontSize="12px"
        fontWeight="bold"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        70
      </Box>
    ),
  },
  {
    value: 80,
    label: (
      <Box
        borderRadius={4}
        width={24}
        height={24}
        bgcolor="primary.light"
        color="primary.contrastText"
        fontSize="12px"
        fontWeight="bold"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        80
      </Box>
    ),
  },
  {
    value: 90,
    label: (
      <Box
        borderRadius={4}
        width={24}
        height={24}
        bgcolor="primary.light"
        color="primary.contrastText"
        fontSize="12px"
        fontWeight="bold"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        90
      </Box>
    ),
  },
  {
    value: 100,
    label: (
      <Box
        borderRadius={4}
        width={24}
        height={24}
        bgcolor="primary.light"
        color="primary.contrastText"
        fontSize="12px"
        fontWeight="bold"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        100
      </Box>
    ),
  },
];
