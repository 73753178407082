import { useContextSelector } from 'use-context-selector';

import { AuthContext } from '../contexts/AuthContext';

export function useUserAuthenticated() {
  const isAuthenticated = useContextSelector(
    AuthContext,
    (auth) => auth.isAuthenticated
  );
  const user = useContextSelector(AuthContext, (auth) => auth.user);
  const refreshUser = useContextSelector(
    AuthContext,
    (auth) => auth.refreshUser
  );

  return {
    isAuthenticated,
    user,
    refreshUser,
  };
}
