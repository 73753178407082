import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function TechnicalSheetIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12.9756 14.4156L12.8957 13.617C16.4091 13.1778 17.6069 9.50451 17.6069 7.90742V6.50996C17.6069 6.07076 17.7266 5.67148 17.9262 5.31214L18.3654 4.59344L19.0441 4.99272L18.605 5.71141C18.4453 5.95097 18.3654 6.23047 18.3654 6.50996V7.90742C18.3654 9.82393 17.0479 13.8965 12.9756 14.4156Z" />
      <path d="M19.1639 5.43194C19.0042 5.43194 18.8845 5.39201 18.7647 5.31216L15.1315 3.07622C14.7722 2.83666 14.6524 2.35753 14.8521 1.99818L15.411 1L16.0897 1.39927L15.5308 2.39746L19.1639 4.63339L19.683 3.71506L20.3617 4.11434L19.8427 5.03267C19.7229 5.2323 19.5632 5.35209 19.3636 5.39201C19.3236 5.43194 19.2438 5.43194 19.1639 5.43194Z" />
      <path d="M22.3579 20.6044H0V21.4029H22.3579V20.6044Z" />
      <path d="M21.1602 21.0036H20.3617V11.0218C20.3617 10.343 19.8427 9.82394 19.164 9.82394H17.7267V9.02539H19.164C20.2818 9.02539 21.1602 9.90379 21.1602 11.0218V21.0036Z" />
      <path d="M1.59699 21.0036H0.798492V11.0218C0.798492 9.90379 1.67684 9.02539 2.79474 9.02539H10.8197V9.82394H2.79474C2.11602 9.82394 1.59699 10.343 1.59699 11.0218V21.0036Z" />
      <path d="M23.9549 20.6044H23.1564V21.4029H23.9549V20.6044Z" />
      <path d="M22.3579 22.2015H11.9774V23H22.3579V22.2015Z" />
      <path d="M11.179 22.2015H9.98123V23H11.179V22.2015Z" />
      <path d="M19.1639 2.63702H18.3654V3.43557H19.1639V2.63702Z" />
      <path d="M5.58948 19.0073H1.19775V19.8058H5.58948V19.0073Z" />
      <path d="M20.7609 10.6225H17.1278V11.421H20.7609V10.6225Z" />
      <path d="M10.4603 10.6225H1.19775V11.421H10.4603V10.6225Z" />
      <path d="M3.99247 9.82395C3.11412 9.82395 2.39548 9.10526 2.39548 8.22686C2.39548 7.34845 3.11412 6.62976 3.99247 6.62976C4.87082 6.62976 5.58946 7.34845 5.58946 8.22686C5.58946 9.10526 4.87082 9.82395 3.99247 9.82395ZM3.99247 7.42831C3.5533 7.42831 3.19397 7.78766 3.19397 8.22686C3.19397 8.66606 3.5533 9.02541 3.99247 9.02541C4.43164 9.02541 4.79097 8.66606 4.79097 8.22686C4.79097 7.78766 4.43164 7.42831 3.99247 7.42831Z" />
      <path d="M7.98499 9.82395C7.10665 9.82395 6.388 9.10526 6.388 8.22686C6.388 7.34845 7.10665 6.62976 7.98499 6.62976C8.86334 6.62976 9.58199 7.34845 9.58199 8.22686C9.58199 9.10526 8.86334 9.82395 7.98499 9.82395ZM7.98499 7.42831C7.54582 7.42831 7.1865 7.78766 7.1865 8.22686C7.1865 8.66606 7.54582 9.02541 7.98499 9.02541C8.42417 9.02541 8.78349 8.66606 8.78349 8.22686C8.78349 7.78766 8.42417 7.42831 7.98499 7.42831Z" />
      <path d="M8.38422 7.10889L7.58572 6.94918L7.90512 5.43194C8.10474 4.51361 8.90325 3.83484 9.82152 3.83484V4.63339C9.26257 4.63339 8.78348 5.03266 8.6637 5.59165L8.38422 7.10889Z" />
      <path d="M4.35182 7.18874L3.63318 6.86932L3.79288 6.50997C4.4716 4.91288 6.0686 3.83484 7.82529 3.83484H10.3805V4.63339H7.82529C6.388 4.63339 5.07047 5.47186 4.51152 6.82939L4.35182 7.18874Z" />
      <path d="M9.54205 16.2124H8.78348C7.62565 16.2124 6.78723 15.4537 6.78723 14.4156C6.78723 13.3775 7.54581 12.6189 8.62378 12.6189C9.22265 12.6189 9.4622 12.7387 10.7398 13.3775L10.3805 14.0962C9.10288 13.4574 8.9831 13.4174 8.62378 13.4174C8.02491 13.4174 7.58573 13.8167 7.58573 14.4156C7.58573 15.1343 8.22453 15.4138 8.78348 15.4138H9.54205V16.2124Z" />
      <path d="M15.1715 18.2087C13.7741 18.2087 12.217 17.7296 11.2987 17.2904L11.6181 16.5717C12.4566 16.9709 13.8939 17.4102 15.1715 17.4102C16.449 17.4102 17.7266 17.0907 18.8845 16.4918L19.2837 17.2105C18.0061 17.8494 16.6087 18.2087 15.1715 18.2087Z" />
      <path d="M20.7609 18.608H19.3236C18.565 18.608 17.9262 18.3285 17.3274 17.7296L17.8863 17.1706C18.3255 17.6098 18.7647 17.8094 19.3236 17.8094H20.7609V18.608Z" />
      <path d="M20.7609 15.813H20.3617C19.124 15.813 18.605 15.5336 18.1658 15.294C17.8464 15.1343 17.6468 15.0145 17.1278 15.0145C16.4091 15.0145 16.1296 15.1742 15.7703 15.3738C15.411 15.5735 14.9718 15.813 14.0535 15.813C13.4147 15.813 12.776 15.4537 12.2569 14.8548L12.8558 14.3357C13.2151 14.735 13.6144 14.9746 14.0136 14.9746C14.7323 14.9746 15.0117 14.8149 15.3711 14.6152C15.7304 14.4156 16.1696 14.176 17.0878 14.176C17.8065 14.176 18.1658 14.3757 18.4852 14.5753C18.8445 14.7749 19.2438 14.9746 20.2818 14.9746H20.6811V15.813H20.7609Z" />
      <path d="M5.98874 13.8167H1.19775V14.6153H5.98874V13.8167Z" />
      <path d="M10.7797 18.2087C10.62 18.2087 10.4603 18.1688 10.3405 18.0889L9.1428 17.2904C8.90325 17.1307 8.78348 16.8911 8.78348 16.6116C8.78348 16.4918 8.82341 16.372 8.86333 16.2523L10.9793 12.02L11.698 12.3793L9.58197 16.6116L10.7797 17.4102L12.776 13.4573L13.4946 13.8167L11.4984 17.7695C11.3387 18.049 11.0991 18.2087 10.7797 18.2087Z" />
      <path d="M13.2151 14.2958L12.4965 13.9365L14.0136 10.9419C14.2532 10.4628 14.3729 9.94376 14.3729 9.4247C14.1733 9.4247 14.0136 9.4247 13.814 9.46463C13.4148 9.54448 13.0954 9.78405 12.9357 10.1833L12.2569 11.8603C12.0573 12.3394 11.6181 12.6189 11.139 12.6189C10.4603 12.6189 9.94128 12.0998 9.94128 11.4211C9.94128 11.3412 9.94129 11.2614 9.98121 11.1815L10.8196 7.30855C10.9394 6.74956 11.179 6.27044 11.5383 5.95102C12.4566 5.07261 13.0554 4.71327 13.6144 4.39385C14.1334 4.07443 14.5726 3.83487 15.2513 3.1561L15.6106 2.79675L16.1696 3.35574L15.8102 3.71508C15.0916 4.43377 14.5327 4.75319 14.0136 5.07261C13.4946 5.39203 12.9356 5.71145 12.0972 6.51C11.8577 6.74956 11.698 7.06898 11.6181 7.46826L10.7797 11.3412C10.7797 11.3811 10.7797 11.3811 10.7797 11.4211C10.7797 11.6606 10.9394 11.8203 11.179 11.8203C11.3387 11.8203 11.4984 11.7405 11.5383 11.5808L12.217 9.90383C12.4566 9.26499 13.0155 8.82579 13.6942 8.70601C13.9338 8.66608 14.1334 8.66608 14.3729 8.66608C14.8121 8.66608 15.1714 9.02542 15.1714 9.46463C15.1714 10.1035 15.0117 10.7423 14.7323 11.3412L13.2151 14.2958Z" />
      <path d="M7.18649 19.0073H6.388V19.8058H7.18649V19.0073Z" />
      <path d="M8.78349 19.0073H7.98499V19.8058H8.78349V19.0073Z" />
      <path d="M10.3805 19.0073H9.58197V19.8058H10.3805V19.0073Z" />
      <path d="M11.9774 19.0073H11.179V19.8058H11.9774V19.0073Z" />
    </SvgIcon>
  );
}
