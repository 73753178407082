import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function CalculatorIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M16.8119 20.0618H7.98667C7.39832 20.0618 6.88351 19.5108 6.88351 18.8865V7.94156C6.88351 7.31718 7.36155 6.83972 7.98667 6.83972H16.8119C17.4371 6.83972 17.9151 7.31718 17.9151 7.94156V18.9599C17.9151 19.5843 17.4371 20.0618 16.8119 20.0618ZM7.98667 7.57428C7.76604 7.57428 7.61895 7.72119 7.61895 7.94156V18.8865C7.61895 19.1068 7.80281 19.3272 7.98667 19.3272H16.8119C17.0326 19.3272 17.1797 19.1803 17.1797 18.9599V7.94156C17.1797 7.72119 17.0326 7.57428 16.8119 7.57428H7.98667Z" />
      <path d="M16.0765 10.1452H8.72212V8.30884H9.45756V9.41067H15.3411V8.30884H16.0765V10.1452Z" />
      <path d="M16.0765 15.6544H15.3411V16.389H16.0765V15.6544Z" />
      <path d="M15.3411 18.5927H9.45756C9.05307 18.5927 8.72212 18.2621 8.72212 17.8581V11.9816C8.72212 11.5776 9.05307 11.2471 9.45756 11.2471H15.3411C15.7456 11.2471 16.0765 11.5776 16.0765 11.9816V14.9199H15.3411V11.9816H9.45756V17.8581H15.3411V17.1235H16.0765V17.8581C16.0765 18.2621 15.7456 18.5927 15.3411 18.5927Z" />
      <path d="M11.6639 11.6143H10.9284V18.2253H11.6639V11.6143Z" />
      <path d="M13.8702 11.6143H13.1348V18.2253H13.8702V11.6143Z" />
      <path d="M15.7088 13.4507H13.5025V14.1853H15.7088V13.4507Z" />
      <path d="M12.3993 13.4507H9.08983V14.1853H12.3993V13.4507Z" />
      <path d="M13.5025 15.6544H9.08983V16.389H13.5025V15.6544Z" />
      <path d="M19.386 2.06512H18.6505V3.16695H19.386V2.06512Z" />
      <path d="M19.386 6.10516H18.6505V7.20701H19.386V6.10516Z" />
      <path d="M19.0183 8.30885C18.3931 8.30885 17.8048 8.16194 17.2532 7.86811L17.6209 7.20701C18.0622 7.42738 18.5402 7.57429 19.0183 7.57429C20.6362 7.57429 21.96 6.25209 21.96 4.63606C21.96 3.02003 20.6362 1.69783 19.0183 1.69783C17.4003 1.69783 16.0765 3.02003 16.0765 4.63606C16.0765 5.51753 16.481 6.36227 17.1797 6.94992L16.7384 7.53756C15.8559 6.83973 15.3411 5.77463 15.3411 4.67279C15.3411 2.65276 16.9958 1 19.0183 1C21.0407 1 22.6955 2.65276 22.6955 4.67279C22.6955 6.69282 21.0407 8.30885 19.0183 8.30885Z" />
      <path d="M19.0183 6.47248C18.2093 6.47248 17.5474 5.99501 17.5474 5.37064H18.2828C18.2828 5.51755 18.577 5.73792 19.0183 5.73792C19.4595 5.73792 19.7537 5.51755 19.7537 5.37064C19.7537 5.22373 19.4595 5.00336 19.0183 5.00336C18.2093 5.00336 17.5474 4.5259 17.5474 3.90152C17.5474 3.27715 18.2093 2.79968 19.0183 2.79968C19.8272 2.79968 20.4891 3.27715 20.4891 3.90152H19.7537C19.7537 3.75461 19.4595 3.53424 19.0183 3.53424C18.577 3.53424 18.2828 3.75461 18.2828 3.90152C18.2828 4.04843 18.577 4.2688 19.0183 4.2688C19.8272 4.2688 20.4891 4.74626 20.4891 5.37064C20.4891 5.99501 19.8272 6.47248 19.0183 6.47248Z" />
      <path d="M5.04492 16.7563H4.30948V17.8581H5.04492V16.7563Z" />
      <path d="M5.04492 20.7963H4.30948V21.8982H5.04492V20.7963Z" />
      <path d="M4.6772 23C2.65474 23 1 21.3472 1 19.3272C1 17.3072 2.65474 15.6544 4.6772 15.6544C5.78036 15.6544 6.84674 16.1686 7.54541 17.0133L6.95706 17.4908C6.40548 16.793 5.55972 16.389 4.6772 16.389C3.05923 16.389 1.73544 17.7112 1.73544 19.3272C1.73544 20.9432 3.05923 22.2654 4.6772 22.2654C6.18485 22.2654 7.43509 21.1269 7.61895 19.6578L8.35439 19.7312C8.13376 21.6043 6.55257 23 4.6772 23Z" />
      <path d="M4.67719 21.1636C3.86821 21.1636 3.20631 20.6861 3.20631 20.0617H3.94175C3.94175 20.2086 4.23593 20.429 4.67719 20.429C5.11846 20.429 5.41263 20.2086 5.41263 20.0617C5.41263 19.9148 5.11846 19.6945 4.67719 19.6945C3.86821 19.6945 3.20631 19.217 3.20631 18.5926C3.20631 17.9682 3.86821 17.4908 4.67719 17.4908C5.48618 17.4908 6.14807 17.9682 6.14807 18.5926H5.41263C5.41263 18.4457 5.11846 18.2253 4.67719 18.2253C4.23593 18.2253 3.94175 18.4457 3.94175 18.5926C3.94175 18.7395 4.23593 18.9599 4.67719 18.9599C5.48618 18.9599 6.14807 19.4374 6.14807 20.0617C6.14807 20.6861 5.48618 21.1636 4.67719 21.1636Z" />
      <path d="M3.57404 7.57428C2.54443 7.57428 1.73544 6.76626 1.73544 5.73788C1.73544 4.7095 2.54443 3.90149 3.57404 3.90149C4.60366 3.90149 5.41264 4.7095 5.41264 5.73788C5.41264 6.76626 4.60366 7.57428 3.57404 7.57428ZM3.57404 4.63605C2.94892 4.63605 2.47088 5.11351 2.47088 5.73788C2.47088 6.36226 2.94892 6.83972 3.57404 6.83972C4.19917 6.83972 4.6772 6.36226 4.6772 5.73788C4.6772 5.11351 4.19917 4.63605 3.57404 4.63605Z" />
      <path d="M7.98668 2.43237H7.25124V4.63604H7.98668V2.43237Z" />
      <path d="M13.8702 4.63605H13.1348V5.37061H13.8702V4.63605Z" />
      <path d="M13.8702 1.69781H13.1348V3.90149H13.8702V1.69781Z" />
      <path d="M3.57403 11.2471H2.83859V11.9816H3.57403V11.2471Z" />
      <path d="M3.57403 8.67615H2.83859V10.5125H3.57403V8.67615Z" />
      <path d="M20.8569 9.41064H20.1214V12.7162H20.8569V9.41064Z" />
      <path d="M20.8569 13.4507H20.1214V14.1853H20.8569V13.4507Z" />
      <path d="M20.8569 14.9199H20.1214V15.6544H20.8569V14.9199Z" />
      <path d="M17.5474 18.96V18.2254C18.3564 18.2254 19.0183 17.5643 19.0183 16.7563C19.0183 15.9483 18.3564 15.2872 17.5474 15.2872V14.5526C18.7609 14.5526 19.7537 15.5443 19.7537 16.7563C19.7537 17.9683 18.7609 18.96 17.5474 18.96Z" />
      <path d="M19.386 19.6945H18.6505V21.8981H19.386V19.6945Z" />
      <path d="M20.8569 18.2254H20.1214V18.96H20.8569V18.2254Z" />
      <path d="M20.8569 19.6945H20.1214V20.429H20.8569V19.6945Z" />
    </SvgIcon>
  );
}
