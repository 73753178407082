import { Box, CircularProgress } from '@material-ui/core';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ButtonLoadMore from '../../../../shared/components/Button/ButtonLoadMore/intex';
import { CardButtons } from '../../../../shared/components/CardButtons';
import { CardButtonItem } from '../../../../shared/components/CardButtons/CardButtonItem';
import { SearchForm } from '../../../../shared/components/Form/SearchForm';
import { Header } from '../../../../shared/components/Header';
import {
  CakesAndPiesIcon,
  SavoryIcon,
  SweetsAndDessertsIcon,
} from '../../../../shared/components/Icons';
import { NoData } from '../../../../shared/components/NoData';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { useRecipes } from '../../../../shared/hooks/reactQuery/useRecipes';
import { Recipe } from '../../../../shared/hooks/reactQuery/useRecipes/types';
import { CardTechnicalSheet } from '../../../technicalSheet/components/CardTechnicalSheet';

type ButtonNavigationProps = {
  name: string;
  isPremiumRequired?: boolean;
  isNew: boolean;
  icon: ReactNode;
  toPage: string;
  onOpenModal?: 'yourRegistration' | 'otherModal';
};

export function Calculator() {
  const history = useHistory();
  const params = useParams<{ slug: string }>();
  const [searchValue, setSearchValue] = useState('');
  const [defaultSearchValue, setDefaultSearchValue] = useState('');

  const cardButtons = useMemo<ButtonNavigationProps[]>(() => {
    return [
      {
        name: 'Bolos e Tortas',

        isNew: false,
        icon: <CakesAndPiesIcon style={{ fontSize: '50px' }} />,
        toPage: '/sales/calculator/select-technical-sheet/cakes-and-pies',
      },
      {
        name: 'Doces e Sobremesas',

        isNew: false,
        icon: <SweetsAndDessertsIcon style={{ fontSize: '50px' }} />,
        toPage: '/sales/calculator/select-technical-sheet/sweets-and-deserts',
      },
      {
        name: 'Salgados',

        isNew: true,
        icon: <SavoryIcon style={{ fontSize: '50px' }} />,
        toPage: '/sales/calculator/select-technical-sheet/salvory',
      },
    ];
  }, []);

  const buttonSelected = useMemo(() => {
    const findButton = cardButtons?.find((button) =>
      button.toPage.includes(params.slug)
    );

    return findButton;
  }, [cardButtons, params.slug]);

  const recipeType = useMemo(() => {
    if (params.slug === 'cakes-and-pies') return 1;

    if (params.slug === 'sweets-and-deserts') return 2;

    if (params.slug === 'salvory') return 3;

    return undefined;
  }, [params.slug]);

  const {
    data,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    isFetching,
  } = useRecipes(searchValue, recipeType);

  useEffect(() => {
    if (searchValue || recipeType || recipeType === undefined) {
      refetch();
    }
  }, [refetch, searchValue, recipeType]);

  useEffect(() => {
    refetch();

    return () => {
      setSearchValue('');
      setDefaultSearchValue('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handlePush(route: string) {
    history.push(route);
  }

  function handleSelectRecipe(recipe: Recipe) {
    history.push(`/sales/calculator/recipe/${recipe.id}`, {
      recipe,
    });
  }

  const handleSearch = useCallback(async (searchValue: string) => {
    setSearchValue(searchValue);
    setDefaultSearchValue(searchValue);
  }, []);

  const handleSearchClear = useCallback(() => {
    setSearchValue('');
    setDefaultSearchValue('');
    refetch();
  }, [refetch]);

  return (
    <PageContainer display="flex" flexDirection="column">
      <CardButtons mb={4}>
        {cardButtons?.map((button) => (
          <CardButtonItem
            key={button.name}
            name={button.name}
            premiumContent={!!button?.isPremiumRequired}
            isNew={button.isNew}
            icon={button.icon}
            onClick={() => handlePush(`${button.toPage}`)}
            isSelected={buttonSelected?.name === button.name}
            isDisabled={!!defaultSearchValue.length}
          />
        ))}
      </CardButtons>

      <Header
        goPush="/"
        title={buttonSelected?.name || 'Fichas técnicas'}
        isLoading={isFetching}
        search={
          <SearchForm
            defaultValue={defaultSearchValue}
            loading={isFetching}
            searchValue={(value) => handleSearch(value)}
            searchClear={handleSearchClear}
            // disabled={loading || !clients.length}
          />
        }
      />
      <Box
        display="grid"
        gridGap="12px"
        gridTemplateColumns="1fr"
        position="relative"
      >
        {status === 'loading' && (
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            top={0}
            left={0}
            minHeight="30vh"
          >
            <CircularProgress />
          </Box>
        )}

        {data &&
          data.pages.map((group, index) => (
            <React.Fragment key={index}>
              {!!group.results?.length &&
                group.results?.map((recipe) => {
                  return (
                    <React.Fragment key={recipe.id}>
                      <CardTechnicalSheet
                        key={recipe.id}
                        title={recipe.title}
                        image={recipe?.image_url}
                        description={recipe.description}
                        quantityIngredients={recipe.recipeHasProducts?.length}
                        onClick={() => handleSelectRecipe(recipe)}
                      />
                    </React.Fragment>
                  );
                })}
            </React.Fragment>
          ))}

        {status === 'error' && !data?.pages[0].results.length && (
          <NoData title="Ops! Ocorreu um erro." description={error?.message} />
        )}

        {status !== 'loading' &&
          !data?.pages[0].results.length &&
          !searchValue && (
            <NoData title="Ops! Nenhuma ficha técnica registrada.">
              <Box mb={3} />
            </NoData>
          )}

        {hasNextPage && (
          <ButtonLoadMore
            disabled={!hasNextPage || isFetchingNextPage}
            loadMore={() => fetchNextPage()}
            loading={isFetchingNextPage}
          />
        )}
      </Box>
      {/* <CardCalculatorModal
        isOpen={modalSelectData}
        onClose={handleCloseCalculator}
        recipeData={selectedTechnicalShett}
        RemoveFromList={(id) => RemoveFromList(id)}
        showResults={() => OpenResults()}
        GetSelectedItems={(item) => GetSelectedItems(item)}
      /> */}
      {/* <CardShowCalculatorResultsModal
        isOpen={modalResultCalc}
        onClose={handleCloseResults}
        onCloseAll={handleCloseResultsAndCalculator}
        recipeData={selectedTechnicalShett}
        showResults={(id) => OpenResults()}
        itemQuantity={allItems}
      /> */}
    </PageContainer>
  );
}
