/* eslint-disable @typescript-eslint/naming-convention */
import { red } from '@material-ui/core/colors';
import { ThemeOptions } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    color: {
      premium: {
        main: string;
        contrastText: string;
      };
      shape: {
        main: string;
        dark: string;
      };
    };
    border: {
      color: string;
    };
    text: {
      primary: string;
      contrastText: string;
      secondary: string;
      buttons: string;
    };
    background: {
      gradient: {
        radial: string;
        contrastText: string;
      };
      primary: string;
    };
    status: {
      danger: string;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    color: {
      premium: {
        main: string;
        contrastText: string;
      };
      shape: {
        main: string;
        dark: string;
      };
    };
    border: {
      color: string;
    };
    text: {
      primary: string;
      contrastText: string;
      secondary: string;
      buttons: string;
    };
    background: {
      gradient: {
        radial: string;
        contrastText: string;
      };
      primary: string;
    };
    status?: {
      danger?: string;
    };
  }
}

const customThemeLight: ThemeOptions = {
  color: {
    premium: {
      main: '#fbcf67',
      contrastText: '#FFFFFF',
    },
    shape: {
      main: '#F9E3E0',
      dark: '#ffd6d0',
    },
  },
  border: {
    color: '#F4DDDD',
  },
  text: {
    primary: '#B54D54',
    contrastText: '#FFFFFF',
    secondary: '#373737',
    buttons: '#BBA2A2',
  },
  background: {
    gradient: {
      radial:
        'radial-gradient(50% 302.45% at 50% 50%, #FFB8AC 0%, #FF9988 100%)',
      contrastText: '#ffffff',
    },
    primary: '#F9836F',
  },
  status: {
    danger: red[500],
  },
  palette: {
    type: 'light',
    primary: {
      light: '#FEB1A3',
      main: '#FF9988',
      dark: '#F9836F',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#666666',
      dark: '#8D6464',
    },
    success: {
      main: '#FF9988',
    },
    warning: {
      main: '#FFEFDA',
    },
    error: {
      main: '#B54D54',
    },
    info: {
      main: '#E3E7C0',
    },
    background: {
      default: '#FBF7F3',
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiDialog: {
      root: {
        width: '100%',
      },
      paperWidthSm: {
        width: '100%',
      },
      paper: {
        margin: 16,
      },
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'antialiased',
        },
      },
    },
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        background: 'linear-gradient(45deg, #FFB8AC 30%, #FF9988 90%)',
        borderRadius: 6,
        border: 0,
        color: '#FFFFFF',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 3px 0px rgba(247, 237, 227, 0.2)',
      },
      textPrimary: {
        color: '#FFFFFF',
      },
      sizeSmall: {
        height: 'auto',
      },
      sizeLarge: {
        height: 48,
      },
      outlinedSizeLarge: {
        height: 48,
      },
      root: {
        textTransform: 'capitalize',
        '&$disabled': {
          background: '#d8d8d8',
        },
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: '20%',
      },
    },
  },
};

const customThemeDark: ThemeOptions = {
  color: {
    premium: {
      main: '#fbcf67',
      contrastText: '#FFFFFF',
    },
    shape: {
      main: '#424242',
      dark: '#393939',
    },
  },
  border: {
    color: '#424242',
  },
  text: {
    primary: '#FF9988',
    contrastText: '#FFFFFF',
    secondary: '#FFFFFF',
    buttons: '#d5d5d5',
  },
  background: {
    gradient: {
      radial:
        'radial-gradient(50% 302.45% at 50% 50%, #FFB8AC 0%, #FF9988 100%)',
      contrastText: '#ffffff',
    },
    primary: '#F9836F',
  },
  status: {
    danger: red[500],
  },
  palette: {
    type: 'dark',
    primary: {
      light: '#FEB1A3',
      main: '#FF9988',
      dark: '#F9836F',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#FFFFFF',
      dark: '#dddddd',
    },
    success: {
      main: '#FF9988',
    },
    warning: {
      main: '#FFEFDA',
    },
    error: {
      main: '#B54D54',
    },
    info: {
      main: '#E3E7C0',
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiDialog: {
      root: {
        width: '100%',
      },
      paperWidthSm: {
        width: '100%',
      },
      paper: {
        margin: 16,
      },
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'antialiased',
        },
      },
    },
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        background: 'linear-gradient(45deg, #FFB8AC 30%, #FF9988 90%)',
        borderRadius: 6,
        border: 0,
        color: '#FFFFFF',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 3px 0px rgba(247, 237, 227, 0.2)',
      },
      textPrimary: {
        color: '#FFFFFF',
      },
      sizeSmall: {
        height: 'auto',
      },
      sizeLarge: {
        height: 48,
      },
      outlinedSizeLarge: {
        height: 48,
      },
      root: {
        textTransform: 'capitalize',
        '&$disabled': {
          background: '#485452',
          color: '#FFFFFF',
        },
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: '20%',
      },
    },
  },
};

export { customThemeLight, customThemeDark };
