/* eslint-disable react/no-danger */
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  MenuItem,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import { AddOutlined, DeleteOutlined, EditOutlined } from '@material-ui/icons';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { Skeleton } from '@material-ui/lab';
import { useCallback, useEffect, useState, MouseEvent } from 'react';

import { Header } from '../../../../shared/components/Header';
import { IconButton } from '../../../../shared/components/IconButton';
import { NoData } from '../../../../shared/components/NoData';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { CreateFofaModal } from '../../components/CreateFofaModal';
import { EditFofaModal } from '../../components/EditFofaModal';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: '1px 6px',
    top: '16px',
    right: '16px',
    position: 'absolute',
    cursor: 'pointer',
    color: '#000',
    fontSize: '5px',
  },
  blue: {
    backgroundColor: '#D1F7FF',
    border: '2px solid #ADE0EB',
    fontSize: '5px',
  },

  contentText: {
    marginTop: '5px',
    color: 'black',
    fontSize: '14px',
    marginLeft: '10px',
    marginRight: '10px',
    fontWeight: 'bold',
    [theme.breakpoints.down(616)]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down(430)]: {
      fontSize: '12px',
    },
  },
  dataText: {
    marginTop: '20px',
    color: 'black',
    fontSize: '16px',
    marginLeft: '10px',
    marginRight: '10px',
    [theme.breakpoints.down(616)]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down(430)]: {
      fontSize: '12px',
    },
  },
  card: {
    width: '100%',
    height: 'auto',
    minHeight: '120px',
    borderRadius: '6px',
  },
  inline: {},
  topCard: {
    backgroundColor: theme.palette.primary.main,
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
  },
  BottomCard: {
    backgroundColor: theme.palette.primary.main,
    borderBottomRightRadius: '5px',
    borderBottomLeftRadius: '5px',
  },
  test: {
    color: theme.text.primary,
  },
  title: {
    color: 'black',
    fontSize: '20px',
    marginLeft: '10px',
    marginTop: '10px',
    fontWeight: 'bold',
    [theme.breakpoints.down(440)]: {
      fontSize: '15px',
    },
  },
  red: {
    background: '#FFD1D1',
    border: '2px solid #EBADAD',
  },
  green: {
    background: ' #E5FFD1',
    border: '2px solid #C8EBAD',
  },
  yellow: {
    background: '#FFF2D1',
    border: '2px solid #EBD9AD',
  },
  TextDescription: {
    '& p, span, h1, h2, h3, h4, h5, strong, span': {
      fontSize: '14px',
    },
  },
  minHeightText: {
    minHeight: 136,
  },
}));

interface FofaData {
  id: number;
  strengths: string;
  weaknesses: string;
  opportunities: string;
  threats: string;
}

export function Fofa() {
  const classes = useStyles();
  const { msgSuccess, msgError } = useSnackMessages();

  const isNotMobile = useMediaQuery('(min-width:600px)');
  const isMobile = useMediaQuery('(max-width:859px)');

  const [createisOpen, setCreateisOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [viewIsOpen, setViewIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [fofa, setFofa] = useState<FofaData | null>(null);

  const [loading, setLoading] = useState(true);

  const loadFofa = useCallback(() => {
    setLoading(true);
    api
      .get<{ fofa: FofaData[] }>('/fofa')
      .then((response) => {
        setFofa(response.data?.fofa?.[0] ?? null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function handleOpenStrengthsModal() {
    setCreateisOpen(true);
  }
  const CreatehandleClose = useCallback(() => {
    setCreateisOpen(false);
    loadFofa();
  }, [loadFofa]);

  const EditHandleClose = useCallback(() => {
    setEditIsOpen(false);
    loadFofa();
  }, [loadFofa]);

  const ViewHandleClose = useCallback(() => {
    setViewIsOpen(false);
    loadFofa();
  }, [loadFofa]);

  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setEditIsOpen(true);
    setAnchorEl(null);
  };
  const handleView = () => {
    setViewIsOpen(true);
    setAnchorEl(null);
  };

  async function handleDelete(id: number) {
    try {
      await api.delete(`/fofa?id=${id}`);
      msgSuccess('O seu FOFA foi deletado com sucesso!');
      CreatehandleClose();
      setAnchorEl(null);
    } catch (e) {
      console.log(e);
    }
  }
  const handleOpenOptions = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    loadFofa();
  }, [loadFofa]);

  return (
    <PageContainer display="flex" flexDirection="column" position="relative">
      <Header goPush="/" title="F.O.F.A (SWOT)">
        {!fofa?.id && !loading && (
          <Button
            size="medium"
            variant="contained"
            color="primary"
            startIcon={<AddOutlined />}
            onClick={handleOpenStrengthsModal}
          >
            Adicionar {isNotMobile && 'F.O.F.A'}
          </Button>
        )}
        {fofa?.id && (
          <>
            <Button
              size="medium"
              variant="contained"
              color="primary"
              startIcon={<EditOutlined />}
              onClick={handleEdit}
            >
              Editar {isNotMobile && 'F.O.F.A'}
            </Button>

            <IconButton
              aria-controls="menu-options-table"
              aria-haspopup="true"
              color="inherit"
              onClick={(event) => handleOpenOptions(event)}
            >
              <MoreVertOutlinedIcon color="inherit" />
            </IconButton>
          </>
        )}
      </Header>

      <CreateFofaModal isOpen={createisOpen} onClose={CreatehandleClose} />

      <Box display="flex" flexDirection="column">
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="40px"
          mb={1}
          className={classes.topCard}
        >
          <Typography
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
          >
            ambiente interno
          </Typography>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gridGap="4px"
          flexDirection="column"
          style={{ flexFlow: 'wrap' }}
          position="relative"
        >
          <Box className={`${classes.card} ${classes.green}`}>
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              alignItems="stretch"
              justifyContent="stretch"
              width="100%"
              height="100%"
            >
              <Typography className={classes.title}>Forças</Typography>
              <Box className={classes.minHeightText}>
                <Typography display="block" className={classes.contentText}>
                  O que a empresa faz bem ?
                </Typography>
                <Typography className={classes.contentText}>
                  Que recursos especiais possui e pode aproveitar ?
                </Typography>
                <Typography display="block" className={classes.contentText}>
                  O que outros acham que você faz bem ?
                </Typography>
              </Box>

              {!loading && fofa?.id && (
                <>
                  <Box
                    className={classes.TextDescription}
                    padding={1}
                    marginTop={2}
                    style={{
                      borderTop: '2px solid #C8EBAD',
                      borderRadius: '0 0 8px 8px',
                    }}
                    bgcolor="background.paper"
                  >
                    {isMobile ? (
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: `${fofa?.strengths.substring(0, 70)}...`,
                        }}
                      />
                    ) : (
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: `${fofa?.strengths.substring(0, 230)}`,
                        }}
                      />
                    )}
                  </Box>
                </>
              )}

              {loading && (
                <Box
                  className={classes.TextDescription}
                  padding={1}
                  marginTop={2}
                  style={{ borderTop: '2px solid #C8EBAD' }}
                >
                  <Skeleton variant="text" width="80%" />
                  <Skeleton variant="text" width="20%" />
                  <Skeleton variant="text" width="40%" />
                </Box>
              )}
            </Box>
          </Box>
          <Box className={`${classes.card} ${classes.yellow}`}>
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              alignItems="stretch"
              justifyContent="stretch"
              width="100%"
              height="100%"
            >
              <Typography className={classes.title}>Fraquezas</Typography>
              <Box className={classes.minHeightText}>
                <Typography display="block" className={classes.contentText}>
                  No que você pode melhorar ?
                </Typography>
                <Typography display="block" className={classes.contentText}>
                  Onde tem menos recursos que os outros ?
                </Typography>
                <Typography display="block" className={classes.contentText}>
                  O que os outros acham que não faz bem ?
                </Typography>
              </Box>

              {!loading && fofa?.id && (
                <Box
                  className={classes.TextDescription}
                  padding={1}
                  marginTop={2}
                  style={{
                    borderTop: '2px solid #EBD9AD',
                    borderRadius: '0 0 8px 8px',
                    height: '100%',
                  }}
                  bgcolor="background.paper"
                >
                  <>
                    {isMobile ? (
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: `${fofa?.weaknesses.substring(0, 70)}...`,
                        }}
                      />
                    ) : (
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: `${fofa?.weaknesses.substring(0, 230)}`,
                        }}
                      />
                    )}
                  </>
                </Box>
              )}

              {loading && (
                <Box
                  className={classes.TextDescription}
                  padding={1}
                  marginTop={2}
                  style={{ borderTop: '2px solid #EBD9AD' }}
                >
                  <Skeleton variant="text" width="80%" />
                  <Skeleton variant="text" width="20%" />
                  <Skeleton variant="text" width="40%" />
                </Box>
              )}
            </Box>
          </Box>
          <Box className={`${classes.card} ${classes.blue}`}>
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              alignItems="stretch"
              justifyContent="stretch"
              width="100%"
              height="100%"
            >
              <Typography className={classes.title}>Oportunidades</Typography>
              <Box className={classes.minHeightText}>
                <Typography display="block" className={classes.contentText}>
                  Quais são as oportunidades externas que você pode pode
                  identificar ?
                </Typography>
                <Typography display="block" className={classes.contentText}>
                  Que tendências e &quot;modas&quot; você pode aproveitar em seu
                  favor
                </Typography>
              </Box>

              {!loading && fofa?.id && (
                <Box
                  className={classes.TextDescription}
                  padding={1}
                  marginTop={2}
                  style={{
                    borderTop: '2px solid #ADE0EB',
                    borderRadius: ' 0 0 8px 8px',
                  }}
                  bgcolor="background.paper"
                >
                  <>
                    {isMobile ? (
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: `${fofa?.opportunities.substring(0, 70)}...`,
                        }}
                      />
                    ) : (
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: `${fofa?.opportunities.substring(0, 230)}`,
                        }}
                      />
                    )}
                  </>
                </Box>
              )}

              {loading && (
                <Box
                  className={classes.TextDescription}
                  padding={1}
                  marginTop={2}
                  style={{ borderTop: '2px solid #ADE0EB' }}
                >
                  <Skeleton variant="text" width="80%" />
                  <Skeleton variant="text" width="20%" />
                  <Skeleton variant="text" width="40%" />
                </Box>
              )}
            </Box>
          </Box>
          <Box className={`${classes.card} ${classes.red}`}>
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              alignItems="stretch"
              justifyContent="stretch"
              width="100%"
              height="100%"
            >
              <Typography className={classes.title}>Ameaças</Typography>
              <Box className={classes.minHeightText}>
                <Typography display="block" className={classes.contentText}>
                  Que ameaças (Leis, regulamentos, concorrentes) podem lhe
                  prejudicar ?
                </Typography>
                <Typography display="block" className={classes.contentText}>
                  O que seu concorrente anda fazendo ?
                </Typography>
              </Box>

              {!loading && fofa?.id && (
                <Box
                  className={classes.TextDescription}
                  padding={1}
                  marginTop={2}
                  style={{
                    borderTop: '2px solid #EBADAD',
                    borderRadius: '0 0 8px 8px',
                    height: '100%',
                  }}
                  bgcolor="background.paper"
                >
                  {isMobile ? (
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: `${fofa?.threats.substring(0, 70)}...`,
                      }}
                    />
                  ) : (
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: `${fofa?.threats.substring(0, 230)}`,
                      }}
                    />
                  )}
                </Box>
              )}

              {loading && (
                <Box
                  className={classes.TextDescription}
                  padding={1}
                  marginTop={2}
                  style={{ borderTop: '2px solid #EBADAD' }}
                >
                  <Skeleton variant="text" width="80%" />
                  <Skeleton variant="text" width="20%" />
                  <Skeleton variant="text" width="40%" />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="40px"
          mt={1}
          className={classes.BottomCard}
        >
          <Typography
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
          >
            ambiente externo
          </Typography>
        </Box>

        {!fofa?.id && !loading && (
          <NoData
            title="Ops! Nenhum FOFA cadastrado."
            description="Adicione o seu FOFA clicando no botão abaixo:"
          >
            <Box mb={3} />
            <Button
              size="medium"
              variant="contained"
              color="primary"
              startIcon={<AddOutlined />}
              onClick={handleOpenStrengthsModal}
            >
              Adicionar {isNotMobile && 'seu FOFA'}
            </Button>
          </NoData>
        )}
      </Box>

      <Menu
        id="menu-options-table"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseOptions}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleEdit}>
          <EditOutlined fontSize="small" color="inherit" />
          <Box ml={1}>Editar</Box>
        </MenuItem>

        <MenuItem>
          <DeleteOutlined fontSize="small" color="inherit" />
          <Box ml={1} onClick={() => handleDelete(fofa?.id || 0)}>
            Deletar
          </Box>
        </MenuItem>
      </Menu>

      {fofa?.id && (
        <EditFofaModal
          isOpen={editIsOpen}
          onClose={EditHandleClose}
          data={fofa}
        />
      )}
    </PageContainer>
  );
}
