import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Theme,
} from '@material-ui/core';

import { PopoverHelp } from '../../../../shared/components/PopoverHelp';

const useStyles = makeStyles((theme: Theme) => ({
  tableBgColor: {
    background: theme.palette.background.paper,
  },
  thead: {
    '& th:first-child': {
      borderTopLeftRadius: 8,
    },
    '& th:last-child': {
      borderTopRightRadius: 8,
    },
    '& th': {
      background: '#FEB1A3',
      color: '#B54D54',
    },
  },
  info: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    background: '#FEB1A3',
    color: '#B54D54',
  },
  infoBgcolor: {
    background: theme.palette.background.paper,
  },
}));

type MonthBalancePoint = {
  month: string;
  balance_point: string;
  fixed_cost: string | number;
  profit_margin?: string | number;
  contribution?: string | number;
};

interface TableMonthsBalancePointProps {
  data: MonthBalancePoint[];
}

function TableMonthsBalancePoint({ data }: TableMonthsBalancePointProps) {
  const classes = useStyles();

  return (
    <>
      <Table>
        <TableHead className={classes.thead}>
          <TableRow>
            <TableCell>Mês</TableCell>
            <TableCell>
              <Box display="flex" alignItems="center">
                Ponto de Equilíbrio{' '}
                <PopoverHelp
                  name="pontoDeEquilibrio"
                  title="O que é ponto de Equilíbrio?"
                  help="É o valor ou a aquantidade que a empresa precisa vender para cobrir o custo das mercadorias vendidas, as despesas variáveis e as despesas fixas. No ponto de equilíbrio, a empresa não terá lucro nem prejuízo."
                />{' '}
                (R$)
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBgColor}>
          {data.map((item) => (
            <TableRow key={item.month}>
              <TableCell
                style={{ border: 'none', textTransform: 'capitalize' }}
              >
                {item.month}
              </TableCell>
              <TableCell
                style={{ border: 'none', borderLeft: '1px solid #eeeeee70' }}
                title={`Custo fixo: R$ ${item.fixed_cost}, Margem de lucro: ${item.profit_margin}%, Contribuição: ${item.contribution}%`}
              >
                R$ {item.balance_point}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Box display="flex" flexDirection="column" width="100%">
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          py={2}
          mt={3}
          className={classes.info}
        >
          Como é realizado este cálculo?
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          width="100%"
          px={1}
          py={2}
          className={classes.infoBgcolor}
        >
          Utilizamos a sua Margem de Contribuição{' '}
          <PopoverHelp
            name="margemDeContribuição"
            title="Margem de Contribuição"
            help="É uma porcentagem destinada ao que se vendeu para Contribuir com os custos fixos. No Aplicativo ela é somada com a margem de lucro que deseja para formação de preço. Assim cada produto que vender dali sairá uma porcentagem para ajudar nos custos fixos."
          />{' '}
          e os seus Custos Fixos{' '}
          <PopoverHelp
            name="custosFixos"
            title="Custo Fixo"
            help="É um fator produção que tem custos independentes do nível de atividade da empresa. Qualquer que seja a quantidade produzida ou vendida, mesmo que seja zero, os custos fixos se mantêm os mesmos. Ou seja, se você vender 10 bolos ou 20, o custo permanece o mesmo. Ex: Aluguel, internet, imposto do mei, funcionário, seu prolabore, etc."
          />{' '}
          para calcular o seu Ponto de Equilíbrio{' '}
          <PopoverHelp
            name="pontoDeEquilibrio2"
            title="Ponto de Equilíbrio"
            help="É o valor ou a quantidade que a empresa precisa vender para cobrir o custo das mercadorias vendidas, as despesas variáveis e as despesas fixas. No ponto de equilíbrio, a empresa não terá lucro nem prejuízo."
          />
        </Box>
      </Box>
    </>
  );
}

export { TableMonthsBalancePoint };
