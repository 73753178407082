import { Budget } from '../modules/budgets/pages/Budget';
import { BudgetCreate } from '../modules/budgets/pages/BudgetCreate';
import { IRoutes } from './types.routes';

export const budgetsRoutes: IRoutes[] = [
  {
    path: '/budgets/create',
    children: <BudgetCreate />,
    isPrivate: true,
    exact: true,
    pageName: 'Orçamento',
  },
  {
    path: '/budget/edit',
    children: <BudgetCreate edit />,
    isPrivate: true,
    exact: true,
    pageName: 'Orçamento',
  },
  {
    path: '/budgets',
    children: <Budget />,
    isPrivate: true,
    exact: true,
    pageName: 'Orçamentos',
  },
];
