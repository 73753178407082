import { useQuery } from 'react-query';

import { unitMeasurement } from '../../../../modules/records/pages/Product/config';
import { api } from '../../../services/apiClient';
import { formatPrice } from '../../../utils/formatPrice';
import { assertIsDataResponse } from '../assertIsDataResponse';
import { Product } from './types';

type IReactQueryResponse = {
  results: Product[];
};

async function getData({ searchParam = '' }) {
  const response = await api.get<{ results: Product[] }>(
    `products/?search=${searchParam}`
  );

  const data: IReactQueryResponse = {
    results: response.data.results.map((product) => ({
      ...product,
      price: product.price,
      formattedPrice: formatPrice(Number(product.price)),
      formatedUnitMeasurementName:
        unitMeasurement[product.unitMeasurement.name],
    })),
  };
  return data;
}

function useProducts(searchParam = '') {
  return useQuery<IReactQueryResponse, Error>(['products', searchParam], () =>
    getData({ searchParam })
  );
}

export { useProducts };
