import { useHistory } from 'react-router-dom';

import { cardButtonsData } from '../../../../config/cardButtonsData';
import { CardButtons } from '../../../../shared/components/CardButtons';
import { CardButtonItem } from '../../../../shared/components/CardButtons/CardButtonItem';
import { useModalYourRegistration } from '../../../../shared/hooks/useModalYourRegistration';
import { usePremium } from '../../../../shared/hooks/usePremium';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { Header } from '../../../../shared/components/Header';

export function Records() {
  const history = useHistory();
  const isPremium = usePremium();
  const { onOpen: onOpenYourRegistration } = useModalYourRegistration();

  function handlePushOrOpenModal(
    route: string,
    onOpenModal?: 'yourRegistration' | 'otherModal'
  ) {
    if (onOpenModal) {
      switch (onOpenModal) {
        case 'yourRegistration':
          onOpenYourRegistration();
          break;

        default:
          break;
      }
    } else {
      history.push(route);
    }
  }

  return (
    <PageContainer>
      <Header goPush="/" title="Cadastros" />
      <CardButtons>
        {cardButtonsData.map((content) => {
          return (
            content.sectionName === 'Cadastros' &&
            content.buttons.map((button) => (
              <CardButtonItem
                key={button.name}
                name={button.name}
                premiumContent={!!button?.isPremiumRequired}
                isDisabled={button?.isPremiumRequired === true && !isPremium}
                isLoading={isPremium === undefined}
                isNew={button.isNew}
                icon={button.icon}
                onClick={() =>
                  handlePushOrOpenModal(`${button.toPage}`, button.onOpenModal)
                }
              />
            ))
          );
        })}
      </CardButtons>
    </PageContainer>
  );
}
