import {
  Box,
  IconButton,
  TextField,
  TextFieldProps,
  Typography,
} from '@material-ui/core';
import {
  ReportProblemOutlined as Error,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@material-ui/icons';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

type Props = TextFieldProps & {
  control: any;
  name: string;
  type?: string;
  error?: any;
};

function InputPassword({
  control,
  name,
  type = 'password',
  error = undefined,
  ...rest
}: Props) {
  const [showPassword, setShowPassword] = useState(type ?? 'password');
  return (
    <Box width="full" display="flex" flexDirection="column" position="relative">
      <Box display="flex" flexDirection="column" position="relative">
        <Box
          position="absolute"
          right={0}
          top={0}
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={1}
          padding={1}
        >
          <IconButton
            onClick={() =>
              setShowPassword((state) =>
                state === 'password' ? 'text' : 'password'
              )
            }
            size="small"
          >
            {showPassword === 'password' ? (
              <VisibilityOffOutlined style={{ width: 20, height: 20 }} />
            ) : (
              <VisibilityOutlined style={{ width: 20, height: 20 }} />
            )}
          </IconButton>
        </Box>
        <Controller
          name={name}
          control={control}
          rules={{ required: rest?.required }}
          render={({ field }) => (
            <TextField {...field} {...rest} type={showPassword} />
          )}
        />
      </Box>

      {name && error?.[name]?.message && (
        <Typography
          variant="subtitle2"
          color="error"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Error color="error" style={{ width: 18, marginRight: 4 }} />
          {error[name]?.message}
        </Typography>
      )}
    </Box>
  );
}

export { InputPassword };
