import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function CashFlowIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M2.13931 11.7704C1.94288 11.4957 1.78573 11.2211 1.66788 10.9464L2.37502 10.5933C2.49288 10.8287 2.65002 11.1034 2.80716 11.3388L2.13931 11.7704Z" />
      <path d="M3.51429 13.2222C3.27858 13.026 3.04286 12.8298 2.84644 12.5944L3.43572 12.045C3.63215 12.2412 3.82858 12.4374 4.02501 12.5944L3.51429 13.2222Z" />
      <path d="M12.7071 12.3982L12.1179 11.8881C13.0607 10.8287 13.6107 9.4161 13.6107 8.00351C13.6107 7.10102 13.4143 6.23777 13.0214 5.453L13.7286 5.09985C14.1607 6.00234 14.3964 6.98331 14.3964 8.00351C14.3571 9.61229 13.7679 11.1818 12.7071 12.3982Z" />
      <path d="M12.275 4.31506C12.1179 4.11887 11.9607 3.92267 11.7643 3.76572L12.3143 3.21637C12.5107 3.41256 12.7071 3.648 12.9036 3.84419L12.275 4.31506Z" />
      <path d="M10.9786 3.13791C10.7429 2.98096 10.5071 2.824 10.2321 2.70629L10.5857 2C10.8607 2.15695 11.175 2.3139 11.4107 2.47085L10.9786 3.13791Z" />
      <path d="M1.31429 10.0831C1.11786 9.41607 1 8.70978 1 8.00349C1 5.84536 2.02143 3.8442 3.78929 2.58856L4.26071 3.21637C2.68929 4.31505 1.78571 6.12003 1.78571 8.00349C1.78571 8.6313 1.90357 9.25913 2.06071 9.8477L1.31429 10.0831Z" />
      <path d="M5.71427 22.5218H4.92855V18.9903H3.35713V22.5218H2.57141V18.2055H5.71427V22.5218Z" />
      <path d="M10.0357 22.5218H9.25V16.636H7.67857V19.3827H6.89285V15.8512H10.0357V22.5218Z" />
      <path d="M7.67857 20.1675H6.89285V20.9522H7.67857V20.1675Z" />
      <path d="M7.67857 21.737H6.89285V22.5218H7.67857V21.737Z" />
      <path d="M14.3572 22.5218H13.5714V14.2817H12V18.2055H11.2143V13.4969H14.3572V22.5218Z" />
      <path d="M12 18.9903H11.2143V19.7751H12V18.9903Z" />
      <path d="M12 20.5598H11.2143V22.5217H12V20.5598Z" />
      <path d="M18.6786 22.5218H17.8928V11.9274H16.3214V17.8131H15.5357V11.1426H18.6786V22.5218Z" />
      <path d="M16.3214 18.5979H15.5357V19.3827H16.3214V18.5979Z" />
      <path d="M16.3214 20.1675H15.5357V20.9522H16.3214V20.1675Z" />
      <path d="M16.3214 21.737H15.5357V22.5218H16.3214V21.737Z" />
      <path d="M23 22.5218H22.2143V7.61113H20.6429V14.6741H19.8571V6.82635H23V22.5218Z" />
      <path d="M20.6429 15.4588H19.8571V16.2436H20.6429V15.4588Z" />
      <path d="M20.6429 17.0284H19.8571V22.5218H20.6429V17.0284Z" />
      <path d="M7.67855 13.1045C4.84998 13.1045 2.57141 10.8287 2.57141 8.00348C2.57141 5.1783 4.84998 2.90247 7.67855 2.90247C10.5071 2.90247 12.7857 5.1783 12.7857 8.00348C12.7857 10.8287 10.5071 13.1045 7.67855 13.1045ZM7.67855 3.68724C5.28213 3.68724 3.35713 5.60993 3.35713 8.00348C3.35713 10.397 5.28213 12.3197 7.67855 12.3197C10.075 12.3197 12 10.397 12 8.00348C12 5.60993 10.075 3.68724 7.67855 3.68724Z" />
      <path d="M7.67858 10.7502C6.57858 10.7502 5.71429 10.0439 5.71429 9.1807H6.50001C6.50001 9.61232 7.05001 9.96547 7.67858 9.96547C8.30715 9.96547 8.85715 9.61232 8.85715 9.1807C8.85715 8.74907 8.30715 8.39593 7.67858 8.39593C6.57858 8.39593 5.71429 7.68963 5.71429 6.82638C5.71429 5.96313 6.57858 5.25684 7.67858 5.25684C8.77858 5.25684 9.64287 5.96313 9.64287 6.82638H8.85715C8.85715 6.39476 8.30715 6.04161 7.67858 6.04161C7.05001 6.04161 6.50001 6.39476 6.50001 6.82638C6.50001 7.25801 7.05001 7.61115 7.67858 7.61115C8.77858 7.61115 9.64287 8.31745 9.64287 9.1807C9.64287 10.0439 8.77858 10.7502 7.67858 10.7502Z" />
      <path d="M8.07142 4.47198H7.28571V5.64914H8.07142V4.47198Z" />
      <path d="M8.07142 10.3578H7.28571V11.5349H8.07142V10.3578Z" />
    </SvgIcon>
  );
}
