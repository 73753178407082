import { ReactNode } from 'react';

import {
  AboutAppIcon,
  AnnualPlaningIcon,
  BackupIcon,
  BlogIcon,
  BudgetIcon,
  CakesAndPiesIcon,
  CalculatorIcon,
  CashFlowIcon,
  ClientIcon,
  FofaIcon,
  ImportIcon,
  ProductIcon,
  SalesAdjustmentsIcon,
  SalesIcon,
  SavoryIcon,
  SweetsAndDessertsIcon,
  ViviTvIcon,
  YourRegistrationIcon,
} from '../shared/components/Icons';

interface CardProps {
  sectionName: string;
  buttons: Array<{
    name: string;
    isPremiumRequired?: boolean;
    isNew: boolean;
    icon: ReactNode;
    toPage: string;
    onOpenModal?: 'yourRegistration' | 'otherModal';
  }>;
}

export const cardButtonsData: CardProps[] = [
  {
    sectionName: 'Cadastros',
    buttons: [
      {
        name: 'Clientes',
        isNew: true,
        icon: <ClientIcon style={{ fontSize: '50px' }} />,
        toPage: '/records/clients',
      },
      {
        name: 'Produtos',
        isNew: false,
        icon: <ProductIcon style={{ fontSize: '50px' }} />,
        toPage: '/records/products',
      },
      {
        name: 'Ajuste de Vendas',
        isPremiumRequired: true,
        isNew: false,
        icon: <SalesAdjustmentsIcon style={{ fontSize: '50px' }} />,
        toPage: '/records/sales-adjustments',
      },
      {
        name: 'Planejamento Anual',
        isPremiumRequired: true,
        isNew: false,
        icon: <AnnualPlaningIcon style={{ fontSize: '50px' }} />,
        toPage: '/records/annual-planning',
      },
      {
        name: 'Fofa (SWOT)',
        isPremiumRequired: true,
        isNew: false,
        icon: <FofaIcon style={{ fontSize: '50px' }} />,
        toPage: '/records/fofa',
      },
      {
        name: 'Seu Cadastro',
        isNew: true,
        icon: <YourRegistrationIcon style={{ fontSize: '50px' }} />,
        toPage: '/',
        onOpenModal: 'yourRegistration',
      },
    ],
  },
  {
    sectionName: 'Fichas Técnicas',
    buttons: [
      {
        name: 'Bolos e Tortas',

        isNew: false,
        icon: <CakesAndPiesIcon style={{ fontSize: '50px' }} />,
        toPage: '/technical-sheets/cakes-and-pies',
      },
      {
        name: 'Doces e Sobremesas',

        isNew: false,
        icon: <SweetsAndDessertsIcon style={{ fontSize: '50px' }} />,
        toPage: '/technical-sheets/sweets-and-deserts',
      },
      {
        name: 'Salgados',

        isNew: true,
        icon: <SavoryIcon style={{ fontSize: '50px' }} />,
        toPage: '/technical-sheets/salvory',
      },
    ],
  },
  {
    sectionName: 'Vendas',
    buttons: [
      {
        name: 'Agenda de Vendas',

        isNew: false,
        icon: <SalesIcon style={{ fontSize: '50px' }} />,
        toPage: '/sales/schedule',
      },
      {
        name: 'Fluxo de Caixa',
        isPremiumRequired: true,
        isNew: false,
        icon: <CashFlowIcon style={{ fontSize: '50px' }} />,
        toPage: '/sales/cash-flow',
      },
      {
        name: 'Calculadora',
        isPremiumRequired: true,
        isNew: false,
        icon: <CalculatorIcon style={{ fontSize: '50px' }} />,
        toPage: '/sales/calculator',
      },
      {
        name: 'Orçamentos',
        isPremiumRequired: true,
        isNew: true,
        icon: <BudgetIcon style={{ fontSize: '50px' }} />,
        toPage: '/budgets',
      },
    ],
  },
  {
    sectionName: 'Informações',
    buttons: [
      {
        name: 'Blog',
        isPremiumRequired: true,
        isNew: false,
        icon: <BlogIcon style={{ fontSize: '50px' }} />,
        toPage: '/information/blog',
      },
      {
        name: 'Tutoriais',
        isNew: false,
        icon: <ViviTvIcon style={{ fontSize: '50px' }} />,
        toPage: '/information/vivi-tv',
      },
    ],
  },
  {
    sectionName: 'Utilidades',
    buttons: [
      {
        name: 'Sobre o APP',
        isNew: false,
        icon: <AboutAppIcon style={{ fontSize: '50px' }} />,
        toPage: '/utilities/about-app',
      },
      {
        name: 'Backup',
        isNew: false,
        icon: <BackupIcon style={{ fontSize: '50px' }} />,
        toPage: '/utilities/backup',
      },
      {
        name: 'Importação',
        isNew: true,
        icon: <ImportIcon style={{ fontSize: '50px' }} />,
        toPage: '/utilities/import',
      },
    ],
  },
];
