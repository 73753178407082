import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createContext } from 'use-context-selector';

import { api } from '../services/apiClient';

type User = {
  id?: number;
  email: string;
  name?: string;
  avatar?: string;
  avatar_url?: string;
  isPremium?: boolean;
  signature?: {
    status?: string;
  };
  profile?: {
    id: string;
    phone?: string;
    birthday?: string;
    address_id?: number;
  };
  address?: {
    id: number;
    city_id: number;
    complement: string;
  };
  permissions: string[];
  roles: string[];
};

type SignInCredentials = {
  email: string;
  password: string;
};

interface AuthContextData {
  signIn: (credentials: SignInCredentials) => Promise<void>;
  signOut: () => void;
  signOutRedirect: () => void;
  refreshUser: () => void;
  user: User | null;
  isAuthenticated: boolean;
}

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthContext = createContext({} as AuthContextData);

export function signOut() {
  localStorage.removeItem('application.auth.token');
  localStorage.removeItem('application.auth.refreshToken');

  // authChannel.postMessage('signOut');
  window.location.reload();
  console.count();
}

export function AuthProvider({ children }: AuthProviderProps) {
  const history = useHistory();
  const [user, setUser] = useState<User | null>(null);
  const isAuthenticated = !!user;
  // const token = useMemo<string | null>(() => {
  //   return localStorage.getItem('application.auth.token');
  // }, []);

  const signOutRedirect = useCallback(() => {
    localStorage.removeItem('application.auth.token');
    localStorage.removeItem('application.auth.refreshToken');

    setUser(null);

    history.push('/sign-in');
  }, [history]);

  const loadUserLogged = useCallback(() => {
    api
      .get('/profile')
      .then((response) => {
        const {
          name,
          email,
          avatar,
          avatar_url,
          profile,
          address,
          permissions,
          role,
          isPremium,
          signature,
        } = response.data;

        setUser({
          name,
          email,
          avatar,
          avatar_url,
          permissions,
          profile,
          address,
          roles: role,
          isPremium,
          signature,
        });
      })
      .catch(() => {
        signOutRedirect();
      });
  }, [signOutRedirect]);

  useEffect(() => {
    if (localStorage.getItem('application.auth.token')) {
      loadUserLogged();
    }
  }, [loadUserLogged]);

  const signIn = useCallback(
    async ({ email, password }: SignInCredentials) => {
      try {
        const response = await api.post('sessions', {
          email,
          password,
        });

        const { token, refreshToken, roles, permissions, user } = response.data;

        api.defaults.headers.Authorization = `Bearer ${token}`;
        localStorage.setItem('application.auth.token', token);
        localStorage.setItem('application.auth.refreshToken', refreshToken);

        setUser({
          email,
          permissions,
          roles,
          isPremium: user?.isPremium,
          signature: user?.signature,
        });

        if (token) {
          loadUserLogged();
        }

        history.push('/');
      } catch (err: any) {
        // console.log(err);
        throw new Error(
          err?.response?.data?.message ||
            'Erro! Não foi possível se comunicar com o servidor.'
        );
      }
    },
    [history, loadUserLogged]
  );

  const refreshUser = useCallback(() => {
    loadUserLogged();
  }, [loadUserLogged]);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        signOutRedirect,
        isAuthenticated,
        user,
        refreshUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
