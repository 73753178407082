import { useContext } from 'react';

import { ThemeCustomContext } from '../contexts/ThemeCustomContext';

export function useTheme() {
  const { isDark, toggleTheme } = useContext(ThemeCustomContext);

  return {
    isDark,
    toggleTheme,
  };
}
