import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function CakesAndPiesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M13.7679 15.2008L13.6893 14.4151C17.1464 13.9829 18.325 10.3684 18.325 8.79687V7.42178C18.325 6.98961 18.4429 6.59673 18.6393 6.24314L19.0714 5.53595L19.7393 5.92883L19.3072 6.63602C19.15 6.87175 19.0714 7.14676 19.0714 7.42178V8.79687C19.0714 10.6827 17.775 14.6901 13.7679 15.2008Z" />
      <path d="M19.8571 6.36099C19.7 6.36099 19.5821 6.3217 19.4643 6.24312L15.8893 4.04299C15.5357 3.80726 15.4179 3.3358 15.6143 2.98221L16.1643 2L16.8321 2.39288L16.2821 3.37509L19.8571 5.57523L20.3678 4.6716L21.0357 5.06448L20.525 5.96811C20.4071 6.16455 20.25 6.28241 20.0536 6.3217C20.0143 6.36099 19.9357 6.36099 19.8571 6.36099Z" />
      <path d="M23 21.2905H1V22.0763H23V21.2905Z" />
      <path d="M21.8214 21.6834H21.0357V11.8614C21.0357 11.1935 20.525 10.6827 19.8572 10.6827H18.4429V9.89697H19.8572C20.9572 9.89697 21.8214 10.7613 21.8214 11.8614V21.6834Z" />
      <path d="M2.57142 21.6834H1.78571V11.8614C1.78571 10.7613 2.64999 9.89697 3.75 9.89697H11.6464V10.6827H3.75C3.08214 10.6827 2.57142 11.1935 2.57142 11.8614V21.6834Z" />
      <path d="M19.8572 3.61084H19.0714V4.3966H19.8572V3.61084Z" />
      <path d="M6.49998 19.719H2.17856V20.5048H6.49998V19.719Z" />
      <path d="M21.4286 11.4684H17.8536V12.2542H21.4286V11.4684Z" />
      <path d="M11.2929 11.4684H2.17856V12.2542H11.2929V11.4684Z" />
      <path d="M8.85713 10.6827C7.99285 10.6827 7.28571 9.97548 7.28571 9.11114C7.28571 8.2468 7.99285 7.53961 8.85713 7.53961C9.72142 7.53961 10.4286 8.2468 10.4286 9.11114C10.4286 9.97548 9.72142 10.6827 8.85713 10.6827ZM8.85713 8.32538C8.42499 8.32538 8.07142 8.67897 8.07142 9.11114C8.07142 9.54331 8.42499 9.89691 8.85713 9.89691C9.28927 9.89691 9.64285 9.54331 9.64285 9.11114C9.64285 8.67897 9.28927 8.32538 8.85713 8.32538Z" />
      <path d="M9.25001 8.01112L8.46429 7.85397L8.77858 6.36102C8.97501 5.45739 9.76073 4.78949 10.6643 4.78949V5.57525C10.1143 5.57525 9.64287 5.96814 9.52502 6.51817L9.25001 8.01112Z" />
      <path d="M10.3893 16.9688H9.64285C8.50356 16.9688 7.67856 16.2223 7.67856 15.2008C7.67856 14.1793 8.42499 13.4329 9.48571 13.4329C10.075 13.4329 10.3107 13.5507 11.5678 14.1793L11.2143 14.8865C9.95713 14.2579 9.83928 14.2186 9.48571 14.2186C8.89642 14.2186 8.46427 14.6115 8.46427 15.2008C8.46427 15.908 9.09285 16.183 9.64285 16.183H10.3893V16.9688V16.9688Z" />
      <path d="M15.9286 18.9332C14.5536 18.9332 13.0214 18.4618 12.1179 18.0296L12.4322 17.3224C13.2572 17.7153 14.6714 18.1475 15.9286 18.1475C17.1857 18.1475 18.4429 17.8332 19.5821 17.2438L19.975 17.951C18.7179 18.5796 17.3429 18.9332 15.9286 18.9332Z" />
      <path d="M21.4286 19.3261H20.0143C19.2678 19.3261 18.6393 19.0511 18.05 18.4618L18.6 17.9117C19.0321 18.3439 19.4643 18.5404 20.0143 18.5404H21.4286V19.3261Z" />
      <path d="M21.4286 16.5759H21.0357C19.8179 16.5759 19.3072 16.3009 18.875 16.0652C18.5607 15.908 18.3643 15.7901 17.8536 15.7901C17.1464 15.7901 16.8714 15.9473 16.5179 16.1437C16.1643 16.3402 15.7322 16.5759 14.8286 16.5759C14.2 16.5759 13.5714 16.2223 13.0607 15.633L13.65 15.1222C14.0036 15.5151 14.3964 15.7508 14.7893 15.7508C15.4964 15.7508 15.7714 15.5937 16.125 15.3973C16.4786 15.2008 16.9107 14.9651 17.8143 14.9651C18.5214 14.9651 18.875 15.1615 19.1893 15.358C19.5429 15.5544 19.9357 15.7508 20.9571 15.7508H21.35V16.5759H21.4286Z" />
      <path d="M6.89285 14.6115H2.17856V15.3973H6.89285V14.6115Z" />
      <path d="M11.6071 18.9332C11.45 18.9332 11.2928 18.8939 11.175 18.8153L9.99642 18.0295C9.76071 17.8724 9.64285 17.6367 9.64285 17.3617C9.64285 17.2438 9.68214 17.1259 9.72143 17.0081L11.8036 12.8435L12.5107 13.1971L10.4286 17.3617L11.6071 18.1474L13.5714 14.2579L14.2786 14.6115L12.3143 18.501C12.1571 18.776 11.9214 18.9332 11.6071 18.9332Z" />
      <path d="M14.0036 15.083L13.2964 14.7294L14.7893 11.7828C15.025 11.3113 15.1429 10.8006 15.1429 10.2898C14.9464 10.2898 14.7893 10.2898 14.5929 10.3291C14.2 10.4077 13.8857 10.6434 13.7286 11.0363L13.0607 12.6864C12.8643 13.1579 12.4321 13.4329 11.9607 13.4329C11.2928 13.4329 10.7821 12.9222 10.7821 12.2543C10.7821 12.1757 10.7821 12.0971 10.8214 12.0185L11.6464 8.20757C11.7643 7.65754 12 7.18608 12.3536 6.87177C13.2571 6.00743 13.8464 5.65384 14.3964 5.33953C14.9071 5.02523 15.3393 4.7895 16.0071 4.1216L16.3607 3.76801L16.9107 4.31804L16.5571 4.67163C15.85 5.37882 15.3 5.69313 14.7893 6.00743C14.2786 6.32174 13.7286 6.63604 12.9036 7.42181C12.6678 7.65754 12.5107 7.97184 12.4321 8.36472L11.6071 12.1757C11.6071 12.215 11.6071 12.215 11.6071 12.2543C11.6071 12.49 11.7643 12.6471 12 12.6471C12.1571 12.6471 12.3143 12.5686 12.3536 12.4114L13.0214 10.7613C13.2571 10.1327 13.8071 9.70052 14.475 9.58266C14.7107 9.54337 14.9071 9.54337 15.1429 9.54337C15.575 9.54337 15.9286 9.89696 15.9286 10.3291C15.9286 10.9577 15.7714 11.5864 15.4964 12.1757L14.0036 15.083Z" />
      <path d="M8.07141 19.719H7.28571V20.5048H8.07141V19.719Z" />
      <path d="M12.7857 19.719H12V20.5048H12.7857V19.719Z" />
    </SvgIcon>
  );
}
