import { Box, Theme, makeStyles, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  iconCheck: {
    borderRadius: '50%',
    width: 18,
    height: 18,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    color: '#81b177',
    background: '#c8f0bf',
    border: '1px solid #9dd492',
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '1rem',
    },
  },
  iconCheckLock: {
    borderRadius: '50%',
    width: 18,
    height: 18,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    color: theme.palette.error.main,
    background: `${theme.palette.error.main}20`,
    border: `1px solid ${theme.palette.error.main}40`,
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '1rem',
    },
  },
  planDescription: {
    lineHeight: '26px',
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
  },
  free: {
    fontWeight: 'bold',
  },
}));

interface SignaturePremiumItemProps {
  children: string;
  isFree?: boolean;
  isBlocked?: boolean;
}

export function SignaturePremiumItem({
  children,
  isFree,
  isBlocked = false,
}: SignaturePremiumItemProps) {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      {!isFree && isBlocked ? (
        <Box className={classes.iconCheckLock}>
          <LockOutlinedIcon fontSize="small" />
        </Box>
      ) : (
        <Box className={classes.iconCheck}>
          <CheckIcon fontSize="small" />
        </Box>
      )}
      <Typography
        variant="body2"
        className={
          isFree
            ? clsx(classes.free, classes.planDescription)
            : classes.planDescription
        }
      >
        {children}
      </Typography>
    </Box>
  );
}
