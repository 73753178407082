import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { Button } from '../../../../shared/components/Button';
import { Input } from '../../../../shared/components/Form/Input';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { PageAnimation } from '../../components/Animation/PageAnimation';
import { Header } from '../../components/Header';

const schema = yup.object().shape({
  email: yup.string().required('O e-mail é obrigatório.'),
});

type FormValues = {
  email: string;
};

function ForgotPassword() {
  const history = useHistory();

  const [minutos, setMinutos] = useState(2);
  const [segundos, setSegundos] = useState(0);
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState('');
  const [resendEmail, setResendEmail] = useState(false);

  const formatTempoRestante = (min: number, sec: number) => {
    return `${min.toString().padStart(2, '0')}:${sec
      .toString()
      .padStart(2, '0')}`;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (segundos === 0) {
        if (minutos === 0) {
          clearInterval(intervalId);
        } else {
          setSegundos(59);
          setMinutos(minutos - 1);
        }
      } else {
        setSegundos(segundos - 1);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [minutos, segundos]);

  useEffect(() => {
    if (minutos === 0 && segundos === 0) {
      setResendEmail(true);
      setTimeout(() => {
        history.push('/login');
      }, 180000);
    }
  }, [minutos, segundos]);

  const { msgSuccess, msgError } = useSnackMessages();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  });

  const onSubmit = useCallback(
    async ({ email }: FormValues) => {
      setEmailSent(false);
      setLoading(true);
      setSegundos(0);
      setMinutos(2);

      try {
        await api.post('password/forgot', {
          email,
          app: 1,
        });

        msgSuccess(
          'E-mail enviado! Verifique no seu e-mail as instruções para redefinir sua senha.'
        );
        setEmailSent(true);
        setEmail(email);
        // history.push('/login');
      } catch (err: any) {
        setLoading(false);
        msgError(err?.response?.data?.message);
      }
    },
    [msgSuccess, msgError, history]
  );

  return (
    <PageAnimation>
      <Header
        title={emailSent ? 'E-mail enviado!' : 'Redefinir senha'}
        link="/sign-in"
        linkName="Fazer login"
        mb={4}
      />
      {emailSent ? (
        <Box
          component="form"
          display="flex"
          flexDirection="column"
          gridGap={8}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            type="email"
            name="email"
            value={email}
            control={control}
            error={errors}
            variant="outlined"
            label="e-mail"
            fullWidth
            required
            disabled
          />
          <p>
            Por favor, verifique seu e-mail para as instruções de redefinição de
            senha. Se não receber em instantes, clique em &apos;Enviar e-mail
            novamente&apos;.
          </p>
          <Button
            type="submit"
            disabled={!resendEmail}
            style={{ width: '100%' }}
          >
            {!resendEmail && (
              <div style={{ marginRight: '10px' }}>
                {formatTempoRestante(minutos, segundos)}
              </div>
            )}
            Enviar e-mail novamente
          </Button>
        </Box>
      ) : (
        <Box
          component="form"
          display="flex"
          flexDirection="column"
          gridGap={8}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            type="email"
            name="email"
            control={control}
            error={errors}
            variant="outlined"
            label="Insira seu e-mail"
            fullWidth
            required
            disabled={loading}
          />

          <Button
            type="submit"
            loading={loading}
            disabled={loading}
            style={{ width: '100%' }}
          >
            Continuar
          </Button>
        </Box>
      )}
    </PageAnimation>
  );
}

export { ForgotPassword };
