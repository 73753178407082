import { Blog } from '../modules/information/pages/Blog';
import { Information } from '../modules/information/pages/Information';
import { ViviTv } from '../modules/information/pages/ViviTv';
import { IRoutes } from './types.routes';

export const informationRoutes: IRoutes[] = [
  {
    path: '/information',
    children: <Information />,
    isPrivate: true,
    exact: true,
    pageName: 'Informações',
  },
  {
    path: '/information/blog',
    children: <Blog />,
    isPrivate: true,
    isPremium: true,
    exact: true,
    pageName: 'Blog',
  },
  {
    path: '/information/vivi-tv',
    children: <ViviTv />,
    isPrivate: true,
    exact: true,
    pageName: 'Tutoriais',
  },
];
