import { Box, Typography } from '@material-ui/core';
import { AddOutlined, ArrowForwardOutlined } from '@material-ui/icons';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import BarChartIcon from '@material-ui/icons/BarChart';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Button } from '../../../../shared/components/Button';
import { ButtonLarge } from '../../../../shared/components/Button/ButtonLarge';
import { SearchForm } from '../../../../shared/components/Form/SearchForm';
import { IconButton } from '../../../../shared/components/IconButton';
import { Loading } from '../../../../shared/components/Loading';
import { NoData } from '../../../../shared/components/NoData';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { SectionContainer } from '../../../../shared/components/SectionContainer';
import { useCashFlow } from '../../../../shared/hooks/reactQuery/useCashFlow';
import { ICashFlow } from '../../../../shared/hooks/reactQuery/useCashFlow/types';
import { formatPrice } from '../../../../shared/utils/formatPrice';
import { CardCashFlow } from '../../components/CardCashFlow';
import { CardsTopCashFlow } from '../../components/CardsTopCashFlow';
import { DateNavigation } from '../../components/DateNavigation';
import { ModalCreateCashFlow } from '../../components/ModalCreateCashFlow';
import { useStyles } from './styles';
import { Header } from '../../../../shared/components/Header';

type CardsTop = {
  input: string;
  output: string;
  balance: string;
  isNegativeBalance?: boolean;
};

export function CashFlow() {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<{ date?: string }>();

  const [selectedDate, setSelectedDate] = useState(moment(params?.date));
  const [selectedMonth, setSelectedMonth] = useState(
    moment(params?.date).format('M')
  );
  const [selectedYear, setSelectedYear] = useState(
    moment(params?.date).format('YYYY')
  );

  const [cardsTop, setCardsTop] = useState<CardsTop>({} as CardsTop);

  const [openModalCreateCashFlow, setOpenModalCreateCashFlow] = useState(false);

  const [cashFlowOriginal, setCashFlowOriginal] = useState<ICashFlow[]>([]);
  const [cashFlow, setCashFlow] = useState<ICashFlow[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [defaultSearchValue, setDefaultSearchValue] = useState('');

  const { data, error, refetch, status, isFetching } = useCashFlow(
    selectedMonth,
    selectedYear
  );

  useEffect(() => {
    const inputTotal =
      data?.results.reduce((accumulator, currentValue) => {
        if (currentValue?.cashFlowType.id === 1 && currentValue?.value) {
          return accumulator + Number(currentValue.value);
        }
        return accumulator;
      }, 0) || 0;

    const outputTotal =
      data?.results.reduce((accumulator, currentValue) => {
        if (currentValue?.cashFlowType.id !== 1 && currentValue?.value) {
          return accumulator + Number(currentValue.value);
        }
        return accumulator;
      }, 0) || 0;

    const balanceTotal =
      inputTotal > outputTotal
        ? inputTotal - outputTotal
        : outputTotal - inputTotal;

    setCardsTop({
      input: formatPrice(inputTotal),
      output: formatPrice(outputTotal),
      balance: formatPrice(balanceTotal),
      isNegativeBalance: inputTotal < outputTotal,
    });
  }, [data?.results]);

  useEffect(() => {
    setCashFlow(data?.results || []);
    setCashFlowOriginal(data?.results || []);
  }, [data?.results]);

  const handleNewDate = useCallback((momentDate: Moment) => {
    setSelectedDate(momentDate);
    setSelectedMonth(momentDate.format('M'));
    setSelectedYear(momentDate.format('YYYY'));
  }, []);

  const handlePrevNewDate = useCallback((momentDate: Moment) => {
    const newPrevDate = momentDate.set('month', momentDate.month() - 1);
    setSelectedDate(newPrevDate);
    setSelectedMonth(newPrevDate.format('M'));
    setSelectedYear(newPrevDate.format('YYYY'));
  }, []);

  const handleNextNewDate = useCallback((momentDate: Moment) => {
    const newNextDate = momentDate.set('month', momentDate.month() + 1);
    setSelectedDate(newNextDate);
    setSelectedMonth(newNextDate.format('M'));
    setSelectedYear(newNextDate.format('YYYY'));
  }, []);

  const handleSuccessCreateCashFlow = useCallback(() => {
    setOpenModalCreateCashFlow(false);
    refetch();
  }, [refetch]);

  const handleSearchClear = useCallback(() => {
    setSearchValue('');
    setDefaultSearchValue('');
    setCashFlow(cashFlowOriginal);
    // refetch();
  }, [cashFlowOriginal]);

  const handleSearch = useCallback(
    async (searchValue: string) => {
      if (searchValue?.length > 0) {
        setSearchValue(searchValue);
        setDefaultSearchValue(searchValue);

        const newCashFlow = cashFlowOriginal.filter((item) => {
          if (
            item?.description?.toLowerCase().includes(searchValue.toLowerCase())
          ) {
            return item;
          }
          return false;
        });

        setCashFlow(newCashFlow);

        return;
      }

      handleSearchClear();
    },
    [handleSearchClear, cashFlowOriginal]
  );

  return (
    <PageContainer display="flex" flexDirection="column">
      <Header goPush="/" title="Fluxo de Caixa" />
      <DateNavigation
        currentDate={selectedDate}
        newDate={(momentDate) => handleNewDate(momentDate)}
        prevDate={() => handlePrevNewDate(selectedDate)}
        nextDate={() => handleNextNewDate(selectedDate)}
      />

      <Box display="inline-flex" width="100%" justifyContent="center">
        <Button
          variant="outlined"
          color="primary"
          style={{ width: '100%', textTransform: 'inherit' }}
          styleMotion={{ width: '100%' }}
          endIcon={<ArrowForwardOutlined />}
          startIcon={<BarChartIcon />}
          onClick={() =>
            history.push(`/sales/cash-flow/balance-point/${selectedYear}`)
          }
        >
          Ponto de equilíbrio {selectedYear}
        </Button>
      </Box>

      <CardsTopCashFlow
        input={cardsTop.input}
        output={cardsTop.output}
        balance={cardsTop.balance}
        isNegativeBalance={cardsTop.isNegativeBalance}
      />

      <ButtonLarge
        startIcon={<AddOutlined />}
        styleButton={{ textTransform: 'inherit' }}
        onClick={() => setOpenModalCreateCashFlow(true)}
      >
        Adicionar Fluxo de Caixa
      </ButtonLarge>

      <Box marginTop={4}>
        <SearchForm
          defaultValue={defaultSearchValue}
          loading={isFetching}
          searchValue={(value) => handleSearch(value)}
          searchClear={handleSearchClear}
          disabled={status === 'loading' || isFetching}
        />
      </Box>

      <SectionContainer position="relative" minHeight={200}>
        {(status === 'loading' || isFetching) && <Loading />}

        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          pt={2}
        >
          <table className={classes.table}>
            <thead>
              <tr>
                <th>Data</th>
                <th>
                  <Typography>Título</Typography>
                  <Typography>Descrição</Typography>
                </th>
                <th>Valor</th>
                <th>Ações</th>
                {/* <th colSpan={2}>
                  <Typography style={{ textAlign: 'center' }}>Valor</Typography>
                  <table style={{ width: '100%' }}>
                    <tr>
                      <th style={{ paddingLeft: 0 }}>Crédito</th>
                      <th style={{ paddingLeft: 0 }}>Débito</th>
                    </tr>
                  </table>
                </th> */}
              </tr>
            </thead>
            <tbody>
              {!!cashFlow?.length &&
                cashFlow.map((item) => (
                  <tr
                    key={item.id}
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      history.push(
                        `/sales/cash-flow/${
                          item.id
                        }/detail/${selectedDate.format('YYYY-MM-DD')}`
                      )
                    }
                  >
                    <td style={{ paddingLeft: '24px' }}>
                      {item.day} {item.month}
                    </td>
                    <td>
                      <Typography style={{ fontWeight: 'bold' }}>
                        {item.title}
                      </Typography>
                      <Typography variant="body2">
                        {item.description}
                      </Typography>
                    </td>
                    <td
                      className={classes.tablePrice}
                      style={{
                        color:
                          item.cashFlowType.id === 1 ? '#22BC44' : '#B54D54',
                      }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        color={
                          item.cashFlowType.id === 1 ? '#22BC44' : '#B54D54'
                        }
                      >
                        {item.cashFlowType.id === 1 ? (
                          <AddOutlinedIcon />
                        ) : (
                          <RemoveOutlinedIcon />
                        )}
                      </Box>
                      R$ {item.formattedValue}
                    </td>
                    <td>
                      <IconButton color="primary">
                        <VisibilityOutlinedIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Box>

        {/* {data &&
          data.results.map((item) => (
            <React.Fragment key={item.id}>
              <CardCashFlow
                title={item.title}
                eventName={item?.event_name}
                value={item.formattedValue}
                day={item.day}
                month={item.month}
                cashFlowType={item.cashFlowType.id}
                onClick={() =>
                  history.push(
                    `/sales/cash-flow/${item.id}/detail/${selectedDate.format(
                      'YYYY-MM-DD'
                    )}`
                  )
                }
              />
            </React.Fragment>
          ))} */}

        <NoData
          visible={
            status !== 'loading' && status !== 'error' && !data?.results.length
          }
        />

        <NoData
          visible={status === 'error' && !data?.results.length}
          error={error?.message}
        />
      </SectionContainer>

      <ButtonLarge
        startIcon={<AddOutlined />}
        styleButton={{ textTransform: 'inherit' }}
        onClick={() => setOpenModalCreateCashFlow(true)}
      >
        Adicionar Fluxo de Caixa
      </ButtonLarge>

      <ModalCreateCashFlow
        isOpen={openModalCreateCashFlow}
        onClose={() => setOpenModalCreateCashFlow(false)}
        onCloseSuccess={handleSuccessCreateCashFlow}
      />
    </PageContainer>
  );
}
