import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { Button } from '../../../../../shared/components/Button';
import { Input } from '../../../../../shared/components/Form/Input';
import { InputCurrency } from '../../../../../shared/components/Form/Input/InputCurrency';
import { InputDatePicker } from '../../../../../shared/components/Form/Input/InputDatePicker';
import { Header } from '../../../../../shared/components/Header';
import { PageContainer } from '../../../../../shared/components/PageContainer';
import { useSnackMessages } from '../../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../../shared/services/apiClient';
import { AnualPlanningData } from '../types';

const schema = yup.object().shape({
  title: yup.string().required('O título é obrigatório.'),
  event_date: yup.string().nullable().required('A data é obrigatória.'),
});

export function EditAnnualPlanning() {
  const { state } = useLocation<{ anualPlanning: AnualPlanningData }>();
  const { msgSuccess, msgError } = useSnackMessages();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const anualPlanning = useMemo(() => {
    return state?.anualPlanning;
  }, [state?.anualPlanning]);

  const [totalDetails, setTotalDetails] = useState<{ id: number }[]>(() => {
    return (
      state?.anualPlanning.detailsAnualPlannings?.map((item) => ({
        id: item.id,
      })) || [{ id: Date.now() }]
    );
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      title: state?.anualPlanning.title,
      event_date: state?.anualPlanning?.event_date,
      items: state?.anualPlanning.detailsAnualPlannings,
    },
    mode: 'onChange',
  });

  useEffect(() => {
    if (!state?.anualPlanning?.id) {
      history.push('/records/annual-planning');
    }
  }, [history, state?.anualPlanning?.id]);

  const onSubmit = useCallback(
    async ({ title, event_date, items }: any) => {
      setLoading(true);

      const filterItems = items?.filter((item: any) => {
        const findDetails = totalDetails.find(
          (detail) => detail.id === item.id
        );
        const findOldItems = anualPlanning.detailsAnualPlannings.find(
          (oldItem) => oldItem.id === item.id
        );

        if (!findOldItems || findDetails) return true;

        return false;
      });

      try {
        const formatData = {
          title,
          event_date: event_date
            ? moment(new Date(event_date)).format('YYYY-MM-DD')
            : undefined,
          items: filterItems?.map((item: any) => {
            const value = `${item?.value || '0.00'}`;

            console.log(value);
            const parseValue = value
              ? value.replace('R$', '').replace(',', '.') || '0'
              : 0;
            console.log(parseValue);

            return {
              ...item,
              start_date: item?.start_date
                ? moment(new Date(item.start_date)).format('YYYY-MM-DD')
                : undefined,
              end_date: item?.end_date
                ? moment(new Date(item.end_date)).format('YYYY-MM-DD')
                : undefined,
              value: parseValue,
            };
          }),
        };

        await api.put(`/anual-planning/${anualPlanning.id}`, formatData);
        msgSuccess('Planejamento atualizado com sucesso!');

        history.push('/records/annual-planning');
      } catch (err: any) {
        setLoading(false);
        console.log(err);
        msgError(err?.response?.data?.message || err?.response?.data?.error);
      }
    },
    [
      history,
      msgError,
      msgSuccess,
      anualPlanning.id,
      totalDetails,
      anualPlanning.detailsAnualPlannings,
    ]
  );

  const handleAddDetails = () => {
    setTotalDetails((state) => [...state, { id: Date.now() }]);
  };

  const handleRemoveDetails = (id: number) => {
    setTotalDetails((state) => state.filter((item) => item.id !== id));
  };

  return (
    <PageContainer display="flex" flexDirection="column">
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Header goBack title="Editar planejamento">
          <Button
            size="medium"
            variant="contained"
            color="primary"
            type="submit"
          >
            Salvar
          </Button>
        </Header>
        <Box display="flex" flexDirection="column" width="100%">
          <Box mb={2} color="primary.dark">
            Cabeçalho:
          </Box>
          <Input
            name="title"
            control={control}
            error={errors}
            variant="outlined"
            label="Titulo do Evento"
            fullWidth
            required
            disabled={loading}
          />
          <Box mb={2} />
          <InputDatePicker
            name="event_date"
            label="Data do evento"
            variant="outlined"
            control={control}
            error={errors}
            disabled={loading}
            required
          />
          <Box mb={3} />

          <Box mb={2} color="primary.dark">
            Detalhes:
          </Box>
          {totalDetails?.map((item, index) => {
            return (
              <React.Fragment key={item.id}>
                <fieldset
                  style={{
                    borderRadius: '4px',
                    borderColor: '#eee',
                    marginBottom: 16,
                  }}
                >
                  <Box display="flex" justifyContent="flex-end" pb={2}>
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleRemoveDetails(item.id)}
                      disabled={loading || totalDetails.length <= 1}
                      style={{
                        opacity: loading || totalDetails.length <= 1 ? 0.4 : 1,
                      }}
                    >
                      Remover
                    </Button>
                  </Box>

                  <Input
                    help="Insira quem irá participar do seu evento Ex: (Clientes / funcionários)"
                    name={`items[${index}][who]`}
                    control={control}
                    error={errors}
                    variant="outlined"
                    label="Quem?"
                    fullWidth
                    disabled={loading}
                    style={{ marginBottom: '15px' }}
                    inputProps={{ maxLength: 500 }}
                    placeholder="500 caracteres"
                  />

                  <InputDatePicker
                    name={`items[${index}][start_date]`}
                    label="Quando inicia?"
                    variant="outlined"
                    control={control}
                    error={errors}
                    disabled={loading}
                  />

                  <InputDatePicker
                    name={`items[${index}][end_date]`}
                    label="Quando termina?"
                    variant="outlined"
                    control={control}
                    error={errors}
                    disabled={loading}
                  />

                  <Input
                    help="Insira suas estratégias para sua data comemorativa / comercial"
                    name={`items[${index}][how]`}
                    control={control}
                    error={errors}
                    variant="outlined"
                    label="Como?"
                    fullWidth
                    disabled={loading}
                    style={{ marginBottom: '15px' }}
                  />

                  <InputCurrency
                    help="Insira o valor que você pretende gastar no evento"
                    name={`items[${index}][value]`}
                    type="text"
                    label="Quanto?"
                    variant="outlined"
                    control={control}
                    error={errors}
                    style={{ marginBottom: 0 }}
                    disabled={loading}
                  />
                  <Box mb={2} />

                  <Input
                    help="Insira algumas observações adicionais do seu evento"
                    name={`items[${index}][additional_observations]`}
                    multiline
                    minRows={3}
                    control={control}
                    error={errors}
                    variant="outlined"
                    label="Observações Adicionais"
                    fullWidth
                    disabled={loading}
                    style={{ marginBottom: '15px' }}
                  />
                </fieldset>
              </React.Fragment>
            );
          })}
        </Box>

        <Box width="100%" display="flex" justifyContent="stretch" pb={1}>
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={handleAddDetails}
            disabled={loading}
            style={{
              width: '100%',
              opacity: loading ? 0.4 : 1,
              height: 56,
              borderStyle: 'dashed',
            }}
            styleMotion={{ width: '100%' }}
          >
            Adicionar mais detalhes
          </Button>
        </Box>

        <DialogActions style={{ padding: 0 }}>
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            gridGap={8}
            mt={4}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              onClick={() => history.push('/records/annual-planning')}
              color="primary"
              variant="outlined"
              size="large"
              disabled={isSubmitting || loading}
            >
              Cancelar
            </Button>

            <Button
              type="submit"
              size="large"
              color="primary"
              variant="contained"
              disabled={isSubmitting || loading}
            >
              {isSubmitting ? 'Salvando...' : 'Salvar'}
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </PageContainer>
  );
}
