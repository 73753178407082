import { Box, BoxProps, makeStyles, Theme } from '@material-ui/core';
import { ChevronLeftOutlined, ChevronRightOutlined } from '@material-ui/icons';
import { DatePicker, DatePickerView } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Moment } from 'moment';

import { Button } from '../../../../shared/components/Button';

const useStyles = makeStyles((theme: Theme) => ({
  inputDate: {
    '& .MuiInputBase-input': {
      textAlign: 'center',
      textTransform: 'capitalize',
      fontWeight: 500,
      color: theme.palette.secondary.dark,
      fontSize: '1.15rem',
    },
    '& .MuiInput-underline:before': {
      display: 'none',
    },
  },
}));

interface DateNavigationProps extends BoxProps {
  currentDate: Moment;
  newDate?: (date: Moment) => void;
  prevDate: () => void;
  nextDate: () => void;
  format?: string;
}

export function DateNavigation({
  currentDate,
  newDate,
  prevDate,
  nextDate,
  format = 'MMMM YYYY',
  ...rest
}: DateNavigationProps) {
  const classes = useStyles();

  const handleNewDate = (selectDate: MaterialUiPickersDate) => {
    if (!newDate) return;

    newDate(selectDate || currentDate);
  };
  return (
    <Box
      py={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      color="secondary.dark"
      {...rest}
    >
      <Button
        onClick={prevDate}
        color="inherit"
        variant="outlined"
        style={{
          borderRadius: '50%',
          height: 28,
          width: 28,
          minWidth: 28,
          padding: 0,
        }}
      >
        <ChevronLeftOutlined />
      </Button>

      <DatePicker
        id="cash-flow-date-picker-dialog"
        format={format}
        value={currentDate}
        onChange={(date) => handleNewDate(date)}
        required
        size="medium"
        views={['year', 'month']}
        className={classes.inputDate}
        style={{ border: 'none' }}
        disabled={!newDate}
      />

      <Button
        onClick={nextDate}
        color="inherit"
        variant="outlined"
        style={{
          borderRadius: '50%',
          height: 28,
          width: 28,
          minWidth: 28,
          padding: 0,
        }}
      >
        <ChevronRightOutlined />
      </Button>
    </Box>
  );
}
