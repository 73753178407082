import { Box, BoxProps } from '@material-ui/core';
import { ReactNode } from 'react';

interface SectionContainerProps extends BoxProps {
  children: ReactNode;
}

function SectionContainer({ children, ...rest }: SectionContainerProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      gridGap={16}
      py={2}
      {...rest}
    >
      {children}
    </Box>
  );
}

export { SectionContainer };
