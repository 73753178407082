/* eslint-disable react/no-unescaped-entities */
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/youtube';

import { Button } from '../../../../shared/components/Button';
import ButtonLoadMore from '../../../../shared/components/Button/ButtonLoadMore/intex';
import { Header } from '../../../../shared/components/Header';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { useVideos } from '../../../../shared/hooks/reactQuery/useVideo';

interface VideoProps {
  id: number;
  url: string;
  title: string;
  description: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    maxWidth: '275px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '5px',
    background: theme.palette.background.paper,
    marginBottom: '16px',
    transition: '0.5s',
    [theme.breakpoints.down(604)]: {
      maxWidth: '225px',
    },
    [theme.breakpoints.down(490)]: {
      maxWidth: '500px',
    },
    '&:hover': {
      boxShadow: '0px 7px 26px -7px #000000',
    },
  },
}));

export function ViviTv() {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useVideos();

  const [currentVideo, setCurrentVideo] = useState<VideoProps | null>(null);
  const [videoModal, setVideoModal] = useState(false);
  const classes = useStyles();

  const openModal = (item: VideoProps) => {
    setCurrentVideo(item);
    setVideoModal(true);
  };
  const closeModal = () => {
    setCurrentVideo(null);
    setVideoModal(false);
  };

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.4,
      },
    },
  };

  const items = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.8,
      },
    },
  };
  return (
    <PageContainer display="flex" flexDirection="column" position="relative">
      <Header goPush="/" title="Tutoriais" />
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        style={{ flexWrap: 'wrap' }}
      >
        {data &&
          data.pages.map((group, index) => (
            <React.Fragment key={index}>
              {group.results.map((item) => {
                return (
                  <motion.div
                    variants={container}
                    initial="hidden"
                    animate="show"
                    className={classes.container}
                    style={{ position: 'relative' }}
                    key={item.id}
                  >
                    <Box
                      zIndex={1}
                      style={{ cursor: 'pointer' }}
                      position="absolute"
                      top={0}
                      left={0}
                      width="100%"
                      height="100%"
                      onClick={() => openModal(item)}
                    />
                    <ReactPlayer url={item.url} width="100%" height={150} />
                    <Box
                      p={2}
                      width="100%"
                      style={{ alignItems: 'flex-start', flexWrap: 'wrap' }}
                    >
                      <motion.div
                        variants={items}
                        initial="hidden"
                        animate="show"
                      >
                        <Typography
                          noWrap
                          style={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            lineHeight: 2,
                          }}
                        >
                          {item.title}
                        </Typography>
                      </motion.div>
                      <Box width="100%" height="auto" pr={2} mb={1}>
                        <Typography noWrap style={{ fontSize: '13px' }}>
                          {item.description}
                        </Typography>
                      </Box>
                    </Box>
                  </motion.div>
                );
              })}
            </React.Fragment>
          ))}

        {hasNextPage && (
          <ButtonLoadMore
            disabled={!hasNextPage || isFetchingNextPage}
            loadMore={() => fetchNextPage()}
            loading={isFetchingNextPage}
          />
        )}
      </Box>
      <Dialog
        aria-labelledby="create-product-dialog"
        open={videoModal}
        maxWidth="sm"
      >
        <DialogContent dividers style={{ padding: 0 }}>
          <ReactPlayer
            url={currentVideo?.url}
            controls
            width="100%"
            height={350}
          />
          <Typography
            style={{
              fontSize: '20px',
              paddingLeft: '10px',
              paddingRight: '10px',
              marginBottom: '5px',
            }}
          >
            {currentVideo?.title}
          </Typography>
          <Typography
            style={{
              fontSize: '15px',
              paddingLeft: '10px',
              paddingRight: '10px',
              marginBottom: '5px',
            }}
          >
            {currentVideo?.description}
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: 0 }}>
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="flex-end"
            alignItems="center"
            p={1}
          >
            <Button
              autoFocus
              onClick={closeModal}
              color="primary"
              variant="outlined"
              size="large"
            >
              Fechar
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
}
