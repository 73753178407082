import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  DeleteOutlined,
  EditOutlined,
  VisibilityOutlined,
} from '@material-ui/icons';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import { MouseEvent, useState } from 'react';

import { IconButton } from '../../../../shared/components/IconButton';
import { ClientData } from '../../pages/Client/types';

interface ClientTableProps {
  data: ClientData[];
  onDelete?: (id: number) => void;
  onEdit?: (client: ClientData) => void;
  onView?: (client: ClientData) => void;
}

export function ClientTable({
  data,
  onDelete,
  onEdit,
  onView,
}: ClientTableProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectClient, setSelectClient] = useState<null | ClientData>(null);

  const handleOpenOptions = (
    event: MouseEvent<HTMLButtonElement>,
    client: ClientData
  ) => {
    setSelectClient(client);
    setAnchorEl(event.currentTarget);
  };

  const handleOpenView = (client: ClientData) => {
    if (!onView) return;

    onView(client);
  };

  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    if (!selectClient?.phone || !onDelete) return;
    setAnchorEl(null);

    onDelete(selectClient.id);
  };

  const handleEdit = () => {
    if (!selectClient || !onEdit) return;
    setAnchorEl(null);

    onEdit(selectClient);
  };

  const handleView = () => {
    if (!selectClient || !onView) return;
    setAnchorEl(null);

    onView(selectClient);
  };

  return (
    <>
      <Table>
        <TableBody>
          {!!data.length &&
            data.map((client) => {
              return (
                <TableRow key={client.phone}>
                  <TableCell>
                    <Box
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleOpenView(client)}
                    >
                      <Typography component="div">
                        <Box fontSize="1rem">{client.name}</Box>
                        <Box fontSize="0.82rem" color="secondary.light">
                          {client.phone}
                        </Box>
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      color="primary.dark"
                    >
                      <IconButton
                        aria-controls="menu-options-table"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={(event) => handleOpenOptions(event, client)}
                      >
                        <MoreVertOutlinedIcon color="inherit" />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>

      <Menu
        id="menu-options-table"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseOptions}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleView}>
          <VisibilityOutlined fontSize="small" color="inherit" />
          <Box ml={1}>Ver</Box>
        </MenuItem>

        <MenuItem onClick={handleEdit}>
          <EditOutlined fontSize="small" color="inherit" />
          <Box ml={1}>Editar</Box>
        </MenuItem>

        <MenuItem onClick={handleDelete}>
          <DeleteOutlined fontSize="small" color="inherit" />
          <Box ml={1}>Deletar</Box>
        </MenuItem>
      </Menu>
    </>
  );
}
