import { Box } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { PowerSettingsNewOutlined } from '@material-ui/icons';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { MouseEvent, useState } from 'react';

import { useTheme } from '../../../hooks/useTheme';
import { IconButton } from '../../IconButton';

interface MenuProfileProps {
  signOut: () => void;
  handlePush: (route: string) => void;
}

export function MenuProfile({ signOut, handlePush }: MenuProfileProps) {
  const { isDark, toggleTheme } = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClickMenuProfile = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenuProfile = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-controls="menu-profile"
        aria-haspopup="true"
        color="inherit"
        onClick={handleClickMenuProfile}
      >
        <PersonOutlineIcon />
      </IconButton>
      <Menu
        id="menu-profile"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenuProfile}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={() => handlePush('/profile')}>
          <PersonOutlineIcon color="inherit" />
          <Box ml={1}>Perfil</Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMenuProfile();
            toggleTheme();
          }}
        >
          {isDark ? (
            <Brightness7Icon fontSize="small" color="inherit" />
          ) : (
            <Brightness3Icon fontSize="small" color="inherit" />
          )}
          <Box ml={1}>Trocar tema</Box>
        </MenuItem>
        <MenuItem onClick={signOut}>
          <PowerSettingsNewOutlined fontSize="small" color="inherit" />
          <Box ml={1}>Sair do App</Box>
        </MenuItem>
      </Menu>
    </>
  );
}
