import moment from 'moment';
import { useQuery } from 'react-query';

import { api } from '../../../services/apiClient';
import { formatPrice } from '../../../utils/formatPrice';
import { ICashFlow, ICashFlowResponse } from './types';

type IReactQueryResponse = {
  results: ICashFlow[];
};

async function getData(month: number | string, year: number | string) {
  const response = await api.get<ICashFlowResponse>(
    `/sales/cash-flow/?month=${month}&year=${year}`
  );

  const data: IReactQueryResponse = {
    results: response.data.results?.map((result) => {
      return {
        ...result,
        day: moment(result.date).format('DD'),
        month: moment(result.date).format('MMM'),
        formattedValue: formatPrice(result.value),
      };
    }),
  };
  return data;
}

function useCashFlow(month: number | string, year: number | string) {
  return useQuery<IReactQueryResponse, Error>(
    ['cash-flow', `${month}-${year}`],
    () => getData(month, year)
  );
}

export { useCashFlow };
