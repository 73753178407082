import { Box, BoxProps } from '@material-ui/core';

interface FormGroupProps extends BoxProps {
  title: string;
  children: React.ReactNode;
}

function FormGroup({ title, children, ...rest }: FormGroupProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      gridGap={16}
      mb={4}
      {...rest}
    >
      <Box fontSize="0.85rem" color="secondary.light">
        {title}
      </Box>

      {children}
    </Box>
  );
}

export { FormGroup };
