/* eslint-disable no-nested-ternary */
import { FC, useCallback } from 'react';
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
  useLocation,
} from 'react-router-dom';

import { usePremium } from '../shared/hooks/usePremium';
import { AuthLayout } from '../shared/layouts/AuthLayout';
import { CleanLayout } from '../shared/layouts/CleanLayout';
import { DefaultLayout } from '../shared/layouts/DefaultLayout';

type Location = {
  hash: string;
  pathname: string;
  search: string;
  state: {
    from: {
      hash: string;
      pathname: string;
      search: string;
    };
  };
};

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  isPremium?: boolean;
  pageName?: string;
  isNotFound?: boolean;
  layout?: 'default' | 'clean';
}

const Route: FC<RouteProps> = ({
  isPrivate = false,
  isPremium = false,
  pageName,
  isNotFound,
  layout = 'default',
  children,
  ...rest
}) => {
  const isUserPremium = usePremium();
  const isAuthenticated = localStorage.getItem('application.auth.token');
  const getLocation = useLocation<Location>();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { from } = getLocation?.state || {
    from: { pathname: '/' },
  };

  const Layout = useCallback(() => {
    if (isPrivate) {
      switch (layout) {
        case 'default':
          return <DefaultLayout pageName={pageName}>{children}</DefaultLayout>;
        case 'clean':
          return <CleanLayout pageName={pageName}>{children}</CleanLayout>;
        default:
          return <DefaultLayout pageName={pageName}>{children}</DefaultLayout>;
      }
    }

    return <AuthLayout>{children}</AuthLayout>;
  }, [isPrivate, pageName, children, layout]);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!isAuthenticated || isNotFound ? (
          !isPremium || (isPremium && isUserPremium) ? (
            <Layout />
          ) : (
            <Redirect
              to={{
                pathname: isPrivate ? '/' : from.pathname,
                state: { from: location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/sign-in' : from.pathname,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export { Route };
