import MomentUtils from '@date-io/moment';
import { IconButton, makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Close } from '@material-ui/icons';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/pt-br';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { createRef, useCallback } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';

import { Router } from './routers';
import ServiceWorkerWrapper from './shared/components/ServiceWorkerWrapper';
import { AnimateLottieAlertProvider } from './shared/contexts/AnimateLottieAlertContext';
import { AuthProvider } from './shared/contexts/AuthContext';
import { ThemeCustomProvider } from './shared/contexts/ThemeCustomContext';
import { UploadProvider } from './shared/contexts/UploadContext';
import { YourRegistrationProvider } from './shared/contexts/YourRegistrationContext';
import { BudgetProvider } from './shared/contexts/BudgetContext';

moment.locale('pt-br');

const queryClient = new QueryClient();

const useStyles = makeStyles(() => ({
  success: {
    backgroundColor: '#FFB7AB',
  },
  error: {
    backgroundColor: '#B54D54',
  },
  warning: {
    backgroundColor: '#FFEFDA',
    color: '#666666',
  },
  info: {
    backgroundColor: '#f0f3f5',
    color: '#333333',
  },
}));

function App() {
  const classes = useStyles();
  // add action to all snackbars
  const notistackRef = createRef<SnackbarProvider>();
  const onClickDismiss = useCallback(
    (key: SnackbarKey) => () => {
      notistackRef?.current?.closeSnackbar(key);
    },
    [notistackRef]
  );

  return (
    <QueryClientProvider client={queryClient}>
      {/* The rest of your application */}
      <ReactQueryDevtools initialIsOpen />
      <ThemeCustomProvider>
        <CssBaseline />
        <AnimateLottieAlertProvider>
          <SnackbarProvider
            classes={{
              variantSuccess: classes.success,
              variantError: classes.error,
              variantWarning: classes.warning,
              variantInfo: classes.info,
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            ref={notistackRef}
            action={(key) => (
              <IconButton color="inherit" onClick={onClickDismiss(key)}>
                <Close />
              </IconButton>
            )}
            maxSnack={5}
          >
            <BrowserRouter>
              <AuthProvider>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
                  <BudgetProvider>
                    <UploadProvider>
                      <YourRegistrationProvider>
                        <ServiceWorkerWrapper />
                        <Router />
                      </YourRegistrationProvider>
                    </UploadProvider>
                  </BudgetProvider>
                </MuiPickersUtilsProvider>
              </AuthProvider>
            </BrowserRouter>
          </SnackbarProvider>
        </AnimateLottieAlertProvider>
      </ThemeCustomProvider>
    </QueryClientProvider>
  );
}

export default App;
