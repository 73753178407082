import { Player } from '@lottiefiles/react-lottie-player';
import { Backdrop, Box, useMediaQuery } from '@material-ui/core';

import { useRenderLottieStyles } from './styles';

interface RenderLottieProps {
  lottie: Record<string, unknown>;
  description?: string;
  isOpen: boolean;
  onClose?: () => void;
}

export function RenderLottie({
  lottie,
  description = '',
  isOpen,
  onClose,
}: RenderLottieProps) {
  const classes = useRenderLottieStyles();
  const isNotMobile = useMediaQuery('(min-width:600px)');

  return (
    <Backdrop className={classes.backdrop} open={isOpen} onClick={onClose}>
      {isOpen && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          bgcolor={description ? 'background.paper' : undefined}
          borderRadius={20}
          py={2}
          px={1}
        >
          <Player
            autoplay
            loop
            src={lottie}
            style={{
              height: isNotMobile ? '300px' : '200px',
              width: isNotMobile ? '300px' : '200px',
            }}
          />

          {description && (
            <Box
              textAlign="center"
              color="primary.main"
              fontWeight="medium"
              fontSize="1rem"
            >
              {description}
            </Box>
          )}
        </Box>
      )}
    </Backdrop>
  );
}
