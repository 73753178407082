import { Box, BoxProps, Typography } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Skeleton } from '@material-ui/lab';
import { Fragment, ReactNode } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Button } from '../../../../shared/components/Button';
import PremiumIcon from '../../../../shared/components/Icons/PremiumIcon';
import { useStyles } from './styles';

interface Props extends BoxProps {
  title: string;
  value: string | number;
  showPercentage?: boolean;
  percentage?: string | number;
  link?: any;
  onEdit?: any;
  visible?: boolean;
  children?: ReactNode;
  isLoading?: boolean;
}

export function CardDetailTechnicalSheet({
  title,
  value,
  showPercentage = false,
  percentage,
  link,
  onEdit,
  visible,
  children,
  isLoading,
  ...rest
}: Props) {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      textAlign="center"
      padding={[1, 1]}
      style={{
        width: '100%',
        height: '100%',
        minHeight: '100%',
        border: '2px solid #F4DDDD',
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      {...rest}
    >
      {visible && onEdit && !isLoading && (
        <Box
          display="flex"
          width="100%"
          justifyContent={['center', 'flex-end']}
          color="primary.main"
          marginBottom={1}
          style={{ cursor: 'pointer' }}
        >
          {!children && (
            <Link to="/records/sales-adjustments">
              <Button
                size="small"
                variant="outlined"
                color="primary"
                style={{ border: 'none', padding: 1 }}
                className={classes.hideOnPrint}
              >
                <EditOutlinedIcon
                  color="inherit"
                  style={{ fontSize: '18px' }}
                />
                <Typography component="small" style={{ fontSize: '0.675rem' }}>
                  Ajuste de venda
                </Typography>
              </Button>
            </Link>
          )}

          {children}
        </Box>
      )}
      {isLoading && (
        <>
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="40%" />
        </>
      )}
      {!isLoading && (
        <>
          <small className={classes.cardTitle}>{title}</small>
          <small className={classes.cardContent}>
            {visible === true && (
              <>
                <Box>{value}</Box>
                {showPercentage && <Box component="strong">{percentage}</Box>}
              </>
            )}
          </small>
        </>
      )}

      {!visible && !isLoading && (
        <Link to="/become-premium">
          <Button
            className={classes.showButton}
            color="primary"
            variant="contained"
            style={{ border: 'none', paddingLeft: 16, paddingRight: 16 }}
            startIcon={<PremiumIcon className={classes.premiumColor} />}
          >
            Mostrar
          </Button>
        </Link>
      )}
    </Box>
  );
}
