import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function ProductIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M23 21.2283H1V4.51903C1 3.68356 1.68276 3 2.51724 3H21.4828C22.3172 3 23 3.68356 23 4.51903V21.2283ZM1.75862 20.4688H22.2414V4.51903C22.2414 4.10129 21.9 3.75951 21.4828 3.75951H2.51724C2.1 3.75951 1.75862 4.10129 1.75862 4.51903V20.4688Z" />
      <path d="M22.6207 10.2154H15.0345V10.9749H22.6207V10.2154Z" />
      <path d="M14.2759 10.2154H13.5172V10.9749H14.2759V10.2154Z" />
      <path d="M12.7586 10.2154H1.3793V10.9749H12.7586V10.2154Z" />
      <path d="M16.5517 22.7474H2.51724C1.68276 22.7474 1 22.0638 1 21.2283V20.8486H1.75862V21.2283C1.75862 21.6461 2.1 21.9878 2.51724 21.9878H16.5517V22.7474Z" />
      <path d="M18.069 21.9879H17.3103V22.7474H18.069V21.9879Z" />
      <path d="M21.4827 22.7474H18.8276V21.9878H21.4827C21.9 21.9878 22.2414 21.6461 22.2414 21.2283V20.8486H23V21.2283C23 22.0638 22.3172 22.7474 21.4827 22.7474Z" />
      <path d="M9.38276 10.9749V10.2154C10.1414 10.2154 10.8621 9.79763 11.2034 9.11407L11.6207 8.2786C11.6207 8.24063 11.6586 8.20265 11.6586 8.16467C11.6586 8.05075 11.5448 7.93682 11.431 7.93682H3.54138C3.42759 7.93682 3.3138 8.05075 3.3138 8.16467C3.3138 8.20265 3.31379 8.24063 3.35172 8.2786L3.76897 9.11407C4.11035 9.79763 4.7931 10.2154 5.58966 10.2154V10.9749C4.52759 10.9749 3.57931 10.3673 3.08621 9.45585L2.66896 8.62038C2.5931 8.46848 2.55518 8.31658 2.55518 8.16467C2.55518 7.63301 3.01034 7.17731 3.54138 7.17731H11.431C11.9621 7.17731 12.4172 7.63301 12.4172 8.16467C12.4172 8.31658 12.3793 8.46848 12.3034 8.62038L11.8862 9.45585C11.3931 10.3673 10.4448 10.9749 9.38276 10.9749Z" />
      <path d="M8.28276 7.55705H7.52414C7.52414 6.72158 6.80345 6.03802 5.93104 6.03802C5.05862 6.03802 4.33794 6.72158 4.33794 7.55705H3.57932C3.57932 6.30385 4.64138 5.2785 5.93104 5.2785C7.22069 5.2785 8.28276 6.30385 8.28276 7.55705Z" />
      <path d="M11.6965 7.55704H10.9379C10.9379 6.30384 9.87585 5.2785 8.5862 5.2785C7.94137 5.2785 7.33447 5.54433 6.8793 6.03801L6.31033 5.54433C6.8793 4.89874 7.71378 4.51898 8.5862 4.51898C10.2552 4.51898 11.6965 5.92408 11.6965 7.55704Z" />
      <path d="M20.7241 7.17735H19.2069V6.41783H20.7241V5.65832H15.4138V6.41783H18.4483V7.17735H15.4138C14.9966 7.17735 14.6552 6.83557 14.6552 6.41783V5.65832C14.6552 5.24059 14.9966 4.8988 15.4138 4.8988H20.7241C21.1414 4.8988 21.4828 5.24059 21.4828 5.65832V6.41783C21.4828 6.83557 21.1414 7.17735 20.7241 7.17735Z" />
      <path d="M18.9793 10.9749V10.2154C19.169 10.2154 19.3207 10.1015 19.3586 9.91158L20.1172 6.72162L20.8379 6.9115L20.0793 10.1015C19.9655 10.5951 19.5103 10.9749 18.9793 10.9749Z" />
      <path d="M17.1586 10.9749C16.6276 10.9749 16.1345 10.5951 16.0586 10.0635L15.3 6.87353L16.0207 6.68365L16.7793 9.87361C16.8172 10.0635 16.969 10.1774 17.1586 10.1774V10.9749Z" />
      <path d="M12.0379 21.1144L11.5069 20.5827C12.3414 19.7473 12.7586 18.608 12.7586 17.4307C12.7586 16.7092 12.569 15.9877 12.2276 15.3421L12.9103 15.0003C13.2897 15.7598 13.5172 16.5953 13.5172 17.4687C13.5172 18.7979 12.9862 20.127 12.0379 21.1144Z" />
      <path d="M4.37589 21.1144C3.42761 20.127 2.89658 18.7979 2.89658 17.4308C2.89658 16.5953 3.08623 15.7219 3.50348 14.9623L4.18623 15.3041C3.84485 15.9497 3.6552 16.6712 3.6552 17.3928C3.6552 18.57 4.11037 19.7093 4.90693 20.5448L4.37589 21.1144Z" />
      <path d="M5.21034 19.7853C4.7931 19.2536 4.52758 18.608 4.41379 17.9245L5.17241 17.8485C5.24827 18.3802 5.43793 18.9118 5.77931 19.3296L5.21034 19.7853Z" />
      <path d="M5.21034 17.051L4.45172 16.975C4.48965 16.7092 4.52758 16.4434 4.64138 16.2155L5.36206 16.4434C5.24827 16.6332 5.21034 16.8611 5.21034 17.051Z" />
      <path d="M13.1379 15.532H11.6207V14.7724H13.1379V14.0129H3.27586V14.7724H10.8621V15.532H3.27586C2.85862 15.532 2.51724 15.1902 2.51724 14.7724V14.0129C2.51724 13.5952 2.85862 13.2534 3.27586 13.2534H13.1379C13.5552 13.2534 13.8966 13.5952 13.8966 14.0129V14.7724C13.8966 15.1902 13.5552 15.532 13.1379 15.532Z" />
      <path d="M9.95174 13.7851L9.57243 12.8357C9.49657 12.6458 9.30691 12.4939 9.11725 12.4939H7.29657C7.10691 12.4939 6.91725 12.6079 6.84139 12.8357L6.46208 13.7851L5.74139 13.5193L6.1207 12.5699C6.31036 12.0762 6.76553 11.7724 7.29657 11.7724H9.11725C9.64828 11.7724 10.1035 12.1142 10.2931 12.5699L10.6724 13.5193L9.95174 13.7851Z" />
      <path d="M20.7241 14.0129H16.1724V14.7725H20.7241V14.0129Z" />
      <path d="M21.1034 20.8485H20.3448V14.5446L18.4483 12.5318L16.5517 14.5446V20.8485H15.7931V14.2408L18.4483 11.3926L21.1034 14.2408V20.8485Z" />
      <path d="M18.069 15.532H17.3103V16.2915H18.069V15.532Z" />
      <path d="M18.069 17.051H17.3103V17.8105H18.069V17.051Z" />
      <path d="M18.069 18.57H17.3103V19.3295H18.069V18.57Z" />
    </SvgIcon>
  );
}
