import { Budget } from '../modules/budgets/pages/Budget';
import { Calculator } from '../modules/sales/pages/Calculator';
import { ResultRecipe } from '../modules/sales/pages/Calculator/ResultRecipe';
import { SelectRecipe } from '../modules/sales/pages/Calculator/SelectRecipe';
import { CashFlow } from '../modules/sales/pages/CashFlow';
import { CashFlowBalancePoint } from '../modules/sales/pages/CashFlowBalancePoint';
import { CashFlowDetail } from '../modules/sales/pages/CashFlowDetail';
import { Sale } from '../modules/sales/pages/Sale';
import { Schedule } from '../modules/sales/pages/Schedule';
import { ScheduleDetail } from '../modules/sales/pages/ScheduleDetail';
import { IRoutes } from './types.routes';

export const salesRoutes: IRoutes[] = [
  {
    path: '/sales',
    children: <Sale />,
    isPrivate: true,
    exact: true,
    pageName: 'Vendas',
  },
  {
    path: '/sales/schedule/:id/detail/:date?',
    children: <ScheduleDetail />,
    isPrivate: true,
    exact: true,
    pageName: 'Detalhe da Agenda de Vendas',
  },
  {
    path: '/sales/schedule/:date?',
    children: <Schedule />,
    isPrivate: true,
    exact: true,
    pageName: 'Agenda de Vendas',
  },
  {
    path: '/sales/cash-flow/:id/detail/:date?',
    children: <CashFlowDetail />,
    isPrivate: true,
    isPremium: true,
    exact: true,
    pageName: 'Detalhe do Fluxo de Caixa',
  },
  {
    path: '/sales/cash-flow/:date?',
    children: <CashFlow />,
    isPrivate: true,
    isPremium: true,
    exact: true,
    pageName: 'Fluxo de Caixa',
  },
  {
    path: '/sales/cash-flow/balance-point/:year?',
    children: <CashFlowBalancePoint />,
    isPrivate: true,
    isPremium: true,
    exact: true,
    pageName: 'Ponto de Equilíbrio',
  },
  {
    path: '/sales/calculator',
    children: <Calculator />,
    isPrivate: true,
    isPremium: true,
    exact: true,
    pageName: 'Calculadora de Compras',
  },

  {
    path: '/sales/budgets',
    children: <Budget />,
    isPrivate: true,
    exact: true,
    pageName: 'Orçamentos',
  },
  {
    path: '/sales/calculator/pages/select-technical-sheet',
    children: <Calculator />,
    isPrivate: true,
    isPremium: true,
    exact: true,
    pageName: 'Calculadora de Compras',
  },
  {
    path: '/sales/calculator/select-technical-sheet/:slug?',
    children: <Calculator />,
    isPrivate: true,
    isPremium: true,
    exact: true,
    pageName: 'Calculadora de Compras',
  },
  {
    path: '/sales/calculator/recipe/results/:id?',
    children: <ResultRecipe />,
    isPrivate: true,
    isPremium: true,
    exact: true,
    pageName: 'Calculadora de Compras',
  },
  {
    path: '/sales/calculator/recipe/:id?',
    children: <SelectRecipe />,
    isPrivate: true,
    isPremium: true,
    exact: true,
    pageName: 'Calculadora de Compras',
  },
];
