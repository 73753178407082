import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function CarIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M4.45523 23C4.34692 23 4.20249 22.9627 4.09417 22.8881L2.5416 21.9187C2.28885 21.7695 2.10832 21.5085 2.03611 21.2475C1.96389 20.9492 2 20.6509 2.14443 20.3898L4.34692 16.5492L4.96072 16.922L2.75823 20.8C2.72213 20.8746 2.72213 20.9865 2.72213 21.061C2.75823 21.1729 2.79434 21.2475 2.90266 21.2848L4.45523 22.2542C4.49134 22.1797 4.52745 22.0305 4.52745 21.9187C4.52745 21.6203 4.41913 21.4712 4.13028 21.1356L3.94975 20.9492L5.82728 17.7424L6.44109 18.1153L4.85241 20.8746C5.06904 21.0983 5.24958 21.3966 5.24958 21.9187C5.24958 22.217 5.17736 22.4407 5.06904 22.6644C4.92462 22.8881 4.70798 23 4.45523 23Z" />
      <path d="M14.2762 15.1695H12.1098C11.496 15.1695 11.0266 14.6848 11.0266 14.0509C11.0266 14.0136 11.0266 13.939 11.0266 13.9017L11.7487 14.0136V14.0509C11.7487 14.2746 11.8932 14.4237 12.1098 14.4237H14.2762V15.1695Z" />
      <path d="M21.4975 15.1695H16.4426V14.4237H21.4975C21.7141 14.4237 21.8585 14.2746 21.8585 14.0508C21.8585 13.8271 21.7141 13.678 21.4975 13.678H12.1098C12.0376 13.678 11.9654 13.678 11.9293 13.7152L11.5682 13.0813C11.7487 12.9695 11.9293 12.9322 12.1459 12.9322H21.5336C22.1474 12.9322 22.6168 13.4169 22.6168 14.0508C22.6168 14.6847 22.1113 15.1695 21.4975 15.1695Z" />
      <path d="M12.4709 23C11.6765 23 11.0266 22.3288 11.0266 21.5085C11.0266 20.6882 11.6765 20.017 12.4709 20.017C13.2652 20.017 13.9151 20.6882 13.9151 21.5085C13.9151 22.3288 13.2652 23 12.4709 23ZM12.4709 20.7627C12.0737 20.7627 11.7487 21.0983 11.7487 21.5085C11.7487 21.9187 12.0737 22.2543 12.4709 22.2543C12.868 22.2543 13.193 21.9187 13.193 21.5085C13.193 21.0983 12.868 20.7627 12.4709 20.7627Z" />
      <path d="M21.1364 23C20.3421 23 19.6921 22.3288 19.6921 21.5085C19.6921 20.6882 20.3421 20.017 21.1364 20.017C21.9307 20.017 22.5807 20.6882 22.5807 21.5085C22.5807 22.3288 21.9307 23 21.1364 23ZM21.1364 20.7627C20.7392 20.7627 20.4143 21.0983 20.4143 21.5085C20.4143 21.9187 20.7392 22.2543 21.1364 22.2543C21.5336 22.2543 21.8585 21.9187 21.8585 21.5085C21.8585 21.0983 21.5336 20.7627 21.1364 20.7627Z" />
      <path d="M21.4975 14.7966H20.7753V20.3898H21.4975V14.7966Z" />
      <path d="M12.8319 14.7966H12.1098V20.3898H12.8319V14.7966Z" />
      <path d="M17.5258 21.1356H13.554V21.8814H17.5258V21.1356Z" />
      <path d="M9.54623 23C9.3657 23 9.18517 22.9627 9.04075 22.8508C8.788 22.7017 8.60747 22.478 8.49915 22.1797L7.23543 17.9288L4.70798 14.8339C4.38302 14.4237 4.16638 13.8644 4.16638 13.2678C4.16638 13.0068 4.20249 12.7085 4.31081 12.4474L5.82728 7.97287C6.04391 7.33897 6.58551 6.9288 7.23543 6.9288C7.84923 6.9288 8.35472 7.30168 8.60747 7.86101L9.79898 10.7322L11.7487 12.4102C12.0015 12.6339 12.1459 12.9322 12.1459 13.2678C12.1459 13.9017 11.6765 14.3864 11.0627 14.3864C10.81 14.3864 10.5572 14.3119 10.3767 14.1254L8.2103 12.261C8.06587 12.1491 7.99366 12 7.92145 11.8508L7.05489 9.7627L7.70481 9.4644L8.57136 11.5525C8.60747 11.5898 8.64357 11.6644 8.67968 11.7017L10.8461 13.5661C10.8822 13.6034 10.9544 13.6407 11.0627 13.6407C11.2793 13.6407 11.4238 13.4915 11.4238 13.2678C11.4238 13.1559 11.3877 13.0441 11.2793 12.9695L9.18517 11.1796L7.92145 8.15931C7.81313 7.86101 7.52428 7.67457 7.19932 7.67457C6.87436 7.67457 6.58551 7.89829 6.47719 8.1966L4.96072 12.6712C4.88851 12.8576 4.8524 13.0441 4.8524 13.2305C4.8524 13.6407 4.99683 14.0135 5.24957 14.3491L7.88534 17.5559L9.22128 21.9932C9.25738 22.1051 9.3296 22.1796 9.40181 22.2169C9.47402 22.2542 9.58234 22.2542 9.65455 22.2542L11.3877 21.6949L11.6404 22.4034L11.5682 22.4407L9.83509 23C9.76287 23 9.65455 23 9.54623 23Z" />
      <path d="M11.2071 21.3966C11.0266 21.2102 10.7377 21.1356 10.3406 21.1356H10.0878L8.93242 17.2576C8.89631 17.183 8.8602 17.1085 8.8241 17.0339L6.65771 13.2305L7.41595 10.6203L8.10197 10.8441L7.45206 13.1559L9.47401 16.6983C9.54623 16.8102 9.61844 16.9593 9.65455 17.1085L10.6655 20.5017C11.1349 20.539 11.496 20.6881 11.7487 20.9491L11.2071 21.3966Z" />
      <path d="M7.77703 4.72881C7.56039 4.72881 7.34375 4.61695 7.19933 4.43051L6.87437 3.98305C6.36888 3.87119 5.97171 3.42373 5.97171 2.86441C5.97171 2.30508 6.26056 1 8.86022 1C9.97952 1 10.6655 1.6339 10.6655 2.67797C10.6655 3.16271 10.4128 3.57288 10.3045 3.75932C10.2323 4.09492 9.94341 4.35593 9.58235 4.35593C8.78801 4.35593 8.06588 4.65424 8.06588 4.65424C7.95756 4.69153 7.88535 4.72881 7.77703 4.72881ZM8.86022 1.74576C8.21031 1.74576 6.69384 1.85763 6.69384 2.86441C6.69384 3.08814 6.83826 3.23729 7.0549 3.23729H7.23543L7.77703 3.98305C7.77703 3.98305 8.60748 3.61017 9.58235 3.61017V3.46102L9.65456 3.34915C9.65456 3.34915 9.94341 3.01356 9.94341 2.64068C9.94341 2.04407 9.58235 1.74576 8.86022 1.74576Z" />
      <path d="M8.49916 6.59322C7.70482 6.59322 7.0549 5.92203 7.0549 5.10169V4.09491H7.77703V5.10169C7.77703 5.51186 8.10199 5.84746 8.49916 5.84746C8.82411 5.84746 9.11297 5.62373 9.18518 5.28813L9.58235 3.61017L10.2684 3.79661L9.8712 5.47458C9.76288 6.10847 9.18518 6.59322 8.49916 6.59322Z" />
      <path d="M21.1364 13.3051H20.4143V10.6949C20.4143 10.2848 20.0893 9.94916 19.6921 9.94916H13.9151C13.5179 9.94916 13.193 10.2848 13.193 10.6949V13.3051H12.4709V10.6949C12.4709 9.87459 13.1208 9.2034 13.9151 9.2034H19.6921C20.4865 9.2034 21.1364 9.87459 21.1364 10.6949V13.3051Z" />
      <path d="M20.0532 9.57627H19.3311V7.71186C19.3311 7.30169 19.0061 6.9661 18.6089 6.9661H14.9983C14.6011 6.9661 14.2762 7.30169 14.2762 7.71186V9.57627H13.554V7.71186C13.554 6.89152 14.204 6.22034 14.9983 6.22034H18.6089C19.4033 6.22034 20.0532 6.89152 20.0532 7.71186V9.57627Z" />
      <path d="M19.3311 12.1864C18.8617 12.1864 18.4284 12.0373 18.0673 11.7763C17.3452 12.3356 16.262 12.3356 15.5399 11.7763C15.1788 12.0373 14.7456 12.1864 14.2762 12.1864C13.5901 12.1864 12.9402 11.8509 12.5431 11.2915L13.1208 10.8441C13.4096 11.217 13.8068 11.4407 14.2762 11.4407C14.6733 11.4407 14.9983 11.2915 15.2871 11.0305L15.5399 10.7695L15.7926 11.0305C16.3342 11.5898 17.273 11.5898 17.8146 11.0305L18.0673 10.7695L18.3201 11.0305C18.6089 11.2915 18.9339 11.4407 19.3311 11.4407C19.8004 11.4407 20.1976 11.217 20.4865 10.8441L21.0642 11.2915C20.667 11.8509 20.0171 12.1864 19.3311 12.1864Z" />
      <path d="M16.4425 6.96611C15.6482 6.96611 14.9983 6.29493 14.9983 5.47459C14.9983 4.65425 15.6482 3.98306 16.4425 3.98306C17.2369 3.98306 17.8868 4.65425 17.8868 5.47459C17.8868 6.29493 17.2369 6.96611 16.4425 6.96611ZM16.4425 4.72883C16.0454 4.72883 15.7204 5.06442 15.7204 5.47459C15.7204 5.88476 16.0454 6.22035 16.4425 6.22035C16.8397 6.22035 17.1647 5.88476 17.1647 5.47459C17.1647 5.06442 16.8397 4.72883 16.4425 4.72883Z" />
      <path d="M17.4535 4.95253L16.9481 4.43049L17.4174 3.94575C17.8507 3.49829 18.4284 3.23727 19.0783 3.23727H19.3311V3.98304H19.0783C18.6451 3.98304 18.2479 4.16948 17.9229 4.46778L17.4535 4.95253Z" />
      <path d="M18.97 21.1356H18.2479V21.8814H18.97V21.1356Z" />
      <path d="M15.7204 14.4237H14.9983V15.1695H15.7204V14.4237Z" />
    </SvgIcon>
  );
}
