/* eslint-disable import/no-extraneous-dependencies */
import { Avatar, Box, Theme, Typography, makeStyles } from '@material-ui/core';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Card,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Modal } from './index';
import { logoPageAuth as logoBase64 } from '../../assets/logoBase64';
import { Flex } from '../Flex';

import { background } from '../../assets/backgroundBase64';
import Iconify from '../Iconify';
import { ButtonLarge } from '../Button/ButtonLarge';
import PremiumIcon from '../Icons/PremiumIcon';
import { api } from '../../services/apiClient';
import { formatPrice } from '../../utils/formatPrice';
import { useSnackMessages } from '../../hooks/useSnackMessages';
import { getStripeJS } from '../../services/stripe-js';

interface ModalBePremiumProps {
  open: boolean;
  handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  plan: {
    background: 'transparent',
    color: '#FFF',
    fontWeight: 500,
    borderColor: '#FFF',
  },
  logo: {
    width: 80,
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  planPrice: {
    '& strong': {
      fontSize: '2rem',
    },
    '& sup, & sub': {
      fontSize: '1rem',
    },
    '& sup': {
      fontSize: '1.2rem',
    },
  },
  divider: {
    borderColor: theme.border.color,
  },
  shadow: {
    color: theme.text.primary,
    boxShadow: theme.shadows[5],
    background: `url(${background}), ${theme.palette.primary.dark}`,
    backgroundSize: '60%',
    backgroundPosition: 'top left',
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    marginTop: -theme.spacing(2),
    [theme.breakpoints.up(560)]: {
      backgroundSize: '50%',
    },
    [theme.breakpoints.up(760)]: {
      backgroundSize: '30%',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: -theme.spacing(3),
      marginRight: -theme.spacing(3),
    },
  },
  title: {
    color: '#FFF',
  },
  subtitle: {
    fontWeight: 'bold',
    color: theme.text.primary,
    marginBottom: theme.spacing(2),
  },
  subtitleLinks: {
    fontWeight: 'bold',
    color: theme.text.primary,
    marginBottom: theme.spacing(2),
    '& a': {
      color: theme.palette.primary.main,
      fontWeight: 'normal',
      display: 'block',
    },
  },

  buttonIcon: {
    color: theme.color.premium.main,
  },
}));
type StripePrice = {
  id: string;
  active: boolean;
  created: number;
  currency: string;
  product: {
    id: string;
    active: boolean;
    created: number;
    images?: string[];
    name: string;
    description?: string;
  };
  recurring: {
    interval: string;
    interval_count: number;
    trial_period_days?: number;
  };
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
  formattedPrice: string;
};

export function ModalBePremium({ open, handleClose }: ModalBePremiumProps) {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const { msgError } = useSnackMessages();
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [stripePrices, setStripePrices] = useState<StripePrice[]>([]);

  useEffect(() => {
    api
      .get<{ results: { data: StripePrice[] } }>('/signature/products')
      .then((response: any) => {
        setStripePrices(
          response.data?.results?.data?.map((item: any) => {
            return {
              ...item,
              formattedPrice: formatPrice(item.unit_amount / 100),
            };
          })
        );
      })
      .catch((err: any) => {
        msgError(err?.response?.data?.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function handleSubscribe(product_price_id: string) {
    if (loading) return;
    setOpenModal(false);
    setLoading(true);

    try {
      const response = await api.post<{ sessionId: string }>(
        'signature/stripe/create-checkout-sessions',
        {
          product_price_id,
        }
      );

      const { sessionId } = response.data;

      const stripe = await getStripeJS();

      await stripe?.redirectToCheckout({ sessionId });
    } catch (err: any) {
      setLoading(false);
      msgError(err?.response?.data?.message);
      if (
        err?.response?.data?.message === 'Você já tem uma assinatura premium'
      ) {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
  }

  const [loading, setLoading] = useState(false);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{
        width: '100%',
      }}
    >
      <Box style={{ width: '90%', margin: '0 auto' }}>
        <Flex
          flexDirection="column"
          p={2}
          borderRadius="0 0 20px 20px"
          color="primary.contrastText"
          className={classes.shadow}
        >
          <Flex
            marginX="auto"
            width="80px"
            height="80px"
            borderRadius="50%"
            bgcolor="#fff"
            boxShadow="0px 8px 4px rgba(0, 0, 0, 0.1)"
            mb={1}
            mt={1}
          >
            <img src={logoBase64} alt="Logo" width="60px" />
          </Flex>
          <Typography
            variant="h6"
            style={{ fontWeight: 'bold' }}
            className={classes.title}
          >
            Adquira seu aplicativo Premium!
          </Typography>
        </Flex>
      </Box>
      <Box style={{ width: '90%', margin: '0 auto' }}>
        <Typography
          variant="body1"
          className={classes.title}
          style={{ fontSize: '24pix', color: '#A06464', margin: '20px 0' }}
        >
          Mais facilidade e praticidade para o seu dia-a-dia!
        </Typography>
        <Typography
          variant="body2"
          className={classes.title}
          style={{ fontSize: '18pix', color: '#A06464', margin: '10px 0' }}
        >
          <Iconify
            icon="icons8:checked"
            color="green"
            fontSize="20px"
            style={{ marginRight: '10px' }}
          />
          Gratuito até 10 Clientes, sendo Premium cadastro ilimitados
        </Typography>
        <Typography
          variant="body2"
          className={classes.title}
          style={{ fontSize: '18pix', color: '#A06464', margin: '10px 0' }}
        >
          <Iconify
            icon="icons8:checked"
            color="green"
            fontSize="20px"
            style={{ marginRight: '10px' }}
          />
          Gratuito até 10 Produtos, sendo Premium cadastro ilimitados
        </Typography>
        <Typography
          variant="body2"
          className={classes.title}
          style={{ fontSize: '18pix', color: '#A06464', margin: '10px 0' }}
        >
          <Iconify
            icon="icons8:checked"
            color="green"
            fontSize="20px"
            style={{ marginRight: '10px' }}
          />
          Gratuito até 10 Fichas Técnicas, sendo Premium cadastro ilimitados
        </Typography>
        <Typography
          variant="body2"
          className={classes.title}
          style={{ fontSize: '18pix', color: '#A06464', margin: '10px 0' }}
        >
          <Iconify
            icon="icons8:checked"
            color="green"
            fontSize="20px"
            style={{ marginRight: '10px' }}
          />
          Calcule as próximas compras com uma calculadora de matéria prima
          completa
        </Typography>
        <Typography
          variant="body2"
          className={classes.title}
          style={{ fontSize: '18pix', color: '#A06464', margin: '10px 0' }}
        >
          <Iconify
            icon="icons8:checked"
            color="green"
            fontSize="20px"
            style={{ marginRight: '10px' }}
          />
          Ajuste as variáveis de Lucro e Contribuição, e saiba por quanto vender
          seu bolo
        </Typography>
        <Typography
          variant="body2"
          className={classes.title}
          style={{ fontSize: '18pix', color: '#A06464', margin: '10px 0' }}
        >
          <Iconify
            icon="icons8:checked"
            color="green"
            fontSize="20px"
            style={{ marginRight: '10px' }}
          />
          Em nossa agenda simplificada, gerencie os pagamentos recebidos pelas
          entregas
        </Typography>
        <Typography
          variant="body2"
          className={classes.title}
          style={{ fontSize: '18pix', color: '#A06464', margin: '10px 0' }}
        >
          <Iconify
            icon="icons8:checked"
            color="green"
            fontSize="20px"
            style={{ marginRight: '10px' }}
          />
          Não veja mais propagandas enquanto usa nosso aplicativo
        </Typography>
        <Typography
          variant="body2"
          className={classes.title}
          style={{ fontSize: '18pix', color: '#A06464', margin: '10px 0' }}
        >
          <Iconify
            icon="icons8:checked"
            color="green"
            fontSize="20px"
            style={{ marginRight: '10px' }}
          />
          Cadastre Clientes e gere Orçamentos em PDF
        </Typography>
        <ButtonLarge
          onClick={handleOpenModal}
          isDisabled={loading}
          startIcon={<PremiumIcon className={classes.buttonIcon} />}
          style={{
            marginBottom: '10px',
          }}
        >
          {loading ? 'Carregando...' : 'Assinar Plano Premium'}
        </ButtonLarge>
      </Box>
      <Modal
        title="Assinar Plano Premium"
        open={openModal}
        onClose={handleCloseModal}
      >
        <DialogContent dividers>
          <Box display="flex" justifyContent="center" width="100%">
            {stripePrices?.map((item) => {
              return (
                <Box
                  key={item.id}
                  width="100%"
                  maxWidth={200}
                  borderRadius={4}
                  bgcolor="background.paper"
                  m={2}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  border={
                    item?.recurring?.interval === 'month'
                      ? '1px solid #FF9988'
                      : '1px solid #B54D54'
                  }
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSubscribe(item.id)}
                >
                  <Box
                    width="100%"
                    color="primary.contrastText"
                    bgcolor={
                      item?.recurring?.interval === 'month'
                        ? 'primary.main'
                        : '#B54D54'
                    }
                    mb={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="4px 4px 0 0"
                    p={1}
                    fontSize="1.1rem"
                  >
                    {item?.recurring?.interval === 'month'
                      ? 'Plano Mensal'
                      : 'Plano Anual'}
                  </Box>
                  <Box>
                    <Avatar
                      src={item.product.images?.[0]}
                      style={{ width: 60, height: 60, borderRadius: 4 }}
                    />
                  </Box>
                  <Box
                    p={2}
                    color="secondary.main"
                    fontSize="1.2rem"
                    fontWeight="bold"
                  >
                    <Box whiteSpace="nowrap">
                      R$ {item.formattedPrice}
                      {item.recurring?.interval === 'month' ? '/mês' : '/ano'}
                    </Box>
                    <Box fontSize="0.75rem" fontWeight="normal">
                      {item?.recurring?.interval === 'month'
                        ? 'A renovação da assinatura será mensal.'
                        : 'A renovação da assinatura será a cada 12 meses.'}
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </DialogContent>
      </Modal>
    </Dialog>
  );
}
