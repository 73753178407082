import { Box, BoxProps, CircularProgress } from '@material-ui/core';

type LoadingProps = BoxProps;

export function Loading({ ...rest }: LoadingProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      position="absolute"
      top={0}
      left={0}
      minHeight="30vh"
      {...rest}
    >
      <CircularProgress />
    </Box>
  );
}
