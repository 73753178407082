import { Box, Theme, makeStyles, Typography } from '@material-ui/core';

import PremiumIcon from '../../../../shared/components/Icons/PremiumIcon';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.text.primary,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  icon: {
    width: 25,
    height: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(3),
    color: theme.color.premium.main,
  },
  title: {
    color: '#fff',
  },
}));

interface BecomePremiumItemProps {
  title: string;
}

export function BecomePremiumItem({ title }: BecomePremiumItemProps) {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" className={classes.container}>
      <Box className={classes.icon}>
        <PremiumIcon fontSize="medium" />
      </Box>
      <Typography variant="body2" className={classes.title}>
        {title}
      </Typography>
    </Box>
  );
}
