import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    border: `2px dashed ${theme.palette.secondary.dark}`,
    color: theme.palette.secondary.dark,
    fontSize: '0.95rem',
    background: 'transparent',
    opacity: 0.5,
    transition: 'all 0.2s ease-out',
    cursor: 'pointer',
    boxShadow: 'none',
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
}));
