import { useInfiniteQuery } from 'react-query';

import { api } from '../../../services/apiClient';
import { assertIsDataResponse } from '../assertIsDataResponse';
import { Recipe } from './types';

type IReactQueryResponse = {
  results: Recipe[];
  next: number | undefined;
};

type IQuery = {
  pageParam: number;
  searchParam?: string;
  recipeType?: number;
};

async function getData({
  pageParam = 1,
  searchParam = '',
  recipeType,
}: IQuery) {
  const response = await api.get(
    `recipes/?page=${pageParam}&search=${searchParam}&recipeType=${
      recipeType || ''
    }`
  );

  const data: IReactQueryResponse = {
    results: response.data.results,
    next:
      response.data.last_page === 0 ||
      response.data.results.length === 0 ||
      response.data.last_page === pageParam
        ? undefined
        : pageParam + 1,
  };
  return data;
}

function useRecipes(searchParam = '', recipeType?: number) {
  return useInfiniteQuery<IReactQueryResponse, Error>(
    ['recipes', searchParam],
    (queryKey) =>
      getData({ pageParam: queryKey.pageParam, searchParam, recipeType }),
    {
      getNextPageParam: (nextPage) => nextPage.next,
    }
  );
}

export { useRecipes };
