import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function SavoryIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M15.8985 16.9726L15.386 16.4601C17.1797 14.703 19.6689 13.6779 22.2313 13.6779V12.9458C19.8519 12.9458 17.5457 13.7145 15.7521 15.1422L15.3128 14.5565C17.2529 13.019 19.7055 12.177 22.2679 12.177C22.6705 12.177 23 12.5065 23 12.9092V13.6413C23 14.044 22.6705 14.3735 22.2679 14.3735C19.8519 14.4101 17.5458 15.3253 15.8985 16.9726Z" />
      <path d="M13.812 19.1691H3.19634C1.98835 19.1691 1 18.1807 1 16.9727V15.1423C1 14.7396 1.32945 14.4101 1.73211 14.4101H15.2762C15.6789 14.4101 16.0083 14.7396 16.0083 15.1423V16.9727C16.0083 18.1807 15.02 19.1691 13.812 19.1691ZM1.73211 15.1423V16.9727C1.73211 17.778 2.39101 18.437 3.19634 18.437H13.812C14.6173 18.437 15.2762 17.778 15.2762 16.9727V15.1423H1.73211Z" />
      <path d="M14.178 21.7317H2.83029V22.4638H14.178V21.7317Z" />
      <path d="M22.2313 21.7317H16.3744V22.4638H22.2313V21.7317Z" />
      <path d="M15.6423 21.7317H14.9102V22.4638H15.6423V21.7317Z" />
      <path d="M2.09817 21.7317H1.36606V22.4638H2.09817V21.7317Z" />
      <path d="M9.78535 22.0978H9.05324V21.9148C9.05324 21.146 8.72379 20.6335 8.50416 20.6335C8.28452 20.6335 7.95507 21.146 7.95507 21.9148V22.0978H7.22296V21.9148C7.22296 20.7799 7.77204 19.9013 8.50416 19.9013C9.23627 19.9013 9.78535 20.7799 9.78535 21.9148V22.0978Z" />
      <path d="M6.49086 22.0978H5.75874V21.9148C5.75874 21.146 5.42929 20.6335 5.20966 20.6335C4.99003 20.6335 4.66058 21.146 4.66058 21.9148V22.0978H3.92847V21.9148C3.92847 20.7799 4.47755 19.9013 5.20966 19.9013C5.94177 19.9013 6.49086 20.7799 6.49086 21.9148V22.0978Z" />
      <path d="M13.0799 22.0978H12.3477V21.9148C12.3477 21.146 12.0183 20.6335 11.7987 20.6335C11.579 20.6335 11.2496 21.146 11.2496 21.9148V22.0978H10.5175V21.9148C10.5175 20.7799 11.0665 19.9013 11.7987 19.9013C12.5308 19.9013 13.0799 20.7799 13.0799 21.9148V22.0978Z" />
      <path d="M7.58903 15.8744H3.19635V16.6065H7.58903V15.8744Z" />
      <path d="M9.4193 15.8744H8.32114V16.6065H9.4193V15.8744Z" />
      <path d="M7.95507 10.7493C6.74708 10.7493 5.75873 9.76089 5.75873 8.55282C5.75873 8.18674 5.86854 7.82067 6.05157 7.49119L8.8336 2.54912C9.01663 2.21965 9.38269 2 9.78535 2C10.0416 2 10.2612 2.07322 10.4442 2.21965L13.3727 4.41613C13.6655 4.63578 13.812 4.96524 13.812 5.29472C13.812 5.58758 13.7022 5.84384 13.4825 6.06349L9.49251 10.127C9.12645 10.5296 8.54076 10.7493 7.95507 10.7493ZM9.78535 2.69555C9.63893 2.69555 9.52911 2.76876 9.4559 2.87859L6.67387 7.82066C6.56405 8.04031 6.49084 8.29657 6.49084 8.55282C6.49084 9.3582 7.14974 10.0171 7.95507 10.0171C8.35773 10.0171 8.72378 9.87071 9.01663 9.57784L13.0066 5.51437C13.0799 5.44115 13.1165 5.36793 13.1165 5.25811C13.1165 5.14828 13.0799 5.03846 12.97 4.96525L10.0416 2.76877C9.93177 2.73216 9.85856 2.69555 9.78535 2.69555Z" />
      <path d="M7.04425 6.50094L6.60498 7.08667L8.68418 8.64618L9.12345 8.06045L7.04425 6.50094Z" />
      <path d="M6.12478 12.9457H5.39267V13.6779H6.12478V12.9457Z" />
      <path d="M6.12478 10.7493H5.39267V11.4814H6.12478V10.7493Z" />
      <path d="M7.58903 11.8475H6.8569V12.5797H7.58903V11.8475Z" />
      <path d="M5.39268 7.45455H4.66056V7.27151C4.66056 6.97865 4.40432 6.72239 4.11148 6.72239H2.2812C1.58569 6.72239 1 6.13666 1 5.44111C1 4.74556 1.58569 4.15983 2.2812 4.15983H3.01331C3.30616 4.15983 3.56239 3.90358 3.56239 3.61071V3.42767H4.29451V3.61071C4.29451 4.30626 3.70882 4.89199 3.01331 4.89199H2.2812C1.98835 4.89199 1.73211 5.14825 1.73211 5.44111C1.73211 5.73398 1.98835 5.99023 2.2812 5.99023H4.11148C4.80699 5.99023 5.39268 6.57596 5.39268 7.27151V7.45455Z" />
      <path d="M16.1913 12.2136H14.9101V11.4815H16.1913C16.4842 11.4815 16.7404 11.2252 16.7404 10.9324C16.7404 10.6395 16.4842 10.3832 16.1913 10.3832H15.0931C14.3976 10.3832 13.812 9.79751 13.812 9.10196V7.82068H14.5441V9.10196C14.5441 9.39482 14.8003 9.65108 15.0931 9.65108H16.1913C16.8868 9.65108 17.4725 10.2368 17.4725 10.9324C17.4725 11.6279 16.8868 12.2136 16.1913 12.2136Z" />
      <path d="M11.9817 13.6779H11.2496V12.7627C11.2496 12.0672 11.8353 11.4814 12.5308 11.4814H14.178V12.2136H12.5308C12.2379 12.2136 11.9817 12.4699 11.9817 12.7627V13.6779Z" />
    </SvgIcon>
  );
}
