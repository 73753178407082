import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function BudgetIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M8.32633 6.79374L5.70667 7.97406L6.01191 8.65747L8.63157 7.47715L8.32633 6.79374Z" />
      <path d="M9.79726 6.15982L9.04633 6.49146L9.34649 7.17711L10.0974 6.84548L9.79726 6.15982Z" />
      <path d="M23 13.1493H22.2542V13.8983H23V13.1493Z" />
      <path d="M23 14.6473H22.2542V15.3964H23V14.6473Z" />
      <path d="M21.5086 14.6473H20.7628V15.3964H21.5086V14.6473Z" />
      <path d="M20.0168 14.6473H19.2711V15.3964H20.0168V14.6473Z" />
      <path d="M18.5253 14.6473H17.7796V15.3964H18.5253V14.6473Z" />
      <path d="M17.0339 14.6473H16.2881V15.3964H17.0339V14.6473Z" />
      <path d="M15.5424 14.6473H14.7967V15.3964H15.5424V14.6473Z" />
      <path d="M14.0508 14.6473H13.3051V15.3964H14.0508V14.6473Z" />
      <path d="M12.5594 14.6473H11.8136V15.3964H12.5594V14.6473Z" />
      <path
        opacity="0.5"
        d="M15.1323 21.7631L3.57295 17.5686C3.34923 17.4937 3.20007 17.269 3.20007 17.0443C3.20007 16.9693 3.20008 16.8944 3.23736 16.8944L3.98312 15.0219C4.0577 14.7597 4.39329 14.5725 4.6916 14.6848L16.139 18.8419L17.5933 21.2763L15.1323 21.7631ZM4.05771 16.9319L15.2068 20.9766L16.4373 20.7519L15.6543 19.4411L4.61703 15.4338L4.05771 16.9319ZM4.72889 15.284C4.6916 15.284 4.6916 15.284 4.72889 15.284V15.284Z"
      />
      <path d="M21.5086 13.8983H10.3221V7.90613H21.5086V13.8983ZM11.0679 13.1493H20.7628V8.65515H11.0679V13.1493Z" />
      <path d="M14.7966 12.4003H12.783L12.1864 11.8011V10.0034L12.783 9.40417H14.7966V10.1532H13.0813L12.9322 10.303V11.5015L13.0813 11.6513H14.7966V12.4003Z" />
      <path d="M19.0474 12.4003H17.0338V11.6513H18.7491L18.8982 11.5015V10.303L18.7491 10.1532H17.0338V9.40417H19.0474L19.644 10.0034V11.8011L19.0474 12.4003Z" />
      <path d="M15.9152 11.6513C15.5051 11.6513 15.1695 11.3142 15.1695 10.9023C15.1695 10.4903 15.5051 10.1533 15.9152 10.1533C16.3254 10.1533 16.661 10.4903 16.661 10.9023C16.661 11.3142 16.3254 11.6513 15.9152 11.6513Z" />
      <path d="M23 8.65515H22.2542V9.40418H23V8.65515Z" />
      <path d="M23 10.1533H22.2542V10.9023H23V10.1533Z" />
      <path d="M23 11.6512H22.2542V12.4003H23V11.6512Z" />
      <path d="M2.00678 11.3517L1.22373 9.8536C1.07458 9.59144 1 9.29183 1 8.99222C1 8.24319 1.44746 7.53161 2.15593 7.26945L12.2237 3.14981C12.4474 3.0749 12.6712 3 12.9322 3C13.6034 3 14.2373 3.37451 14.5729 3.97373L16.139 6.89494L15.4678 7.232L13.9017 4.3108C13.6407 3.82393 13.044 3.59922 12.4847 3.78648L2.41694 7.90613C2.00677 8.09338 1.70847 8.50535 1.70847 8.95476C1.70847 9.14202 1.74577 9.29183 1.82034 9.47908L2.26779 10.3405L4.61695 9.21692L4.95254 9.89105L2.00678 11.3517Z" />
      <path d="M17.0713 19.7782L16.773 19.1041L20.8374 17.4188L20.2408 16.3701L20.912 15.9956L21.8815 17.7933L17.0713 19.7782Z" />
      <path d="M11.105 22.25L9.61346 19.3662L10.2474 19.0292L11.4779 21.2763L13.8643 20.3025L14.1626 20.9766L11.105 22.25Z" />
      <path d="M7.71192 15.7709L3.83397 8.4679C3.64753 8.13084 3.27464 7.90613 2.86448 7.90613C2.71532 7.90613 2.56617 7.94358 2.45431 7.98103L2.15601 7.26946C2.37973 7.19456 2.60346 7.1571 2.86448 7.1571C3.53566 7.1571 4.16955 7.53162 4.46786 8.09339L8.34582 15.3964L7.71192 15.7709Z" />
      <path d="M15.1321 21.3886C15.0576 21.3886 14.983 21.3886 14.8711 21.3512L2.23047 16.857C1.93216 16.7447 1.74573 16.4825 1.74573 16.1455C1.74573 16.0706 1.74573 15.9582 1.78302 15.8833L2.52878 14.0107C2.64064 13.7111 2.90166 13.5239 3.23725 13.5239C3.31183 13.5239 3.3864 13.5613 3.46098 13.5613L16.027 18.018C16.1389 18.0555 16.2508 18.1304 16.3254 18.2427L18.0033 20.0779C18.1152 20.1902 18.1525 20.3026 18.1525 20.4524C18.1525 20.7145 17.966 20.9392 17.6677 21.0141L15.2067 21.3886C15.2067 21.3886 15.1694 21.3886 15.1321 21.3886ZM3.23725 14.2729L2.49149 16.1455L15.1321 20.6396L17.2203 20.3026L15.8033 18.7296L3.23725 14.2729Z" />
      <path d="M5.0029 14.4684L3.98389 17.0439L4.67692 17.3205L5.69594 14.745L5.0029 14.4684Z" />
    </SvgIcon>
  );
}
