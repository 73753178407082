import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { Button } from '../../../../shared/components/Button';
import { FormGroup } from '../../../../shared/components/Form/FormGroup';
import { Input } from '../../../../shared/components/Form/Input';
import { InputCurrency } from '../../../../shared/components/Form/Input/InputCurrency';
import { InputDatePicker } from '../../../../shared/components/Form/Input/InputDatePicker';
import { InputSlider } from '../../../../shared/components/Form/Input/InputSlider';
import { Select } from '../../../../shared/components/Form/Select';
import { Modal } from '../../../../shared/components/Modal';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { RecipeType } from '../../../technicalSheet/pages/TechnicalSheet/types';
import { marksSliceQuantity } from './marksSliceQuantity';
import { useStyles } from './styles';
import { FormValues } from './types';

const schema = yup.object().shape({
  amount: yup
    .string()
    .required('O valor é obrigatório.')
    .max(254, 'No máximo 40 digitos.'),
  amount_paid: yup.string().nullable(),
  name: yup.string().nullable(),
  age: yup.string().nullable(),
  party_theme: yup.string().nullable(),
  party_address: yup.string().nullable(),
  party_date: yup.string().required('A data é obrigatória.'),
  additional_information: yup.string().nullable(),
  flavor: yup.string().required('O sabor é obrigatório.'),
  flavor_filling: yup.string().nullable(),
  quantity: yup.string().required('A quantidade é obrigatória.'),
  client: yup
    .object({
      id: yup.string(),
      name: yup.string(),
    })
    .required('O cliente é obrigatório.'),
  recipe_type: yup
    .object({
      id: yup.string(),
      name: yup.string(),
    })
    .required('O tipo da encomenda é obrigatória.'),
});

type IClient = {
  id: number;
  name: string;
};

interface ModalCreateScheduleProps {
  onClose: () => void;
  onCloseSuccess: () => void;
  isOpen: boolean;
}

export function ModalCreateSchedule({
  onClose,
  onCloseSuccess,
  isOpen,
}: ModalCreateScheduleProps) {
  const classes = useStyles();
  const { msgSuccess, msgError } = useSnackMessages();
  const history = useHistory();

  const [recipeTypes, setRecipeTypes] = useState<RecipeType[]>([]);
  const [selectedRecipeTypeCake, setSelectedRecipeTypeCake] = useState(true);
  const [clients, setClients] = useState<IClient[]>([]);
  const [loading, setLoading] = useState(true);

  const [values, setValues] = useState([
    {
      id: new Date().getTime(),
      amount_paid: undefined,
      date_amount_paid: moment().format(),
    },
  ]);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      amount: undefined,
      name: '',
      age: undefined,
      party_theme: '',
      party_address: '',
      party_date: moment().format(),
      additional_information: '',
      flavor: '',
      flavor_filling: '',
      quantity: 10,
      client: undefined,
      recipe_type: undefined,
    },
  });

  useEffect(() => {
    setLoading(true);
    api
      .get<{ results: IClient[] }>('/clients/list')
      .then((response) => {
        setClients(response.data.results);
        setValue('client', response.data?.results?.[0]);
      })
      .finally(() => {
        setLoading(false);
      });

    api.get<RecipeType[]>('/recipes/recipe-types').then((response) => {
      setRecipeTypes(response.data);
      setValue('recipe_type', response.data?.[0]);
    });
  }, [setValue]);

  const onSubmit = useCallback(
    async ({
      amount,
      client,
      flavor,
      party_date,
      quantity,
      recipe_type,
      additional_information,
      age,
      amount_paid,
      flavor_filling,
      name,
      party_address,
      party_theme,
      values,
    }: FormValues) => {
      console.log(
        amount,
        client,
        flavor,
        party_date,
        quantity,
        recipe_type,
        additional_information,
        age,
        amount_paid,
        flavor_filling,
        name,
        party_address,
        party_theme,
        values
      );

      const errorValidateValues = values?.find(
        (value) => value?.amount_paid && !value?.date_amount_paid
      );
      if (errorValidateValues) {
        msgError('Lembre-se de informa a data para cada valor informado.');
        return;
      }

      try {
        const formatData = {
          amount: Number(amount.toString().replace('R$', '').replace(',', '.')),
          client_id: client.id,
          flavor,
          party_date: moment(party_date).format('YYYY-MM-DDTHH:mm:ssZ'),
          quantity,
          recipe_type_id: recipe_type.id,
          additional_information,
          age,
          // amount_paid: amount_paid
          //   ? Number(amount_paid.toString().replace('R$', '').replace(',', '.'))
          //   : undefined,
          flavor_filling,
          name,
          party_address,
          party_theme,
          amounts: values?.map((value) => {
            if (value?.amount_paid) {
              return {
                amount_paid: Number(
                  value.amount_paid
                    .toString()
                    .replace('R$', '')
                    .replace(',', '.')
                ),
                date_amount_paid: moment(value.date_amount_paid).format(
                  'YYYY-MM-DDTHH:mm:ssZ'
                ),
              };
            }
            return undefined;
          }),
        };
        console.log(formatData);

        await api.post('/sales/schedule', formatData);

        msgSuccess('Agenda de vendas adicionada com sucesso!');

        reset();
        onCloseSuccess();
      } catch (err: any) {
        msgError(err?.response?.data?.message || err?.response?.data?.error);
      }
    },
    [msgError, msgSuccess, reset, onCloseSuccess]
  );

  const handleSelectRecipeType = useCallback(
    (value) => {
      if (!value?.id) return;

      setSelectedRecipeTypeCake(
        recipeTypes.find((recipeType) => recipeType.id === value?.id)?.type ===
          'slices' || false
      );
    },
    [recipeTypes]
  );

  const handleClose = () => {
    onClose();
  };

  const handleNewValue = useCallback(() => {
    setValues((state) => [
      ...state,
      {
        id: new Date().getTime() + Math.random(),
        amount_paid: undefined,
        date_amount_paid: moment().format(),
      },
    ]);
  }, []);

  return (
    <Modal title="Adicionar Encomenda" open={isOpen} onClose={handleClose}>
      <DialogContent dividers>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column" width="100%">
            <FormGroup title="Informações do cliente">
              {!!clients.length && (
                <Select
                  options={clients}
                  name="client"
                  control={control}
                  error={errors}
                  variant="outlined"
                  label="Cliente"
                  fullWidth
                  required
                  disabled={isSubmitting}
                />
              )}
            </FormGroup>

            {!loading && !clients.length && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box>
                  Você ainda não cadastrou nenhum cliente. Clique no botão
                  abaixo!
                </Box>
                <Button onClick={() => history.push('/records/clients')}>
                  Clientes
                </Button>
              </Box>
            )}

            <FormGroup title="Informações de preço">
              <InputCurrency
                name="amount"
                control={control}
                error={errors}
                variant="outlined"
                label="Valor total"
                fullWidth
                required
                disabled={isSubmitting}
              />

              <Box fontSize="0.85rem" color="secondary.light">
                Pagamentos
              </Box>
              <Box
                borderRadius={8}
                pt={2}
                px={1}
                pb={0}
                width="100%"
                display="flex"
                flexDirection="column"
                bgcolor="#f9f9f9"
              >
                {values.map((value, index) => (
                  <Box
                    display="flex"
                    flexDirection="row"
                    key={value.id}
                    width="100%"
                  >
                    <InputCurrency
                      name={`values[${index}][amount_paid]`}
                      control={control}
                      error={errors}
                      variant="outlined"
                      label="Valor pago"
                      fullWidth
                      disabled={isSubmitting}
                      style={{ width: '100%', marginRight: 15 }}
                    />

                    <InputDatePicker
                      name={`values[${index}][date_amount_paid]`}
                      label="Data do Pagamento"
                      variant="outlined"
                      control={control}
                      error={errors}
                      disabled={isSubmitting}
                      style={{ width: '100%' }}
                    />
                  </Box>
                ))}
                <Box display="flex" width="100%" mb={1}>
                  <Button
                    type="button"
                    className={classes.button}
                    styleMotion={{ width: '100%' }}
                    onClick={handleNewValue}
                  >
                    Clique aqui para adicionar mais pagamentos!
                  </Button>
                </Box>
              </Box>
              {/* <InputCurrency
                name="amount_paid"
                control={control}
                error={errors}
                variant="outlined"
                label="Valor pago"
                fullWidth
                disabled={isSubmitting}
              /> */}
            </FormGroup>

            <FormGroup title="Informações do evento">
              <Input
                name="name"
                control={control}
                error={errors}
                variant="outlined"
                label="Nome do Evento"
                fullWidth
                disabled={isSubmitting}
              />
              <Input
                name="age"
                control={control}
                error={errors}
                variant="outlined"
                label="Idade"
                fullWidth
                disabled={isSubmitting}
              />
              <Input
                name="party_theme"
                control={control}
                error={errors}
                variant="outlined"
                label="Tema da festa"
                fullWidth
                disabled={isSubmitting}
              />
              <Input
                name="party_address"
                control={control}
                error={errors}
                variant="outlined"
                label="Endereço da festa"
                fullWidth
                disabled={isSubmitting}
              />
              <Input
                name="additional_information"
                control={control}
                error={errors}
                variant="outlined"
                label="Informações adicionais"
                fullWidth
                disabled={isSubmitting}
                inputProps={{ maxLength: 500 }}
                placeholder="500 caracteres"
              />

              <InputDatePicker
                name="party_date"
                label="Data da festa"
                variant="outlined"
                control={control}
                error={errors}
                disabled={isSubmitting}
                required
              />
            </FormGroup>

            <FormGroup title="Informações da encomenda" mb={2}>
              {!!recipeTypes.length && (
                <Select
                  options={recipeTypes}
                  name="recipe_type"
                  control={control}
                  error={errors}
                  variant="outlined"
                  label="Tipo de fixa técnica"
                  fullWidth
                  required
                  disabled={isSubmitting}
                  getSelectValue={(value) => handleSelectRecipeType(value)}
                />
              )}
              <Input
                name="flavor"
                control={control}
                error={errors}
                variant="outlined"
                label="Sabor"
                fullWidth
                required
                disabled={isSubmitting}
              />
              <Input
                name="flavor_filling"
                control={control}
                error={errors}
                variant="outlined"
                label="Sabor do recheio"
                fullWidth
                disabled={isSubmitting}
              />

              {selectedRecipeTypeCake ? (
                <InputSlider
                  name="quantity"
                  control={control}
                  error={errors}
                  label="Quantidade de fatias"
                  step={10}
                  min={10}
                  max={100}
                  marks={marksSliceQuantity}
                  valueLabelDisplay="off"
                  disabled={isSubmitting}
                />
              ) : (
                <Input
                  name="quantity"
                  type="number"
                  control={control}
                  error={errors}
                  variant="outlined"
                  label="Quantidade de unidades"
                  fullWidth
                  required
                  disabled={isSubmitting}
                />
              )}
            </FormGroup>
          </Box>

          <DialogActions style={{ padding: 0 }}>
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              gridGap={8}
              mt={4}
              alignItems="stretch"
            >
              <Button
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                disabled={isSubmitting || !clients.length}
                style={{ width: '100%' }}
              >
                {isSubmitting ? 'Salvando...' : 'Salvar'}
              </Button>
              <Button
                onClick={handleClose}
                color="primary"
                variant="outlined"
                size="large"
                style={{ width: '100%' }}
                disabled={isSubmitting}
              >
                Cancelar
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </DialogContent>
    </Modal>
  );
}
