import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { Button } from '../../../../../shared/components/Button';
import { Input } from '../../../../../shared/components/Form/Input';
import { InputCurrency } from '../../../../../shared/components/Form/Input/InputCurrency';
import { InputDatePicker } from '../../../../../shared/components/Form/Input/InputDatePicker';
import { Header } from '../../../../../shared/components/Header';
import { PageContainer } from '../../../../../shared/components/PageContainer';
import { useSnackMessages } from '../../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../../shared/services/apiClient';

const schema = yup.object().shape({
  title: yup.string().required('O título é obrigatório.'),
  event_date: yup.string().nullable().required('A data é obrigatória.'),
});

export function CreateAnnualPlanning() {
  const history = useHistory();
  const { msgSuccess, msgError } = useSnackMessages();
  const [totalDetails, setTotalDetails] = useState<{ id: number }[]>([
    { id: Date.now() },
  ]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      title: '',
      event_date: '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = useCallback(
    async ({ title, event_date, items }: any) => {
      console.log(title, event_date, items);

      try {
        const formatData = {
          title,
          event_date: event_date
            ? moment(new Date(event_date)).format('YYYY-MM-DD')
            : undefined,
          items: items?.map((item: any) => {
            return {
              ...item,
              start_date: item.start_date
                ? moment(new Date(item.start_date)).format('YYYY-MM-DD')
                : undefined,
              end_date: item.end_date
                ? moment(new Date(item.end_date)).format('YYYY-MM-DD')
                : undefined,
              value: item?.value
                ? item?.value?.replace('R$', '').replace(',', '.') || '0'
                : 0,
            };
          }),
        };

        await api.post('/anual-planning', formatData);
        console.log(event_date);
        msgSuccess('Planejamento anual cadastrado com sucesso!');
        reset();

        history.push('/records/annual-planning');
      } catch (err: any) {
        msgError(err?.response?.data?.message);
      }
    },
    [msgError, msgSuccess, history, reset]
  );

  const handleAddDetails = () => {
    setTotalDetails((state) => [...state, { id: Date.now() }]);
  };

  const handleRemoveDetails = (id: number) => {
    setTotalDetails((state) => state.filter((item) => item.id !== id));
  };

  return (
    <PageContainer display="flex" flexDirection="column">
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Header goBack title="Novo planejamento">
          <Button
            size="medium"
            variant="contained"
            color="primary"
            type="submit"
          >
            Salvar
          </Button>
        </Header>

        <Box display="flex" flexDirection="column" gridGap={16} width="100%">
          <Box mb={2} color="primary.dark">
            Cabeçalho:
          </Box>
          <Input
            help="Insira um título de destaque para seu planejamento anual"
            name="title"
            control={control}
            error={errors}
            variant="outlined"
            label="Título do Evento"
            fullWidth
            required
            disabled={isSubmitting}
          />
          <Box mb={2} />
          <InputDatePicker
            name="event_date"
            label="Data do Evento"
            variant="outlined"
            control={control}
            error={errors}
            disabled={isSubmitting}
            required
          />
          <Box mb={3} />

          <Box mb={2} color="primary.dark">
            Detalhes:
          </Box>
          {totalDetails?.map((item, index) => {
            return (
              <React.Fragment key={item.id}>
                <fieldset
                  style={{
                    borderRadius: '4px',
                    borderColor: '#eee',
                    marginBottom: 16,
                  }}
                >
                  <Box display="flex" justifyContent="flex-end" pb={2}>
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleRemoveDetails(item.id)}
                      disabled={totalDetails.length <= 1}
                      style={{ opacity: totalDetails.length <= 1 ? 0.4 : 1 }}
                    >
                      Remover
                    </Button>
                  </Box>

                  <Input
                    help="Insira quem irá participar do seu evento Ex: (Clientes / funcionários)"
                    name={`items[${index}][who]`}
                    control={control}
                    error={errors}
                    variant="outlined"
                    label="Quem?"
                    fullWidth
                    disabled={isSubmitting}
                    style={{ marginBottom: '15px' }}
                  />

                  <InputDatePicker
                    name={`items[${index}][start_date]`}
                    label="Quando inicia?"
                    variant="outlined"
                    control={control}
                    error={errors}
                    disabled={isSubmitting}
                  />

                  <InputDatePicker
                    name={`items[${index}][end_date]`}
                    label="Quando termina?"
                    variant="outlined"
                    control={control}
                    error={errors}
                    disabled={isSubmitting}
                  />

                  <Input
                    help="Insira suas estratégias para sua data comemorativa / comercial"
                    name={`items[${index}][how]`}
                    control={control}
                    error={errors}
                    variant="outlined"
                    label="Como?"
                    fullWidth
                    disabled={isSubmitting}
                    style={{ marginBottom: '15px' }}
                  />

                  <InputCurrency
                    help="Insira o valor que você pretende gastar no evento"
                    name={`items[${index}][value]`}
                    type="text"
                    label="Quanto?"
                    variant="outlined"
                    control={control}
                    error={errors}
                    style={{ marginBottom: 0 }}
                    disabled={!!isSubmitting}
                  />
                  <Box mb={2} />

                  <Input
                    help="Insira algumas observações adicionais do seu evento"
                    name={`items[${index}][additional_observations]`}
                    multiline
                    minRows={3}
                    control={control}
                    error={errors}
                    variant="outlined"
                    label="Observações Adicionais"
                    fullWidth
                    disabled={isSubmitting}
                    style={{ marginBottom: '15px' }}
                    inputProps={{ maxLength: 500 }}
                    placeholder="500 caracteres"
                  />
                </fieldset>
              </React.Fragment>
            );
          })}
        </Box>

        <Box width="100%" display="flex" justifyContent="stretch" pb={1}>
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={handleAddDetails}
            disabled={isSubmitting}
            style={{
              width: '100%',
              opacity: isSubmitting ? 0.4 : 1,
              height: 56,
              borderStyle: 'dashed',
            }}
            styleMotion={{ width: '100%' }}
          >
            Adicionar mais detalhes
          </Button>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          gridGap={8}
          mt={4}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            autoFocus
            onClick={() => history.push('/records/annual-planning')}
            color="primary"
            variant="outlined"
            size="large"
            disabled={isSubmitting}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            autoFocus
            size="large"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
          >
            Cadastrar planejamento
          </Button>
        </Box>
      </Box>
    </PageContainer>
  );
}
