import {
  ReactNode,
  useCallback,
  useEffect,
  useState,
  createContext,
} from 'react';

import { useUserAuthenticated } from '../hooks/useUserAuthenticated';
import { api } from '../services/apiClient';

type City = {
  id: number;
  name: string;
  state?: string;
};

type YourRegistrationProps = {
  id?: number;
  name?: string;
  email: string;
  profile?: {
    id: string;
    phone?: string;
    birthday?: string;
    address_id?: number;
    cpf_cnpj?: string;
    type_document?: string;
  };
  address?: {
    id: number;
    city_id: number;
    complement: string;
  };
};

interface YourRegistrationContextData {
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
  yourRegistration: YourRegistrationProps;
  cities: City[];
}

type YourRegistrationProviderProps = {
  children: ReactNode;
};

export const YourRegistrationContext = createContext(
  {} as YourRegistrationContextData
);

export function YourRegistrationProvider({
  children,
}: YourRegistrationProviderProps) {
  const [yourRegistration, setYourRegistration] =
    useState<YourRegistrationProps>({} as YourRegistrationProps);
  const [open, setOpen] = useState(false);
  const [cities, setCities] = useState<City[]>([]);
  const { user } = useUserAuthenticated();

  useEffect(() => {
    if (user?.email && user?.name) {
      setYourRegistration(user);
    }
  }, [user]);

  useEffect(() => {
    api
      .get<{ cities: City[] }>('/cities')
      .then((response) => {
        setCities(response.data?.cities);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <YourRegistrationContext.Provider
      value={{
        onOpen,
        onClose,
        isOpen: open,
        yourRegistration,
        cities,
      }}
    >
      {children}
    </YourRegistrationContext.Provider>
  );
}
