import {
  Typography,
  Box,
  makeStyles,
  Theme,
  useMediaQuery,
  Button,
} from '@material-ui/core';
import { motion } from 'framer-motion';
import { memo, useMemo } from 'react';

import noImage from '../../../../shared/assets/no-image.png';
import { Flex } from '../../../../shared/components/Flex';
import CarIcon from '../../../../shared/components/Icons/CarIcon';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: 'auto',
    border: 'none',
    background: 'transparent',
    borderRadius: theme.spacing(1),
    width: '100%',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    padding: 0,
  },
  container: {
    display: 'flex',
    width: '100%',
    border: 'none',
    maxHeight: theme.spacing(19),
    borderRadius: theme.spacing(1),
    background: theme.palette.background.paper,
    borderBottom: `3px solid ${theme.background.primary}`,
    [theme.breakpoints.up('sm')]: {
      maxHeight: theme.spacing(20),
    },
  },
  image: {
    width: '100%',
    maxWidth: '20vh',
    maxHeight: '150px',
    objectFit: 'cover',
    objectPosition: 'center',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    [theme.breakpoints.up('sm')]: {
      maxHeight: theme.spacing(20),
    },
    [theme.breakpoints.down(370)]: {
      maxWidth: '15vh',
    },
  },
  title: {
    color: theme.text.primary,
  },
}));

interface CardTechnicalSheetProps {
  title: string;
  description?: string;
  image?: string;
  onClick?: () => void;
  quantityIngredients?: number | string;
}

function CardTechnicalSheetComponent({
  title,
  description,
  image,
  onClick,
  quantityIngredients,
}: CardTechnicalSheetProps) {
  const classes = useStyles();
  const isNotMobile = useMediaQuery('(min-width:600px)');

  const newDescription = useMemo(() => {
    if (!description) return '';

    if (description.length < 76) return description;

    if (isNotMobile && description.length > 172) {
      return `${description.slice(0, 172)}...`;
    }

    if (!isNotMobile && description.length > 72) {
      return `${description.slice(0, 72)}...`;
    }

    return description;
  }, [description, isNotMobile]);

  return (
    <motion.div
      whileTap={{ scale: 1.03 }}
      style={{ width: '100%', border: 'none' }}
    >
      <Button className={classes.root} onClick={onClick}>
        <Box className={classes.container}>
          <img src={image ?? noImage} alt="Imagem" className={classes.image} />

          <Flex
            p={2}
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
            textAlign="left"
          >
            <Typography
              variant="subtitle2"
              component="div"
              style={{ textTransform: 'none' }}
            >
              <Box className={classes.title} component="span" display="block">
                {title}
              </Box>
              {description && (
                <Box
                  component="span"
                  display="block"
                  color="secondary.main"
                  fontWeight="normal"
                  fontSize="0.75rem"
                >
                  {newDescription}
                </Box>
              )}
            </Typography>

            {quantityIngredients && (
              <Box
                fontSize="0.75rem"
                color="primary.dark"
                textAlign="right"
                display="flex"
                alignItems="center"
                width="100%"
                justifyContent="flex-end"
                fontWeight="400"
                mt={1}
              >
                <CarIcon fontSize="small" style={{ marginRight: 4 }} />
                <span>{quantityIngredients} ingredientes</span>
              </Box>
            )}

            {!quantityIngredients && (
              <Box
                fontSize="0.75rem"
                color="secondary.light"
                textAlign="right"
                display="flex"
                alignItems="center"
                width="100%"
                justifyContent="flex-end"
                fontWeight="400"
                mt={1}
              >
                <span>Nenhum ingrediente</span>
              </Box>
            )}
          </Flex>
        </Box>
      </Button>
    </motion.div>
  );
}

export const CardTechnicalSheet = memo(CardTechnicalSheetComponent);
