import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { Button } from '../../../../shared/components/Button';
import { Input } from '../../../../shared/components/Form/Input';
import { InputCurrency } from '../../../../shared/components/Form/Input/InputCurrency';
import { Select } from '../../../../shared/components/Form/Select';
import PremiumIcon from '../../../../shared/components/Icons/PremiumIcon';
import { usePremium } from '../../../../shared/hooks/usePremium';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { stylesThemeMui, useStyles } from './styles';
import { DialogTitleProps, FormValues } from './types';

interface UnitTypeResponse {
  id: number;
  name: string;
}
interface ProductTypeResponse {
  id: number;
  name: string;
}

interface DataResults {
  product_types: ProductTypeResponse[];
  unit_measurements: UnitTypeResponse[];
}
const schema = yup.object().shape({
  product_name: yup.string().required('O nome é obrigatório.'),
  price: yup.string().required('O valor é obrigatório.'),
  quantity: yup
    .number()
    .required('A quantidade é obrigatória')
    .typeError('Você deve especificar um número')
    .min(1, 'Quantidade mínima é 1'),
});

const DialogTitle = withStyles(stylesThemeMui)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface CreateProductModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function CreateProductModal({
  isOpen,
  onClose,
}: CreateProductModalProps) {
  const [loading, setLoading] = useState(false);
  const { msgSuccess, msgError } = useSnackMessages();
  const isPremium = usePremium();
  const history = useHistory();
  const [unitType, setUnitType] = useState<UnitTypeResponse[]>([]);
  const [producType, setProductType] = useState<ProductTypeResponse[]>([]);
  const classes = useStyles();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      product_name: '',
      price: '',
      quantity: 0,
      stock: 0,
      unit_measurements: { id: 1, name: '' },
      product_type: { id: 1, name: '' },
    },
    mode: 'onChange',
  });

  useEffect(() => {
    api.get<DataResults>('/products/types-and-units').then((response) => {
      setUnitType(response.data?.unit_measurements);
      setProductType(response.data?.product_types);
    });
  }, []);

  const onSubmit = useCallback(
    async ({
      product_name,
      price,
      quantity,
      stock,
      unit_measurements,
      product_type,
    }: FormValues) => {
      setLoading(true);

      const product_type_id = product_type.id;
      const unit_of_measurement_id = unit_measurements.id;
      try {
        const formatData = {
          product_name,
          price: Number(price.toString().replace('R$', '').replace(',', '.')),
          quantity,
          stock,
          unit_of_measurement_id,
          product_type_id,
        };

        await api.post('/products', formatData);

        msgSuccess('Produto cadastrado com sucesso!');

        setLoading(false);
        reset();
        onClose();
      } catch (err: any) {
        setLoading(false);
        msgError(err?.response?.data?.message);
      }
    },
    [msgError, msgSuccess, onClose, reset]
  );

  return (
    <Dialog aria-labelledby="create-product-dialog" open={isOpen} maxWidth="sm">
      <DialogTitle id="create-product-dialog" onClose={onClose}>
        Cadastro de Produto
      </DialogTitle>

      <DialogContent dividers>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column" gridGap={16} width="100%">
            <Input
              help="Insira o nome do seu produto"
              name="product_name"
              control={control}
              error={errors}
              variant="outlined"
              label="Nome do produto"
              fullWidth
              required
              disabled={loading}
            />

            <InputCurrency
              help="Insira o valor que será cobrado por (Unidade , Litro, Mililitro, Grama ou Quilograma)
              de acordo com o seu produto"
              name="price"
              type="text"
              label="Valor"
              variant="outlined"
              control={control}
              error={errors}
              style={{ marginBottom: 0 }}
              disabled={!!loading}
              required
            />

            <Box>
              <Input
                help="Insira a medida que o seu produto tem"
                name="quantity"
                type="number"
                control={control}
                error={errors}
                variant="outlined"
                label="Quantidade da Embalagem"
                fullWidth
                required
                disabled={loading}
              />
            </Box>

            <Select
              options={unitType}
              name="unit_measurements"
              control={control}
              error={errors}
              variant="outlined"
              label="Unidade de medida"
              fullWidth
              required
              disabled={loading}
            />
            <Select
              options={producType}
              name="product_type"
              control={control}
              error={errors}
              variant="outlined"
              label="Tipo de produto"
              fullWidth
              required
              disabled={loading}
            />

            <Box position="relative">
              {!isPremium && (
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  zIndex={1}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    endIcon={<PremiumIcon className={classes.premiumColor} />}
                    onClick={() => history.push('/become-premium')}
                  >
                    Seja Premium
                  </Button>
                </Box>
              )}

              <Input
                help="Insira a quantidade em estoque do produto"
                name="stock"
                type="number"
                control={control}
                error={errors}
                variant="outlined"
                label="Quantidade em Estoque"
                fullWidth
                disabled={loading || !isPremium}
                style={{ opacity: !isPremium ? 0.5 : 1 }}
              />
            </Box>
          </Box>

          <DialogActions style={{ padding: 0 }}>
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              gridGap={8}
              mt={4}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                autoFocus
                onClick={onClose}
                color="primary"
                variant="outlined"
                size="large"
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                autoFocus
                size="large"
                color="primary"
                variant="contained"
              >
                Cadastrar produto
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
