import { Typography, Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { memo, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import { GoBackIconButton } from '../Button/GoBackIconButton';
import { Flex } from '../Flex';

const useStyles = makeStyles((theme) => ({
  hideOnPrint: {
    '@media print': {
      display: 'none',
    },
  },
  content: {
    borderBottom: `1px solid ${theme.border.color}`,
    '@media print': {
      borderBottom: 'none',
    },
  },
}));

interface HeaderProps {
  title: string;
  children?: ReactNode;
  search?: ReactNode;
  isLoading?: boolean;
  goBack?: boolean;
  goPush?: string;
}

function HeaderComponent({
  title,
  children,
  search,
  isLoading,
  goBack,
  goPush,
}: HeaderProps) {
  const classes = useStyles();
  const history = useHistory();

  const handlePushOrGoBack = () => {
    if (goPush) {
      history.push(goPush);
    } else {
      history.goBack();
    }
  };

  return (
    <Flex alignItems="flex-start" flexDirection="column" width="100%">
      <Flex
        width="100%"
        justifyContent="space-between"
        className={classes.content}
        pb={2}
        mb={2}
      >
        <Typography
          component="div"
          variant="h6"
          style={{ fontSize: '1.15rem', fontWeight: 500 }}
        >
          <Box
            color="secondary.dark"
            mr={2}
            display="inline-flex"
            alignItems="center"
          >
            {(goBack || goPush) && (
              <GoBackIconButton
                className={classes.hideOnPrint}
                color="inherit"
                size="small"
                onClick={handlePushOrGoBack}
                style={{ marginRight: 8 }}
              />
            )}
            <span>{title}</span>
            {isLoading && (
              <CircularProgress size={20} style={{ marginLeft: 8 }} />
            )}
          </Box>
        </Typography>

        <Flex justifyContent="flex-end">{children}</Flex>
      </Flex>
      {search && (
        <Flex width="100%" mb={2}>
          {search}
        </Flex>
      )}
    </Flex>
  );
}

export const Header = memo(HeaderComponent);
