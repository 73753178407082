import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { ReactNode } from 'react';

import { stylesThemeMui } from './styles';

export interface DialogTitleProps extends WithStyles<typeof stylesThemeMui> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(stylesThemeMui)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          autoFocus
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface ModalProps extends DialogProps {
  title: string;
  children: ReactNode;
  open: boolean;
  onClose: () => void;
}

export function Modal({ title, children, open, onClose, ...rest }: ModalProps) {
  return (
    <Dialog
      aria-labelledby={`create-${title}-dialog`}
      maxWidth="sm"
      {...rest}
      open={open}
    >
      <DialogTitle id="create-product-dialog" onClose={onClose}>
        {title}
      </DialogTitle>

      {children}
    </Dialog>
  );
}
