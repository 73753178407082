import {
  Box,
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { PowerSettingsNewOutlined } from '@material-ui/icons';
import { memo, ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ENVIRONMENT } from '../../../../config/environment';
import { logoPageAuth as logoBase64 } from '../../../assets/logoBase64';
import { usePremium } from '../../../hooks/usePremium';
import { Button } from '../../Button';
import { Flex } from '../../Flex';
import { Footer } from '../../Footer';
import {
  AboutAppIcon,
  BudgetIcon,
  HomeIcon,
  SalesIcon,
  TechnicalSheetIcon,
  ViviTvIcon,
} from '../../Icons';
import PremiumIcon from '../../Icons/PremiumIcon';
import { useStylesMenu } from './styles';
import Iconify from '../../Iconify';

type MenuItemProps = {
  title: string;
  icon: ReactNode;
  link?: string;
  lock?: boolean;
};

const menuPrimary: MenuItemProps[] = [
  { title: 'Início', icon: <HomeIcon />, link: '/' },
  { title: 'Cadastros', icon: <BudgetIcon />, link: '/records' },
  {
    title: 'Fichas Técnicas',
    icon: <TechnicalSheetIcon />,
    link: '/technical-sheets',
  },
  { title: 'Vendas', icon: <SalesIcon />, link: '/sales', lock: true },
];

const menuSecondary: MenuItemProps[] = [
  { title: 'Informações', icon: <ViviTvIcon />, link: '/information' },
  { title: 'Utilidades', icon: <AboutAppIcon />, link: '/utilities' },
];

interface MenuContentProps {
  onClose: () => void;
  onPush: (route?: string) => void;
  onSignOut: () => void;
  isPremium?: boolean;
  pathname: string;
}

function MenuContentComponent({
  onClose,
  onPush,
  onSignOut,
  isPremium = false,
  pathname,
}: MenuContentProps) {
  const classes = useStylesMenu();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      width="100%"
      height="100%"
      pb={1}
    >
      <div
        className={classes.list}
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
      >
        <Flex
          flexDirection="column"
          p={2}
          borderRadius="0 0 20px 20px"
          color="primary.contrastText"
          className={classes.shadow}
        >
          <Flex
            marginX="auto"
            width="80px"
            height="80px"
            borderRadius="50%"
            bgcolor="#fff"
            boxShadow="0px 8px 4px rgba(0, 0, 0, 0.1)"
            mb={1}
            mt={1}
          >
            <img src={logoBase64} alt="Logo" width="60px" />
          </Flex>
          <Typography
            variant="h6"
            style={{ fontWeight: 'bold' }}
            className={classes.title}
          >
            Bolo no Bolso
          </Typography>
        </Flex>

        <Flex pt={3} pb={1}>
          {isPremium ? (
            <Button
              color="primary"
              variant="outlined"
              size="small"
              endIcon={<PremiumIcon className={classes.premiumColor} />}
              onClick={() => onPush('/signature/premium')}
            >
              Ver Assinatura
            </Button>
          ) : (
            <Button
              color="primary"
              variant="outlined"
              size="small"
              endIcon={<PremiumIcon className={classes.premiumColor} />}
              onClick={() => onPush('/become-premium')}
            >
              Seja Premium
            </Button>
          )}
        </Flex>

        <List>
          {menuPrimary.map((item) => (
            <ListItem
              button
              key={item.title}
              onClick={() => onPush(item?.link)}
            >
              <ListItemIcon
                className={
                  pathname === item?.link
                    ? classes.listIconSelect
                    : classes.listIcon
                }
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                className={
                  pathname === item?.link ? classes.textColor : classes.text
                }
              />
              {item.lock && (
                <PremiumIcon
                  fontSize="small"
                  className={classes.premiumColor}
                />
              )}
            </ListItem>
          ))}
        </List>

        <Divider style={{ width: '20%', margin: '0 auto' }} />

        <List>
          {menuSecondary.map((item) => (
            <ListItem
              button
              key={item.title}
              onClick={() => onPush(item?.link)}
            >
              <ListItemIcon
                className={
                  pathname === item?.link
                    ? classes.listIconSelect
                    : classes.listIcon
                }
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                className={
                  pathname === item?.link ? classes.textColor : classes.text
                }
              />
            </ListItem>
          ))}
        </List>

        <Divider style={{ width: '20%', margin: '0 auto' }} />

        <List>
          <ListItem button className={classes.colorSignOut} onClick={onSignOut}>
            <ListItemIcon className={classes.listIcon}>
              <PowerSettingsNewOutlined className={classes.colorSignOut} />
            </ListItemIcon>
            <ListItemText primary="Sair do App" />
          </ListItem>
        </List>
      </div>

      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          margin: '0 30px 20px 30px',
        }}
      >
        <Link href="https://www.instagram.com/bolonobolso" target="_blank">
          <Iconify icon="bi:instagram" fontSize="20px" />
        </Link>
        <Link href="https://www.youtube.com/@vivicostamentoria" target="_blank">
          <Iconify icon="ant-design:youtube-outlined" fontSize="25px" />
        </Link>
        <Link href="mailto:contato@bolonobolso.com.br" target="_blank">
          <Iconify icon="fontisto:email" fontSize="25px" />
        </Link>
      </Box>
    </Box>
  );
}

export const MenuContent = memo(MenuContentComponent);
