import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';
import { motion, TargetAndTransition, VariantLabels } from 'framer-motion';
import { FC } from 'react';

type GoBackIconButtonProps = MuiIconButtonProps & {
  whileTap?: VariantLabels | TargetAndTransition | undefined;
};

export const GoBackIconButton: FC<GoBackIconButtonProps> = ({
  whileTap,
  ...rest
}) => {
  return (
    <motion.div
      className="animatable"
      whileTap={whileTap || { scale: 0.85 }}
      style={{ display: 'flex' }}
    >
      <MuiIconButton {...rest}>
        <ArrowBackIcon color="inherit" />
      </MuiIconButton>
    </motion.div>
  );
};
