import {
  ReactNode,
  useCallback,
  useEffect,
  useState,
  createContext,
} from 'react';

import lottieConfirmation from '../assets/lottie/confirmation-bubble-bottom-sheet.json';

const lotties: { [key: string]: Record<string, unknown> } = {
  confirmation: lottieConfirmation,
  close: lottieConfirmation,
};

type Lotties = 'confirmation' | 'close';

interface AnimateLottieAlertContextData {
  isOpen: boolean;
  onLottieOpen: (type: Lotties, title?: string) => void;
  onLottieClose: () => void;
  description?: string;
  lottie: Record<string, unknown>;
}

type AnimateLottieAlertProviderProps = {
  children: ReactNode;
};

export const AnimateLottieAlertContext = createContext(
  {} as AnimateLottieAlertContextData
);

export function AnimateLottieAlertProvider({
  children,
}: AnimateLottieAlertProviderProps) {
  const [description, setDescription] = useState<string>();
  const [open, setOpen] = useState(false);
  const [lottie, setLottie] = useState<Record<string, unknown>>(
    lotties.confirmation
  );

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
  }, [open]);

  const onLottieOpen = useCallback((type: Lotties, title?: string) => {
    setDescription(title);
    setLottie(lotties[type]);
    setOpen(true);
  }, []);

  const onLottieClose = useCallback(() => {
    setOpen(false);
    setDescription(undefined);
  }, []);

  return (
    <AnimateLottieAlertContext.Provider
      value={{
        isOpen: open,
        onLottieOpen,
        onLottieClose,
        description,
        lottie,
      }}
    >
      {children}
    </AnimateLottieAlertContext.Provider>
  );
}
