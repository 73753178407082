import {
  TableRow,
  Table,
  TableBody,
  TableCell,
  Box,
  useMediaQuery,
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Button } from '../../../../shared/components/Button';
import { Header } from '../../../../shared/components/Header';
import { Loading } from '../../../../shared/components/Loading';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { PopoverHelp } from '../../../../shared/components/PopoverHelp';
import { SimpleDialog } from '../../../../shared/components/SimpleDialog';
import { useCashFlowDetail } from '../../../../shared/hooks/reactQuery/useCashFlowDetail';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { ModalEditCashFlow } from '../../components/ModalEditCashFlow';

export function CashFlowDetail() {
  const isNotMobile = useMediaQuery('(min-width:600px)');
  const history = useHistory();
  const { id, date } = useParams<{ id: string; date?: string }>();
  const { msgSuccess, msgError } = useSnackMessages();

  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [openModalEditCashFlow, setOpenModalEditCashFlow] = useState(false);

  const { data, error, refetch, status } = useCashFlowDetail(id);

  if (error) {
    msgError(error?.message);
    history.push(`/sales/cash-flow/${date || ''}`);
  }

  const handleDeleteCashFlow = async () => {
    setOpenModalDelete(false);

    try {
      await api.delete(`/sales/cash-flow/${id}`);

      msgSuccess('Fluxo de caixa deletado com sucesso!');

      history.push(`/sales/cash-flow/${date || ''}`);
    } catch (err: any) {
      msgError(err?.response?.data?.message || err?.response?.data?.error);
    }
  };

  const handleSuccessEditCashFlow = useCallback(() => {
    refetch();
    history.push(`/sales/cash-flow/${date || ''}`);
  }, [history, refetch, date]);

  return (
    <PageContainer display="flex" flexDirection="column">
      <Header title="Voltar" goPush={`/sales/cash-flow/${date || ''}`}>
        <Button
          autoFocus
          size="medium"
          variant="contained"
          color="primary"
          startIcon={<EditOutlinedIcon />}
          style={{ borderRadius: 50 }}
          disabled={status === 'loading'}
          onClick={() => setOpenModalEditCashFlow(true)}
        >
          Editar {isNotMobile && 'Fluxo de Caixa'}
        </Button>
      </Header>

      {status === 'loading' && (
        <Box position="relative">
          <Loading />
        </Box>
      )}

      {status === 'success' && (
        <>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ border: 'none' }}>
                  <Box
                    fontSize="0.88rem"
                    fontWeight="bold"
                    color="secondary.dark"
                  >
                    Título:
                  </Box>
                  <Box>{data?.result.title}</Box>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ border: 'none' }}>
                  <Box
                    fontSize="0.88rem"
                    fontWeight="bold"
                    color="secondary.dark"
                  >
                    Descrição:
                  </Box>
                  <Box>{data?.result?.description}</Box>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ border: 'none' }}>
                  <Box
                    fontSize="0.88rem"
                    fontWeight="bold"
                    color="secondary.dark"
                  >
                    Tipo do fluxo:
                  </Box>
                  <Box>{data?.result.cashFlowType.name}</Box>
                </TableCell>
              </TableRow>

              {data?.result.cashFlowType.id === 2 &&
                data?.result.cashFlowTypeOutput?.id && (
                  <TableRow>
                    <TableCell style={{ border: 'none' }}>
                      <Box
                        fontSize="0.88rem"
                        fontWeight="bold"
                        color="secondary.dark"
                      >
                        Tipo do fluxo de saída:
                      </Box>
                      <Box display="inline-flex" alignItems="center">
                        {data?.result.cashFlowTypeOutput?.name}
                        {data.result.cashFlowTypeOutput.id === 2 && (
                          <PopoverHelp
                            name="cashFlowTypeOutput-2"
                            title="Custo Fixo"
                            help="É um fator produção que tem custos independentes do nível de atividade da empresa. Qualquer que seja a quantidade produzida ou vendida, mesmo que seja zero, os custos fixos se mantêm os mesmos. Ou seja, se você vender 10 bolos ou 10, o custo permanece o mesmo. Ex: Aluguel, internet, imposto do mei, funcionário, seu prolabore, etc."
                          />
                        )}
                        {data.result.cashFlowTypeOutput.id === 1 && (
                          <PopoverHelp
                            name="cashFlowTypeOutput-1"
                            title="Custo Variável"
                            help="É a soma dos fatores variáveis de produção. Custos que mudam de acordo com a produção ou quantidade de trabalho, exemplos incluem o custo de materiais, suprimentos e salários da equipe de trabalho. São exemplos de custos variáveis: Matéria prima, insumos diretos, embalagens, impostos diretos de vendas (ICMS / SIMPLES / ISS / PIS / CONFINS / IPI / IRPJ / CONTRIBUIÇÃO SOCIAL). fornecedores, energia, gás de cozinha, diarista, etc."
                          />
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                )}

              <TableRow>
                <TableCell style={{ border: 'none' }}>
                  <Box
                    fontSize="0.88rem"
                    fontWeight="bold"
                    color="secondary.dark"
                  >
                    Valor total:
                  </Box>
                  <Box>R$ {data?.result.formattedPrice}</Box>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ border: 'none' }}>
                  <Box
                    fontSize="0.88rem"
                    fontWeight="bold"
                    color="secondary.dark"
                  >
                    Data:
                  </Box>
                  <Box>{data?.result.formattedDate}</Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            mt={3}
            pt={2}
            borderTop="2px solid #F4DDDD"
          >
            <Button
              variant="outlined"
              color="primary"
              style={{ width: '100%' }}
              onClick={() => setOpenModalDelete(true)}
            >
              Deletar Fluxo de Caixa
            </Button>

            <SimpleDialog
              open={openModalDelete}
              title="Deletar Fluxo de Caixa?"
              onClose={() => setOpenModalDelete(false)}
            >
              <Box display="flex" flexDirection="column" gridGap="16px" p={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDeleteCashFlow}
                  style={{ width: '100%', height: 56 }}
                >
                  Deletar
                </Button>

                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenModalDelete(false)}
                  style={{ width: '100%', height: 56 }}
                >
                  Cancelar
                </Button>
              </Box>
            </SimpleDialog>
          </Box>
        </>
      )}

      {status === 'success' && data?.result.id && (
        <ModalEditCashFlow
          data={data.result}
          isOpen={openModalEditCashFlow}
          onClose={() => setOpenModalEditCashFlow(false)}
          onCloseSuccess={handleSuccessEditCashFlow}
        />
      )}
    </PageContainer>
  );
}
