import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  DeleteOutlined,
  EditOutlined,
  VisibilityOutlined,
} from '@material-ui/icons';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import { MouseEvent, useState } from 'react';

import { Flex } from '../../../../shared/components/Flex';
import { IconButton } from '../../../../shared/components/IconButton';
import { AnnualPlaningIcon } from '../../../../shared/components/Icons';
import CarIcon from '../../../../shared/components/Icons/CarIcon';
import { AnualPlanningData } from '../../pages/AnnualPlanning/types';

const useStyles = makeStyles((theme: Theme) => ({
  subtitle2: {
    color: theme.text.primary,
  },
  title: {
    color: theme.text.primary,
    fontSize: '18px',
  },
  root: {
    display: 'flex',
    height: 'auto',
    border: 'none',
    background: 'transparent',
    borderRadius: theme.spacing(1),
    width: '100%',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    padding: 0,
    marginBottom: '10px',
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '120px',
    border: 'none',
    maxHeight: theme.spacing(19),
    borderRadius: theme.spacing(1),
    background: theme.palette.background.paper,
    borderBottom: `3px solid ${theme.background.primary}`,
    [theme.breakpoints.up('sm')]: {
      maxHeight: theme.spacing(20),
    },
    [theme.breakpoints.down(322)]: {
      height: '140px',
    },
  },
  image: {
    objectPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      maxHeight: theme.spacing(20),
    },
    [theme.breakpoints.down(370)]: {
      maxWidth: '15vh',
    },
  },
}));

interface AnnualPlanningTableProps {
  data: AnualPlanningData[];
  onDelete: (id: number) => void;
  onEdit: (anualPlanning: AnualPlanningData) => void;
  onView: (anualPlanning: AnualPlanningData) => void;
}

export function AnnualPlanningTable({
  data,
  onDelete,
  onEdit,
  onView,
}: AnnualPlanningTableProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectAnualPlanning, setSelectAnualPlanning] =
    useState<null | AnualPlanningData>(null);

  const handleOpenOptions = (
    event: MouseEvent<HTMLButtonElement>,
    anualPlanning: AnualPlanningData
  ) => {
    setSelectAnualPlanning(anualPlanning);
    setAnchorEl(event.currentTarget);
  };

  const handleOpenView = (anualPlanning: AnualPlanningData) => {
    if (!onView) return;

    onView(anualPlanning);
  };

  const handleCloseOptions = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    if (!selectAnualPlanning?.id || !onDelete) return;
    setAnchorEl(null);
    onDelete(selectAnualPlanning.id);
  };

  const handleView = () => {
    if (!selectAnualPlanning || !onView) return;
    setAnchorEl(null);
    onView(selectAnualPlanning);
  };

  const handleEdit = () => {
    if (!selectAnualPlanning || !onEdit) return;
    setAnchorEl(null);
    onEdit(selectAnualPlanning);
  };

  return (
    <>
      {!!data.length &&
        data.map((anualPlanning) => {
          return (
            <Box key={anualPlanning.id}>
              {/* <Box
                display="flex"
                // width="100%"
                ml={1}
                mr={1}
                mt={2}
                alignItems="center"
                justifyContent="space-between"
                style={{ borderTop: '2px solid grey' }}
              >
                <Typography
                  className={classes.title}
                  style={{
                    marginBottom: '10px',
                    marginTop: '5px',
                    textTransform: 'capitalize',
                  }}
                >
                  {anualPlanning.month}
                </Typography>
              </Box> */}
              <Box className={classes.root}>
                <Box className={classes.container}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    textAlign="center"
                    mt={2}
                    mb={2}
                    ml={3}
                    onClick={() => handleOpenView(anualPlanning)}
                    style={{ cursor: 'pointer' }}
                  >
                    <Box
                      fontSize="2rem"
                      lineHeight={1}
                      mb="4px"
                      pb="4px"
                      borderBottom="1px solid #eee"
                      className={classes.subtitle2}
                    >
                      {anualPlanning.formattedDay}
                    </Box>
                    <Box fontSize="1.18rem" color="secondary.light">
                      {anualPlanning.formattedMonth}
                    </Box>
                    <Box color="secondary.light">
                      {anualPlanning.formattedYear}
                    </Box>
                  </Box>
                  <Flex
                    px={2}
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    textAlign="left"
                    borderLeft="1px solid #eeeeee52"
                    ml={2}
                    my={2}
                    style={{ paddingLeft: 16, cursor: 'pointer' }}
                    onClick={() => handleOpenView(anualPlanning)}
                  >
                    <Typography
                      variant="subtitle2"
                      component="div"
                      style={{ textTransform: 'none' }}
                    >
                      <Box display="flex" alignItems="center">
                        <AnnualPlaningIcon
                          style={{
                            marginRight: 4,
                            width: '24px',
                            height: '24px',
                          }}
                        />
                        <Box
                          className={classes.title}
                          component="span"
                          display="block"
                        >
                          {anualPlanning.title}
                        </Box>
                      </Box>
                      <Box
                        component="span"
                        display="block"
                        color="secondary.main"
                        fontWeight="normal"
                        fontSize="0.75rem"
                      >
                        Evento para o dia {anualPlanning.formatedEventDate}
                      </Box>
                    </Typography>
                  </Flex>
                  <Box
                    fontSize="0.75rem"
                    color="primary.dark"
                    textAlign="right"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    fontWeight="400"
                    mt={1}
                  >
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      color="primary.dark"
                    >
                      <IconButton
                        aria-controls="menu-options-table"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={(event) =>
                          handleOpenOptions(event, anualPlanning)
                        }
                      >
                        <MoreVertOutlinedIcon color="inherit" />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}

      <Menu
        id="menu-options-table"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseOptions}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleView}>
          <VisibilityOutlined fontSize="small" color="inherit" />
          <Box ml={1}>Ver</Box>
        </MenuItem>

        <MenuItem onClick={handleEdit}>
          <EditOutlined fontSize="small" color="inherit" />
          <Box ml={1}>Editar</Box>
        </MenuItem>

        <MenuItem onClick={handleDelete}>
          <DeleteOutlined fontSize="small" color="inherit" />
          <Box ml={1}>Deletar</Box>
        </MenuItem>
      </Menu>
    </>
  );
}
