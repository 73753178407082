import { Box, Typography } from '@material-ui/core';
import { ReactNode } from 'react';

import NotFoundIcon from '../Icons/NotFoundIcon';

interface NoDataProps {
  visible?: boolean;
  title?: string;
  error?: string;
  description?: string;
  children?: ReactNode;
}

export function NoData({
  visible,
  title = 'Ops! Nenhum registro encontrado.',
  error,
  description,
  children,
}: NoDataProps) {
  if (visible === false) return <></>;

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      color="primary.main"
      textAlign="center"
      py={4}
      px={2}
    >
      <NotFoundIcon color="inherit" style={{ fontSize: '5rem' }} />

      <Typography component="div">
        <Box mt={3}>{error ? 'Ops! Ocorreu um erro.' : title}</Box>
        {description && (
          <Box fontSize="0.82rem" color="secondary.main">
            {description}
          </Box>
        )}
        {error && (
          <Box fontSize="0.82rem" color="secondary.main">
            {error}
          </Box>
        )}
      </Typography>

      {children}
    </Box>
  );
}
