import { Box, CircularProgress, useMediaQuery } from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { cardButtonsData } from '../../../../config/cardButtonsData';
import { Button } from '../../../../shared/components/Button';
import { ButtonLarge } from '../../../../shared/components/Button/ButtonLarge';
import ButtonLoadMore from '../../../../shared/components/Button/ButtonLoadMore/intex';
import { CardButtons } from '../../../../shared/components/CardButtons';
import { CardButtonItem } from '../../../../shared/components/CardButtons/CardButtonItem';
import { SearchForm } from '../../../../shared/components/Form/SearchForm';
import { Header } from '../../../../shared/components/Header';
import { NoData } from '../../../../shared/components/NoData';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { useRecipes } from '../../../../shared/hooks/reactQuery/useRecipes';
import { CardTechnicalSheet } from '../../components/CardTechnicalSheet';

export function TechnicalSheet() {
  const isNotMobile = useMediaQuery('(min-width:600px)');
  const history = useHistory();
  const params = useParams<{ slug: string }>();

  const urlAddTechnicalSheet = `/technical-sheets/new/${params.slug ?? ''}`;

  const [searchValue, setSearchValue] = useState('');
  const [defaultSearchValue, setDefaultSearchValue] = useState('');

  const cardButtons = useMemo(() => {
    return cardButtonsData.find(
      (card) => card.sectionName === 'Fichas Técnicas'
    );
  }, []);

  const buttonSelected = useMemo(() => {
    const findButton = cardButtons?.buttons.find((button) =>
      button.toPage.includes(params.slug)
    );

    return findButton;
  }, [cardButtons?.buttons, params.slug]);

  const recipeType = useMemo(() => {
    if (params.slug === 'cakes-and-pies') return 1;

    if (params.slug === 'sweets-and-deserts') return 2;

    if (params.slug === 'salvory') return 3;

    return undefined;
  }, [params.slug]);

  const {
    data,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    isFetching,
  } = useRecipes(searchValue, recipeType);

  useEffect(() => {
    if (searchValue || recipeType || recipeType === undefined) {
      refetch();
    }
  }, [refetch, searchValue, recipeType]);

  useEffect(() => {
    refetch();

    return () => {
      setSearchValue('');
      setDefaultSearchValue('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePush = useCallback(
    (route: string) => {
      history.push(route);
    },
    [history]
  );

  const handleSearch = useCallback(async (searchValue: string) => {
    setSearchValue(searchValue);
    setDefaultSearchValue(searchValue);
  }, []);

  const handleSearchClear = useCallback(() => {
    setSearchValue('');
    setDefaultSearchValue('');
    refetch();
  }, [refetch]);

  return (
    <PageContainer display="flex" flexDirection="column">
      <CardButtons mb={4}>
        {cardButtons?.buttons.map((button) => (
          <CardButtonItem
            key={button.name}
            name={button.name}
            premiumContent={!!button?.isPremiumRequired}
            isNew={button.isNew}
            icon={button.icon}
            onClick={() => handlePush(`${button.toPage}`)}
            isSelected={buttonSelected?.name === button.name}
            isDisabled={!!defaultSearchValue.length}
          />
        ))}
      </CardButtons>

      <Header
        goPush="/"
        title={buttonSelected?.name || 'Lista de fichas técnicas'}
        isLoading={isFetching}
        search={
          <SearchForm
            defaultValue={defaultSearchValue}
            loading={isFetching}
            searchValue={(value) => handleSearch(value)}
            searchClear={handleSearchClear}
            // disabled={loading || !clients.length}
          />
        }
      >
        <Button
          size="medium"
          variant="contained"
          color="primary"
          startIcon={<AddOutlined />}
          onClick={() => handlePush(urlAddTechnicalSheet)}
        >
          Adicionar {isNotMobile && 'Ficha Técnica'}
        </Button>
      </Header>

      <Box
        display="grid"
        gridGap="12px"
        gridTemplateColumns="1fr"
        position="relative"
      >
        {status === 'loading' && (
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            top={0}
            left={0}
            minHeight="30vh"
          >
            <CircularProgress />
          </Box>
        )}
        {data &&
          data.pages.map((group, index) => (
            <React.Fragment key={index}>
              {!!group.results?.length &&
                group.results.map((recipe) => (
                  <CardTechnicalSheet
                    key={recipe.id}
                    title={recipe.title}
                    image={recipe?.image_url}
                    description={recipe.description}
                    quantityIngredients={recipe.recipeHasProducts?.length}
                    onClick={() =>
                      handlePush(
                        `/technical-sheets/details/${recipe.id}/${
                          params?.slug || ''
                        }`
                      )
                    }
                  />
                ))}
            </React.Fragment>
          ))}

        {status !== 'loading' &&
          !data?.pages[0].results.length &&
          searchValue && (
            <NoData
              title="Ops! Nenhum registro encontrado."
              description="Sua pesquisa não encontrou nenhuma ficha técnica. Limpe o filtro de pesquisa."
            />
          )}

        {status === 'error' && !data?.pages[0].results.length && (
          <NoData title="Ops! Ocorreu um erro." description={error?.message} />
        )}

        {status !== 'loading' &&
          !data?.pages[0].results.length &&
          !searchValue && (
            <NoData
              title="Ops! Nenhuma ficha técnica registrada."
              description="Suas fichas técnicas aparecerão aqui. Clique no botão abaixo:"
            >
              <Box mb={3} />
              <ButtonLarge
                startIcon={<AddOutlined />}
                onClick={() => handlePush(urlAddTechnicalSheet)}
              >
                Adicionar Ficha Técnica
              </ButtonLarge>
            </NoData>
          )}

        {hasNextPage && (
          <ButtonLoadMore
            disabled={!hasNextPage || isFetchingNextPage}
            loadMore={() => fetchNextPage()}
            loading={isFetchingNextPage}
          />
        )}
      </Box>

      {!!data?.pages[0].results.length && (
        <ButtonLarge
          startIcon={<AddOutlined />}
          onClick={() => handlePush(urlAddTechnicalSheet)}
        >
          Adicionar Ficha Técnica
        </ButtonLarge>
      )}
    </PageContainer>
  );
}
