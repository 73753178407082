import { CreateTechnicalSheet } from '../modules/technicalSheet/pages/CreateTechnicalSheet';
import { DetailTechnicalSheet } from '../modules/technicalSheet/pages/DetailTechnicalSheet';
import { EditTechnicalSheet } from '../modules/technicalSheet/pages/EditTechnicalSheet';
import { TechnicalSheet } from '../modules/technicalSheet/pages/TechnicalSheet';
import { IRoutes } from './types.routes';

export const technicalSheetsRoutes: IRoutes[] = [
  {
    path: '/technical-sheets/details/:id/:slug?',
    children: <DetailTechnicalSheet />,
    isPrivate: true,
    exact: true,
    pageName: 'Detalhes da Fichas Técnicas',
  },
  {
    path: '/technical-sheets/edit/:id/:slug?',
    children: <EditTechnicalSheet />,
    isPrivate: true,
    exact: true,
    pageName: 'Editar Fichas Técnicas',
  },
  {
    path: '/technical-sheets/new/:slug?',
    children: <CreateTechnicalSheet />,
    isPrivate: true,
    exact: true,
    pageName: 'Fichas Técnicas',
  },
  {
    path: '/technical-sheets/:slug?',
    children: <TechnicalSheet />,
    isPrivate: true,
    exact: true,
    pageName: 'Fichas Técnicas',
  },
];
