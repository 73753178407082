import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { Input } from '../../../../../shared/components/Form/Input';
import { InputCurrency } from '../../../../../shared/components/Form/Input/InputCurrency';
import { Select } from '../../../../../shared/components/Form/Select';
import PremiumIcon from '../../../../../shared/components/Icons/PremiumIcon';
import { PageContainer } from '../../../../../shared/components/PageContainer';
import { usePremium } from '../../../../../shared/hooks/usePremium';
import { api } from '../../../../../shared/services/apiClient';
import { useStyles } from './styles';
import { FormValues } from './types';
import { Header } from '../../../../../shared/components/Header';

const schema = yup.object().shape({
  product_name: yup.string().required('O nome é obrigatório.'),
  price: yup.string().required('O preço é obrigatório.'),
  quantity: yup.string().required('A quantidade é obrigatória'),
  stock: yup.string(),
  product_type_id: yup
    .object({
      id: yup.string(),
      name: yup.string(),
    })
    .required('O tipo de produto é obrigatório.'),
  unit_measurements: yup
    .object({
      id: yup.string(),
      name: yup.string(),
    })
    .required('A unidade de medida é obrigatória.'),
});

interface Product {
  id: number;
  product_name: string;
  price: string;
  quantity: number;
  stock: number;
  productTypes: { id: number; name: string };
  unitMeasurement: { id: number; name: string };
}

interface UnitTypeResponse {
  id: number;
  name: string;
}
interface ProductTypeResponse {
  id: number;
  name: string;
}

interface DataResults {
  product_types: ProductTypeResponse[];
  unit_measurements: UnitTypeResponse[];
}
export function EditProduct() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [defaultPrice, setDefaultPrice] = useState<string | undefined>();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams<{ id: string }>();
  const [yourProduct, setYourProduct] = useState<Product>();
  const [unitType, setUnitType] = useState<UnitTypeResponse[]>([]);
  const [producType, setProductType] = useState<ProductTypeResponse[]>([]);
  const isPremium = usePremium();
  const classes = useStyles();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const setDefaultValues = useCallback(
    (yourProduct: Product) => {
      setValue('product_name', yourProduct.product_name);
      setValue('price', yourProduct.price);
      setValue('quantity', yourProduct.quantity);
      setValue('product_type', yourProduct.productTypes);
      setValue('unit_measurements', yourProduct.unitMeasurement);
      setValue('stock', yourProduct.stock);
      setDefaultPrice(yourProduct.price);
    },
    [setValue]
  );
  useEffect(() => {
    api.get(`/products/${params.id}`).then((response) => {
      setYourProduct(response.data.product);
    });
  }, [params.id]);

  useEffect(() => {
    if (yourProduct?.product_name) {
      setLoading(true);
      setDefaultValues(yourProduct);
      setLoading(false);
    }
  }, [yourProduct, setDefaultValues]);

  useEffect(() => {
    api.get<DataResults>('/products/types-and-units').then((response) => {
      setUnitType(response.data?.unit_measurements);
      setProductType(response.data?.product_types);
      // setValue(
      //   'product_type',
      //   response.data?.product_types?.find(
      //     (item) => item?.id === yourProduct?.productTypes?.id
      //   )
      // );
      // setValue(
      //   'unit_measurements',
      //   response.data?.unit_measurements?.find(
      //     (item) => item?.id === yourProduct?.unitMeasurement?.id
      //   )
      // );
    });
  }, []);

  const onSubmit = useCallback(
    async ({
      product_name,
      price,
      quantity,
      unit_measurements,
      product_type,
      stock,
    }: FormValues) => {
      setLoading(true);

      const formatData = {
        product_name,
        price: Number(price.toString().replace('R$', '').replace(',', '.')),
        quantity,
        stock,
        unit_of_measurement_id: unit_measurements.id,
        product_type_id: product_type.id,
      };

      try {
        await api.put(`/products/${params.id}`, formatData);

        enqueueSnackbar('Editado com sucesso!', { variant: 'success' });
        history.push('/records/products');
      } catch (err: any) {
        setLoading(false);
        if (err?.response?.data?.message) {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'warning',
          });
          return;
        }
        enqueueSnackbar('Erro no servidor.', {
          variant: 'error',
        });
      }
    },
    [history, params.id, enqueueSnackbar]
  );

  if (!yourProduct?.product_name) return <></>;

  return (
    <PageContainer display="flex" flexDirection="column">
      <Header goPush="/" title="Editar produto" />
      <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
        <Box display="flex" flexDirection="column" gridGap={16} width="100%">
          {yourProduct?.product_name && (
            <>
              {defaultPrice && (
                <Input
                  help="Insira o nome do seu produto"
                  name="product_name"
                  control={control}
                  defaultValue={yourProduct.product_name}
                  error={errors}
                  variant="outlined"
                  label="Nome do produto"
                  fullWidth
                  required
                  disabled={loading}
                />
              )}

              {defaultPrice && (
                <InputCurrency
                  help="Insira o valor que será cobrado por (Unidade , Litro, Mililitro, Grama ou Quilograma)
              de acordo com o seu produto"
                  name="price"
                  type="text"
                  label="Valor"
                  variant="outlined"
                  control={control}
                  defaultValue={defaultPrice}
                  error={errors}
                  style={{ marginBottom: 0 }}
                  disabled={!!loading}
                  required
                />
              )}
              {defaultPrice && (
                <Input
                  help="Insira a quantidade do produto que o cliente irar comprar pelo preço definido no campo anterior"
                  name="quantity"
                  type="number"
                  control={control}
                  defaultValue={yourProduct.quantity}
                  error={errors}
                  variant="outlined"
                  label="Quantidade da Embalagem"
                  fullWidth
                  required
                  disabled={loading}
                />
              )}
            </>
          )}

          <Select
            options={unitType}
            name="unit_measurements"
            control={control}
            defaultValue={yourProduct?.unitMeasurement}
            error={errors}
            variant="outlined"
            label="Unidade de medida"
            fullWidth
            required
            disabled={loading}
          />

          <Select
            options={producType}
            name="product_type"
            control={control}
            defaultValue={yourProduct?.productTypes}
            error={errors}
            variant="outlined"
            label="Tipo de produto"
            fullWidth
            required
            disabled={loading}
          />

          {defaultPrice && (
            <Box position="relative">
              {!isPremium && (
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  zIndex={1}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    endIcon={<PremiumIcon className={classes.premiumColor} />}
                    onClick={() => history.push('/become-premium')}
                  >
                    Seja Premium
                  </Button>
                </Box>
              )}
              <Input
                help="Insira a quantidade em estoque do produto"
                name="stock"
                type="number"
                control={control}
                error={errors}
                defaultValue={yourProduct.stock}
                variant="outlined"
                label="Quantidade em Estoque"
                fullWidth
                required
                disabled={loading || !isPremium}
                style={{ opacity: !isPremium ? 0.5 : 1 }}
              />
            </Box>
          )}
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          gridGap={8}
          mt={4}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            autoFocus
            color="primary"
            variant="outlined"
            size="large"
            onClick={() => history.push('/records/products')}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            autoFocus
            size="large"
            color="primary"
            variant="contained"
          >
            Alterar produto
          </Button>
        </Box>
      </Box>
    </PageContainer>
  );
}
