import { useContext } from 'react';

import { BudgetContext } from '../contexts/BudgetContext';

export const useBudget = () => {
  const context = useContext(BudgetContext);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};
