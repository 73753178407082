import { Box, makeStyles, Popover, Theme, Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    maxWidth: 300,
  },
}));

interface PopoverHelpProps {
  name: string;
  title?: string;
  help: string;
}

function PopoverHelp({ name, title, help }: PopoverHelpProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <Box
        color="primary.dark"
        aria-owns={open ? `${name}-mouse-over-popover` : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onClick={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <HelpIcon color="inherit" fontSize="small" style={{ opacity: 0.6 }} />
      </Box>

      <Popover
        id={`${name}-mouse-over-popover`}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
      >
        {title && (
          <Box color="secondary.main" fontWeight="bold" fontSize="1.1rem">
            {title}
          </Box>
        )}
        <Typography color="primary" variant="inherit">
          {help}
        </Typography>
      </Popover>
    </>
  );
}

export { PopoverHelp };
