import { Box, BoxProps, makeStyles, Theme } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: `calc(100% + ${theme.spacing(4)}px)`,
    overflowX: 'auto',

    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  card: {
    minWidth: 140,
    position: 'relative',
    boxShadow: '0 0 10px #51515133',
    [theme.breakpoints.up('sm')]: {
      minWidth: 180,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 250,
    },
  },
}));

interface CardsTopCashFlowProps extends BoxProps {
  input: string;
  output: string;
  balance: string;
  isNegativeBalance?: boolean;
}

export function CardsTopCashFlow({
  input = '0,00',
  output = '0,00',
  balance = '0,00',
  isNegativeBalance = false,
  ...rest
}: CardsTopCashFlowProps) {
  const classes = useStyles();

  return (
    <Box
      className={classes.container}
      {...rest}
      display="flex"
      flexDirection="row"
      flexWrap="no-wrap"
      alignItems="center"
      justifyContent={['flex-start', 'space-between']}
      py={2}
      px={[2, '4px']}
      mt={1}
      mx={[-2, 0]}
      bgcolor="transparent"
    >
      <Box
        className={classes.card}
        display="flex"
        flexDirection="column"
        p={2}
        bgcolor="green"
        borderRadius={8}
        mr={2}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="flex-end"
          alignItems="flex-start"
          color="#22BC44"
        >
          <AddOutlinedIcon />
        </Box>

        <Box
          component="strong"
          fontWeight="normal"
          fontSize="1.1rem"
          color="white"
        >
          R$ {input}
        </Box>
        <Box
          component="small"
          fontSize="0.88rem"
          color="white"
          style={{ fontStyle: 'oblique' }}
        >
          Entradas
        </Box>
      </Box>

      <Box
        className={classes.card}
        display="flex"
        flexDirection="column"
        p={2}
        bgcolor="#9F0303"
        borderRadius={8}
        mr={2}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="flex-end"
          alignItems="flex-start"
          color="red"
        >
          <RemoveOutlinedIcon />
        </Box>

        <Box
          component="strong"
          fontWeight="normal"
          fontSize="1.1rem"
          color="white"
        >
          R$ {output}
        </Box>
        <Box
          component="small"
          fontSize="0.88rem"
          color="white"
          style={{ fontStyle: 'oblique' }}
        >
          Saídas
        </Box>
      </Box>

      <Box
        className={classes.card}
        display="flex"
        flexDirection="column"
        p={2}
        bgcolor="white"
        borderRadius={8}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="flex-end"
          alignItems="flex-start"
          color="black"
        >
          <MonetizationOnOutlinedIcon />
        </Box>

        <Box
          component="strong"
          fontSize="1.1rem"
          color={isNegativeBalance ? 'red' : 'green'}
        >
          {isNegativeBalance && '-'}R$ {balance}
        </Box>
        <Box
          component="small"
          fontSize="0.88rem"
          color="black"
          style={{ fontStyle: 'oblique' }}
        >
          Saldo
        </Box>
      </Box>
    </Box>
  );
}
