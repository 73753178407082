/* eslint-disable prettier/prettier */
import { Box } from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../shared/components/Button';
import ButtonLoadMore from '../../../../shared/components/Button/ButtonLoadMore/intex';
import { Header } from '../../../../shared/components/Header';
import { ModalBePremium } from '../../../../shared/components/Modal/ModalBePremium';
import { NoData } from '../../../../shared/components/NoData';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { SectionContainer } from '../../../../shared/components/SectionContainer';
import { useBudgets } from '../../../../shared/hooks/reactQuery/useBudgets';
import { IBudgetProps } from '../../../../shared/hooks/reactQuery/useBudgets/types';
import { useBudget } from '../../../../shared/hooks/useBudget';
import { usePremium } from '../../../../shared/hooks/usePremium';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { BudgetList } from '../../components/BudgetList';

export function Budget() {
  const history = useHistory();
  const isPremium = usePremium();
  const { msgError, msgSuccess, msgWarning } = useSnackMessages();
  const [loading, setLoading] = useState(false);
  const [budgets, setBudgets] = useState<IBudgetProps[] | null>(null);

  const { updateBudgetData } = useBudget();

  const {
    data,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    isFetching,
  } = useBudgets();

  const queryData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/budgets`);

      setBudgets(response.data.results);
    } catch (error) {
      // history.push('/');
    }
    setLoading(false);
  };
  useEffect(() => {
    queryData();
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
    history.push('/');
  };

  const handleDelete = useCallback(
    (data: any) => {
      api
        .delete(`/budgets/${data?.file}`)
        .then(() => {
          msgSuccess('Orçamento deletado com sucesso!');
          refetch();
        })
        .catch((err: any) => {
          msgError(err.response.data?.message);
        });
    },
    [msgSuccess, msgError, refetch]
  );

  useEffect(() => {
    if (isPremium === false) {
      setOpenModal(true);
    }
  }, [isPremium]);

  return (
    <PageContainer display="flex" flexDirection="column">
      <Header goPush="/" title="Orçamentos">
        <Button
          size="medium"
          variant="contained"
          color="primary"
          startIcon={<AddOutlined />}
          onClick={() => {
            updateBudgetData(null);
            history.push('/budgets/create');
          }}
        >
          Novo
        </Button>
      </Header>

      <SectionContainer>
        {budgets &&
          budgets?.map((item: any) => (
            <React.Fragment key={item.id}>
              <BudgetList
                key={`${item.id}_${item.id}`}
                data={item}
                onDelete={(data) => handleDelete(data)}
              />
            </React.Fragment>
          ))}

        {status === 'error' && !data?.pages[0].results.length && (
          <NoData title="Ops! Ocorreu um erro." description={error?.message} />
        )}

        {status !== 'loading' && !data?.pages[0].results.length && (
          <NoData title="Ops! Nenhum orçamento registrado.">
            <Box mb={3} />
            <Button
              size="medium"
              variant="contained"
              color="primary"
              startIcon={<AddOutlined />}
              onClick={() => history.push('/budgets/create')}
            >
              Novo orçamento
            </Button>
          </NoData>
        )}
      </SectionContainer>

      {/* {hasNextPage && (
        <ButtonLoadMore
          disabled={!hasNextPage || isFetchingNextPage}
          loadMore={() => fetchNextPage()}
          loading={isFetchingNextPage}
        />
      )} */}
      <ModalBePremium open={openModal} handleClose={closeModal} />
    </PageContainer>
  );
}
