import { Box, TableCell, TableRow } from '@material-ui/core';

type TableRowScheduleData = {
  title: string;
  children: React.ReactNode;
};

export function TableRowSchedule({ title, children }: TableRowScheduleData) {
  return (
    <TableRow>
      <TableCell style={{ border: 'none' }}>
        <Box fontSize="0.88rem" fontWeight="bold" color="primary.main">
          {title}
        </Box>

        <Box display="flex" flexDirection="column" width="100%">
          {children}
        </Box>
      </TableCell>
    </TableRow>
  );
}
