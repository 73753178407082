import { Box } from '@material-ui/core';
import {
  CheckOutlined,
  DoneAll,
  PriorityHighOutlined,
} from '@material-ui/icons';

export function ScheduleStatusInformation() {
  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="flex-end"
      flexDirection="column"
      mt={3}
    >
      <Box display="flex" alignItems="center" mb={1}>
        <Box fontSize="0.78rem" mr={1}>
          Não entregue
        </Box>
        <PriorityHighOutlined fontSize="small" style={{ color: '#B54D54' }} />
      </Box>
      <Box display="flex" alignItems="center" mb={1}>
        <Box fontSize="0.78rem" mr={1}>
          Entregue, pagamento pendente
        </Box>
        <CheckOutlined fontSize="small" style={{ color: '#fbe20c' }} />
      </Box>
      <Box display="flex" alignItems="center">
        <Box fontSize="0.78rem" mr={1}>
          Entregue, pagamento realizado
        </Box>
        <DoneAll fontSize="small" style={{ color: '#22BC44' }} />
      </Box>
    </Box>
  );
}
