import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@material-ui/core/IconButton';
import { motion, TargetAndTransition, VariantLabels } from 'framer-motion';
import { FC } from 'react';

type IconButtonProps = MuiIconButtonProps & {
  whileTap?: VariantLabels | TargetAndTransition | undefined;
};

export const IconButton: FC<IconButtonProps> = ({
  whileTap,
  children,
  ...rest
}) => {
  return (
    <motion.div
      className="animatable"
      whileTap={whileTap || { scale: 0.85 }}
      style={{ display: 'flex' }}
    >
      <MuiIconButton {...rest}>{children}</MuiIconButton>
    </motion.div>
  );
};
