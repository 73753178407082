import { useQuery } from 'react-query';

import { api } from '../../../services/apiClient';
import { IBalancePoint, ICashFlowResponse } from './types';

type IReactQueryResponse = {
  results: IBalancePoint[];
};

async function getData(year: number | string) {
  const response = await api.get<ICashFlowResponse>(
    `/sales/cash-flow/balance-point/?year=${year}`
  );

  const data: IReactQueryResponse = {
    results: response.data.results,
  };
  return data;
}

function useBalancePoint(year: number | string) {
  return useQuery<IReactQueryResponse, Error>(['cash-flow', `${year}`], () =>
    getData(year)
  );
}

export { useBalancePoint };
