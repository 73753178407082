import {
  Box,
  createStyles,
  Dialog,
  DialogContent,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useCallback, useEffect, useState } from 'react';

import { Button } from '../../shared/components/Button';
import { usePremium } from '../../shared/hooks/usePremium';
import { api } from '../../shared/services/apiClient';

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      '& .MuiDialog-paper': {
        overflow: 'inherit',
      },
    },
  })
);

interface AdProps {
  id: number;
  image: string;
  url_redirect: string;
  image_url: string;
  title: string;
  type: string;
  status: boolean;
}

export function FullPageAdModal() {
  const classes = useStyles();
  const isPremium = usePremium();
  const [fullAd, setFullAd] = useState<AdProps[]>([]);
  const [fullViewModal, setFullViewModal] = useState<AdProps>();
  const [modal, setModal] = useState(true);

  const sortRandom = useCallback(() => {
    setFullViewModal(
      (state) => fullAd[Math.floor(Math.random() * fullAd.length)] || state
    );
  }, [fullAd]);

  useEffect(() => {
    if (fullAd.length) {
      setInterval(sortRandom, 20000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortRandom]);

  useEffect(() => {
    api.get<{ results: AdProps[] }>('/ads').then((response) => {
      const adsFullPage = response.data.results.filter(
        (item) => item.type === 'fullscreen' && item.status === true
      );

      if (adsFullPage.length) {
        setFullAd(adsFullPage);
        setFullViewModal(
          adsFullPage[Math.floor(Math.random() * adsFullPage.length)]
        );
      }
    });
  }, []);

  const closeModal = () => {
    setModal(false);
  };

  return (
    <Box>
      {!isPremium && fullViewModal && (
        <Dialog
          className={classes.modal}
          aria-labelledby="create-ad-dialog"
          open={modal}
          maxWidth="sm"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflowY: 'auto',
          }}
        >
          <Box
            position="absolute"
            width="100%"
            display="flex"
            p={1}
            justifyContent="flex-end"
            zIndex={3}
          >
            <Box style={{ top: '10px', left: '250px', right: '0px' }}>
              <Button
                onClick={closeModal}
                color="primary"
                variant="contained"
                size="small"
              >
                <CloseIcon />
              </Button>
            </Box>
          </Box>
          <a
            target="_blank"
            href={fullViewModal.url_redirect}
            rel="noreferrer"
            style={{ cursor: 'alias' }}
          >
            <DialogContent style={{ padding: 0 }}>
              <img
                src={fullViewModal.image_url}
                alt={fullViewModal.title}
                style={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'contain',
                  objectPosition: 'center',
                  borderTopLeftRadius: 4,
                  borderTopRightRadius: 4,
                }}
              />
            </DialogContent>
          </a>
        </Dialog>
      )}
    </Box>
  );
}
