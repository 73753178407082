import { useContext } from 'react';

import { YourRegistrationContext } from '../contexts/YourRegistrationContext';

export function useModalYourRegistration() {
  const { isOpen, onClose, onOpen, yourRegistration, cities } = useContext(
    YourRegistrationContext
  );

  return {
    isOpen,
    onClose,
    onOpen,
    yourRegistration,
    cities,
  };
}
