import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function SalesAdjustmentsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M22.1542 20.1767C22.1175 20.14 21.1263 19.4067 20.7592 18.7833C20.6123 18.5267 20.3921 18.0133 20.0617 17.17C19.6579 16.18 19.1072 14.86 18.8502 14.5667C18.5932 14.2733 18.2261 14.0167 17.8223 13.87L16.2071 13.3933L16.4273 12.6967L18.0426 13.1733C18.5933 13.3567 19.0705 13.65 19.4376 14.09C19.7313 14.4567 20.1718 15.4833 20.7592 16.9133C21.0162 17.5733 21.3098 18.2333 21.42 18.4533C21.6769 18.8567 22.3744 19.4433 22.6314 19.6633L22.1542 20.1767Z" />
      <path d="M20.8693 22.5233L20.3186 22.23C20.2819 22.23 20.2452 22.1933 20.2085 22.1567C18.4097 21.2767 17.1615 20.3967 16.4273 19.59C15.9868 19.15 15.4728 18.6366 15.0323 18.1966C14.7386 17.9033 14.4449 17.61 14.2247 17.39C14.0044 17.17 13.7474 16.9133 13.7474 16.4C13.7474 16.1066 13.8576 15.8133 14.0778 15.63C14.4449 15.2633 14.9956 15.19 15.4361 15.4833C15.5463 15.5566 17.0147 16.3266 17.9692 16.84L17.6388 17.5C17.3818 17.39 15.2526 16.2533 15.069 16.1433C14.8855 16.0333 14.7019 16.07 14.5918 16.18C14.5184 16.2533 14.4817 16.3266 14.4817 16.4366C14.4817 16.6566 14.5551 16.6933 14.7386 16.9133C14.9589 17.1333 15.2159 17.39 15.5463 17.72C15.9868 18.16 16.5007 18.6733 16.9413 19.1133C17.6388 19.8833 18.8502 20.69 20.5389 21.5333C20.6123 21.57 20.649 21.6067 20.6858 21.6067L21.2364 21.9L20.8693 22.5233Z" />
      <path d="M15.5829 17.8666H5.30396V18.6H15.5829V17.8666Z" />
      <path d="M9.70927 10.1667H8.97506C8.97506 8.88336 8.38769 7.34336 8.094 6.64669L5.92807 7.93002C6.29517 8.33336 6.77241 9.06669 6.77241 10.13H6.03819C6.03819 8.81002 5.15713 8.18669 5.08371 8.15003L4.60648 7.82003L8.38768 5.54669L8.57124 5.91336C8.60795 6.06003 9.70927 8.33336 9.70927 10.1667Z" />
      <path d="M16.2438 10.1667H15.5095C15.5095 9.03001 16.0969 7.96667 16.464 7.41667L14.2614 6.57334C14.0411 6.94001 13.6006 7.67334 13.417 8.33334L12.7195 8.11334C13.0499 7.08667 13.7842 5.95 13.8209 5.91334L13.9677 5.65668L17.6755 7.05001L17.3451 7.45334C17.3084 7.49001 16.2438 8.77334 16.2438 10.1667Z" />
      <path d="M14.0411 10.3134L13.3803 10.02L13.9677 8.70002C13.417 8.66336 12.4993 8.59002 11.8017 8.37002L11.1042 10.3134L10.4067 10.0567L11.3612 7.41669L11.7283 7.56335C12.6828 7.96669 14.4816 7.96669 14.5183 7.96669H15.069L14.0411 10.3134Z" />
      <path d="M11.7283 8.15001L11.4713 6.31668L8.82818 7.52667L8.53448 6.86668L12.0587 5.21667L12.4625 8.04002L11.7283 8.15001Z" />
      <path d="M15.2158 20.0667H6.40528C5.59765 20.0667 4.93686 19.4067 4.93686 18.6V12.3667C4.93686 11.56 5.59765 10.9 6.40528 10.9H15.2158C16.0235 10.9 16.6843 11.56 16.6843 12.3667V16.5467H15.95V12.3667C15.95 11.9634 15.6197 11.6334 15.2158 11.6334H6.40528C6.00147 11.6334 5.67107 11.9634 5.67107 12.3667V18.6C5.67107 19.0034 6.00147 19.3334 6.40528 19.3334H15.2158C15.5462 19.3334 15.8399 19.1134 15.9133 18.82L16.6108 19.04C16.4273 19.6267 15.8766 20.0667 15.2158 20.0667Z" />
      <path d="M8.60795 12.3667H6.4053V13.1H8.60795V12.3667Z" />
      <path d="M10.8106 12.3667H9.34216V13.1H10.8106V12.3667Z" />
      <path d="M4.93686 3.20001H4.20264V5.76668H4.93686V3.20001Z" />
      <path d="M7.50661 1.36664H6.7724V2.09997H7.50661V1.36664Z" />
      <path d="M7.50661 2.83331H6.7724V3.56664H7.50661V2.83331Z" />
      <path d="M7.50661 4.29999H6.7724V5.39999H7.50661V4.29999Z" />
      <path d="M10.4435 1H9.70926V3.93334H10.4435V1Z" />
      <path d="M13.3803 1H12.6461V1.73333H13.3803V1Z" />
      <path d="M13.3803 2.46667H12.6461V3.20001H13.3803V2.46667Z" />
      <path d="M15.583 2.09998H14.8488V5.39997H15.583V2.09998Z" />
      <path d="M17.4185 1H16.6843V2.46667H17.4185V1Z" />
      <path d="M17.4185 3.20001H16.6843V3.93335H17.4185V3.20001Z" />
      <path d="M21.0896 6.8667H20.3553V7.60004H21.0896V6.8667Z" />
      <path d="M21.0896 8.33331H20.3553V9.06665H21.0896V8.33331Z" />
      <path d="M21.0896 9.79999H20.3553V10.5333H21.0896V9.79999Z" />
      <path d="M2.73422 11.2667H2V12H2.73422V11.2667Z" />
      <path d="M2.73422 12.7333H2V13.4667H2.73422V12.7333Z" />
      <path d="M2.73422 14.2H2V14.9333H2.73422V14.2Z" />
      <path d="M3.83553 17.5H3.10132V18.2333H3.83553V17.5Z" />
      <path d="M3.83553 18.9667H3.10132V19.7H3.83553V18.9667Z" />
      <path d="M7.87371 20.8H7.1395V21.5333H7.87371V20.8Z" />
      <path d="M7.87371 22.2667H7.1395V23H7.87371V22.2667Z" />
      <path d="M15.583 20.8H14.8488V21.5333H15.583V20.8Z" />
      <path d="M15.583 22.2667H14.8488V23H15.583V22.2667Z" />
      <path d="M22.1909 13.8333H21.4567V14.5667H22.1909V13.8333Z" />
      <path d="M22.1909 15.3H21.4567V16.0333H22.1909V15.3Z" />
    </SvgIcon>
  );
}
