import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function FofaIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M9.06666 8.25362L8.92 8.18005C8.66333 8.06969 6.5 7.15005 6.5 5.7154C6.5 4.53825 7.85666 3.65539 9.06666 4.46468C9.32333 4.28075 9.61666 4.13361 10.02 4.13361C10.9 4.13361 11.6333 4.83254 11.6333 5.7154C11.6333 7.11326 9.46999 8.06969 9.21333 8.18005L9.06666 8.25362ZM8.11333 4.90611C7.63666 4.90611 7.23333 5.27397 7.23333 5.75218C7.23333 6.4879 8.48 7.18683 9.06666 7.44433C9.65333 7.18683 10.9 6.45112 10.9 5.75218C10.9 5.27397 10.4967 4.90611 10.02 4.90611C9.76333 4.90611 9.54333 5.05325 9.32333 5.23718L9.10333 5.42111L8.88333 5.23718C8.59 5.01647 8.33333 4.90611 8.11333 4.90611Z" />
      <path d="M12 10.424C11.89 10.424 11.78 10.3872 11.67 10.3504L10.5333 9.7986C10.0567 9.98253 9.58 10.0929 9.06666 10.0929C6.82999 10.0929 5.03333 8.29038 5.03333 6.04645C5.03333 3.80251 6.82999 2 9.06666 2C11.3033 2 13.1 3.80251 13.1 6.04645C13.1 6.85574 12.8433 7.62824 12.4033 8.32717L12.6967 9.57789C12.6967 9.61468 12.7333 9.68825 12.7333 9.76182C12.7333 10.1297 12.4033 10.424 12 10.424ZM10.57 8.98932L12 9.68825L11.6333 8.14324L11.7433 7.9961C12.1833 7.40752 12.4033 6.74538 12.4033 6.00966C12.4033 4.17037 10.9367 2.69893 9.10333 2.69893C7.27 2.69893 5.80333 4.17037 5.80333 6.00966C5.80333 7.84895 7.27 9.32039 9.10333 9.32039C9.58 9.32039 10.02 9.21003 10.4233 9.0261L10.57 8.98932Z" />
      <path d="M12.4708 8.81107L11.9321 9.31018L13.9469 11.4993L14.4857 11.0002L12.4708 8.81107Z" />
      <path d="M14.4567 23.299C14.3833 23.299 14.31 23.299 14.2367 23.2622L10.13 22.3793C9.90999 22.3426 9.72666 22.2322 9.57999 22.0483L1.62334 13.4772C1.47667 13.33 1.44 13.1461 1.44 12.9622C1.44 12.6679 1.62333 12.4104 1.88 12.3L8.40667 9.28357L8.7 9.94572L2.17334 12.9622L10.13 21.5333C10.1667 21.57 10.24 21.6068 10.3133 21.6436L14.42 22.5265C14.4933 22.5265 14.6033 22.5265 14.6767 22.4897L20.69 19.694L20.9833 20.3561L14.97 23.1518C14.7867 23.299 14.6033 23.299 14.4567 23.299Z" />
      <path d="M14.2367 23.1886L12.4767 21.0918L10.1667 22.3058L9.83667 21.6436L12.1467 20.4297C12.4767 20.2826 12.8067 20.3561 12.99 20.5768L14.7867 22.6736L14.2367 23.1886Z" />
      <path d="M8.88331 13.3668C8.18664 13.3668 7.59998 12.7782 7.59998 12.0793C7.59998 11.3804 8.18664 10.7918 8.88331 10.7918C9.57998 10.7918 10.1666 11.3804 10.1666 12.0793C10.1666 12.815 9.57998 13.3668 8.88331 13.3668ZM8.88331 11.5275C8.58998 11.5275 8.33331 11.785 8.33331 12.0793C8.33331 12.3736 8.58998 12.6311 8.88331 12.6311C9.17664 12.6311 9.43331 12.3736 9.43331 12.0793C9.43331 11.785 9.17664 11.5275 8.88331 11.5275Z" />
      <path d="M21.2628 10.2784L20.5767 10.5382L22.092 14.5646L22.778 14.3047L21.2628 10.2784Z" />
      <path d="M13.1 13.0357C13.1 12.5575 13.2466 12.0425 13.54 11.5275L13.5766 11.4907C13.6133 11.4172 14.7866 9.90895 15.52 9.17323C16.2533 8.43751 17.5 8.2168 17.8666 8.2168C18.16 8.2168 19.1133 8.25358 19.8833 8.65823L19.5166 9.28358C19.04 9.02608 18.3433 8.91573 17.8666 8.91573C17.5366 8.91573 16.5466 9.13644 16.0333 9.65144C15.3733 10.3136 14.31 11.6747 14.1633 11.8586C13.9433 12.2265 13.8333 12.6311 13.8333 12.9622L13.1 13.0357Z" />
      <path d="M21.9733 20.1354L20.03 17.0086C19.92 16.7879 19.7 16.6776 19.48 16.604C19.0767 16.5304 18.71 16.42 18.3433 16.2729L18.27 16.2361C18.16 16.1625 18.0133 16.089 17.83 16.0154C17.2067 15.7211 16.2533 15.2429 15.85 15.1693C15.3 15.059 14.8967 14.5808 14.8967 14.1025C14.8967 13.4772 15.3733 12.999 15.9967 12.999H18.93V13.7347H15.9967C15.7767 13.7347 15.63 13.8818 15.63 14.1025C15.63 14.2865 15.8133 14.4336 15.9967 14.4704C16.51 14.5808 17.4633 15.059 18.16 15.39C18.3067 15.4636 18.4533 15.5372 18.5633 15.574C18.9667 15.7579 19.3333 15.8315 19.59 15.905C20.03 15.9786 20.3967 16.2361 20.6533 16.6408L22.5967 19.7676L21.9733 20.1354Z" />
      <path d="M13.5767 15.574H11.0467L12.0734 13.4772L21.02 7.66505C21.24 7.51791 21.46 7.44434 21.7167 7.44434C22.4134 7.44434 23 8.03291 23 8.73184C23 9.17327 22.78 9.57792 22.4134 9.79863L16.51 13.6244L16.1067 12.999L22.01 9.17327C22.1567 9.06291 22.2667 8.91577 22.2667 8.69506C22.2667 8.40077 22.01 8.14327 21.7167 8.14327C21.6067 8.14327 21.4967 8.18006 21.4234 8.21684L12.66 13.9186L12.2567 14.7647H13.3934L15.1534 13.6611L15.5567 14.2865L13.5767 15.574Z" />
      <path d="M6.68334 23.7772L4.92334 23.299L1.18333 17.3029C1.07333 17.119 1 16.9351 1 16.7144C1 16.089 1.47667 15.6108 2.1 15.6108C2.46667 15.6108 2.83333 15.7947 3.01667 16.1258L6.86667 22.1587L6.68334 23.7772ZM5.4 22.6737L6.06 22.8576L6.13334 22.3058L2.43 16.4936C2.35667 16.3833 2.24667 16.3097 2.13667 16.3097C1.91667 16.3097 1.77 16.4569 1.77 16.6776C1.77 16.7511 1.80666 16.8247 1.84333 16.8615L5.4 22.6737Z" />
      <path d="M8.99334 16.6776L8.26001 16.5304C8.77334 13.5875 10.3867 13.3668 10.8633 13.3668V14.1025C10.2033 14.1025 9.36001 14.8015 8.99334 16.6776Z" />
      <path d="M10.3133 19.0686L10.2034 18.6272C10.1667 18.5168 9.43335 15.9786 9.43335 14.8383C9.43335 14.029 10.0933 13.3668 10.9 13.3668V14.1025C10.4967 14.1025 10.1667 14.4336 10.1667 14.8383C10.1667 15.6108 10.57 17.2293 10.79 18.0018L14.31 16.2361L14.64 16.8983L10.3133 19.0686Z" />
      <path d="M11.89 19.7308L11.56 19.0687L12.2567 18.7376L11.8167 17.8915L12.4767 17.5604L13.2467 19.0687L11.89 19.7308Z" />
      <path d="M13.8333 18.7744L13.1 17.2661L13.76 16.9351L14.1633 17.7811L14.64 17.5604L14.97 18.2226L13.8333 18.7744Z" />
    </SvgIcon>
  );
}
