import { Box, TextField } from '@material-ui/core';
import { AddOutlined, RemoveOutlined } from '@material-ui/icons';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from '../../../../../shared/components/Button';
import { Header } from '../../../../../shared/components/Header';
import { Loading } from '../../../../../shared/components/Loading';
import { PageContainer } from '../../../../../shared/components/PageContainer';
import { Recipe } from '../../../../../shared/hooks/reactQuery/useRecipes/types';
import { useSnackMessages } from '../../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../../shared/services/apiClient';
import { useStyles } from './styles';

type Calculator = {
  id: number;
  title: string | number;
  productPurchaseCalculator: Array<{
    id: number;
    quantity: number;
    product_id: number;
    product: {
      product_name: string;
      unitMeasurement: {
        name: string;
      };
    };
  }>;
};

type ITdInputProps = {
  defaultValue?: unknown;
  onChange: (value: number | string) => void;
  type?: string;
};

export function TdInput({
  defaultValue,
  onChange,
  type = 'number',
}: ITdInputProps) {
  const classes = useStyles();

  return (
    <td>
      <TextField
        className={classes.input}
        defaultValue={defaultValue}
        name="test"
        variant="outlined"
        type={type}
        size="small"
        onChange={(e) => onChange(e.target.value)}
        required
      />
    </td>
  );
}

type TableHead = {
  name: string;
  unit: string;
};

type CalculatorProducts = {
  rowId: number;
  title: number | string;
  products: Array<{
    quantity?: number;
    product_id: number;
  }>;
};

export function SelectRecipe() {
  const classes = useStyles();
  const history = useHistory();
  const { msgError } = useSnackMessages();

  const { state } = useLocation<{ recipe: Recipe }>();
  const [recipe, setRecipe] = useState<Recipe>({} as Recipe);
  const [tableHead, setTableHead] = useState<TableHead[]>([]);
  const [calculatorProducts, setCalculatorProducts] = useState<
    CalculatorProducts[]
  >([]);
  const [saveProducts, setSaveProducts] = useState(false);
  const [loading, setLoading] = useState(true);

  const handlePush = useCallback(() => {
    history.push('/sales/calculator');
  }, [history]);

  useEffect(() => {
    if (!state?.recipe?.id) {
      handlePush();
    }
  }, [state?.recipe?.id, handlePush]);

  useEffect(() => {
    setLoading(true);

    if (state?.recipe?.id) {
      api
        .get<Recipe>(`/recipes/${state.recipe.id}`)
        .then((response) => {
          setRecipe(response.data);

          api
            .get<Calculator[]>(`/purchase-calculator/${state.recipe.id}`)
            .then((res) => {
              res.data?.forEach((row) => {
                setTableHead(
                  row.productPurchaseCalculator.map((item) => {
                    return {
                      name: item.product.product_name,
                      unit: item.product.unitMeasurement.name,
                    };
                  })
                );
              });

              setCalculatorProducts(
                res.data?.map((row) => {
                  return {
                    rowId: row.id,
                    title: row.title,
                    products: row.productPurchaseCalculator.map((product) => {
                      return {
                        quantity: product.quantity,
                        product_id: product.product_id,
                      };
                    }),
                  };
                })
              );
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              msgError(err?.response?.data?.message);
            });
        })
        .catch((err) => {
          setLoading(false);
          msgError(err?.response?.data?.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.recipe?.id]);

  const handleChangeQuantityProduct = useCallback(
    ({ newQuantity, rowId, selectProduct }: any) => {
      setSaveProducts(true);
      //
      setCalculatorProducts((state) =>
        state.map((item) => {
          if (item.rowId === rowId) {
            const products = item.products.map((product) => {
              if (product.product_id === selectProduct.product_id) {
                return {
                  product_id: product.product_id,
                  quantity: Number(newQuantity),
                };
              }
              return product;
            });

            return {
              ...item,
              products,
            };
          }

          return item;
        })
      );
    },
    []
  );

  const handleChangeQuantityIncome = useCallback(
    ({ newQuantity, rowId }: any) => {
      setSaveProducts(true);
      //
      setCalculatorProducts((state) =>
        state.map((item) => {
          if (item.rowId === rowId) {
            return {
              ...item,
              title: newQuantity,
              products: item.products.map((product) => ({ ...product })),
            };
          }

          return item;
        })
      );
    },
    []
  );

  const handleAddIncoming = () => {
    setSaveProducts(true);

    setCalculatorProducts((state) => [
      ...state,
      {
        rowId: Math.floor(Math.random() * (500 - 111 + 1) + 111),
        title: 0,
        products: state[0].products.map((product) => ({
          ...product,
          quantity: undefined,
        })),
      },
    ]);
  };

  const handleRemoveIncoming = () => {
    setSaveProducts(true);

    const lastItem = calculatorProducts.length;

    setCalculatorProducts((state) =>
      state.filter((_, index) => index !== lastItem - 1)
    );
  };

  const handleNextPage = () => {
    if (saveProducts) {
      api
        .post('/purchase-calculator', {
          items: calculatorProducts,
          recipe_id: recipe.id,
        })
        .then(() => {
          console.log('Ok, Produtos adicionado');
          history.push(
            `/sales/calculator/recipe/results/${state?.recipe?.id}`,
            {
              recipe,
              calculatorProducts,
            }
          );
        })
        .catch((err) => {
          msgError(
            err?.response?.data?.message ||
              'Não foi possível registrar os seus dados. Tente novamente ou entre em contato com o suporte.'
          );
        });
    } else {
      console.log('Ok, Nada foi alterado.');
      history.push(`/sales/calculator/recipe/results/${state?.recipe?.id}`, {
        recipe,
        calculatorProducts,
      });
    }
  };

  return (
    <PageContainer display="flex" flexDirection="column">
      <Header
        title={state.recipe.title || ''}
        goPush="/sales/calculator"
        isLoading={false}
      >
        <Button onClick={handleNextPage}>Avançar</Button>
      </Header>

      {loading && (
        <Box
          position="relative"
          display="flex"
          width="100%"
          height="200px"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Loading />
        </Box>
      )}

      {!loading && (
        <>
          <Box display="flex" width="100%" flexDirection="column" pl={1}>
            <Box color="primary.dark" fontSize="1.2rem">
              Tabela de Rendimentos
            </Box>
            <Box color="secondary.main">
              Adicione seus rendimentos e defina a quantidade para cada produto.
            </Box>
          </Box>
          <Box width="100%" px={1} pb={2} pt={2} style={{ overflowX: 'auto' }}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>Rendimento</th>
                  {tableHead.map((item) => (
                    <th key={`thead-${item.name}`}>
                      <Box>{item.name}</Box>
                      <Box fontSize="0.755rem">({item.unit})</Box>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {calculatorProducts.map((item, index) => (
                  <tr key={`item-${item.rowId}-${index}`}>
                    <TdInput
                      type="text"
                      defaultValue={item.title}
                      onChange={(value) =>
                        handleChangeQuantityIncome({
                          newQuantity: value,
                          rowId: item.rowId,
                        })
                      }
                    />

                    {item.products?.map((product) => (
                      <TdInput
                        key={product.product_id}
                        defaultValue={product.quantity}
                        onChange={(value) =>
                          handleChangeQuantityProduct({
                            newQuantity: value,
                            rowId: item.rowId,
                            selectProduct: product,
                          })
                        }
                      />
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>

          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            mt={3}
            flexDirection={['column-reverse', 'row']}
            gridGap={16}
          >
            <Button
              size="medium"
              variant="outlined"
              onClick={handleRemoveIncoming}
              disabled={calculatorProducts?.length <= 1}
              startIcon={<RemoveOutlined color="inherit" />}
              style={{ marginBottom: 16, height: 48, width: '100%' }}
            >
              Remover um Rendimento
            </Button>

            <Button
              size="medium"
              color="primary"
              onClick={handleAddIncoming}
              startIcon={<AddOutlined color="inherit" />}
              style={{ marginBottom: 16, height: 48, width: '100%' }}
            >
              Adicionar novo Rendimento
            </Button>
          </Box>
        </>
      )}
    </PageContainer>
  );
}
