import {
  Box,
  Theme,
  Typography,
  makeStyles,
  Divider,
  DialogContent,
  Avatar,
} from '@material-ui/core';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { background } from '../../../../shared/assets/backgroundBase64';
import { logoPageAuth as logoBase64 } from '../../../../shared/assets/logoBase64';
import { ButtonLarge } from '../../../../shared/components/Button/ButtonLarge';
import { Flex } from '../../../../shared/components/Flex';
import PremiumIcon from '../../../../shared/components/Icons/PremiumIcon';
import { Modal } from '../../../../shared/components/Modal';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { usePremium } from '../../../../shared/hooks/usePremium';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { getStripeJS } from '../../../../shared/services/stripe-js';
import { formatPrice } from '../../../../shared/utils/formatPrice';
import { BecomePremiumItem } from '../../components/BecomePremiumItem';

const useStyles = makeStyles((theme: Theme) => ({
  plan: {
    background: 'transparent',
    color: '#FFF',
    fontWeight: 500,
    borderColor: '#FFF',
  },
  logo: {
    width: 80,
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  planPrice: {
    '& strong': {
      fontSize: '2rem',
    },
    '& sup, & sub': {
      fontSize: '1rem',
    },
    '& sup': {
      fontSize: '1.2rem',
    },
  },
  divider: {
    borderColor: theme.border.color,
  },
  shadow: {
    color: theme.text.primary,
    boxShadow: theme.shadows[5],
    background: `url(${background}), ${theme.palette.primary.dark}`,
    backgroundSize: '60%',
    backgroundPosition: 'top left',
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    marginTop: -theme.spacing(2),
    [theme.breakpoints.up(560)]: {
      backgroundSize: '50%',
    },
    [theme.breakpoints.up(760)]: {
      backgroundSize: '30%',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: -theme.spacing(3),
      marginRight: -theme.spacing(3),
    },
  },
  title: {
    color: '#FFF',
  },
  subtitle: {
    fontWeight: 'bold',
    color: theme.text.primary,
    marginBottom: theme.spacing(2),
  },
  subtitleLinks: {
    fontWeight: 'bold',
    color: theme.text.primary,
    marginBottom: theme.spacing(2),
    '& a': {
      color: theme.palette.primary.main,
      fontWeight: 'normal',
      display: 'block',
    },
  },

  buttonIcon: {
    color: theme.color.premium.main,
  },
}));

type StripePrice = {
  id: string;
  active: boolean;
  created: number;
  currency: string;
  product: {
    id: string;
    active: boolean;
    created: number;
    images?: string[];
    name: string;
    description?: string;
  };
  recurring: {
    interval: string;
    interval_count: number;
    trial_period_days?: number;
  };
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
  formattedPrice: string;
};

export function BecomePremium() {
  const classes = useStyles();
  const history = useHistory();
  const isPremium = usePremium();
  const { msgError } = useSnackMessages();

  const [stripePrices, setStripePrices] = useState<StripePrice[]>([]);

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  // useEffect(() => {
  //   if (isPremium) {
  //     console.log(true);
  //     history.push('/');
  //   }
  // }, [isPremium, history]);

  useEffect(() => {
    api
      .get<{ results: { data: StripePrice[] } }>('/signature/products')
      .then((response) => {
        setStripePrices(
          response.data?.results?.data?.map((item) => {
            return {
              ...item,
              formattedPrice: formatPrice(item.unit_amount / 100),
            };
          })
        );
      })
      .catch((err) => {
        msgError(err?.response?.data?.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  async function handleSubscribe(product_price_id: string) {
    if (loading) return;
    setOpenModal(false);
    setLoading(true);

    try {
      const response = await api.post<{ sessionId: string }>(
        'signature/stripe/create-checkout-sessions',
        {
          product_price_id,
        }
      );

      const { sessionId } = response.data;

      const stripe = await getStripeJS();

      await stripe?.redirectToCheckout({ sessionId });
    } catch (err: any) {
      setLoading(false);
      msgError(err?.response?.data?.message);
      if (
        err?.response?.data?.message === 'Você já tem uma assinatura premium'
      ) {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
  }

  return (
    <>
      <Flex
        flexDirection="column"
        p={2}
        borderRadius="0 0 20px 20px"
        color="primary.contrastText"
        className={classes.shadow}
      >
        <Flex
          marginX="auto"
          width="80px"
          height="80px"
          borderRadius="50%"
          bgcolor="#fff"
          boxShadow="0px 8px 4px rgba(0, 0, 0, 0.1)"
          mb={1}
          mt={1}
        >
          <img src={logoBase64} alt="Logo" width="60px" />
        </Flex>
        <Typography
          variant="h6"
          style={{ fontWeight: 'bold' }}
          className={classes.title}
        >
          Adquira seu aplicativo Premium!
        </Typography>
        <Typography
          variant="body2"
          className={classes.title}
          style={{ fontSize: '1.2rem' }}
        >
          Apenas <strong>R$ 9,90</strong> ao mês ou <strong>R$ 99,00</strong> ao
          ano
        </Typography>
      </Flex>

      <PageContainer display="flex" flexDirection="column" mt={3}>
        <Typography component="div">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <Box fontSize="1.4rem" color="secondary.dark" fontWeight="bold">
              Muito mais benefícios
              <br /> para você!
            </Box>
            <Box fontSize="0.85rem" color="secondary.main">
              Assine nosso plano Premium e tenha acesso à todas as
              funcionalidades do Aplicativo!
            </Box>
          </Box>
        </Typography>

        <Box mb={3}>
          <motion.div
            whileTap={{ scale: 1.03 }}
            style={{
              width: '100%',
              border: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {isPremium ? (
              <ButtonLarge
                onClick={() => history.push('/signature/premium')}
                startIcon={<PremiumIcon className={classes.buttonIcon} />}
              >
                Ver Assinatura
              </ButtonLarge>
            ) : (
              <ButtonLarge
                onClick={handleOpenModal}
                isDisabled={loading}
                startIcon={<PremiumIcon className={classes.buttonIcon} />}
              >
                {loading ? 'Carregando...' : 'Assinar Plano Premium'}
              </ButtonLarge>
            )}
          </motion.div>
        </Box>

        <Box py={2}>
          <Divider className={classes.divider} />
        </Box>

        <Box width="100%" display="flex" flexDirection="column">
          <BecomePremiumItem title="Gratuito até 10 Clientes, sendo Premium cadastro ilimitados" />
          <BecomePremiumItem title="Gratuito até 10 Produtos, sendo Premium cadastro ilimitados" />
          <BecomePremiumItem title="Gratuito até 10 Fichas Técnicas, sendo Premium cadastro ilimitados" />
          <BecomePremiumItem
            title="Calcule as próximas compras com uma calculadora de matéria prima
                completa"
          />
          <BecomePremiumItem
            title="Ajuste as variáveis de Lucro e Contribuição, e saiba por quanto
                vender seu bolo"
          />
          <BecomePremiumItem
            title="Em nossa agenda simplificada, gerencie os pagamentos recebidos
                pelas entregas"
          />
          <BecomePremiumItem title="Não veja mais propagandas enquanto usa nosso aplicativo" />
          <BecomePremiumItem title="Cadastre Clientes e gere Orçamentos em PDF" />
        </Box>

        <Box width="100%" display="flex" flexDirection="column" mt={4}>
          <Typography variant="body2" className={classes.subtitle}>
            Outras informações sobre a inscrição
          </Typography>
          <Typography variant="body2">
            A inscrição tem duração de 1 mês;
            <br />
            A inscrição será renovada automaticamente todo mês, durante todo o
            período de inscrição;
            <br />
            A inscrição será renovada automaticamente a não ser que seja
            cancelada 24 horas antes do fim do período corrente;
            <br />
            A inscrição será cobrada 24 antes do fim do período corrente;
            <br />
            As inscrições são gerenciadas pelo usuário;
            <br />
            Esta inscrição pode ser cancelada indo nas configurações de conta de
            usuário.
            <br />
            Outras informações sobre uso do aplicativo
            <br />
          </Typography>

          <Box pb={2} />

          <Typography variant="body2" className={classes.subtitleLinks}>
            Políticas de privacidade e Termos de uso do aplicativo Bolo no
            Bolso:
            <a
              href="http://api.bolonobolso.com.br/v1/terms/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              clique aqui!
            </a>
          </Typography>
        </Box>

        <Box mb={3}>
          <motion.div
            whileTap={{ scale: 1.03 }}
            style={{
              width: '100%',
              border: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {isPremium ? (
              <ButtonLarge
                onClick={() => history.push('/signature/premium')}
                startIcon={<PremiumIcon className={classes.buttonIcon} />}
              >
                Ver Assinatura
              </ButtonLarge>
            ) : (
              <ButtonLarge
                onClick={handleOpenModal}
                isDisabled={loading}
                startIcon={<PremiumIcon className={classes.buttonIcon} />}
              >
                {loading ? 'Carregando...' : 'Assinar Plano Premium'}
              </ButtonLarge>
            )}
          </motion.div>
        </Box>
      </PageContainer>

      <Modal
        title="Assinar Plano Premium"
        open={openModal}
        onClose={handleCloseModal}
      >
        <DialogContent dividers>
          <Box display="flex" justifyContent="center" width="100%">
            {stripePrices?.map((item) => {
              return (
                <Box
                  key={item.id}
                  width="100%"
                  maxWidth={200}
                  borderRadius={4}
                  bgcolor="background.paper"
                  m={2}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  border={
                    item?.recurring?.interval === 'month'
                      ? '1px solid #FF9988'
                      : '1px solid #B54D54'
                  }
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSubscribe(item.id)}
                >
                  <Box
                    width="100%"
                    color="primary.contrastText"
                    bgcolor={
                      item?.recurring?.interval === 'month'
                        ? 'primary.main'
                        : '#B54D54'
                    }
                    mb={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="4px 4px 0 0"
                    p={1}
                    fontSize="1.1rem"
                  >
                    {item?.recurring?.interval === 'month'
                      ? 'Plano Mensal'
                      : 'Plano Anual'}
                  </Box>
                  <Box>
                    <Avatar
                      src={item.product.images?.[0]}
                      style={{ width: 60, height: 60, borderRadius: 4 }}
                    />
                  </Box>
                  <Box
                    p={2}
                    color="secondary.main"
                    fontSize="1.2rem"
                    fontWeight="bold"
                  >
                    <Box whiteSpace="nowrap">
                      R$ {item.formattedPrice}
                      {item.recurring?.interval === 'month' ? '/mês' : '/ano'}
                    </Box>
                    <Box fontSize="0.75rem" fontWeight="normal">
                      {item?.recurring?.interval === 'month'
                        ? 'A renovação da assinatura será mensal.'
                        : 'A renovação da assinatura será a cada 12 meses.'}
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </DialogContent>
      </Modal>
    </>
  );
}
