import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '../../../../shared/components/Button';
import { Input } from '../../../../shared/components/Form/Input';
import { Header } from '../../../../shared/components/Header';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Informe seu e-mail.')
    .email('Informe um e-mail válido.'),
});

type FormValues = {
  email: string;
};

export function Import() {
  const { msgSuccess, msgError } = useSnackMessages();

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = useCallback(
    async ({ email }: FormValues) => {
      console.log(email);
      api
        .post('/import', { email })
        .then(() => {
          reset();
          msgSuccess('Dados importado com sucesso!');
        })
        .catch((err) => {
          msgError(err?.response?.data?.message);
        });
    },
    [msgError, msgSuccess, reset]
  );

  return (
    <PageContainer display="flex" flexDirection="column">
      <Header goPush="/" title="Importação" />

      <Box textAlign="center" mb={3}>
        <Box fontSize="1rem" color="primary.main">
          Antes de realizar a importação, verifique se já realizou o seu backup.
        </Box>
      </Box>

      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box
          fontSize="0.77rem"
          mb={2}
          textAlign="center"
          maxWidth="400px"
          mx="auto"
          color="secondary.main"
        >
          Informe abaixo o seu e-mail utilizado no Aplicativo antigo, caso
          informe um e-mail que não foi utilizado no outro APP nenhum dado será
          importado.
        </Box>
        <Input
          name="email"
          control={control}
          error={errors}
          variant="outlined"
          label="Seu e-mail"
          fullWidth
          disabled={isSubmitting}
        />

        <Box mb={2} />

        <Button
          style={{ width: '100%' }}
          disabled={isSubmitting || !watch('email')}
          type="submit"
        >
          {isSubmitting ? 'Importando...' : 'Importar'}
        </Button>
      </Box>
    </PageContainer>
  );
}
