import { makeStyles } from '@material-ui/core/styles';

export const useStylesBottomNavigation = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    width: '100vw',
    position: 'fixed',
    bottom: 0,
    boxShadow: theme.shadows[10],
    height: 72,
    justifyContent: 'space-evenly',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '& .Mui-selected': {
      background: theme.background.primary,
    },
    '& .MuiBottomNavigationAction-root.Mui-selected': {
      color: theme.palette.primary.contrastText,
    },
    '& .Mui-disabled': {
      background: 'transparent',
      opacity: '0.4',
    },
  },
  button: {
    color: theme.palette.primary.contrastText,
    '& .MuiBottomNavigationAction-label.Mui-selected': {
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.primary.contrastText,
    },
  },
  premiumColor: {
    color: theme.color.premium.main,
  },
  teste: {
    color: theme.palette.primary.contrastText,
  },
}));
