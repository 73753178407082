import { AboutApp } from '../modules/utilities/pages/AboutApp';
import { Backup } from '../modules/utilities/pages/Backup';
import { Import } from '../modules/utilities/pages/Import';
import { Utility } from '../modules/utilities/pages/Utility';
import { IRoutes } from './types.routes';

export const utilityRoutes: IRoutes[] = [
  {
    path: '/utilities',
    children: <Utility />,
    isPrivate: true,
    exact: true,
    pageName: 'Utilidades',
  },
  {
    path: '/utilities/backup',
    children: <Backup />,
    isPrivate: true,
    exact: true,
    pageName: 'Backup',
  },
  {
    path: '/utilities/about-app',
    children: <AboutApp />,
    isPrivate: true,
    exact: true,
    pageName: 'Sobre o APP',
  },
  {
    path: '/utilities/import',
    children: <Import />,
    isPrivate: true,
    exact: true,
    pageName: 'Importação',
  },
];
