/* eslint-disable prefer-const */
import { Box, Divider } from '@material-ui/core';
import RemoveOutlined from '@material-ui/icons/RemoveOutlined';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import loadFile from '../../../../shared/assets/lottie/load-file.json';
import { Button } from '../../../../shared/components/Button';
import { ButtonLarge } from '../../../../shared/components/Button/ButtonLarge';
import { Input } from '../../../../shared/components/Form/Input';
import { InputCurrency } from '../../../../shared/components/Form/Input/InputCurrency';
import { InputDatePicker } from '../../../../shared/components/Form/Input/InputDatePicker';
import { Header } from '../../../../shared/components/Header';
import { RenderLottie } from '../../../../shared/components/Lottie';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { SectionContainer } from '../../../../shared/components/SectionContainer';
import {
  Budget,
  IBudgetProps,
} from '../../../../shared/hooks/reactQuery/useBudgets/types';
import { useBudget } from '../../../../shared/hooks/useBudget';
import { useLottieAlert } from '../../../../shared/hooks/useLottieAlert';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { formatPrice } from '../../../../shared/utils/formatPrice';
import { ClientCustomSelect } from '../../components/ClientCustomSelect';
import { useBudgetCreateStyles } from './styles';

type DescriptionItems = {
  id: number;
  value?: string;
  description?: string;
  budgetId?: number;
};

interface BudgetEditProps {
  edit?: boolean;
}

export function BudgetCreate({ edit }: BudgetEditProps) {
  const classes = useBudgetCreateStyles();
  const { onLottieOpen } = useLottieAlert();
  const { msgError } = useSnackMessages();
  const history = useHistory();
  const { budgetData, updateBudgetData } = useBudget();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [total, setTotal] = useState('');

  const valueRefs = useRef<HTMLInputElement[]>([]);
  const descriptionRefs = useRef<HTMLInputElement[]>([]);
  const [descriptionItems, setDescriptionItems] = useState<DescriptionItems[]>(
    () => {
      return [
        {
          id: new Date().getTime(),
        },
      ];
    }
  );

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      end_date: moment().add(30, 'days'),
      client_phone: budgetData?.client?.phone,
      client_event_date: budgetData?.client_event_date,
      client_description: budgetData?.client_description,
      client_event_place: budgetData?.client_event_place,
      client_event_address: budgetData?.client_event_address,
      client: budgetData?.client,
    },
  });

  const handleNewDescriptionItem = useCallback(async () => {
    await setDescriptionItems((state) => [
      ...state,
      { id: new Date().getTime() + Math.random() },
    ]);
  }, []);

  const handleRemoveDescriptionItem = async (removeItem: DescriptionItems) => {
    const newDescriptionItems = await descriptionItems.filter(
      (item) => item.id !== removeItem.id
    );
    await setDescriptionItems(newDescriptionItems);
    let sumValue = 0;
    // eslint-disable-next-line array-callback-return
    newDescriptionItems.map((item) => {
      if (item.value) {
        sumValue += Number(item.value?.split(' ')[1].replace(',', '.'));
      }
    });

    setTotal(formatPrice(sumValue));
  };

  const handleChangeValue = (id: number, value?: string) => {
    const oldTotal = descriptionItems.reduce((accumulator, item) => {
      const currentValue =
        item?.value?.replace('R$', '').replace('.', '').replace(',', '.') ||
        '0';

      if (item.id !== id) {
        return accumulator + Number(currentValue);
      }
      return accumulator;
    }, 0);

    const currentValue = value?.replace('R$', '').replace(',', '.') || '0';
    setTotal(formatPrice(oldTotal + Number(currentValue)));

    setDescriptionItems((state) =>
      state.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            value: formatPrice(Number(currentValue)),
          };
        }
        return item;
      })
    );
  };

  const onSubmit = useCallback(
    ({
      client,
      end_date,
      client_event_address,
      client_event_place,
      client_phone,
      client_event_date,
      client_description,
    }: any) => {
      const formatItems = descriptionItems?.map((item, index) => {
        const description = getValues(`items[${index}][description]`);

        const value = String(item?.value);

        const currentValue = value?.replace('R$', '')?.replace(',', '.') || '0';

        return {
          ...item,
          description,
          value:
            currentValue !== 'undefined' && currentValue !== undefined
              ? `R$ ${formatPrice(Number(currentValue))}`
              : undefined,
        };
      });

      const formattedData = {
        client,
        end_date: moment(end_date).format('YYYY-MM-DDTHH:mm:ssZ'),
        items: formatItems,
        client_event_address,
        client_event_place,
        client_event_date: client_event_date
          ? moment(client_event_date).format('YYYY-MM-DDTHH:mm:ssZ')
          : undefined,
        client_description,
        client_phone,
        total_value: `R$ ${total}`,
      };
      if (edit) {
        api
          .patch(`budgets/edit/${budgetData?.file}`, formattedData)
          .then(() => {
            onLottieOpen('confirmation');

            history.push('/budgets');
          })
          .catch((err) => {
            onLottieOpen('close');
            msgError(err?.response?.data?.message);
          });
      } else {
        api
          .post('budgets', formattedData)
          .then(() => {
            onLottieOpen('confirmation');

            history.push('/budgets');
          })
          .catch((err) => {
            onLottieOpen('close');
            msgError(err?.response?.data?.message);
          });
      }
    },
    [onLottieOpen, msgError, total, history]
  );

  const handleSetClient = useCallback(
    (client: any) => {
      setValue('client', client);
      if (client?.phone) {
        setValue('client_phone', client?.phone);
      }
      setSubmitDisabled(!client);
    },
    [setValue]
  );

  useEffect(() => {
    if (budgetData !== null) {
      // eslint-disable-next-line array-callback-return
      let totalItens = 0;
      setDescriptionItems(budgetData.items);
      // eslint-disable-next-line array-callback-return
      budgetData.items.map((item, index) => {
        setValue(`items[${index}][description]`, item.description);
        setValue(`items[${index}][value]`, item.value);

        totalItens += Number(
          item.value.replace('R$', '').replace(',', '.') || '0'
        );

        // handleChangeValue(item.id, item.value);

        setSubmitDisabled(false);
      });
      setTotal(formatPrice(totalItens));
    }
  }, [budgetData]);

  return (
    <PageContainer
      display="flex"
      flexDirection="column"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Header
        title={budgetData && edit ? 'Editar Orçamento' : 'Novo Orçamento'}
        goPush="/budgets"
      >
        <Button size="medium" variant="contained" color="primary" type="submit">
          Salvar
        </Button>
      </Header>

      <SectionContainer>
        <Box display="flex" flexDirection="column" mb={1}>
          <Box
            fontWeight="bold"
            fontSize="1.1rem"
            color="primary.dark"
            lineHeight="1.8"
          >
            Dados do Orçamento
          </Box>
          <Box color="secondary.main">Adicione os seus dados abaixo</Box>
        </Box>
        <Box
          display="flex"
          width="100%"
          alignItems={['flex-start', 'center']}
          justifyContent="space-between"
          flexDirection={['column', 'row']}
          gridGap={24}
          mb={2}
        >
          <InputDatePicker
            name="end_date"
            label="Data de expiração"
            variant="outlined"
            control={control}
            error={errors}
            disabled={isSubmitting}
            style={{ marginBottom: 0, width: '100%' }}
            required
          />
        </Box>

        <Divider />

        <Box display="flex" flexDirection="column">
          <Box
            fontWeight="bold"
            fontSize="1.1rem"
            color="primary.dark"
            lineHeight="1.8"
          >
            Dados do Cliente
          </Box>
          <Box color="secondary.main">Adicione os dados do cliente abaixo.</Box>
          <Box color="secondary.main" mt={1} fontSize="0.78rem">
            Escolha seu cliente cadastrado na lista abaixo.
          </Box>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns={['1fr', 'repeat(auto-fit, minmax(350px, 1fr))']}
          gridGap={24}
        >
          <ClientCustomSelect
            getValue={(value) => handleSetClient(value)}
            edit={!!edit}
          />

          <Input
            disabled
            name="client_phone"
            control={control}
            error={errors}
            label="Telefone"
            variant="outlined"
            placeholder="(xx)xxxxx-xxxx"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          <InputDatePicker
            name="client_event_date"
            label="Data do Evento"
            variant="outlined"
            control={control}
            error={errors}
            disabled={isSubmitting}
            style={{ marginBottom: 0, width: '100%' }}
          />
          <Input
            name="client_event_place"
            control={control}
            error={errors}
            variant="outlined"
            label="Local do Evento"
            fullWidth
            inputProps={{ maxLength: 180 }}
          />
          <Input
            name="client_event_address"
            control={control}
            error={errors}
            variant="outlined"
            label="Endereço do Evento"
            fullWidth
            inputProps={{ maxLength: 180 }}
          />
          <Input
            name="client_description"
            control={control}
            error={errors}
            variant="outlined"
            multiline
            minRows={4}
            label="Observações"
            fullWidth
            inputProps={{ maxLength: 500 }}
            placeholder="500 caracteres"
          />
        </Box>

        <Divider />

        <Box display="flex" flexDirection="column" mb={1}>
          <Box
            fontWeight="bold"
            fontSize="1.1rem"
            color="primary.dark"
            lineHeight="1.8"
          >
            Itens do Orçamento
          </Box>
          <Box color="secondary.main">
            Adicione os ítens e valores inclusos no orçamento abaixo
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gridGap={8} width="100%">
          {descriptionItems.map((item, index) => {
            return (
              <Box
                key={item.id}
                display="grid"
                gridTemplateColumns="2fr 1fr 40px"
                width="100%"
                gridGap={8}
              >
                <Input
                  label="Descrição do Item"
                  name={`items[${index}][description]`}
                  control={control}
                  error={errors}
                  variant="outlined"
                  multiline
                  fullWidth
                  required
                  inputProps={{ maxLength: 180 }}
                />
                <InputCurrency
                  label="Valor"
                  name={`items[${index}][value]`}
                  control={control}
                  error={errors}
                  variant="outlined"
                  fullWidth
                  className={classes.input}
                  style={{ marginBottom: 0 }}
                  onChange={(value) =>
                    handleChangeValue(item.id, value?.target.value)
                  }
                />
                <Box
                  display="inline-flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => handleRemoveDescriptionItem(item)}
                    disabled={descriptionItems.length === 1}
                    style={{
                      minWidth: 'auto',
                      borderRadius: '50%',
                      height: 35,
                      width: 35,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <RemoveOutlined />
                  </Button>
                </Box>
              </Box>
            );
          })}

          <Box display="flex" width="100%" mb={1}>
            <Button
              type="button"
              className={classes.button}
              styleMotion={{ width: '100%' }}
              onClick={handleNewDescriptionItem}
            >
              Clique aqui para adicionar mais itens!
            </Button>
          </Box>

          <Divider />

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            fontWeight="bold"
            fontSize="1.1rem"
            color="primary.dark"
            lineHeight="1.8"
          >
            <Box fontSize="1rem">Valor Total:</Box>{' '}
            <Box>R$ {total || '0,00'}</Box>
          </Box>
        </Box>
      </SectionContainer>

      <RenderLottie
        lottie={loadFile}
        description="Gerando PDF..."
        isOpen={isSubmitting}
      />

      <ButtonLarge type="submit" isDisabled={isSubmitting || submitDisabled}>
        Salvar Orçamento
      </ButtonLarge>
    </PageContainer>
  );
}
