import { Box, TextField } from '@material-ui/core';
import {
  AddOutlined,
  RemoveOutlined,
  AddCircleRounded,
  RemoveCircleRounded,
} from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Button } from '../../../../../shared/components/Button';
import { Header } from '../../../../../shared/components/Header';
import { IconButton } from '../../../../../shared/components/IconButton';
import { Loading } from '../../../../../shared/components/Loading';
import { PageContainer } from '../../../../../shared/components/PageContainer';
import { Recipe } from '../../../../../shared/hooks/reactQuery/useRecipes/types';
import { useSnackMessages } from '../../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../../shared/services/apiClient';
import { useStyles } from './styles';

type ITdInputProps = {
  defaultValue?: unknown;
  onChange: (value: number) => void;
};

export function TdInput({ defaultValue, onChange }: ITdInputProps) {
  const classes = useStyles();
  const [value, setValue] = useState(String(defaultValue || ''));

  const handleChange = (currentValue: number) => {
    setValue(String(currentValue));

    if (currentValue && currentValue < 0) {
      onChange(0);
    } else {
      onChange(currentValue);
    }
  };

  return (
    <td>
      <Box width="100%" position="relative">
        {/* <Box position="absolute" left="0" top="0" height="100%" zIndex={2}>
          <IconButton size="small" color="primary">
            <RemoveCircleRounded />
          </IconButton>
        </Box> */}
        <TextField
          className={classes.input}
          value={value}
          name="test"
          variant="outlined"
          type="number"
          size="small"
          onChange={(e) => handleChange(Number(e.target.value))}
          required
        />
        {/* <Box position="absolute" right="0" top="0" height="100%" zIndex={2}>
          <IconButton size="small" color="primary" onClick={handleAdd}>
            <AddCircleRounded />
          </IconButton>
        </Box> */}
      </Box>
    </td>
  );
}

type Calculator = {
  id: number;
  title: string | number;
  productPurchaseCalculator: Array<{
    id: number;
    quantity: number;
    product_id: number;
    product: {
      price: string;
      quantity: number;
      product_name: string;
      unitMeasurement: {
        name: string;
      };
    };
  }>;
};

type TableHead = {
  name: string;
  unit: string;
};

type TableFoot = {
  product_id: number;
  total: number;
};

type CalculatorProducts = {
  rowId: number;
  title: number | string;
  total?: number;
  products: Array<{
    quantity_by_price: number;
    price: string;
    quantity?: number;
    product_id: number;
    total?: number;
  }>;
};

type FooterTotalValues = {
  value: number;
  formatValue: string;
};

export function ResultRecipe() {
  const classes = useStyles();
  const history = useHistory();
  const { msgError } = useSnackMessages();
  const params = useParams<{ id: string }>();

  const [recipe, setRecipe] = useState<Recipe>({} as Recipe);
  const [tableHead, setTableHead] = useState<TableHead[]>([]);
  const [tableFoot, setTableFoot] = useState<TableFoot[]>([]);
  const [total, setTotal] = useState(0);
  const [calculatorProducts, setCalculatorProducts] = useState<
    CalculatorProducts[]
  >([]);
  const [totalValue, setTotalValue] = useState<
    Array<{ rowId: number; value: string }>
  >([]);
  const [footerTotalValues, setFooterTotalValues] = useState<FooterTotalValues>(
    {
      value: 0,
      formatValue: '',
    } as FooterTotalValues
  );
  const [loading, setLoading] = useState(true);

  const handlePush = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    if (!params?.id) {
      handlePush();
    }
  }, [params?.id, handlePush]);

  useEffect(() => {
    setLoading(true);

    if (params?.id) {
      api
        .get<Recipe>(`/recipes/${params.id}`)
        .then((response) => {
          setRecipe(response.data);

          api
            .get<Calculator[]>(`/purchase-calculator/${params.id}`)
            .then((res) => {
              res.data?.forEach((row) => {
                setTableHead(
                  row.productPurchaseCalculator.map((item) => {
                    return {
                      name: item.product.product_name,
                      unit: item.product.unitMeasurement.name,
                    };
                  })
                );
              });

              setCalculatorProducts(
                res.data?.map((row) => {
                  return {
                    rowId: row.id,
                    title: row.title,
                    products: row.productPurchaseCalculator.map((product) => {
                      return {
                        price: product.product.price,
                        quantity_by_price: product.product.quantity,
                        quantity: product.quantity,
                        product_id: product.product_id,
                      };
                    }),
                  };
                })
              );

              setTotalValue(
                res.data?.map((row) => {
                  return {
                    rowId: row.id,
                    value: '',
                  };
                })
              );

              setTableFoot(
                res?.data?.[0]?.productPurchaseCalculator.map((product) => {
                  return {
                    product_id: product.product_id,
                    total: 0,
                  };
                })
              );

              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              msgError(err?.response?.data?.message);
            });
        })
        .catch((err) => {
          setLoading(false);
          msgError(err?.response?.data?.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  const handleCalculateTotal = (total: number, rowId: number) => {
    setTableFoot(
      calculatorProducts[0].products.map((product) => {
        return {
          product_id: product.product_id,
          total: 0,
        };
      })
    );
    setTotal(0);

    setCalculatorProducts((state) =>
      state.map((item) => {
        if (item.rowId === rowId) {
          const products = item.products.map((product) => {
            const newQuantity = product?.quantity
              ? total * product.quantity
              : 0;

            return {
              ...product,
              total: newQuantity,
            };
          });

          const calculatorTotal = products?.reduce(
            (accumulator, currentProduct) => {
              return (
                accumulator +
                (Number(currentProduct.price) * currentProduct.total) /
                  currentProduct.quantity_by_price
              );
            },
            0
          );
          // console.log(products, calculatorTotal);

          // Somando o valor dos produtos
          setTotalValue((state) =>
            state.map((item) => {
              if (item.rowId === rowId) {
                return {
                  rowId,
                  value: calculatorTotal.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }),
                };
              }

              return item;
            })
          );

          return {
            ...item,
            products,
            total,
          };
        }

        return item;
      })
    );
  };

  // console.log(totalValue);

  useEffect(() => {
    setFooterTotalValues({
      formatValue: '',
      value: 0,
    });
    if (calculatorProducts.length) {
      // console.log(calculatorProducts);
      setFooterTotalValues({
        formatValue: '',
        value: 0,
      });

      calculatorProducts.forEach((calculator) => {
        if (calculator?.total) {
          setTotal((state) => state + (calculator?.total || 0));

          calculator.products.forEach((product) => {
            setTableFoot((itemFoot) =>
              itemFoot.map((t) => {
                if (t.product_id === product.product_id) {
                  return {
                    ...t,
                    total: t.total + (product?.total || 0),
                  };
                }
                return t;
              })
            );
          });

          // Somar todo os produtos de uma receita
          const calculatorTotal = calculator?.products?.reduce(
            (accumulator, currentProduct) => {
              return (
                accumulator +
                (Number(currentProduct.price) * (currentProduct?.total || 0)) /
                  currentProduct.quantity_by_price
              );
            },
            0
          );

          // Somando o valor de todas as fichas técnicas
          setFooterTotalValues((state) => ({
            value: state.value + calculatorTotal,
            formatValue: (state.value + calculatorTotal).toLocaleString(
              'pt-br',
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            ),
          }));
        }
      });
    }
  }, [calculatorProducts]);

  return (
    <PageContainer display="flex" flexDirection="column">
      <Header title={recipe.title || ''} goBack isLoading={false}>
        <Button onClick={() => history.push('/sales/calculator')}>
          Novo Calculo
        </Button>
      </Header>

      {loading && (
        <Box
          position="relative"
          display="flex"
          width="100%"
          height="200px"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Loading />
        </Box>
      )}

      {!loading && (
        <>
          <Box width="100%" px={1} pb={2} pt={2} style={{ overflowX: 'auto' }}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>Rendimento</th>
                  <th>
                    <strong>Digite aqui</strong> quantas receitas serão feitas
                  </th>
                  {tableHead?.map((item) => (
                    <th key={`thead-${item.name}`}>
                      <Box>{item.name}</Box>
                      <Box fontSize="0.755rem">({item.unit})</Box>
                    </th>
                  ))}
                  <th>
                    <Box component="strong">Valor Total</Box>
                    <Box fontSize="0.755rem">(R$)</Box>
                  </th>
                </tr>
              </thead>
              <tbody>
                {calculatorProducts.map((item, index) => (
                  <tr key={`item-${item.rowId}-${index}`}>
                    <td className={classes.cursorNoDrop}>{item.title}</td>

                    <TdInput
                      onChange={(value) =>
                        handleCalculateTotal(value, item.rowId)
                      }
                    />

                    {item.products?.map((product) => (
                      <td
                        key={product.product_id}
                        className={classes.cursorNoDrop}
                      >
                        {item?.total ? product?.total || 0 : 0}
                      </td>
                    ))}

                    <td>
                      {totalValue?.map(
                        (total) =>
                          total.rowId === item.rowId && (
                            <React.Fragment
                              key={`${total.rowId}-${item.rowId}`}
                            >
                              {total.value?.length && `R$ ${total.value}`}
                            </React.Fragment>
                          )
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th className={classes.cursorNoDrop}>TOTAL</th>
                  <th className={classes.cursorNoDrop}>{total}</th>
                  {tableFoot.map((itemFoot) => (
                    <th
                      className={classes.cursorNoDrop}
                      key={`foot-${itemFoot.product_id}`}
                    >
                      {itemFoot.total}
                    </th>
                  ))}
                  <th
                    className={classes.cursorNoDrop}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {footerTotalValues?.value &&
                      `R$ ${footerTotalValues?.formatValue}`}
                  </th>
                </tr>
              </tfoot>
            </table>
          </Box>

          <Box display="flex" width="100%" justifyContent="flex-end" mt={3}>
            <Button
              variant="outlined"
              onClick={() => history.goBack()}
              startIcon={<ArrowBackIcon color="inherit" />}
              size="large"
            >
              Voltar
            </Button>
          </Box>
        </>
      )}
    </PageContainer>
  );
}
