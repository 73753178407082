import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { motion } from 'framer-motion';
import React, { useState } from 'react';

import noImage from '../../../../shared/assets/no-image.png';
import { Button } from '../../../../shared/components/Button';
import ButtonLoadMore from '../../../../shared/components/Button/ButtonLoadMore/intex';
import { Flex } from '../../../../shared/components/Flex';
import { Header } from '../../../../shared/components/Header';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { useBlog } from '../../../../shared/hooks/reactQuery/useBlog';

interface BlogProps {
  id: number;
  title: string;
  description: string;
  image_url: string;
  created_at: string;
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    height: 'auto',
    border: 'none',
    background: 'transparent',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    padding: 0,
  },

  container: {
    display: 'flex',
    width: '100%',
    border: 'none',
    overflow: 'hidden',
    maxHeight: theme.spacing(20),
    borderRadius: theme.spacing(1),
    background: theme.palette.background.paper,
    borderBottom: `3px solid ${theme.background.primary}`,
    [theme.breakpoints.up('sm')]: {
      maxHeight: theme.spacing(20),
    },
  },

  image: {
    width: '100%',
    maxWidth: '20vh',
    maxHeight: '150px',
    objectFit: 'cover',
    objectPosition: 'center',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    [theme.breakpoints.up('sm')]: {
      maxHeight: theme.spacing(20),
    },
    [theme.breakpoints.down(370)]: {
      maxWidth: '15vh',
    },
  },
  modalImage: {
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  title: {
    color: theme.text.primary,
  },
  t: {
    '& a': {
      color: '#001b85 !important',
      textDecoration: 'underline !important',
    },
  },
}));
export function Blog() {
  const classes = useStyles();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useBlog();

  const isNotMobile = useMediaQuery('(min-width:600px)');

  const [currentBlogPost, setCurrentBlogPost] = useState<BlogProps | null>(
    null
  );
  const [blogModal, setBlogModal] = useState(false);

  const openModal = (item: BlogProps) => {
    setCurrentBlogPost(item);
    setBlogModal(true);
  };
  const closeModal = () => {
    setBlogModal(false);
    setCurrentBlogPost(null);
  };
  return (
    <>
      <PageContainer display="flex" flexDirection="column" position="relative">
        <Header goPush="/" title="Blog" />
        {data &&
          data.pages.map((group, index) => (
            <React.Fragment key={index}>
              {group.results.map((item) => {
                return (
                  <Box key={item.id}>
                    <motion.div
                      whileTap={{ scale: 1.03 }}
                      style={{
                        width: '100%',
                        border: 'none',
                        marginBottom: '10px',
                      }}
                    >
                      <Button
                        className={classes.root}
                        onClick={() => openModal(item)}
                      >
                        <Box className={classes.container}>
                          <img
                            src={item.image_url ?? noImage}
                            alt={item.title}
                            className={classes.image}
                          />

                          <Flex
                            mb={-6}
                            p={2}
                            flexDirection="column"
                            justifyContent="space-evenly"
                            alignItems="flex-start"
                            textAlign="left"
                          >
                            <Box
                              mb={-6}
                              className={classes.title}
                              component="span"
                              display="block"
                            >
                              {isNotMobile && item.description.length > 170 ? (
                                <Typography noWrap>{`${item.title.slice(
                                  0,
                                  50
                                )}`}</Typography>
                              ) : (
                                <Typography
                                  style={{
                                    marginBottom: '25px',
                                  }}
                                >{`${item.title.slice(0, 30)}...`}</Typography>
                              )}
                              {/* <Typography>{item.title}</Typography> */}
                            </Box>
                            {/* <Typography
                              variant="subtitle2"
                              component="div"
                              style={{
                                textTransform: 'none',
                                maxHeight: '20px',
                                marginBottom: '20px',
                              }}
                            >
                              {item.description && (
                                <Box
                                  display="block"
                                  color="secondary.main"
                                  fontWeight="normal"
                                  fontSize="0.75rem"
                                  width="100%"
                                  height="100%"
                                >
                                  {isNotMobile && item.description.length > 172
                                    ? `${item.description.slice(0, 300)}...`
                                    : `${item.description.slice(0, 72)}...`}
                                </Box>
                              )}
                            </Typography> */}

                            {isNotMobile && item.description.length > 170 ? (
                              <Typography
                                style={{
                                  marginTop: '10px',
                                  lineHeight: '1.2',
                                  fontSize: '13px',
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: `${item.description.slice(0, 55)}...`,
                                }}
                              />
                            ) : (
                              <Typography
                                style={{
                                  lineHeight: '1.2',
                                  paddingBottom: '15px',
                                  fontSize: '13px',
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: `${item.description.slice(0, 18)}`,
                                }}
                              />
                            )}
                          </Flex>
                        </Box>
                      </Button>
                    </motion.div>
                  </Box>
                );
              })}
              {hasNextPage && (
                <ButtonLoadMore
                  disabled={!hasNextPage || isFetchingNextPage}
                  loadMore={() => fetchNextPage()}
                  loading={isFetchingNextPage}
                />
              )}
            </React.Fragment>
          ))}

        <Dialog
          aria-labelledby="create-product-dialog"
          open={blogModal}
          maxWidth="sm"
        >
          <DialogContent dividers style={{ padding: 0 }}>
            <img
              src={currentBlogPost?.image_url ?? noImage}
              alt={currentBlogPost?.title}
              className={classes.modalImage}
            />
            <Typography
              style={{
                fontSize: '20px',
                paddingLeft: '10px',
                paddingRight: '10px',
                marginBottom: '5px',
              }}
            >
              {currentBlogPost?.title}
            </Typography>
            <Typography
              className={classes.t}
              style={{
                fontSize: '15px',
                paddingLeft: '10px',
                paddingRight: '10px',
                marginBottom: '5px',
              }}
              dangerouslySetInnerHTML={{
                __html: `${currentBlogPost?.description}`,
              }}
            />
          </DialogContent>
          <DialogActions style={{ padding: 0 }}>
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              justifyContent="flex-end"
              alignItems="center"
              p={1}
            >
              <Button
                autoFocus
                onClick={closeModal}
                color="primary"
                variant="outlined"
                size="large"
              >
                Fechar
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </PageContainer>
    </>
  );
}
