import {
  Box,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { ReportProblemOutlined as Error } from '@material-ui/icons';

import { Input } from '../../../../../shared/components/Form/Input';

const useStyles = makeStyles((theme: Theme) => ({
  subCard: {
    background: theme.palette.background.default,
  },
}));

interface SalesAdjustmentsSubCardProps {
  title: string;
  name?: string;
  control: any;
  error?: string;
  value?: string | number;
  required?: boolean;
  isEditing?: boolean;
}

export function SalesAdjustmentsSubCard({
  title,
  name,
  control,
  error,
  value,
  required,
  isEditing,
}: SalesAdjustmentsSubCardProps) {
  const classes = useStyles();
  const isNotMobile = useMediaQuery('(min-width:600px)');

  return (
    <Box
      display="flex"
      flexDirection="column"
      mt={1}
      p={2}
      borderRadius={8}
      className={classes.subCard}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>{title}</Box>

        {name && isEditing && (
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            <Input
              type="number"
              name={name}
              control={control}
              variant="outlined"
              size="small"
              required={required}
              style={{ width: isNotMobile ? 110 : 80, textAlign: 'right' }}
            />
            <Box fontSize="1rem">%</Box>
          </Box>
        )}

        {!isEditing && <Box fontWeight="bold">{value || 0}%</Box>}
      </Box>

      {error && (
        <Typography
          component="p"
          variant="subtitle2"
          color="error"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Error color="error" style={{ width: 18, marginRight: 4 }} />
          {error}
        </Typography>
      )}
    </Box>
  );
}
