import { createTheme, ThemeProvider } from '@material-ui/core';
import { parseCookies, setCookie } from 'nookies';
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { customThemeLight, customThemeDark } from '../theme/materialUITheme';

interface ThemeCustomContextData {
  toggleTheme: () => void;
  isDark: boolean;
}

type ThemeCustomProviderProps = {
  children: ReactNode;
};

export const ThemeCustomContext = createContext({} as ThemeCustomContextData);

export function ThemeCustomProvider({ children }: ThemeCustomProviderProps) {
  const [theme, setTheme] = useState<'light' | 'dark'>(() => {
    const { 'bolonobolso.theme': getTheme } = parseCookies();

    return getTheme === 'dark' ? 'dark' : 'light';
  });
  const isDark = theme === 'dark';

  const toggleTheme = useCallback(() => {
    console.log(theme);
    const newTheme = theme === 'dark' ? 'light' : 'dark';

    setTheme(newTheme);

    setCookie(undefined, 'bolonobolso.theme', newTheme, {
      maxAge: 60 * 60 * 24 * 60, // 60 days
      path: '/',
    });
  }, [theme]);

  const appliedTheme = createTheme(isDark ? customThemeDark : customThemeLight);

  return (
    <ThemeCustomContext.Provider value={{ toggleTheme, isDark }}>
      <ThemeProvider theme={appliedTheme}>{children}</ThemeProvider>
    </ThemeCustomContext.Provider>
  );
}
