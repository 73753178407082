import moment from 'moment';
import { useQuery } from 'react-query';

import { api } from '../../../services/apiClient';
import { formatPrice } from '../../../utils/formatPrice';
import { ICashflowDetail, IResponse } from './types';
// import { assertIsSimplesDataResponse } from '../assertIsSimplesDataResponse';

type IReactQueryResponse = {
  result: ICashflowDetail;
};

async function getData(id: number | string) {
  const response = await api.get<IResponse>(`/sales/cash-flow/${id}`);

  // assertIsSimplesDataResponse(response);

  const data: IReactQueryResponse = {
    result: {
      ...response.data.result,
      formattedPrice: formatPrice(Number(response.data.result.value || 0)),
      formattedDate: moment(response.data.result.date).format('DD/MM/YYYY'),
    },
  };
  return data;
}

function useCashFlowDetail(id: number | string) {
  return useQuery<IReactQueryResponse, Error>(['cash-flow-detail', id], () =>
    getData(id)
  );
}

export { useCashFlowDetail };
