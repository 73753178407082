import {
  Box,
  Button,
  Chip,
  Fade,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { useCallback, useState, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';

import { ButtonLarge } from '../../../../shared/components/Button/ButtonLarge';
import { SearchForm } from '../../../../shared/components/Form/SearchForm';
import { Header } from '../../../../shared/components/Header';
import { IconButton } from '../../../../shared/components/IconButton';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { SimpleDialog } from '../../../../shared/components/SimpleDialog';
import { useProducts } from '../../../../shared/hooks/reactQuery/useProducts';
import { usePremium } from '../../../../shared/hooks/usePremium';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { CreateProductModal } from '../../components/CreateProductModal';
import Iconify from '../../../../shared/components/Iconify';

const useStyles = makeStyles((theme: Theme) => ({
  hideOnPrint: {
    '@media print': {
      display: 'none',
    },
  },
  cardContainer: {
    width: '100%',
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.border.color}`,
  },
  productTag: {
    color: '#F9836F',
    background: '#FF998820',
    marginLeft: '0',
    marginRight: '8px',
    width: 'auto',
    height: 'auto',
    '& > span': {
      whiteSpace: 'initial',
    },
  },
  tooltip: {
    display: 'inline-block',
    position: 'relative',
    borderBottom: '1px dotted #666',
    textAlign: 'left',
  },
  top: {
    minWidth: '200px',
    maxWidth: '400px',
    top: '-20px',
    left: '50%',
    transform: 'translate(-30%,-100%)',
    padding: '10px 20px',
    color: '#ffffff',
    backgroundColor: '#009cdc',
    fontWeight: 'normal',
    fontSize: '14px',
    borderRadius: '8px',
    position: 'absolute',

    boxSizing: 'border-box',
    boxShadow: '0 1px 8px rgba(0,0,0,0.5)',
    display: 'none',
    '.top &:hover': {
      display: 'block',
      color: 'red',
    },
  },
  stockChip: {
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(0.5),
  },
  price: {
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
  },
  title: {
    color: theme.palette.secondary.main,
  },
}));

export function Product() {
  const isPremium = usePremium();
  const { msgError, msgSuccess } = useSnackMessages();
  const classes = useStyles();
  const isDesktop = useMediaQuery('(min-width:600px)');
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [defaultSearchValue, setDefaultSearchValue] = useState('');
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(0);

  const { data, refetch, isFetching, status } = useProducts(searchValue);

  const handleOpenCreateProductModal = () => {
    setOpenProductModal(true);
  };
  const handleCloseModalCreateProduct = () => {
    setOpenProductModal(false);
    refetch();
  };

  function handlePush(route: string) {
    history.push(route);
  }

  const handleSearch = useCallback(async (searchValue: string) => {
    setSearchValue(searchValue);
    setDefaultSearchValue(searchValue);
  }, []);

  const handleSearchClear = useCallback(() => {
    setSearchValue('');
    setDefaultSearchValue('');
    refetch();
  }, [refetch]);

  async function handleDeleteProduct(selectedProductId: number) {
    if (!selectedProductId) return;
    setLoading(true);

    api
      .delete(`/products?id=${selectedProductId}`)
      .then(() => {
        msgSuccess('Deletado com sucesso');
      })
      .catch((err: any) => {
        msgError(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
        setAnchorEl(null);
        setOpenModalDelete(false);
        refetch();
      });
  }
  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  const handleOpenOptions = (
    event: MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setSelectedProductId(id);
    setAnchorEl(event.currentTarget);
  };

  return (
    <PageContainer display="flex" flexDirection="column">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="70%"
          className={classes.cardContainer}
          mb={2}
        >
          <Header
            goPush="/"
            title="Lista de produtos"
            isLoading={isFetching}
            search={
              <Box width="100%" className={classes.hideOnPrint}>
                <SearchForm
                  defaultValue={defaultSearchValue}
                  loading={status === 'loading'}
                  searchValue={(value) => handleSearch(value)}
                  searchClear={handleSearchClear}
                />
              </Box>
            }
          >
            <Iconify
              icon="dashicons:printer"
              onClickCapture={() => window.print()}
              color="#B54D54"
              fontSize="26px"
              marginRight="20px"
              className={classes.hideOnPrint}
              style={{ cursor: 'pointer' }}
            />
            <Button
              size="medium"
              variant="contained"
              color="primary"
              startIcon={<AddOutlined />}
              onClick={handleOpenCreateProductModal}
              className={classes.hideOnPrint}
            >
              Adicionar {isDesktop && 'produto'}
            </Button>
          </Header>
        </Box>

        {status !== 'loading' && (
          <>
            {data?.results.map((product) => {
              return (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  className={clsx(classes.cardContainer, classes.borderBottom)}
                  borderBottom="1.3px solid #202020"
                  mb={1}
                  px={1}
                  pb={2}
                  key={product.id}
                >
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={() =>
                      handlePush(`/records/product/details/edit/${product.id}`)
                    }
                    style={{ cursor: 'pointer' }}
                    px={1}
                  >
                    <Box display="flex" flexDirection="column">
                      <Typography
                        className={classes.title}
                        style={{ fontSize: '1rem', fontWeight: 'bold' }}
                      >
                        {product.product_name}
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="flex-start"
                        flexDirection="column"
                        justifyContent="flex-start"
                      >
                        <Chip
                          className={classes.productTag}
                          label={product.productTypes.name}
                          variant="default"
                          size="small"
                        />
                        {isPremium && (
                          <Chip
                            className={classes.stockChip}
                            label={`Estoque : ${product.stock}`}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-end"
                      mb={1}
                    >
                      <span className={classes.price}>
                        R$ {product.formattedPrice}
                      </span>
                      <small>
                        por {product.quantity}{' '}
                        {product.formatedUnitMeasurementName}
                      </small>
                    </Box>
                  </Box>
                  <IconButton
                    className={classes.hideOnPrint}
                    onClick={(event) => handleOpenOptions(event, product.id)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Box>
              );
            })}
          </>
        )}

        {status === 'loading' && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              mb={2}
            >
              <Box display="flex" flexDirection="column" width="100%">
                <Skeleton width="60%" />
                <Skeleton width="30%" />
                <Skeleton width="42%" />
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                alignItems="flex-end"
              >
                <Skeleton width="40%" />
                <Skeleton width="20%" />
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              mb={2}
            >
              <Box display="flex" flexDirection="column" width="100%">
                <Skeleton width="65%" />
                <Skeleton width="40%" />
                <Skeleton width="32%" />
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                alignItems="flex-end"
              >
                <Skeleton width="45%" />
                <Skeleton width="23%" />
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              mb={2}
            >
              <Box display="flex" flexDirection="column" width="100%">
                <Skeleton width="50%" />
                <Skeleton width="20%" />
                <Skeleton width="32%" />
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                alignItems="flex-end"
              >
                <Skeleton width="30%" />
                <Skeleton width="20%" />
              </Box>
            </Box>
          </>
        )}

        <ButtonLarge
          className={classes.hideOnPrint}
          startIcon={<AddOutlined />}
          onClick={handleOpenCreateProductModal}
        >
          Adicionar produto
        </ButtonLarge>
      </Box>

      <CreateProductModal
        onClose={handleCloseModalCreateProduct}
        isOpen={openProductModal}
      />

      <Menu
        id="menu-options"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseOptions}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() =>
            handlePush(`/records/product/details/edit/${selectedProductId}`)
          }
          disabled={loading}
        >
          <Box ml={1}>Editar</Box>
        </MenuItem>

        <MenuItem onClick={() => setOpenModalDelete(true)} disabled={loading}>
          <Box ml={1}>Deletar</Box>
        </MenuItem>
      </Menu>

      <SimpleDialog
        open={openModalDelete}
        title="Você deseja deletar este produto ?"
        subTitle="Caso o produto seja deletado ele também será excluido do seu estoque."
        onClose={() => setOpenModalDelete(false)}
      >
        <Box display="flex" flexDirection="column" gridGap="16px" p={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDeleteProduct(selectedProductId)}
            style={{ width: '100%' }}
            className={classes.hideOnPrint}
          >
            Deletar
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenModalDelete(false)}
            style={{ width: '100%' }}
            className={classes.hideOnPrint}
          >
            Cancelar
          </Button>
        </Box>
      </SimpleDialog>
    </PageContainer>
  );
}
