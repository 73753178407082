import { Box } from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PageContainer } from '../../../../shared/components/PageContainer';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';

export function ShowBudget() {
  const { id, file } = useParams<{ id: string; file: string }>();

  const { msgSuccess, msgError } = useSnackMessages();
  const [loading, setLoading] = useState(false);

  const [budget, setBuget] = useState<string | null>(null);

  const queryData = async () => {
    await api
      .get(`/budgets/get/orcamento?id=${id}&file=${file}`)
      .then((response) => {
        setBuget(response.data.budget.file_url);
      })
      .catch((err) => {
        msgError(err.message);
      });
  };

  useEffect(() => {
    queryData();
  }, []);

  useEffect(() => {
    if (budget) {
      window.location.href = budget;
    }
  }, [budget]);

  return (
    <PageContainer display="flex" flexDirection="column">
      <Box sx={{ display: 'flex' }}>{!loading && <CircularProgress />}</Box>
    </PageContainer>
  );
}
