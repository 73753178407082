import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function SalesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M14.9588 18.9667L14.4081 18.82L14.6284 18.0867L15.0689 18.2333H15.95L17.4184 17.06V16.9133L16.9412 16.7667L17.1614 16.0333L18.1526 16.3633V17.4267L16.2436 18.9667H14.9588Z" />
      <path d="M16.6843 20.4333H15.95V22.6333H16.6843V20.4333Z" />
      <path d="M8.97512 20.3967H8.24091V18.05L13.2703 13.9067L13.7108 14.4933L8.97512 18.38V20.3967Z" />
      <path d="M12.279 21.35H11.5448V19.0033L16.9046 14.6766L17.3451 15.2633L12.279 19.3333V21.35Z" />
      <path d="M7.50677 13.8334H6.77255V16.0334H7.50677V13.8334Z" />
      <path d="M11.912 14.75H11.1777V12.7333L6.11166 8.66333L6.55219 8.07666L11.912 12.4033V14.75Z" />
      <path d="M15.2159 13.7966H14.4816V11.78L9.48898 7.67329L9.96622 7.12329L15.2159 11.45V13.7966Z" />
      <path d="M14.5918 10.6067L14.4083 9.87336L15.5096 9.58002L13.9677 8.29669L13.3804 8.18669L12.3892 8.48002L12.1689 7.78335L13.3437 7.41669L14.2614 7.63669L16.3172 9.25002V10.0934L14.5918 10.6067Z" />
      <path d="M8.31417 12.3667H7.3964L5.30389 11.12V9.91002L6.29508 9.58002L6.51535 10.2767L6.03811 10.4234V10.68L7.61667 11.6334H8.16733L8.86483 11.4134L9.0851 12.11L8.31417 12.3667Z" />
      <path d="M11.5449 1H10.8106V2.83333H11.5449V1Z" />
      <path d="M7.29975 2.01823L6.66385 2.38483L7.63649 4.06795L8.27239 3.70135L7.29975 2.01823Z" />
      <path d="M4.13009 4.94599L3.76291 5.58105L5.44786 6.55292L5.81504 5.91785L4.13009 4.94599Z" />
      <path d="M15.0319 2.0267L14.041 3.74158L14.6769 4.10817L15.6678 2.39329L15.0319 2.0267Z" />
      <path d="M18.2183 4.97176L16.5331 5.94324L16.9001 6.57838L18.5853 5.60691L18.2183 4.97176Z" />
      <path d="M7.1395 14.2C6.99266 14.2 6.8091 14.1267 6.69897 14.0533L2.25697 10.3867C2.11013 10.2033 2 10.02 2 9.8C2 9.47 2.22026 9.21333 2.51395 9.10333L13.931 5.80333C14.1145 5.76666 14.3715 5.76666 14.5551 5.91333L18.9971 9.58C19.1806 9.72666 19.254 9.91 19.254 10.13C19.254 10.46 19.0338 10.7167 18.7401 10.8267L7.32305 14.1267C7.28634 14.2 7.21292 14.2 7.1395 14.2ZM14.1145 6.5L2.73421 9.8L7.1395 13.4667L18.5198 10.1667L14.1145 6.5Z" />
      <path d="M7.1395 16.4C6.99266 16.4 6.8091 16.3267 6.69897 16.2533L2.25697 12.5867C2.11013 12.4033 2 12.22 2 12V9.79999H2.73421V12L7.1395 15.6667L18.5198 12.3667V10.1667H19.254V12.3667C19.254 12.6967 19.0338 12.9533 18.7401 13.0633L7.32305 16.3633C7.28634 16.4 7.21292 16.4 7.1395 16.4Z" />
      <path d="M16.3171 20.8C16.2437 20.8 16.1702 20.8 16.1335 20.7633L4.71649 17.4633C4.3861 17.3533 4.20255 17.0967 4.20255 16.7667C4.20255 16.5467 4.31268 16.3633 4.45952 16.2167L5.89124 15.0067L6.36848 15.5567L4.93676 16.7667L16.3171 20.0667L20.7224 16.4L13.8575 14.42L14.0777 13.7233L20.9426 15.7033C21.273 15.8133 21.4566 16.07 21.4566 16.4C21.4566 16.62 21.3464 16.8033 21.1996 16.95L16.7943 20.6167C16.6475 20.7267 16.4639 20.8 16.3171 20.8Z" />
      <path d="M16.3171 23C16.2437 23 16.1702 23 16.1335 22.9634L4.71649 19.6634C4.3861 19.5534 4.20255 19.2967 4.20255 18.9667V16.7667H4.93676V18.9667L16.3171 22.2667L20.7224 18.6V16.4H21.4566V18.6C21.4566 18.82 21.3464 19.0034 21.1996 19.15L16.7943 22.8167C16.6475 22.9267 16.4639 23 16.3171 23Z" />
    </SvgIcon>
  );
}
