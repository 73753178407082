import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function BlogIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12.1836 8.83049C10.7297 8.83049 9.5741 7.67456 9.5741 6.22032V5.47456H10.3197V6.22032C10.3197 7.26439 11.1398 8.08473 12.1836 8.08473C13.2274 8.08473 14.0475 7.26439 14.0475 6.22032V3.98303H14.7931V6.22032C14.7931 7.67456 13.6375 8.83049 12.1836 8.83049Z" />
      <path d="M9.57409 6.9661C8.34389 6.9661 7.33737 5.95932 7.33737 4.72881C7.33737 2.52881 9.9096 1 12.1836 1C14.2339 1 15.1659 1.9322 15.1659 2.86441C15.1659 3.23729 15.0168 3.42373 14.9422 3.57288C14.8677 3.68475 14.7931 3.75932 14.7931 3.98305C14.7931 4.39322 14.4576 4.72881 14.0475 4.72881C11.7735 4.72881 10.3942 5.6983 10.3942 5.6983C10.2824 5.81017 10.096 5.84746 9.94688 5.84746C9.64865 5.84746 9.38769 5.66102 9.27586 5.4L8.71668 4.20678L9.3877 3.90847L9.94688 5.10169C9.94688 5.10169 11.4753 3.98305 14.0475 3.98305C14.0475 3.53559 14.1966 3.31186 14.3458 3.12542C14.4203 3.01356 14.4203 3.01356 14.4203 2.86441C14.4203 2.30508 13.6375 1.74576 12.1836 1.74576C10.0214 1.74576 8.08294 3.16271 8.08294 4.72881C8.08294 5.54915 8.75396 6.22034 9.57409 6.22034C9.64865 6.22034 9.79776 6.22034 9.9096 6.18305L10.0587 6.92881C9.94688 6.92881 9.76048 6.9661 9.57409 6.9661Z" />
      <path d="M15.9114 17.0339H15.1659V14.1627L14.1966 12.5593H10.9161L9.94687 14.1627V17.0339H9.20129V13.939L10.4688 11.8135H14.644L15.9114 13.939V17.0339Z" />
      <path d="M17.9618 15.9153C17.7008 15.9153 17.4399 15.8407 17.2535 15.6915L15.3523 14.3492L15.7623 13.7526L17.6635 15.0949C17.8127 15.2068 18.0736 15.2068 18.26 15.0949L21.3169 13.2678C21.4287 13.1932 21.5032 13.0814 21.5032 12.9322C21.5032 12.7085 21.3541 12.5593 21.1305 12.5593C21.0932 12.5593 21.0186 12.5593 20.9813 12.5966L18.1482 13.9017L15.7996 10.7695C15.6505 10.5831 15.4268 10.4339 15.2032 10.322L13.7866 9.8373L13.0037 8.2712L13.6747 7.93561L14.3085 9.24069L15.4268 9.61357C15.7996 9.72544 16.1351 9.98646 16.3961 10.322L18.3718 12.9695L20.6458 11.8882C20.795 11.8136 20.9441 11.7763 21.1305 11.7763C21.7642 11.7763 22.2488 12.261 22.2488 12.8949C22.2488 13.2678 22.0251 13.6407 21.6896 13.8644L18.6328 15.6915C18.4464 15.8407 18.1855 15.9153 17.9618 15.9153Z" />
      <path d="M8.08294 20.7627C7.6356 20.7627 7.22553 20.5017 7.03914 20.0915L5.54799 16.6237C5.47343 16.4746 5.43616 16.2881 5.43616 16.1017C5.43616 15.9152 5.47343 15.7661 5.54799 15.6169L7.59832 11.1796C7.89654 10.5085 8.493 10.0237 9.16402 9.79998L10.6552 9.31524V8.1593H11.4007V9.83727L9.38769 10.5085C8.90307 10.6576 8.493 11.0305 8.26933 11.5152L6.21901 15.9525C6.21901 15.9898 6.18173 16.0644 6.18173 16.139C6.18173 16.2135 6.18173 16.2881 6.21901 16.3627L7.71015 19.8305C7.78471 19.9796 7.89655 20.0542 8.04566 20.0542C8.26933 20.0542 8.41845 19.9051 8.41845 19.6813C8.41845 19.6441 8.41845 19.6068 8.41845 19.5695L7.37464 16.2508L9.27585 13.8644L9.87231 14.3118L8.23205 16.4L9.16402 19.3457C9.2013 19.4576 9.2013 19.5695 9.2013 19.6813C9.2013 20.278 8.71668 20.7627 8.08294 20.7627Z" />
      <path d="M14.7931 9.68811H14.0475V12.1864H14.7931V9.68811Z" />
      <path d="M11.0652 9.68811H10.3196V12.1864H11.0652V9.68811Z" />
      <path d="M12.5564 10.6949C11.2143 10.6949 10.767 9.8 10.7297 9.72542L11.4007 9.38983C11.4007 9.38983 11.699 9.91186 12.5564 9.91186C13.4138 9.91186 13.712 9.38983 13.712 9.35254L14.383 9.68813C14.3457 9.8 13.8984 10.6949 12.5564 10.6949Z" />
      <path d="M17.7754 23H7.71014V20.3898H8.45572V22.2542H17.0298V18.1525C17.0298 17.7424 16.6943 17.4068 16.2842 17.4068H9.20129C8.94034 17.4068 8.67939 17.5559 8.56755 17.7797L7.93382 17.4068C8.19477 16.922 8.67939 16.661 9.23857 16.661H16.3215C17.1416 16.661 17.8127 17.3322 17.8127 18.1525V23H17.7754Z" />
      <path d="M13.6747 20.7627C13.1528 20.7627 12.8173 20.539 12.5564 20.3152C12.2954 20.1288 12.109 20.0169 11.8108 20.0169C11.4753 20.0169 11.2889 20.1661 11.0652 20.3152C10.8043 20.5017 10.4688 20.7627 9.94687 20.7627C9.38769 20.7627 8.90307 20.4644 8.56757 20.2034L9.01491 19.6068C9.35042 19.8678 9.68592 20.0169 9.94687 20.0169C10.2078 20.0169 10.3942 19.9051 10.6179 19.7186C10.8788 19.5322 11.2516 19.2712 11.8108 19.2712C12.37 19.2712 12.7055 19.5322 13.0037 19.7186C13.2274 19.9051 13.4138 20.0169 13.6747 20.0169C13.9357 20.0169 14.1594 19.8678 14.383 19.7186C14.6813 19.5322 15.054 19.2712 15.5387 19.2712C16.5079 19.2712 17.589 20.0542 17.6263 20.0915L17.1789 20.6881C16.918 20.5017 16.1351 20.0169 15.5387 20.0169C15.2777 20.0169 15.054 20.1661 14.8304 20.3152C14.4949 20.539 14.1594 20.7627 13.6747 20.7627Z" />
      <path d="M11.699 17.3322C11.2889 16.9593 11.0652 16.4746 11.0652 15.9152C11.0652 15.6169 11.2144 15.3932 11.4753 15.244L12.9665 14.4983C13.0783 14.461 13.1901 14.4237 13.302 14.4237C14.1221 14.4237 14.4203 15.9152 14.4203 16.2881C14.4203 16.5864 14.3458 16.8847 14.2339 17.183L13.5629 16.8847C13.6375 16.6983 13.6747 16.5118 13.6747 16.2881C13.6747 15.9152 13.4511 15.3186 13.302 15.1695L11.8108 15.9152C11.8108 16.2508 11.9599 16.5491 12.1836 16.7729L11.699 17.3322Z" />
      <path d="M19.2665 22.2542H17.4026V23H19.2665V22.2542Z" />
      <path d="M20.7577 22.2542H20.0121V23H20.7577V22.2542Z" />
      <path d="M8.08294 22.2542H5.84622V23H8.08294V22.2542Z" />
      <path d="M21.8387 12.7458L21.466 12.0746C21.6896 11.9254 21.8387 11.7017 21.8387 11.4034C21.8387 10.9932 21.5032 10.6576 21.0932 10.6576C20.6831 10.6576 20.3476 10.9932 20.3476 11.4034C20.3476 11.6644 20.4594 11.8881 20.6831 12.0373L20.273 12.6712C19.8257 12.4102 19.5648 11.9254 19.5648 11.4034C19.5648 10.5831 20.2358 9.91187 21.0559 9.91187C21.876 9.91187 22.547 10.5831 22.547 11.4034C22.6216 12 22.3234 12.4847 21.8387 12.7458Z" />
      <path d="M21.5032 10.322H20.7577V9.79997C20.7577 9.0915 21.1304 8.38302 21.6896 7.97285L22.2861 7.56268L22.6961 8.15929L22.0997 8.56946C21.6896 8.83048 21.466 9.27794 21.466 9.76268V10.322H21.5032Z" />
      <path d="M5.10064 5.47461H4.35507V6.22037H5.10064V5.47461Z" />
      <path d="M3.6095 5.47461H2.86392V6.22037H3.6095V5.47461Z" />
      <path d="M6.59179 5.47461H5.84622V6.22037H6.59179V5.47461Z" />
      <path d="M7.82199 9.57624C7.71015 9.57624 7.59832 9.53895 7.48648 9.46438L6.47996 8.83048H2.11836C1.48462 8.83048 1 8.34574 1 7.71184V3.98302C1 3.34913 1.48462 2.86438 2.11836 2.86438H6.59179V3.61014H2.11836C1.89469 3.61014 1.74557 3.7593 1.74557 3.98302V7.71184C1.74557 7.93557 1.89469 8.08472 2.11836 8.08472H6.70363L7.71015 8.71862V7.33896H8.45573V8.94235C8.45573 9.16608 8.34389 9.3898 8.12022 9.50167C8.04566 9.53896 7.93383 9.57624 7.82199 9.57624Z" />
      <path d="M17.5849 2.09873L16.9298 3.76434L17.6236 4.03737L18.2787 2.37176L17.5849 2.09873Z" />
      <path d="M20.7009 3.88535L19.5147 5.07184L20.0419 5.59917L21.2281 4.41268L20.7009 3.88535Z" />
    </SvgIcon>
  );
}
