import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function AnnualPlaningIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M18.2333 20.2302H1V20.9638H18.2333V20.2302Z" />
      <path d="M5.06999 20.6336L3.56665 4.89958C3.56665 4.86291 3.56665 4.86291 3.56665 4.82623C3.56665 4.4228 3.89665 4.09271 4.29998 4.09271H6.09665V4.82623H4.29998L5.80332 20.5603L5.06999 20.6336Z" />
      <path d="M12.1467 4.09271H7.23332V4.82623H12.1467V4.09271Z" />
      <path d="M16.7667 4.09271H13.4667V4.82623H16.7667V4.09271Z" />
      <path d="M21.57 20.6336L20.0667 4.82623H18.1967V4.09271H20.0667C20.4333 4.09271 20.7633 4.38612 20.8 4.75288L22.3033 20.5603L21.57 20.6336Z" />
      <path d="M12.3806 3.00341L11.6505 3.07318L11.9226 5.92104L12.6526 5.85127L12.3806 3.00341Z" />
      <path d="M18.4303 3.00247L17.7003 3.0722L17.9722 5.92006L18.7022 5.85033L18.4303 3.00247Z" />
      <path d="M6.32207 3.00003L5.59212 3.07013L5.86543 5.91771L6.59538 5.84761L6.32207 3.00003Z" />
      <path d="M8.44334 18.3964H7.05001C6.68334 18.3964 6.35334 18.103 6.31667 17.7362L6.13334 15.9024C6.09667 15.6823 6.17 15.499 6.31667 15.3523C6.46334 15.2055 6.64667 15.0955 6.86667 15.0955H8.51668C8.88334 15.0955 9.21334 15.3889 9.25001 15.7557L9.32335 16.5259L8.59001 16.5992L8.51668 15.829H6.86667L7.05001 17.6628H8.44334V18.3964Z" />
      <path d="M18.2333 19.4967H8.88333C8.51666 19.4967 8.18666 19.2033 8.15 18.8365L7.96667 17.0027C7.93 16.7827 8.00333 16.5993 8.15 16.4526C8.29666 16.3059 8.48 16.1959 8.7 16.1959H18.05C18.4167 16.1959 18.7466 16.4893 18.7833 16.856L18.9666 18.6898C19.0033 18.9099 18.93 19.0933 18.7833 19.24C18.6366 19.4234 18.4533 19.4967 18.2333 19.4967ZM8.7 16.9294L8.88333 18.7632H18.2333L18.05 16.9294H8.7Z" />
      <path d="M19.7 18.3964H18.5267V17.6628H19.7L19.5167 15.829H17.8667L17.94 16.5259L17.2067 16.5992L17.1334 15.9024C17.0967 15.6823 17.17 15.499 17.3167 15.3523C17.4634 15.2055 17.6467 15.0955 17.8667 15.0955H19.5167C19.8833 15.0955 20.2133 15.3889 20.25 15.7557L20.4333 17.5895C20.47 17.8095 20.3967 17.9929 20.25 18.1396C20.1033 18.323 19.92 18.3964 19.7 18.3964Z" />
      <path d="M3.56053 4.75982L1.67958 20.5658L2.40778 20.6525L4.28873 4.84652L3.56053 4.75982Z" />
      <path d="M15.3 14.362H9.79999C8.99333 14.362 8.33333 13.7019 8.33333 12.895C8.33333 12.0881 8.99333 11.428 9.79999 11.428C10.1667 11.428 10.4967 11.5013 10.7533 11.6113C11.0833 11.7214 11.4133 11.7947 12 11.7947C12.7333 11.7947 13.32 11.428 13.54 11.2812L13.9433 11.8681C13.6133 12.0881 12.9167 12.4916 12 12.4916C11.34 12.4916 10.9 12.3815 10.57 12.2715C10.3133 12.1981 10.0933 12.1248 9.79999 12.1248C9.39666 12.1248 9.06666 12.4549 9.06666 12.8583C9.06666 13.2617 9.39666 13.5918 9.79999 13.5918H15.3V14.362Z" />
      <path d="M10.3764 13.9079L9.66499 14.0858L10.3052 16.6475L11.0166 16.4697L10.3764 13.9079Z" />
      <path d="M16.29 16.6359L15.5567 16.4892L16.07 13.6285H16.3633C16.7667 13.6285 17.0967 13.2984 17.0967 12.895C17.0967 12.4548 16.84 12.0514 16.4367 11.868C16.0333 11.6846 15.63 11.4279 15.63 10.6944C15.63 9.88752 14.97 9.22736 14.1633 9.22736C14.1267 9.22736 14.09 9.22736 14.0167 9.22736C13.7233 9.99755 12.99 10.5477 12 10.6944C11.56 10.7677 10.13 11.0245 10.13 11.7947H9.39667C9.39667 10.8778 10.3133 10.1809 11.9267 9.96088C12.2933 9.9242 13.21 9.70414 13.3933 8.82392L13.43 8.60386L13.65 8.56719C13.7967 8.53051 13.98 8.49384 14.1633 8.49384C15.3733 8.49384 16.3633 9.48409 16.3633 10.6944C16.3633 11.0612 16.4367 11.0978 16.6933 11.2079C17.3533 11.5013 17.83 12.1614 17.83 12.895C17.83 13.5918 17.3167 14.1786 16.6933 14.3253L16.29 16.6359Z" />
      <path d="M15.3 6.66003H4.15334V7.39356H15.3V6.66003Z" />
      <path d="M16.7667 6.66003H16.0333V7.39356H16.7667V6.66003Z" />
      <path d="M20.6533 6.66003H17.5V7.39356H20.6533V6.66003Z" />
      <path d="M8.70002 21.6973H7.96667V22.4308H8.70002V21.6973Z" />
      <path d="M13.4667 21.6973H9.43333V22.4308H13.4667V21.6973Z" />
      <path d="M19.7 20.2302H18.9667V20.9638H19.7V20.2302Z" />
      <path d="M23 20.2302H20.4333V20.9638H23V20.2302Z" />
      <path d="M6.13332 8.12708H5.39999V8.8606H6.13332V8.12708Z" />
      <path d="M7.6 8.12708H6.86667V8.8606H7.6V8.12708Z" />
      <path d="M9.06667 8.12708H8.33333V8.8606H9.06667V8.12708Z" />
    </SvgIcon>
  );
}
