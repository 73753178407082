import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function PeoplePremiumIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M6.15237 8.26667L6.01396 8.22988C5.80634 8.1563 4.07617 7.42052 4.07617 6.24326C4.07617 5.28674 5.18348 4.55095 6.15237 5.17637C6.35999 5.02921 6.60222 4.95564 6.87904 4.95564C7.60571 4.95564 8.22857 5.54426 8.22857 6.24326C8.22857 7.38373 6.4638 8.1563 6.29078 8.22988L6.15237 8.26667ZM5.4257 5.65463C5.07967 5.65463 4.76824 5.91216 4.76824 6.20647C4.76824 6.68473 5.63332 7.19978 6.15237 7.45731C6.67142 7.23657 7.5365 6.68473 7.5365 6.20647C7.5365 5.87537 7.25968 5.65463 6.87904 5.65463C6.67142 5.65463 6.53301 5.765 6.35999 5.91216L6.15237 6.0961L5.94475 5.91216C5.73713 5.765 5.56411 5.65463 5.4257 5.65463Z" />
      <path d="M9.2667 9.70145H3.0381C2.44984 9.70145 2 9.22319 2 8.59778V3.07938C2 2.45397 2.44984 1.9757 3.0381 1.9757H9.2667C9.85495 1.9757 10.3048 2.45397 10.3048 3.07938V8.59778C10.3048 9.22319 9.85495 9.70145 9.2667 9.70145ZM3.0381 2.71149C2.83048 2.71149 2.69207 2.85865 2.69207 3.07938V8.59778C2.69207 8.81851 2.83048 8.96567 3.0381 8.96567H9.2667C9.47432 8.96567 9.61273 8.81851 9.61273 8.59778V3.07938C9.61273 2.85865 9.47432 2.71149 9.2667 2.71149H3.0381V2.71149Z" />
      <path d="M6.15232 3.44727H2.34595V4.18306H6.15232V3.44727Z" />
      <path d="M7.53655 3.44727H6.84448V4.18306H7.53655V3.44727Z" />
      <path d="M8.92082 3.44727H8.22876V4.18306H8.92082V3.44727Z" />
      <path d="M4.07635 1.24024H3.38428V2.34392H4.07635V1.24024Z" />
      <path d="M8.92082 1.24024H8.22876V2.34392H8.92082V1.24024Z" />
      <path d="M18.9555 19.6346H18.2634V22.5777H18.9555V19.6346Z" />
      <path d="M20.6858 22.9456H16.5334C16.1527 22.9456 15.8413 22.6145 15.8413 22.2098C15.8413 21.6212 16.1873 21.0694 16.741 20.8854L16.8794 20.8486V19.5242H17.5715V21.3637L16.9486 21.5844C16.7064 21.658 16.5334 21.9155 16.5334 22.2098H20.6858C20.6858 21.9155 20.5128 21.6948 20.2705 21.5844L19.6477 21.3637V19.5242H20.3397V20.8486L20.4781 20.8854C20.9972 21.0694 21.3778 21.6212 21.3778 22.2098C21.3778 22.6513 21.0664 22.9456 20.6858 22.9456Z" />
      <path d="M18.6097 7.4941C17.8484 7.4941 17.2256 6.83189 17.2256 6.02252V5.32353H17.9177V6.02252C17.9177 6.42721 18.2291 6.75831 18.6097 6.75831C18.9904 6.75831 19.3018 6.42721 19.3018 6.02252V4.91884H19.9939V6.02252C19.9939 6.86868 19.371 7.4941 18.6097 7.4941Z" />
      <path d="M21.032 14.4841C20.7898 14.4841 20.5475 14.3737 20.3745 14.2265L20.8244 13.6747C20.859 13.7115 20.9282 13.7483 21.032 13.7483C21.2396 13.7483 21.378 13.6011 21.378 13.3804C21.378 13.1229 21.032 10.8051 20.8936 10.1429C20.7898 9.44393 20.2707 8.96567 19.6479 8.96567H17.5716C16.9488 8.96567 16.4297 9.44393 16.3259 10.1429C16.0491 11.9088 15.8415 13.1964 15.8415 13.3804C15.8415 13.6011 15.9799 13.7483 16.1875 13.7483C16.2913 13.7483 16.3605 13.7115 16.3951 13.6747L16.845 14.2265C16.672 14.4105 16.4297 14.4841 16.1875 14.4841C15.5993 14.4841 15.1494 14.0058 15.1494 13.3804C15.1494 13.0493 15.4608 11.1362 15.6339 10.0326C15.7723 8.96567 16.6028 8.22989 17.5716 8.22989H19.6479C20.6167 8.22989 21.4472 9.00246 21.5856 10.0326C21.6548 10.2533 21.6894 10.658 21.8279 11.5409C22.0701 13.1229 22.0701 13.3068 22.0701 13.3804C22.0701 14.0058 21.6202 14.4841 21.032 14.4841Z" />
      <path d="M12.727 4.91917C12.2079 4.91917 11.7235 4.58807 11.4813 4.10981L11.1352 3.37402C11.0314 3.15329 10.9968 2.93255 10.9968 2.71181C10.9968 1.90245 11.6197 1.24024 12.381 1.24024C12.9 1.24024 13.3845 1.57135 13.6267 2.04961L13.9727 2.78539C14.0765 3.00613 14.1111 3.22686 14.1111 3.4476C14.1111 4.29375 13.4883 4.91917 12.727 4.91917ZM12.381 1.97603C12.0003 1.97603 11.6889 2.30713 11.6889 2.71181C11.6889 2.82218 11.7235 2.93255 11.7581 3.04292L12.1041 3.7787C12.2079 4.03623 12.4502 4.18339 12.727 4.18339C13.1076 4.18339 13.4191 3.85228 13.4191 3.4476C13.4191 3.33723 13.3845 3.22686 13.3498 3.1165L13.0038 2.38071C12.8654 2.15997 12.6578 1.97603 12.381 1.97603Z" />
      <path d="M9.64734 16.6914C9.47432 16.6914 9.3013 16.6178 9.16289 16.4707L8.194 15.4406C7.77876 14.9991 7.77876 14.3001 8.194 13.8954L11.1007 10.8051L11.5851 11.3202L8.67845 14.4105C8.54003 14.5576 8.54003 14.7784 8.67845 14.9255L9.64734 15.9556C9.85496 15.9556 9.95877 15.7717 9.95877 15.5877C9.95877 15.4038 9.92417 15.2566 9.64734 14.7784L9.50892 14.5209L11.4121 12.4974L11.8966 13.0125L10.374 14.668C10.547 14.9991 10.6508 15.2934 10.6508 15.5877C10.6508 16.2132 10.201 16.6914 9.64734 16.6914Z" />
      <path d="M14.8031 22.5777H14.111V17.7951H10.3046V22.5777H9.61255V17.7951C9.61255 17.3904 9.92398 17.0593 10.3046 17.0593H14.111C14.4916 17.0593 14.8031 17.3904 14.8031 17.7951V22.5777Z" />
      <path d="M13.4191 18.5309H9.95874V19.2667H13.4191V18.5309Z" />
      <path d="M7.88249 22.2098H7.19043V22.9456H7.88249V22.2098Z" />
      <path d="M16.8795 22.2098H8.57471V22.9456H16.8795V22.2098Z" />
      <path d="M16.5681 5.91216L16.4297 5.17637L19.371 4.62453L19.7862 3.07938L18.8519 3.55764L17.9523 2.30681L17.2256 3.77838L16.1183 3.55764L16.5335 4.55095L15.9453 4.84527L14.8726 2.63791L16.8449 2.96901L17.883 0.945602L19.0596 2.63791L20.8935 1.6446L19.9246 5.24995L16.5681 5.91216Z" />
      <path d="M18.6097 20.0025C18.4712 20.0025 15.4954 20.0025 15.4954 18.8988C15.4954 18.5309 16.5681 12.2767 16.8795 10.3637L17.5716 10.5108C17.0525 13.5643 16.2912 18.1262 16.1874 18.8252C16.395 19.0092 17.3293 19.2667 18.6097 19.2667C19.89 19.2667 20.7897 19.0092 21.0319 18.8252C20.9627 18.1262 20.1668 13.5643 19.6478 10.5108L20.3398 10.3637C20.6513 12.2767 21.724 18.5309 21.724 18.8988C21.724 20.0025 18.7481 20.0025 18.6097 20.0025Z" />
      <path d="M13.7651 17.7951H12.381C11.7927 17.7951 11.3429 17.3168 11.3429 16.6914V12.9757C11.3429 12.7918 11.3083 12.6078 11.2737 12.4239L11.1006 11.6881C11.0314 11.3938 10.9968 11.0995 10.9968 10.8051C10.9968 9.62788 11.239 8.22988 11.5851 7.34694C11.7235 6.97905 11.9657 6.68473 12.2771 6.464L15.253 4.367C15.4261 4.25664 15.6337 4.18306 15.8413 4.18306C16.4295 4.18306 16.8794 4.66132 16.8794 5.28674C16.8794 5.65463 16.7064 5.98573 16.4295 6.20647L14.2841 7.71483L13.4537 15.6981L13.5575 15.7349C14.1111 15.9189 14.4572 16.4707 14.4572 17.0593C14.4572 17.5008 14.1457 17.7951 13.7651 17.7951ZM15.8413 4.91884C15.7721 4.91884 15.7029 4.95563 15.6683 4.99242L12.6924 7.08941C12.4848 7.23657 12.3464 7.42052 12.2425 7.64125C11.9311 8.45062 11.6889 9.73825 11.7235 10.8051C11.7235 11.0259 11.7581 11.2834 11.8273 11.5041L12.0003 12.2399C12.0695 12.4607 12.0695 12.7182 12.0695 12.9757V16.6914C12.0695 16.9122 12.2079 17.0593 12.4156 17.0593H13.7997C13.7997 16.765 13.6267 16.5443 13.3845 16.4339L12.727 16.2132L13.6613 7.31015L16.0489 5.61784C16.1527 5.54426 16.1873 5.43389 16.1873 5.32353C16.1873 5.066 16.0143 4.95563 15.8413 4.91884Z" />
    </SvgIcon>
  );
}
