import { yupResolver } from '@hookform/resolvers/yup';
import { Box, TextField } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '../../../../shared/components/Button';
import { Input } from '../../../../shared/components/Form/Input';
import { InputDatePicker } from '../../../../shared/components/Form/Input/InputDatePicker';
import { Select } from '../../../../shared/components/Form/Select';
import { Modal } from '../../../../shared/components/Modal';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { City } from '../../pages/Client/types';
import { FormValues } from './types';

const schema = yup.object().shape({
  phone: yup
    .string()
    .required('O telefone é obrigatório.')
    .min(10, 'No mínimo 10 digitos.')
    .max(190, 'No máximo 20 digitos.'),
  email: yup.string().email('Digite um e-mail valido').nullable(),
  name: yup.string().required('O nome é obrigatório.'),
  complement: yup.string(),
  birthday: yup.string().nullable(),
  comments: yup.string().max(250, 'No máximo 250 caracteres.'),
});

interface CreateClientModalProps {
  cities: City[];
  onClose: () => void;
  onCloseSuccess: () => void;
  isOpen: boolean;
}

export function CreateClientModal({
  cities,
  onClose,
  onCloseSuccess,
  isOpen,
}: CreateClientModalProps) {
  const { msgSuccess, msgError } = useSnackMessages();

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = useCallback(
    async ({
      name,
      email,
      phone,
      birthday,
      city,
      complement,
      comments,
    }: FormValues) => {
      setLoading(true);

      try {
        const formatData = {
          name,
          email,
          phone,
          birthday: birthday
            ? moment(new Date(birthday)).format('YYYY-MM-DD')
            : undefined,
          complement,
          city_id: city?.id || undefined,
          comments,
        };

        await api.post('/clients', formatData);

        msgSuccess('Cliente cadastrado com sucesso!');

        reset();
        setLoading(false);
        onCloseSuccess();
      } catch (err: any) {
        setLoading(false);
        msgError(err?.response?.data?.message || err?.response?.data?.error);
      }
    },
    [msgError, msgSuccess, reset, onCloseSuccess]
  );

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal title="Cadastrar Cliente" open={isOpen} onClose={handleClose}>
      <DialogContent dividers>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column" width="100%">
            <Input
              name="name"
              control={control}
              error={errors}
              variant="outlined"
              label="Nome do Cliente"
              fullWidth
              required
              disabled={loading}
            />
            <Box mb={2} />

            <Input
              name="phone"
              type="tel"
              control={control}
              error={errors}
              variant="outlined"
              label="Telefone (xx)nnnnn-nnnn"
              fullWidth
              required
              disabled={loading}
            />

            <Box mb={2} />

            <Input
              name="email"
              type="email"
              control={control}
              error={errors}
              variant="outlined"
              label="E-mail do Cliente"
              fullWidth
              disabled={loading}
            />
            <Box mb={2} />

            <InputDatePicker
              name="birthday"
              label="Data de aniversário"
              variant="outlined"
              control={control}
              error={errors}
              disabled={loading}
            />
            <Box mb={2} />

            {!!cities.length && (
              <Box display="flex" flexDirection="column" width="100%" mt={1}>
                <Box fontSize="0.85rem" color="secondary.light" mb={1}>
                  Endereço do Cliente
                </Box>
                <Input
                  name="complement"
                  type="text"
                  control={control}
                  error={errors}
                  variant="outlined"
                  label="Endereço"
                  fullWidth
                  disabled={loading}
                />

                <Box mb={2} />

                <Select
                  options={cities}
                  name="city"
                  control={control}
                  error={errors}
                  variant="outlined"
                  label="Cidade"
                  fullWidth
                  disabled={loading}
                  onClear={() => {
                    setValue('city', '');
                  }}
                />
                <Box mb={2} />
              </Box>
            )}

            <Box display="flex" flexDirection="column" width="100%" mt={1}>
              <Box fontSize="0.85rem" color="secondary.light" mb={1}>
                Outras informações
              </Box>
              <Input
                name="comments"
                type="text"
                control={control}
                error={errors}
                variant="outlined"
                label="Observações"
                fullWidth
                multiline
                minRows={5}
                disabled={loading}
              />
            </Box>
          </Box>

          <DialogActions style={{ padding: 0 }}>
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              gridGap={8}
              mt={4}
              alignItems="stretch"
            >
              <Button
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
                style={{ width: '100%' }}
              >
                {isSubmitting ? 'Salvando...' : 'Salvar'}
              </Button>
              <Button
                onClick={handleClose}
                color="primary"
                variant="outlined"
                size="large"
                style={{ width: '100%' }}
                disabled={isSubmitting}
              >
                Cancelar
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </DialogContent>
    </Modal>
  );
}
