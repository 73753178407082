import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function BackupIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M18.1319 23L18.0189 22.8114C17.9058 22.5849 17.8304 22.3585 17.8304 22.0944C17.8304 21.6038 18.0943 21.151 18.5089 20.8868L20.5442 19.6793C20.7704 19.5661 20.9965 19.4906 21.2604 19.4906C21.7503 19.4906 22.2026 19.7547 22.4665 20.1698L22.5796 20.3585L21.9388 20.7359L21.8257 20.5472C21.6373 20.2453 21.2603 20.1699 20.9588 20.3208L18.9235 21.5283C18.735 21.6415 18.6219 21.868 18.6219 22.0566C18.6219 22.1698 18.6596 22.2831 18.6973 22.3963L18.8104 22.5849L18.1319 23Z" />
      <path d="M13.6844 19.1132H3.50766C2.67845 19.1132 2 18.434 2 17.6038V2.50944C2 1.67925 2.67845 1 3.50766 1H13.6844C14.5136 1 15.192 1.67925 15.192 2.50944V5.90566H14.4382V2.50944C14.4382 2.09434 14.099 1.75472 13.6844 1.75472H3.50766C3.09305 1.75472 2.75383 2.09434 2.75383 2.50944V17.6038C2.75383 18.0189 3.09305 18.3585 3.50766 18.3585H13.6844V19.1132Z" />
      <path d="M9.16139 11.566H2.37692V12.3208H9.16139V11.566Z" />
      <path d="M4.26148 6.6604H3.50766V10.8113H4.26148V6.6604Z" />
      <path d="M4.26148 13.0755H3.50766V15.717H4.26148V13.0755Z" />
      <path d="M19.715 10.8113H18.9612V11.5661H19.715V10.8113Z" />
      <path d="M20.4688 9.30188H19.715V10.0566H20.4688V9.30188Z" />
      <path d="M18.2073 11.566H17.4535V12.3208H18.2073V11.566Z" />
      <path d="M20.8458 7.79248H20.0919V8.5472H20.8458V7.79248Z" />
      <path d="M19.715 4.77356H18.9612V5.52827H19.715V4.77356Z" />
      <path d="M18.2073 4.01886H17.4535V4.77358H18.2073V4.01886Z" />
      <path d="M20.4688 6.28302H19.715V7.03774H20.4688V6.28302Z" />
      <path d="M16.6997 3.64148H15.9459V4.39619H16.6997V3.64148Z" />
      <path d="M12.1767 19.8679H11.4229V20.6226H12.1767V19.8679Z" />
      <path d="M14.4382 22.1321H13.6844V22.8868H14.4382V22.1321Z" />
      <path d="M12.9305 21.3774H12.1767V22.1321H12.9305V21.3774Z" />
      <path d="M15.9459 21.3774H15.192V22.1321H15.9459V21.3774Z" />
      <path d="M14.7397 15.3396L13.7974 13.7925C13.6467 13.8302 13.4582 13.8302 13.3074 13.8302C11.2344 13.8302 9.5383 12.1321 9.5383 10.0566C9.5383 9.18868 9.83983 8.3585 10.4052 7.67925L10.7444 7.26415L12.4029 9.98113L13.3451 10.0566L13.7221 9.18868L12.0636 6.4717L12.5913 6.3585C12.8551 6.32076 13.119 6.28302 13.3451 6.28302C14.3628 6.28302 15.3051 6.66038 16.0212 7.37736C16.7374 8.09434 17.1143 9.03774 17.1143 10.0566C17.1143 10.8491 16.8881 11.6038 16.4358 12.2453L17.8304 14.5472L17.1897 14.9245L15.5312 12.1698L15.682 11.9811C16.1343 11.4528 16.3605 10.7736 16.3605 10.0566C16.3605 9.26415 16.0589 8.50943 15.4935 7.90566C14.9282 7.33962 14.1743 7.03774 13.3451 7.03774C13.3074 7.03774 13.3074 7.03774 13.2698 7.03774L14.5136 9.11321L13.7974 10.8113L11.9505 10.6604L10.7067 8.58491C10.4806 9.03774 10.3298 9.5283 10.3298 10.0189C10.3298 11.6792 11.6867 13.0377 13.3451 13.0377C13.5336 13.0377 13.7597 13 13.9482 12.9623L14.212 12.9245L15.4182 14.9245L14.7397 15.3396Z" />
      <path d="M18.3958 21.717C18.3204 21.6793 16.0966 20.7359 15.3428 20.1698C14.5136 19.5661 13.6844 17.717 13.6844 15.717V15.5283C13.6844 15 14.099 14.5849 14.6267 14.5849C14.8151 14.5849 15.0036 14.6604 15.1544 14.7736C15.5313 15.0378 16.5489 15.7548 17.642 16.4717C17.6797 16.5095 17.6797 16.5095 17.7174 16.5095C17.7174 16.5095 17.8304 16.5095 17.8304 16.3963V16.3585L17.2651 15.1887C17.152 15 17.1143 14.8491 17.1143 14.6227C17.1143 13.9812 17.6043 13.4906 18.2451 13.4906C18.5843 13.4906 18.9235 13.6415 19.1496 13.9434C19.1873 14.0189 20.2804 15.5661 20.695 16.3208C21.2227 17.3397 21.2981 18.6227 21.2981 19.9812H20.5442C20.5442 18.7359 20.5065 17.5283 20.0542 16.6604C19.6773 15.9434 18.5843 14.3585 18.5843 14.3585C18.5089 14.283 18.3958 14.2076 18.2827 14.2076C18.0566 14.2076 17.9058 14.3585 17.9058 14.5849C17.9058 14.6604 17.9058 14.6981 17.9812 14.7736L18.5466 15.9057C18.622 16.0189 18.6597 16.1698 18.6597 16.3208C18.6597 16.8114 18.2827 17.1887 17.7928 17.1887C17.6043 17.1887 17.4535 17.151 17.3028 17.0378C16.2097 16.3208 15.192 15.6038 14.8151 15.3397C14.7021 15.2642 14.5136 15.3397 14.5136 15.4906V15.6793C14.5136 17.5283 15.3051 19.1132 15.8705 19.5283C16.4359 19.9434 18.132 20.6981 18.7727 20.9623L18.3958 21.717Z" />
    </SvgIcon>
  );
}
