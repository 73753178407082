import { motion } from 'framer-motion';
import { ReactNode } from 'react';

import { pageTransition, pageVariants } from '../animation';

interface PageAnimationProps {
  children: ReactNode;
}

export function PageAnimation({ children }: PageAnimationProps) {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      {children}
    </motion.div>
  );
}
