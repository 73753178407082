/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-use-before-define */
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import React, { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Input } from '../../../../shared/components/Form/Input';
import { useBudget } from '../../../../shared/hooks/useBudget';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';

type IClient = {
  inputValue?: string;
  id?: number;
  name: string;
  phone?: string;
};

const filter = createFilterOptions<IClient>();

interface CustomSelectProps {
  getValue?: (value: IClient | null) => void;
  edit?: boolean;
}

const CustomSelect = ({ getValue = () => {}, edit }: CustomSelectProps) => {
  const { msgError } = useSnackMessages();
  const [value, setValue] = React.useState<IClient | null>(null);
  const [open, toggleOpen] = React.useState(false);

  const { budgetData } = useBudget();

  useEffect(() => {
    if (budgetData && edit) {
      setValue({
        name: budgetData.client.name,
        id: budgetData.client.id,
        phone: budgetData.client.phone,
      });
    }
  }, [budgetData]);

  const [dialogValue, setDialogValue] = React.useState({
    name: '',
    phone: '',
    id: '',
  });

  const {
    handleSubmit,
    control,
    setValue: setValueForm,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    mode: 'onChange',
    defaultValues: { name: dialogValue.name, phone: dialogValue.phone },
  });

  const [clients, setClients] = React.useState<IClient[]>([]);

  React.useEffect(() => {
    if (value) {
      getValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValue]);

  React.useEffect(() => {
    api.get<{ results: IClient[] }>('/clients/list').then((response) => {
      setClients(response.data?.results);
    });
  }, [setValue]);

  const handleClose = () => {
    setDialogValue({
      name: '',
      phone: '',
      id: '',
    });
    toggleOpen(false);
  };

  const onSubmit = (data: any) => {
    console.log(data);
    const formatData = {
      name: data.name,
      phone: data.phone,
    };

    api
      .post<{ result: any }>('/clients', formatData)
      .then((response) => {
        if (response.data?.result?.id) {
          const newClient = {
            id: response.data?.result.id,
            name: response.data?.result.name,
            phone: response.data?.result.phone,
          };
          getValue(newClient);
          setClients((state) => [...state, newClient]);
          setValue(newClient);
          setDialogValue({
            ...dialogValue,
            name: data.name,
            phone: data.phone,
          });
          reset();
        }
        handleClose();
      })
      .catch((err) => {
        msgError(err?.response?.data?.message || err?.response?.data?.error);
      });
  };

  return (
    <>
      <Autocomplete
        style={{ width: '100%' }}
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setValueForm('name', newValue);
              setDialogValue({
                name: newValue,
                phone: '',
                id: '',
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setValueForm('name', newValue.inputValue);
            setDialogValue({
              name: newValue.inputValue,
              phone: '',
              id: '',
            });
          } else {
            getValue(newValue);
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params) as IClient[];

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Clique aqui e adicione este novo Cliente: "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="client-dialog-autocomplete"
        options={clients}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => option.name}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label="Selecionar cliente cadastrado"
            variant="outlined"
          />
        )}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="client-dialog-dialog-title"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="client-dialog-dialog-title">
            Adicionar cliente
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Não encontrou o cliente na lista? Então adicione um novo cliente!
            </DialogContentText>
            <Input
              autoFocus
              name="name"
              control={control}
              error={errors}
              variant="outlined"
              label="Nome do cliente"
              fullWidth
              required
            />
            <Box mb={2} />
            <Input
              name="phone"
              control={control}
              error={errors}
              variant="outlined"
              label="Telefone"
              fullWidth
              required
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              disabled={isSubmitting}
            >
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={isSubmitting}>
              Adicionar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export const ClientCustomSelect = memo(CustomSelect);

interface OptionType {
  inputValue?: string;
  name: string;
  phone?: number | string;
  id?: number | string;
}
