import moment from 'moment';
import { useQuery } from 'react-query';

import { api } from '../../../services/apiClient';
import { formatPrice } from '../../../utils/formatPrice';
import { ISchedule, IScheduleResponse } from './types';

type IReactQueryResponse = {
  results: ISchedule[];
};

async function getData(month: number | string, year: number | string) {
  const response = await api.get<IScheduleResponse>(
    `/sales/schedule/?month=${month}&year=${year}`
  );

  const data: IReactQueryResponse = {
    results: response.data.results?.map((result) => {
      return {
        ...result,
        day: moment(result.party_date).format('DD'),
        month: moment(result.party_date).format('MMM'),
        formattedValue: formatPrice(Number(result.amount)),
      };
    }),
  };
  return data;
}

function useSchedule(month: number | string, year: number | string) {
  return useQuery<IReactQueryResponse, Error>(
    ['schedule', `${month}-${year}`],
    () => getData(month, year)
  );
}

export { useSchedule };
