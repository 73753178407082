import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { Star } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { ReactNode, FC, useState } from 'react';
import { ModalBePremium } from '../../Modal/ModalBePremium';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up(360)]: {
      maxWidth: theme.spacing(13),
    },
    [theme.breakpoints.down(360)]: {
      maxWidth: theme.spacing(11),
    },
  },
  header: {
    backgroundImage: theme.background.gradient.radial,
    color: theme.background.gradient.contrastText,
  },
  headerText: {
    color: theme.text.primary,
  },

  cardContainer: {
    width: '100%',
    minHeight: '120px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '118px',
    padding: 0,
    borderRadius: theme.spacing(1),
    color: theme.text.primary,
    border: 'none',
    background: `linear-gradient(45deg, ${theme.palette.background.paper} 30%, ${theme.palette.background.paper} 90%)`,
    borderBottom: `3px solid ${theme.palette.primary.dark}`,
    boxShadow: theme.shadows[5],
    '& span': {
      flexDirection: 'column',
    },
  },
  cardDisabled: {
    width: '100%',
    minHeight: '120px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '118px',
    padding: 0,
    borderRadius: theme.spacing(1),
    color: theme.text.primary,
    background: `${theme.palette.background.paper} !important`,
    border: 'none !important',
    boxShadow: 'none',
    opacity: 0.7,
    '& span': {
      flexDirection: 'column',
    },
  },
  cardSelected: {
    background: `linear-gradient(185deg, ${theme.palette.primary.light}78 20%, ${theme.palette.primary.light} 90%)`,
    color: '#B54D54',
  },
  title: {
    color: theme.text.buttons,
  },
  titleLight: {
    color: '#B54D54',
  },
  iconNew: {
    color: theme.text.primary,
    borderColor: theme.text.primary,
    '& .MuiChip-icon': {
      color: theme.text.primary,
    },
  },
}));

interface CardProps {
  name: string;
  icon: ReactNode;
  premiumContent: boolean;
  isNew: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  isSelected?: boolean;
}

export const CardButtonItem: FC<CardProps> = ({
  name,
  icon,
  premiumContent,
  isNew,
  isDisabled = false,
  isLoading,
  onClick,
  isSelected,
}) => {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);
  const handleOnclick = (onClick?: () => void) => {
    if (premiumContent && isDisabled) {
      setOpenModal(true);
    } else if (onClick) {
      onClick();
    }
  };
  const closeModal = () => {
    setOpenModal(false);
  };
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="row"
      width="100%"
      pb="10px"
      className={classes.root}
    >
      {isLoading ? (
        <Skeleton variant="rect" width="100%" height={118} />
      ) : (
        <motion.div
          className="animatable"
          whileTap={{ scale: 0.95 }}
          style={{ display: 'flex', width: '100%' }}
        >
          <Button
            variant="outlined"
            className={clsx(true, classes.cardContainer)}
            onClick={() => handleOnclick(onClick)}
          >
            {isNew && (
              <Chip
                icon={<Star style={{ fontSize: '13px' }} />}
                label="Novo"
                variant="outlined"
                className={classes.iconNew}
                style={{
                  fontSize: '10px',
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  height: '16px',
                  margin: '2px',
                }}
              />
            )}
            {premiumContent && (
              <Box style={{ position: 'absolute', top: '0', right: '0' }}>
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M32 0H0L40 40V8C40 3.58172 36.4183 0 32 0Z"
                    fill="#DF7664"
                  />
                  <path
                    d="M22.5861 16.2083H32.1917C32.3949 16.2083 32.5611 16.3724 32.5611 16.5729V17.3021C32.5611 17.5026 32.3949 17.6667 32.1917 17.6667H22.5861C22.3829 17.6667 22.2167 17.5026 22.2167 17.3021V16.5729C22.2167 16.3724 22.3829 16.2083 22.5861 16.2083ZM21.1083 8.91667C21.7202 8.91667 22.2167 9.40658 22.2167 10.0104C22.2167 10.1722 22.1797 10.3226 22.1151 10.4616L23.7868 11.4505C24.1424 11.6602 24.6019 11.5417 24.8074 11.1862L26.6892 7.93685C26.4422 7.73633 26.2805 7.43555 26.2805 7.09375C26.2805 6.48991 26.777 6 27.3889 6C28.0008 6 28.4972 6.48991 28.4972 7.09375C28.4972 7.43555 28.3356 7.73633 28.0885 7.93685L29.9704 11.1862C30.1759 11.5417 30.6377 11.6602 30.991 11.4505L32.6604 10.4616C32.598 10.3249 32.5588 10.1722 32.5588 10.0104C32.5588 9.40658 33.0552 8.91667 33.6671 8.91667C34.279 8.91667 34.7778 9.40658 34.7778 10.0104C34.7778 10.6143 34.2813 11.1042 33.6694 11.1042C33.6094 11.1042 33.5494 11.0951 33.4916 11.0859L31.8222 15.4792H22.9555L21.2861 11.0859C21.2284 11.0951 21.1684 11.1042 21.1083 11.1042C20.4964 11.1042 20 10.6143 20 10.0104C20 9.40658 20.4964 8.91667 21.1083 8.91667Z"
                    fill="#FFDF6F"
                  />
                </svg>
              </Box>
            )}

            {icon}

            <Box
              display="flex"
              textAlign="center"
              alignItems="center"
              justifyContent="center"
              mt="4px"
              px={1}
              className={clsx(
                true,
                !isSelected && classes.title,
                isSelected && classes.titleLight
              )}
            >
              <Typography
                variant="body1"
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  lineHeight: '1.2',
                  textTransform: 'none',
                }}
                component="div"
              >
                {name}
              </Typography>
            </Box>
          </Button>
        </motion.div>
      )}
      <ModalBePremium open={openModal} handleClose={closeModal} />
    </Box>
  );
};
