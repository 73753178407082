import {
  Box,
  createStyles,
  Input,
  makeStyles,
  Theme,
  InputProps,
} from '@material-ui/core';
import { ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      cursor: 'pointer',
      borderWidth: 0,
      borderRadius: theme.spacing(5),
      '&:focus': {
        outline: 0,
        boxShadow: 0,
      },

      '&::placeholder': {
        color: 'transparent',
      },
    },
  })
);

interface UploadProps extends InputProps {
  children: ReactNode;
}

export function Upload({ children, ...rest }: UploadProps) {
  const classes = useStyles();

  return (
    <Box position="relative" width="100%">
      <label className={classes.label}>
        <Input
          type="file"
          style={{ visibility: 'hidden', display: 'none' }}
          {...rest}
        />

        {children}
      </label>
    </Box>
  );
}
