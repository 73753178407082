import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  details: {
    width: 'calc(100% + 32px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '-16px',
    overflow: 'hidden',
    position: 'relative',
  },

  imagem: {
    width: '100%',
    opacity: '89%',
    height: '300px',
    objectFit: 'cover',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  imageTitleContainer: {
    display: 'flex',
    width: '100%',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: '0px',
    backgroundImage: 'linear-gradient(0deg , #121212a3, #23232300)',
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  title: {
    display: 'inline-flex',
    alignItems: 'center',
    border: 'none',
    background: 'transparent',
    padding: 4,
    fontWeight: 'bold',
    color: '#FFF',
    cursor: 'pointer',
    boxShadow: 'none',
    textAlign: 'left',

    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
    '& h6': {
      marginLeft: theme.spacing(2),
      lineHeight: 1.4,
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '86px',
    height: '50px',
    borderRadius: '50px',
  },
  showButton: {
    display: 'flex',
    width: '103px',
    height: '28px',
    borderRadius: '6px',
    marginBottom: '-10px',
    marginTop: '7px',
    [theme.breakpoints.down(390)]: {
      marginTop: '10px',
      width: '76px',
      height: '30px',
      fontSize: '12px',
    },
  },
  table: {
    width: '100%',
    textAlign: 'left',
    borderCollapse: 'collapse',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },

    '& thead tr': {
      background: '#DF7664',
      color: '#FFFFFF',
      fontWeight: 'normal',
    },
    '& thead th': {
      fontWeight: 'normal',
      fontSize: '0.95rem',
      padding: '6px 14px',
      textAlign: 'center',
      verticalAlign: 'top',
      border: '1px solid',
    },
    '& tbody th, tbody td': {
      fontSize: '0.95rem',
      padding: 0,
      color: theme.palette.secondary.dark,
      border: '1px solid',
      textAlign: 'center',
    },
  },
  tablePrice: {
    fontWeight: 'bold',
    textAlign: 'right',
  },

  texUnitMeasurementType: {
    textTransform: 'lowercase',
  },

  card: {
    width: '100%',
    height: '84px',
    border: '2px solid #F4DDDD',
    borderRadius: '8px',
  },

  cardTitle: {
    fontSize: '12px',
    [theme.breakpoints.down(390)]: {
      fontSize: '11px',
      textAlign: 'center',
    },
  },
  cardContent: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#F9836F',
  },
  premiumColor: {
    color: theme.color.premium.main,
  },
  input: {
    width: '100%',
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: '6px 14px',
      textAlign: 'center',
      color: theme.palette.secondary.dark,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
  },
}));
