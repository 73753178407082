import { useContext } from 'react';

import { UploadContext } from '../contexts/UploadContext';

export type FileProps = {
  name: string;
  size: number;
  type: string;
  file: any;
};

export function useUpload() {
  const {
    onUpload,
    uploadProgress,
    urlPreview,
    setUrlPreview,
    imageUploaded,
    isSubmitting,
    setImageUploaded,
  } = useContext(UploadContext);

  return {
    uploadProgress,
    urlPreview,
    setUrlPreview,
    onUpload,
    imageUploaded,
    isSubmitting,
    setImageUploaded,
  };
}
