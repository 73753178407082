import React, { useCallback } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

import { Button } from '..';

type Props = {
  loadMore(): void;
  disabled?: boolean;
  loading?: boolean;
};

const ButtonLoadMore: React.FC<Props> = ({
  loadMore,
  disabled,
  loading = false,
}) => {
  const handleLoadMore = useCallback(() => {
    loadMore();
  }, [loadMore]);

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      mt={2}
      mb={2}
    >
      <Button
        variant="outlined"
        size="small"
        color="primary"
        disabled={disabled}
        onClick={handleLoadMore}
        style={{
          opacity: disabled ? '0.5' : 1,
          paddingLeft: 16,
          paddingRight: 16,
          background: 'transparent',
        }}
      >
        {loading && (
          <>
            <CircularProgress size={18} style={{ marginRight: 5 }} />
            Carregando...
          </>
        )}
        {!loading && (
          <>
            Ver mais
            {!disabled && <KeyboardArrowDown color="inherit" />}
          </>
        )}
      </Button>
    </Box>
  );
};

export default ButtonLoadMore;
