import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function ImportIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M18.5955 8.69998C18.5588 8.69998 18.4855 8.69998 18.4489 8.69998L7.15842 6.49999L7.30504 5.76665L18.5955 7.96665L12.3271 5.06999L9.57779 5.83999L9.39451 5.14332L12.4004 4.29999L18.8887 7.30666C19.1453 7.41666 19.3286 7.67332 19.3286 7.96665C19.3286 8.36998 18.9987 8.69998 18.5955 8.69998Z" />
      <path d="M7.29336 7.24628L7.15363 7.96619L15.1786 9.52465L15.3183 8.80474L7.29336 7.24628Z" />
      <path d="M16.1377 8.98389L15.998 9.7038L16.6457 9.82959L16.7854 9.10968L16.1377 8.98389Z" />
      <path d="M10.7875 13.4666H7.96486C7.34168 13.4666 6.86514 12.99 6.86514 12.3666V6.27998C6.71851 6.09664 6.49857 5.76665 6.49857 5.25331C6.49857 4.55665 6.93845 3.96998 7.59828 3.78665L10.8974 2.86998C11.0074 2.83332 11.1174 2.83331 11.264 2.83331C12.0705 2.83331 12.7303 3.56665 12.7303 4.48331V4.66665H11.9972V4.48331C11.9972 3.96998 11.6672 3.56665 11.264 3.56665C11.2274 3.56665 11.154 3.56665 11.1174 3.60331L7.81823 4.51998C7.52497 4.59331 7.23171 4.84998 7.23171 5.25331C7.23171 5.65665 7.45166 5.83998 7.45166 5.83998L7.56163 5.94998V6.13331V12.3666C7.56163 12.5866 7.70825 12.7333 7.9282 12.7333H10.7508V13.4666H10.7875Z" />
      <path d="M18.2289 13.4667H13.0602V12.7333H18.2289C18.4489 12.7333 18.5955 12.5867 18.5955 12.3667V7.96667H19.3287V12.3667C19.3287 12.99 18.8521 13.4667 18.2289 13.4667Z" />
      <path d="M17.789 12C17.2025 12 16.726 11.67 16.3594 11.3767C15.9928 11.12 15.6996 10.9 15.2963 10.9C15.0031 10.9 14.7465 11.0467 14.4532 11.1933C14.0866 11.3767 13.6468 11.6333 13.0969 11.6333C12.4371 11.6333 11.9605 11.23 11.5939 10.9367C11.3373 10.7167 11.1174 10.5333 10.8975 10.5333C10.4576 10.5333 10.1643 10.7533 9.8344 11.0467C9.50448 11.34 9.10125 11.6333 8.55139 11.6333C7.63496 11.6333 6.97513 10.79 6.97513 10.7533L7.56164 10.3133C7.67161 10.46 8.11151 10.9 8.55139 10.9C8.84465 10.9 9.06459 10.7533 9.39451 10.46C9.76108 10.1667 10.201 9.79999 10.9341 9.79999C11.374 9.79999 11.7406 10.0933 12.0705 10.35C12.4004 10.6433 12.7303 10.9 13.1336 10.9C13.5001 10.9 13.7934 10.7533 14.1233 10.57C14.4532 10.3867 14.8564 10.1667 15.333 10.1667C15.9562 10.1667 16.4327 10.4967 16.7993 10.79C17.1292 11.0467 17.4225 11.2667 17.789 11.2667C18.3755 11.2667 18.7055 11.01 18.7421 10.9733L19.2187 11.5233C19.1453 11.5967 18.6321 12 17.789 12Z" />
      <path d="M8.40476 15.0067C6.71852 14.75 2.83286 13.98 2.83286 12.1834C2.83286 10.7167 5.54549 9.91002 7.15841 9.58002L7.30504 10.3134C4.73903 10.8267 3.566 11.6334 3.566 12.1834C3.566 12.8434 5.10561 13.76 8.51473 14.2734L8.40476 15.0067Z" />
      <path d="M12.9136 15.3C12.4737 15.3 12.0338 15.3 11.6306 15.2633L11.6673 14.53C12.0705 14.53 12.5104 14.5667 12.9136 14.5667C18.7055 14.5667 22.2612 13.1733 22.2612 12.1833C22.2612 11.7067 21.3815 10.9367 18.8888 10.3867L19.0354 9.65332C21.6014 10.24 22.9944 11.12 22.9944 12.1466C22.9944 14.2 17.789 15.3 12.9136 15.3Z" />
      <path d="M1.58651 21.3133L1.25659 20.6533L3.85926 19.3333C4.18918 19.15 4.44578 18.8933 4.55575 18.5266L4.88567 17.5367C5.17893 16.6933 5.72878 15.96 6.46193 15.4833L11.484 12.22C11.7039 12.0733 11.9239 12 12.1805 12C12.877 12 13.4635 12.5867 13.4635 13.2833C13.4635 13.6867 13.2802 14.0167 12.9869 14.2733L9.79775 16.8767L9.3212 16.3267L12.5104 13.7233C12.6204 13.6133 12.6937 13.4667 12.6937 13.2833C12.6937 12.99 12.4371 12.7333 12.1438 12.7333C12.0338 12.7333 11.9239 12.77 11.8506 12.8067L6.8285 16.07C6.24199 16.4733 5.76544 17.06 5.5455 17.72L5.21558 18.71C5.0323 19.26 4.66573 19.6633 4.15252 19.92L1.58651 21.3133Z" />
      <path d="M6.02205 22.3767L5.72879 21.7167L7.56166 20.8733C7.70828 20.8 7.89157 20.7267 8.11152 20.69C10.6775 20.2133 13.6468 17.7567 15.5529 14.6033L16.1761 14.97C14.16 18.3433 11.0441 20.8367 8.25815 21.3867C8.11152 21.4233 8.00155 21.46 7.89157 21.4967L6.02205 22.3767Z" />
      <path d="M22.9944 3.56665H22.2612V4.29999H22.9944V3.56665Z" />
      <path d="M22.2612 5.40002H21.5281V6.13336H22.2612V5.40002Z" />
      <path d="M20.4284 1H19.6952V1.73333H20.4284V1Z" />
      <path d="M17.8624 3.56665H17.1292V4.29999H17.8624V3.56665Z" />
      <path d="M18.5955 1.73334H17.8624V2.46667H18.5955V1.73334Z" />
      <path d="M22.2612 1.73334H21.5281V2.46667H22.2612V1.73334Z" />
      <path d="M18.5955 5.40002H17.8624V6.13336H18.5955V5.40002Z" />
      <path d="M20.4284 6.1333H19.6952V6.86663H20.4284V6.1333Z" />
      <path d="M3.93258 3.93335H3.19943V4.66669H3.93258V3.93335Z" />
      <path d="M5.39888 4.66669H4.66574V5.40001H5.39888V4.66669Z" />
      <path d="M1.73314 6.1333H1V6.86663H1.73314V6.1333Z" />
      <path d="M6.13201 6.1333H5.39886V6.86663H6.13201V6.1333Z" />
      <path d="M3.93258 8.33331H3.19943V9.06664H3.93258V8.33331Z" />
      <path d="M2.46627 7.60004H1.73312V8.33337H2.46627V7.60004Z" />
      <path d="M5.39888 7.60004H4.66574V8.33337H5.39888V7.60004Z" />
      <path d="M2.46627 4.66669H1.73312V5.40001H2.46627V4.66669Z" />
      <path d="M14.1966 21.1667H13.4635V21.9H14.1966V21.1667Z" />
      <path d="M15.6629 21.9H14.9297V22.6333H15.6629V21.9Z" />
      <path d="M17.1292 22.2667H16.3961V23H17.1292V22.2667Z" />
      <path d="M20.0618 21.1667H19.3286V21.9H20.0618V21.1667Z" />
      <path d="M20.7949 19.7H20.0618V20.4333H20.7949V19.7Z" />
      <path d="M18.5955 21.9H17.8624V22.6333H18.5955V21.9Z" />
      <path d="M21.1615 18.2333H20.4284V18.9667H21.1615V18.2333Z" />
      <path d="M20.0618 15.3H19.3286V16.0333H20.0618V15.3Z" />
      <path d="M20.7949 16.7667H20.0618V17.5H20.7949V16.7667Z" />
      <path d="M13.4635 19.7H12.7303V20.4333H13.4635V19.7Z" />
    </SvgIcon>
  );
}
