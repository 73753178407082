import { useHistory } from 'react-router-dom';

import { cardButtonsData } from '../../../../config/cardButtonsData';
import { CardButtons } from '../../../../shared/components/CardButtons';
import { CardButtonItem } from '../../../../shared/components/CardButtons/CardButtonItem';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { usePremium } from '../../../../shared/hooks/usePremium';
import { Header } from '../../../../shared/components/Header';

export function Sale() {
  const isPremium = usePremium();
  const history = useHistory();

  function handlePushOrOpenModal(route: string) {
    history.push(route);
  }

  return (
    <PageContainer display="flex" flexDirection="column">
      <Header goPush="/" title="Vendas" />
      <CardButtons>
        {cardButtonsData.map((content) => {
          return (
            content.sectionName === 'Vendas' &&
            content.buttons.map((button) => (
              <CardButtonItem
                key={button.name}
                name={button.name}
                premiumContent={!!button?.isPremiumRequired}
                isDisabled={button?.isPremiumRequired === true && !isPremium}
                isLoading={isPremium === undefined}
                isNew={button.isNew}
                icon={button.icon}
                onClick={() => handlePushOrOpenModal(button.toPage)}
              />
            ))
          );
        })}
      </CardButtons>
    </PageContainer>
  );
}
