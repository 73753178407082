import { Box, BoxProps, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

interface HeaderProps extends BoxProps {
  title: string;
  link?: string;
  linkName?: string;
}

function Header({ title, link, linkName, ...rest }: HeaderProps) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      {...rest}
    >
      <Typography color="secondary" variant="h5" style={{ fontWeight: 'bold' }}>
        {title}
      </Typography>

      {link && (
        <Link to={link}>
          <Typography color="primary" component="div">
            <Box fontSize="14px">{linkName ?? 'Link'}</Box>
          </Typography>
        </Link>
      )}
    </Box>
  );
}

export { Header };
