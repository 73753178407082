import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select as MuiSelect,
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Edit } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { motion } from 'framer-motion';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '../../../../shared/components/Button';
import { Input } from '../../../../shared/components/Form/Input';
import { InputDatePicker } from '../../../../shared/components/Form/Input/InputDatePicker';
import { InputFormat } from '../../../../shared/components/Form/Input/InputFormat';
import { InputPassword } from '../../../../shared/components/Form/Input/InputPassword';
import { Select } from '../../../../shared/components/Form/Select';
import { Modal } from '../../../../shared/components/Modal';
import { useModalYourRegistration } from '../../../../shared/hooks/useModalYourRegistration';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { useUserAuthenticated } from '../../../../shared/hooks/useUserAuthenticated';
import { api } from '../../../../shared/services/apiClient';
import { AvatarUpload } from '../AvatarUpload';
import { FormValues } from './types';

type City = {
  id: number;
  name: string;
};

const schema = yup.object().shape({
  name: yup.string().required('O nome é obrigatório.'),
  email: yup.string().required('O e-mail é obrigatório.'),
  phone: yup.string(),
  city: yup
    .object({
      id: yup.string(),
      name: yup.string(),
    })
    .required(),
  complement: yup.string(),
  birthday: yup.string().nullable(),
  password: yup.string(),
});

export function YourRegistration() {
  const { msgSuccess, msgError } = useSnackMessages();
  const { isOpen, onClose, yourRegistration, cities } =
    useModalYourRegistration();
  const { refreshUser } = useUserAuthenticated();

  const [typeDocument, setTypeDocument] = useState('cnpj');
  const [loading, setLoading] = useState(true);
  const [onEdit, setOnEdit] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      city: {
        id: 5,
        name: 'City',
      },
    },
  });

  const setDefaultValues = useCallback(() => {
    setValue('name', yourRegistration?.name || '');
    setValue('email', yourRegistration.email);
    if (yourRegistration.profile?.type_document) {
      setValue('cpf_cnpj', yourRegistration.profile.cpf_cnpj || '');
      setTypeDocument(yourRegistration.profile.type_document);
    }
    setValue(
      'birthday',
      yourRegistration?.profile?.birthday
        ? moment(yourRegistration.profile.birthday, 'YYYY-MM-DD').format(
            'YYYY-MM-DD'
          )
        : null
    );
    setValue('phone', yourRegistration?.profile?.phone || undefined);

    if (yourRegistration?.address?.city_id) {
      setValue(
        'city',
        cities.find((city) => city.id === yourRegistration.address?.city_id)
      );
    }
    setValue('complement', yourRegistration?.address?.complement || undefined);
  }, [yourRegistration, setValue, cities]);

  useEffect(() => {
    if (yourRegistration) {
      setLoading(true);

      setDefaultValues();

      setLoading(false);
    }
  }, [yourRegistration, setDefaultValues]);

  const onSubmit = useCallback(
    async ({
      name,
      email,
      cpf_cnpj,
      phone,
      birthday,
      city,
      complement,
      password,
    }: FormValues) => {
      setLoading(true);

      try {
        const formatData = {
          name,
          email,
          cpf_cnpj,
          type_document: typeDocument,
          phone,
          birthday: birthday
            ? moment(new Date(birthday)).format('YYYY-MM-DD')
            : undefined,
          complement,
          address_id: yourRegistration?.profile?.address_id,
          city_id: city?.id || undefined,
          password,
        };

        await api.post('/profile', formatData);

        msgSuccess('Perfil atualizado com sucesso!');

        refreshUser();

        setLoading(false);
        setOnEdit(false);

        onClose();
      } catch (err: any) {
        setLoading(false);
        msgError(err?.response?.data?.message);
      }
    },
    [msgError, msgSuccess, onClose, yourRegistration, refreshUser, typeDocument]
  );

  const handleClose = () => {
    setOnEdit(false);
    setDefaultValues();
    onClose();
  };

  const handleChangeTypeDocument = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setTypeDocument(event.target.value as string);
  };

  return (
    <Modal title="Seu Cadastro" open={isOpen} onClose={handleClose}>
      {!onEdit && (
        <Box
          mb={3}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            startIcon={<Edit />}
            autoFocus
            size="large"
            color="primary"
            variant="outlined"
            onClick={() => setOnEdit(true)}
          >
            Clique aqui para editar
          </Button>
        </Box>
      )}
      <DialogContent dividers>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          mb={4}
          borderRadius={4}
          p={2}
        >
          {onEdit && (
            <>
              <Box
                color="primary.dark"
                textAlign="center"
                bgcolor="#fa9c8b30"
                borderRadius={4}
                p={1}
                mb={1}
              >
                Clique no botão abaixo (com icone de câmera) para alterar a foto
                de perfil.
                <Box>
                  Selecione uma imagem quadrada &quot;4x4&quot; para uma melhor
                  visualização no aplicativo.
                </Box>
              </Box>
            </>
          )}
          <motion.div whileTap={{ scale: onEdit ? 0.95 : 1 }}>
            <AvatarUpload isDisabled={!onEdit} />
          </motion.div>
        </Box>

        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          {isOpen && Object.keys(yourRegistration).length && (
            <Box display="flex" flexDirection="column" width="100%">
              <Input
                name="name"
                control={control}
                error={errors}
                variant="outlined"
                label="Seu nome"
                fullWidth
                required
                disabled={loading || !onEdit}
              />
              <Box mb={2} />

              <Input
                name="email"
                type="email"
                control={control}
                error={errors}
                variant="outlined"
                label="Seu e-mail"
                fullWidth
                required
                disabled={loading || !onEdit}
              />
              <Box mb={2} />

              <Box
                display="flex"
                flexDirection="row"
                width="100%"
                justifyContent="stretch"
              >
                <FormControl variant="outlined" style={{ width: 120 }}>
                  <MuiSelect
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={typeDocument}
                    onChange={handleChangeTypeDocument}
                    disabled={loading || !onEdit}
                  >
                    <MenuItem value="cpf">CPF</MenuItem>
                    <MenuItem value="cnpj">CNPJ</MenuItem>
                  </MuiSelect>
                </FormControl>

                {/* <Input
                  name="cpf_cnpj"
                  control={control}
                  error={errors}
                  variant="outlined"
                  label={
                    typeDocument === 'cpf'
                      ? 'Informe o seu CPF'
                      : 'Informe o seu CNPJ'
                  }
                  style={{ width: '100%' }}
                  fullWidth
                  disabled={loading || !onEdit}
                /> */}

                <InputFormat
                  format={
                    typeDocument === 'cpf'
                      ? '###.###.###-##'
                      : '##.###.###/####-##'
                  }
                  name="cpf_cnpj"
                  control={control}
                  error={errors}
                  variant="outlined"
                  label={
                    typeDocument === 'cpf'
                      ? 'Informe o seu CPF'
                      : 'Informe o seu CNPJ'
                  }
                  style={{ width: '100%' }}
                  fullWidth
                  disabled={loading || !onEdit}
                />
              </Box>

              <Box mb={2} />

              <Input
                name="phone"
                type="tel"
                control={control}
                error={errors}
                variant="outlined"
                label="Telefone"
                fullWidth
                disabled={loading || !onEdit}
              />
              <Box mb={2} />

              <InputDatePicker
                name="birthday"
                label="Data de aniversário"
                variant="outlined"
                control={control}
                error={errors}
                disabled={loading || !onEdit}
              />
              <Box mb={2} />

              {!!cities.length && (
                <Box display="flex" flexDirection="column" width="100%" mt={1}>
                  <Box fontSize="0.85rem" color="secondary.light" mb={1}>
                    Seu endereço
                  </Box>

                  <Input
                    name="complement"
                    type="text"
                    control={control}
                    error={errors}
                    variant="outlined"
                    label="Endereço"
                    fullWidth
                    disabled={loading || !onEdit}
                  />
                  <Box mb={2} />
                  <Select
                    options={cities}
                    name="city"
                    label="Cidade"
                    control={control}
                    error={errors}
                    variant="outlined"
                    style={{ marginBottom: 0 }}
                    required
                    disabled={loading || !onEdit}
                  />
                  <Box mb={2} />
                </Box>
              )}

              <Box display="flex" flexDirection="column" width="100%" mt={1}>
                <Box fontSize="0.85rem" color="secondary.light" mb={1}>
                  Acesso ao APP
                </Box>
                <InputPassword
                  name="password"
                  control={control}
                  error={errors}
                  variant="outlined"
                  label="Nova senha"
                  disabled={loading || !onEdit}
                  autoComplete="new-password"
                />
              </Box>
            </Box>
          )}

          {!yourRegistration && (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <CircularProgress />
              <span>Aguarde, carregando...</span>
            </Box>
          )}

          <DialogActions style={{ padding: 0 }}>
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              gridGap={8}
              mt={4}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                onClick={handleClose}
                color="primary"
                variant="outlined"
                size="large"
              >
                {onEdit ? 'Cancelar' : 'Fechar'}
              </Button>
              {onEdit && (
                <Button
                  type="submit"
                  size="large"
                  color="primary"
                  variant="contained"
                >
                  Salvar
                </Button>
              )}
            </Box>
          </DialogActions>
        </Box>
      </DialogContent>
    </Modal>
  );
}
