type UnitMeasurementType = {
  Litros: string;
  Mililitros: string;
  Gramas: string;
  Kilogramas: string;
  Unidades: string;
  Horas: string;
  Minutos: string;
  Segundos: string;
};

export const unitMeasurement: UnitMeasurementType = {
  Litros: 'L',
  Mililitros: 'ml',
  Gramas: 'g',
  Kilogramas: 'kg',
  Unidades: 'un',
  Horas: 'h',
  Minutos: 'min',
  Segundos: 's',
};
