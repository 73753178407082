import { Box, BoxProps, makeStyles, Theme } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import PriorityHighOutlinedIcon from '@material-ui/icons/PriorityHighOutlined';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: `calc(100% + ${theme.spacing(4)}px)`,
    overflowX: 'auto',

    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  card: {
    minWidth: 140,
    position: 'relative',
    boxShadow: '0 0 10px #51515133',
    [theme.breakpoints.up('sm')]: {
      minWidth: 180,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 250,
    },
  },
}));

interface CardsTopSalesScheduleProps extends BoxProps {
  received: string;
  toReceive: string;
  total: string;
}

export function CardsTopSalesSchedule({
  received = '0,00',
  toReceive = '0,00',
  total = '0,00',
  ...rest
}: CardsTopSalesScheduleProps) {
  const classes = useStyles();

  return (
    <Box
      className={classes.container}
      {...rest}
      display="flex"
      flexDirection="row"
      flexWrap="no-wrap"
      alignItems="center"
      justifyContent={['flex-start', 'space-between']}
      py={2}
      px={[2, '4px']}
      mt={1}
      mx={[-2, 0]}
      bgcolor="transparent"
    >
      <Box
        className={classes.card}
        display="flex"
        flexDirection="column"
        p={2}
        bgcolor="#eeeeee12"
        borderRadius={8}
        mr={2}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="flex-end"
          alignItems="flex-start"
          color="#22BC44"
        >
          <DoneAllIcon />
        </Box>

        <Box
          component="strong"
          fontWeight="normal"
          fontSize="1.1rem"
          color="primary.dark"
        >
          R$ {received}
        </Box>
        <Box
          component="small"
          fontSize="0.88rem"
          color="secondary.light"
          style={{ fontStyle: 'oblique' }}
        >
          Recebido
        </Box>
      </Box>

      <Box
        className={classes.card}
        display="flex"
        flexDirection="column"
        p={2}
        bgcolor="#eeeeee12"
        borderRadius={8}
        mr={2}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="flex-end"
          alignItems="flex-start"
          color="#B54D54"
        >
          <PriorityHighOutlinedIcon />
        </Box>

        <Box
          component="strong"
          fontWeight="normal"
          fontSize="1.1rem"
          color="primary.dark"
        >
          R$ {toReceive}
        </Box>
        <Box
          component="small"
          fontSize="0.88rem"
          color="secondary.light"
          style={{ fontStyle: 'oblique' }}
        >
          A receber
        </Box>
      </Box>

      <Box
        className={classes.card}
        display="flex"
        flexDirection="column"
        p={2}
        bgcolor="primary.main"
        borderRadius={8}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="flex-end"
          alignItems="flex-start"
          color="#FFFFFF"
        >
          <MonetizationOnOutlinedIcon />
        </Box>

        <Box component="strong" fontSize="1.1rem" color="#FFFFFF">
          R$ {total}
        </Box>
        <Box
          component="small"
          fontSize="0.88rem"
          color="#FFFFFF"
          style={{ fontStyle: 'oblique' }}
        >
          Total
        </Box>
      </Box>
    </Box>
  );
}
