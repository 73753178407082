import {
  Box,
  makeStyles,
  Popover,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {
  ReportProblemOutlined as Error,
  Help as HelpIcon,
} from '@material-ui/icons';
import React, { ReactNode } from 'react';

import { Input } from '../../../../shared/components/Form/Input';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    background: theme.color.shape.main,
    color: theme.text.secondary,
  },
  subCard: {
    background: theme.color.shape.dark,
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

interface SalesAdjustmentsCardProps {
  title: string;
  name?: string;
  control: any;
  error?: string;
  value?: string | number;
  required?: boolean;
  isEditing?: boolean;
  children?: ReactNode;
  customInput?: ReactNode;
  help?: string;
}

export function SalesAdjustmentsCard({
  title,
  name,
  control,
  error,
  value,
  required,
  isEditing,
  children,
  customInput,
  help,
}: SalesAdjustmentsCardProps) {
  const classes = useStyles();
  const isNotMobile = useMediaQuery('(min-width:600px)');
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      p={2}
      borderRadius={8}
      mb={2}
      className={classes.card}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="inline-flex" alignItems="center">
          {help && (
            <>
              <Box
                mr={1}
                color="inherit"
                aria-owns={open ? `${title}-mouse-over-popover` : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <Box display="inline-flex" alignItems="center">
                  <HelpIcon color="primary" style={{ marginRight: 8 }} />
                  <span>{title}</span>
                </Box>
              </Box>

              <Popover
                id={`${title}-mouse-over-popover`}
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography color="secondary" variant="inherit">
                  {help}
                </Typography>
              </Popover>
            </>
          )}
          {!help && title}
        </Box>

        {name && isEditing && !customInput && (
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            <Input
              type="number"
              name={name}
              control={control}
              variant="outlined"
              size="small"
              required={required}
              style={{ width: isNotMobile ? 110 : 70, textAlign: 'right' }}
            />
            <Box fontSize="1rem">%</Box>
          </Box>
        )}

        {customInput}

        {!isEditing && !customInput && (
          <Box fontWeight="bold">{value || 0}%</Box>
        )}
      </Box>

      {error && (
        <Typography
          component="p"
          variant="subtitle2"
          color="error"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Error color="error" style={{ width: 18, marginRight: 4 }} />
          {error}
        </Typography>
      )}

      {children}
    </Box>
  );
}
