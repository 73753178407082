import { AnnualPlanning } from '../modules/records/pages/AnnualPlanning';
import { CreateAnnualPlanning } from '../modules/records/pages/AnnualPlanning/Create';
import { AnnualPlanningDetails } from '../modules/records/pages/AnnualPlanning/Details';
import { EditAnnualPlanning } from '../modules/records/pages/AnnualPlanning/Edit';
import { Client } from '../modules/records/pages/Client';
import { Fofa } from '../modules/records/pages/Fofa';
import { Product } from '../modules/records/pages/Product';
import { EditProduct } from '../modules/records/pages/Product/EditProduct';
import { Records } from '../modules/records/pages/Records';
import { SalesAdjustments } from '../modules/records/pages/SalesAdjustments';
import { IRoutes } from './types.routes';

export const recordsRoutes: IRoutes[] = [
  {
    path: '/records',
    children: <Records />,
    isPrivate: true,
    exact: true,
    pageName: 'Cadastros',
  },
  {
    path: '/records/clients',
    children: <Client />,
    isPrivate: true,
    exact: true,
    pageName: 'Clientes',
  },
  {
    path: '/records/products',
    children: <Product />,
    isPrivate: true,
    exact: true,
    pageName: 'Produtos',
  },

  {
    path: '/records/product/details/edit/:id',
    children: <EditProduct />,
    isPrivate: true,
    exact: true,
    pageName: 'Editar produto',
  },

  {
    path: '/records/sales-adjustments',
    children: <SalesAdjustments />,
    isPrivate: true,
    isPremium: true,
    exact: true,
    pageName: 'Ajuste de Vendas',
  },
  {
    path: '/records/annual-planning',
    children: <AnnualPlanning />,
    isPrivate: true,
    isPremium: true,
    exact: true,
    pageName: 'Planejamento Anual',
  },
  {
    path: '/records/annual-planning/create',
    children: <CreateAnnualPlanning />,
    isPrivate: true,
    isPremium: true,
    exact: true,
    pageName: 'Planejamento Anual',
  },
  {
    path: '/records/annual-planning/details/:id',
    children: <AnnualPlanningDetails />,
    isPrivate: true,
    isPremium: true,
    exact: true,
    pageName: 'Planejamento Anual',
  },
  {
    path: '/records/annual-planning/edit/:id',
    children: <EditAnnualPlanning />,
    isPrivate: true,
    isPremium: true,
    exact: true,
    pageName: 'Planejamento Anual',
  },
  {
    path: '/records/fofa',
    children: <Fofa />,
    isPrivate: true,
    isPremium: true,
    exact: true,
    pageName: 'Fofa',
  },
];
