import { useContext } from 'react';

import { AnimateLottieAlertContext } from '../contexts/AnimateLottieAlertContext';

export function useLottieAlert() {
  const { isOpen, lottie, onLottieClose, onLottieOpen, description } =
    useContext(AnimateLottieAlertContext);

  return {
    isOpen,
    lottie,
    onLottieClose,
    onLottieOpen,
    description,
  };
}
