import { Box, Button } from '@material-ui/core';
import MUiBottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { motion } from 'framer-motion';
import { memo } from 'react';

import { usePremium } from '../../hooks/usePremium';
import { BudgetIcon, HomeIcon, SalesIcon, TechnicalSheetIcon } from '../Icons';
import PremiumIcon from '../Icons/PremiumIcon';
import { useStylesBottomNavigation } from './styles';

interface BottomNavigationProps {
  pathname: string;
  onNavigate: (value: string) => void;
}

function BottomNavigationComponent({
  pathname,
  onNavigate,
}: BottomNavigationProps) {
  const classes = useStylesBottomNavigation();
  const isPremium = usePremium();
  return (
    <MUiBottomNavigation
      value={pathname}
      onChange={(_, newValue) => {
        onNavigate(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        className={classes.button}
        label="Início"
        icon={<HomeIcon fontSize="large" />}
        component={motion.button}
        whileTap={{ scale: 0.8 }}
        value="/"
      />
      <BottomNavigationAction
        className={classes.button}
        label="Orçamentos"
        icon={<BudgetIcon fontSize="large" />}
        component={motion.button}
        whileTap={{ scale: 0.8 }}
        value="/budgets"
        
      />
      <BottomNavigationAction
        className={classes.button}
        label="Vendas"
        icon={<SalesIcon fontSize="large" />}
        component={motion.button}
        whileTap={{ scale: 0.8 }}
        value="/sales"
      />
      <BottomNavigationAction
        className={classes.button}
        label="Fichas Técnicas"
        icon={<TechnicalSheetIcon fontSize="large" />}
        component={motion.button}
        whileTap={{ scale: 0.8 }}
        value="/technical-sheets"
      />
    </MUiBottomNavigation>
  );
}

export const BottomNavigation = memo(BottomNavigationComponent);
