import { Snackbar, Button } from '@material-ui/core';
import React, { FC } from 'react';

import { register } from '../../serviceWorker';

const ServiceWorkerWrapper: FC = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [installingWorker, setInstallingWorker] =
    React.useState<ServiceWorker | null>(null);

  React.useEffect(() => {
    const onSWUpdate = (registration: ServiceWorkerRegistration) => {
      setShowReload(true);

      setInstallingWorker(registration.installing);
      setInstallingWorker(registration.waiting);
    };

    register({ onUpdate: onSWUpdate });
  }, []);
  const reloadPage = () => {
    installingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload();
  };

  return (
    <Snackbar
      open={showReload}
      message="Uma nova versão está disponível!"
      onClick={reloadPage}
      data-test-id="screens-new-version-snackbar"
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        <Button color="inherit" size="small" onClick={reloadPage}>
          Recarregar
        </Button>
      }
    />
  );
};

export default ServiceWorkerWrapper;
