import { useInfiniteQuery } from 'react-query';

import { api } from '../../../services/apiClient';
import { assertIsDataResponse } from '../assertIsDataResponse';
import { Videos } from './types';

type IReactQueryResponse = {
  results: Videos[];
  next: number | undefined;
};

type IQuery = {
  pageParam: number;
};

async function getData({ pageParam = 1 }: IQuery) {
  const response = await api.get(`videos/?page=${pageParam}`);

  const data: IReactQueryResponse = {
    results: response.data.results,
    next:
      response.data.last_page === 0 ||
      response.data.results.length === 0 ||
      response.data.last_page === pageParam
        ? undefined
        : pageParam + 1,
  };
  return data;
}

function useVideos() {
  return useInfiniteQuery<IReactQueryResponse, Error>(
    ['videos'],
    (queryKey) => getData({ pageParam: queryKey.pageParam }),
    {
      getNextPageParam: (nextPage) => nextPage.next,
    }
  );
}

export { useVideos };
