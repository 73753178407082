import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function AboutAppIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M10.1356 6.74697C9.86439 6.74697 9.62711 6.57744 9.52541 6.34009L9.01694 5.25506L9.62711 4.98381L10.1356 6.06883C10.1356 6.06883 11.5254 5.05162 13.8644 5.05162C13.8644 4.64474 14 4.37349 14.0678 4.13614C14.1356 3.9666 14.2034 3.83097 14.2034 3.69535C14.2034 3.22065 13.1864 2.67814 11.8305 2.67814V2C13.5593 2 14.8813 2.71205 14.8813 3.69535C14.8813 4.00051 14.7796 4.20395 14.7118 4.40739C14.6102 4.61083 14.5424 4.78037 14.5424 5.05162C14.5424 5.4246 14.2373 5.72976 13.8644 5.72976C11.7966 5.72976 10.5424 6.61134 10.5424 6.61134C10.4068 6.71306 10.2712 6.74697 10.1356 6.74697Z" />
      <path d="M8.94916 6.4079C8.1017 6.4079 7.42374 5.79758 7.42374 5.05162C7.42374 3.42409 9.83051 2 11.8305 2C11.9661 2 12.0678 2 12.2034 2L12.1695 2.67814C12.0678 2.67814 11.9661 2.67814 11.8305 2.67814C10.0678 2.67814 8.1017 3.93269 8.1017 5.05162C8.1017 5.4246 8.47458 5.72976 8.94916 5.72976C9.15255 5.72976 9.32204 5.66195 9.49153 5.59413L9.86441 6.17055C9.59323 6.30618 9.28814 6.4079 8.94916 6.4079Z" />
      <path d="M12 9.45953C10.6102 9.45953 9.45761 8.3067 9.45761 6.91652V6.13666H10.1356V6.91652C10.1356 7.93372 10.983 8.7814 12 8.7814C13.0169 8.7814 13.8644 7.93372 13.8644 6.91652V5.05164H14.5423V6.91652C14.5423 8.3067 13.3898 9.45953 12 9.45953Z" />
      <path d="M9.55931 15.9358L8.67795 13.1893C8.54236 12.7824 8.30507 12.4434 7.96609 12.1721L3.76271 8.91703C3.49152 8.71359 3.35593 8.40843 3.35593 8.10327C3.35593 7.52685 3.7966 7.08606 4.37287 7.08606C4.54237 7.08606 4.71186 7.11997 4.88135 7.22169L8.47456 9.2561C9.05083 9.59517 9.6949 9.76471 10.339 9.79861C10.6441 9.35782 10.7457 8.84922 10.7457 8.84922L11.4237 8.95094C11.4237 8.98485 11.2881 9.76471 10.7796 10.3411L10.678 10.4428H10.4746C9.661 10.4428 8.84744 10.2394 8.13558 9.83252L4.54237 7.7981C4.47457 7.7642 4.44067 7.7642 4.37287 7.7642C4.16948 7.7642 4.03389 7.89983 4.03389 8.10327C4.03389 8.20499 4.06779 8.30671 4.16948 8.37452L8.37287 11.6296C8.81355 11.9687 9.15253 12.4434 9.32202 12.9859L10.2034 15.7323L9.55931 15.9358Z" />
      <path d="M9.45763 13.8335L8.91525 13.4266L10.4407 11.4261L10.1356 10.2054L10.8136 10.0698L11.1864 11.5617L9.45763 13.8335Z" />
      <path d="M15.4237 12.952L14.8135 12.6807C15.0169 12.2738 15.2881 11.9348 15.6271 11.6635L19.8305 8.40843C19.8983 8.34062 19.9661 8.2389 19.9661 8.13717C19.9661 7.93373 19.8305 7.7981 19.6271 7.7981C19.5593 7.7981 19.5254 7.7981 19.4576 7.83201L15.8644 9.86643C15.1525 10.2733 14.3389 10.4768 13.5254 10.4768H13.3559L13.2542 10.375C12.7457 9.76471 12.6101 8.98485 12.6101 8.98485L13.2881 8.88313L12.9491 8.91703L13.2881 8.84922C13.2881 8.84922 13.3559 9.35782 13.6949 9.79861C14.3389 9.76471 14.983 9.59517 15.5593 9.2561L19.1525 7.22169C19.322 7.11997 19.4915 7.08606 19.661 7.08606C20.2372 7.08606 20.6779 7.52685 20.6779 8.10327C20.6779 8.40843 20.5423 8.71359 20.2711 8.91703L16.0339 12.1721C15.7627 12.3755 15.5593 12.6468 15.4237 12.952Z" />
      <path d="M13.1864 11.1548H10.8135V11.833H13.1864V11.1548Z" />
      <path d="M13.8305 12.918L12.8135 11.5617L13.1864 10.0698L13.8644 10.2054L13.5593 11.4261L14.3729 12.4772L13.8305 12.918Z" />
      <path d="M17.2542 20.3098H2V20.9879H17.2542V20.3098Z" />
      <path d="M18.6102 20.3098H17.9322V20.9879H18.6102V20.3098Z" />
      <path d="M21.322 20.3098H19.2881V20.9879H21.322V20.3098Z" />
      <path d="M9.79658 20.6488H9.11862V17.5972C9.11862 17.0208 8.67794 16.58 8.10167 16.58H4.37286C3.79659 16.58 3.35591 17.0208 3.35591 17.5972V20.6488H2.67795V17.5972C2.67795 16.6478 3.42371 15.9019 4.37286 15.9019H8.10167C9.05082 15.9019 9.79658 16.6478 9.79658 17.5972V20.6488Z" />
      <path d="M5.38984 16.58C4.64407 16.58 4.03391 15.9697 4.03391 15.2237C4.03391 14.4778 4.64407 13.8674 5.38984 13.8674C6.1356 13.8674 6.74577 14.4778 6.74577 15.2237C6.74577 15.9697 6.1356 16.58 5.38984 16.58ZM5.38984 14.5456C5.01695 14.5456 4.71187 14.8507 4.71187 15.2237C4.71187 15.5967 5.01695 15.9018 5.38984 15.9018C5.76272 15.9018 6.0678 15.5967 6.0678 15.2237C6.0678 14.8507 5.76272 14.5456 5.38984 14.5456Z" />
      <path d="M7.08473 16.58V15.9018C7.45761 15.9018 7.7627 15.5967 7.7627 15.2237C7.7627 14.8507 7.45761 14.5456 7.08473 14.5456C6.84744 14.5456 6.64405 14.6473 6.50846 14.8507L5.93219 14.4778C6.16948 14.1048 6.61015 13.8674 7.05083 13.8674C7.79659 13.8674 8.40676 14.4778 8.40676 15.2237C8.40676 15.9697 7.83049 16.58 7.08473 16.58Z" />
      <path d="M8.10168 19.2925C7.38981 19.2925 7.11863 18.7839 6.91524 18.411C6.74575 18.038 6.64405 17.9363 6.40676 17.9363C6.06778 17.9363 5.83049 18.1736 5.52541 18.5466C5.25422 18.8857 4.91524 19.2925 4.37287 19.2925C3.89829 19.2925 3.62711 19.0552 3.45761 18.8517C3.28812 18.6822 3.22033 18.6144 3.01694 18.6144V17.9363C3.49151 17.9363 3.7627 18.1736 3.93219 18.377C4.10168 18.5466 4.16948 18.6144 4.37287 18.6144C4.57626 18.6144 4.74575 18.411 5.01693 18.1058C5.32202 17.7328 5.7288 17.2581 6.40676 17.2581C7.08473 17.2581 7.35592 17.7328 7.52541 18.1058C7.7288 18.4788 7.83049 18.6144 8.10168 18.6144C8.30507 18.6144 8.37286 18.5466 8.54235 18.377C8.71185 18.2075 8.98303 17.9363 9.45761 17.9363V18.6144C9.25422 18.6144 9.18642 18.6822 9.01693 18.8517C8.84744 19.0552 8.57625 19.2925 8.10168 19.2925Z" />
      <path d="M16.4746 20.7166L15.7966 20.581L16.2373 18.2753H12.1695L12.6102 20.581L11.9322 20.7166L11.4915 18.411C11.4915 18.3771 11.4915 18.3431 11.4915 18.3092C11.4915 17.9363 11.7966 17.6311 12.1695 17.6311H16.2373C16.6102 17.6311 16.9152 17.9363 16.9152 18.3092C16.9152 18.3431 16.9152 18.3771 16.9152 18.411L16.4746 20.7166Z" />
      <path d="M15.2203 18.9535H14.5424V19.6316H15.2203V18.9535Z" />
      <path d="M13.8644 18.9535H12V19.6316H13.8644V18.9535Z" />
      <path d="M12.2373 18.1736C11.9661 17.9363 11.8305 17.5972 11.8305 17.2581C11.8305 16.5122 12.5085 15.9019 13.3559 15.9019C13.9322 15.9019 14.4407 16.1731 14.7119 16.6139L14.1356 16.953C14 16.7156 13.6949 16.5461 13.3559 16.5461C12.8814 16.5461 12.5085 16.8512 12.5085 17.2242C12.5085 17.4277 12.6102 17.5633 12.7119 17.665L12.2373 18.1736Z" />
      <path d="M16.5423 18.0719L15.9322 17.7667C15.9322 17.7667 16.2373 17.1564 16.2373 16.4444C16.2373 15.868 15.6949 15.1559 15.2881 14.7151C14.9491 15.122 14.4746 15.2237 14.0678 15.3254C13.4915 15.4611 13.1864 15.5289 13.1864 16.3087H12.5085C12.5085 15.0203 13.322 14.8168 13.9322 14.6812C14.4407 14.5795 14.7457 14.4778 14.9152 14.0709L15.0847 13.5962L15.4576 13.9353C15.5932 14.0709 16.9152 15.2915 16.9152 16.4444C16.9152 17.326 16.5423 18.0719 16.5423 18.0719Z" />
      <path d="M11.1525 16.58H9.28812V17.2581H11.1525V16.58Z" />
      <path d="M20.1017 20.7166L19.1525 17.0208C19.0847 16.7495 18.8474 16.58 18.5763 16.58H16.5763V15.9019H18.5763C19.1525 15.9019 19.661 16.3087 19.7966 16.8513L20.7458 20.5471L20.1017 20.7166Z" />
      <path d="M18.7118 16.5461L18.4407 15.9357C18.4745 15.9357 18.983 15.6645 18.983 14.8846C18.5762 14.8846 17.6271 15.0202 17.6271 16.2409H16.9491C16.9491 14.749 18.0339 14.2065 18.983 14.2065C19.3559 14.2065 19.661 14.5116 19.661 14.8846C19.6271 16.1053 18.7457 16.5461 18.7118 16.5461Z" />
    </SvgIcon>
  );
}
