import { Box, Dialog, DialogTitle, Typography } from '@material-ui/core';
import { ReactNode } from 'react';

export interface SimpleDialogProps {
  open: boolean;
  title?: string;
  subTitle?: string;
  onClose: () => void;
  children?: ReactNode;
}

export function SimpleDialog({
  open,
  title,
  subTitle,
  children,
  onClose,
}: SimpleDialogProps) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {title && <DialogTitle id="simple-dialog-title">{title}</DialogTitle>}
        {subTitle && (
          <Typography id="simple-dialog-subtitle">{subTitle}</Typography>
        )}
      </Box>
      {children}
    </Dialog>
  );
}
