import { Box, Typography } from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link, useHistory, useParams } from 'react-router-dom';

import noImage from '../../../../shared/assets/no-image.png';
import { Button } from '../../../../shared/components/Button';
import { GoBackIconButton } from '../../../../shared/components/Button/GoBackIconButton';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { SimpleDialog } from '../../../../shared/components/SimpleDialog';
import { useRecipe } from '../../../../shared/hooks/reactQuery/useRecipe';
import { useSalesAdjustments } from '../../../../shared/hooks/reactQuery/useSalesAdjustments';
import { usePremium } from '../../../../shared/hooks/usePremium';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { formatPrice } from '../../../../shared/utils/formatPrice';
import { CardButtonTechnicalSheetDetail } from '../../components/CardButtonTechnicalSheetDetail';
import { CardDetailTechnicalSheet } from '../../components/CardDetailTechnicalSheet';
import { useStyles } from './styles';
import { IRecipe, IRecipeResponse } from './types';
import PremiumIcon from '../../../../shared/components/Icons/PremiumIcon';
import Iconify from '../../../../shared/components/Iconify';

export function DetailTechnicalSheet() {
  const classes = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();
  const params = useParams<{ id: string; slug?: string }>();
  const isPremium = usePremium();
  const { msgSuccess, msgError } = useSnackMessages();

  const [sellingCost, setSellingCost] = useState<string | number>(0);
  const [contribution, setContribution] = useState<string | number>(0);
  const [profitMargin, setProfitMargin] = useState<string | number>(0);
  const [suggestedSellingPrice, setSuggestedSellingPrice] = useState<
    string | number
  >(0);
  const [suggestedSellingPriceApp, setSuggestedSellingPriceApp] = useState<
    string | number
  >(0);
  const [recipe, setRecipe] = useState<IRecipe>({} as IRecipe);

  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [loading, setLoading] = useState(true);

  const { data: salesAdjustment, status } = useSalesAdjustments();
  // const { data: recipeResponse, error: recipeResponseError } = useRecipe(
  //   params.id
  // );

  const getRecipeData = useCallback(async (id: number) => {
    return api.get<IRecipeResponse>(`/recipes/${id}`);
  }, []);

  useEffect(() => {
    async function loadData() {
      try {
        const { data } = await getRecipeData(+params.id);

        if (data?.title) {
          const calculatorTotal = data?.recipeHasProducts.reduce(
            (accumulator, currentProduct) => {
              return (
                accumulator +
                (currentProduct.product.price * currentProduct.quantity) /
                  currentProduct.product.quantity
              );
            },
            0
          );
          setRecipe({
            ...data,
            recipeHasProducts: data?.recipeHasProducts.map(
              (currentProduct) => ({
                ...currentProduct,
                totalPrice: formatPrice(
                  (currentProduct.product.price * currentProduct.quantity) /
                    currentProduct.product.quantity
                ),
                unitMeasurementType: currentProduct.product.unitMeasurement.name
                  .split(' ')[0]
                  .replace('(', '')
                  .replace(')', ''),
              })
            ),
            totalPrice: calculatorTotal.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            total: calculatorTotal,
          });
        }

        setLoading(false);
      } catch (err) {
        history.push(`/technical-sheets/${params?.slug || ''}`);
      }
    }

    loadData();

    return () => {
      setRecipe({} as IRecipe);
    };
  }, [history, params.id, params?.slug, getRecipeData]);

  const getRecipeSuggestedSellingPrice = useCallback(() => {
    const saleRateWithCards =
      Number(salesAdjustment?.result?.sale_rate_with_cards || 0) / 100;
    const salesFeePerMe =
      Number(salesAdjustment?.result?.sales_fee_per_me || 0) / 100;
    const contribution = Number(recipe?.contribution || 0) / 100;
    const profitMargin = Number(recipe?.profit_margin || 0) / 100;
    const applicationFee =
      Number(salesAdjustment?.result?.application_fee || 0) / 100;
    /*
      recipeValue / (1
      - this.settings.sales.profit.value
      - this.settings.sales.contribution.value
      - (this.settings.sales.cardTax.disabled ? 0 : this.settings.sales.cardTax.value)
      - (this.settings.sales.corporateTax.disabled ? 0 : this.settings.sales.corporateTax.value)
    )
    */
    const recipeTotalValue = recipe?.total || 0;

    /**
     * LOGICA
     * valor total / (100% - margem de lucro - contribuição - taxa do cartão - taxa de venda como ME)
     */
    const value =
      recipeTotalValue /
      (1 - profitMargin - contribution - saleRateWithCards - salesFeePerMe);

    setSuggestedSellingPrice(formatPrice(value));
  }, [
    recipe?.total,
    recipe?.contribution,
    recipe?.profit_margin,
    salesAdjustment?.result?.application_fee,
    salesAdjustment?.result?.sale_rate_with_cards,
    salesAdjustment?.result?.sales_fee_per_me,
  ]);

  const getRecipeSuggestedSellingPriceApp = useCallback(() => {
    const applicationFee =
      Number(salesAdjustment?.result?.application_fee || 0) / 100;
    if (applicationFee <= 0) {
      return;
    }

    const saleRateWithCards =
      Number(salesAdjustment?.result?.sale_rate_with_cards || 0) / 100;
    const salesFeePerMe =
      Number(salesAdjustment?.result?.sales_fee_per_me || 0) / 100;
    const contribution = Number(recipe?.contribution || 0) / 100;
    const profitMargin = Number(recipe?.profit_margin || 0) / 100;

    const recipeTotalValue = recipe?.total || 0;

    /**
     * LOGICA
     * valor total / (100% - margem de lucro - contribuição - taxa do cartão - taxa de venda como ME)
     */
    const value =
      recipeTotalValue /
      (1 - profitMargin - contribution - saleRateWithCards - salesFeePerMe);

    const valueApp = value * applicationFee + value;
    console.log(`Preço no app: valor ${value}, taxa app ${applicationFee}`);
    setSuggestedSellingPriceApp(formatPrice(valueApp));
  }, [
    recipe?.total,
    recipe?.contribution,
    recipe?.profit_margin,
    salesAdjustment?.result?.application_fee,
    salesAdjustment?.result?.sale_rate_with_cards,
    salesAdjustment?.result?.sales_fee_per_me,
  ]);

  useEffect(() => {
    if (!loading) {
      getRecipeSuggestedSellingPrice();
      getRecipeSuggestedSellingPriceApp();
      // let contributionCalculate = 0;
      // let profitMarginCalculate = 0;
      let sellingCostCalculate = 0;

      const saleRateWithCards = Number(
        salesAdjustment?.result?.sale_rate_with_cards || 0
      );
      const salesFeePerMe = Number(
        salesAdjustment?.result?.sales_fee_per_me || 0
      );
      const contribution = Number(recipe?.contribution || 0);
      const profit_margin = Number(recipe?.profit_margin || 0);
      const applicationFee = Number(
        salesAdjustment?.result?.application_fee || 0
      );

      const percent =
        (saleRateWithCards + salesFeePerMe + contribution + profit_margin) /
        100;
      const recipeTotalValue = Number(recipe?.total || 0);

      const suggestedSellingPriceCalculate =
        recipeTotalValue * percent + recipeTotalValue;

      if ((contribution || profit_margin) && recipeTotalValue) {
        const contributionCalculate = (contribution * recipeTotalValue) / 100;

        const profitMarginCalculate = (profit_margin * recipeTotalValue) / 100;

        setContribution(formatPrice(contributionCalculate));
        setProfitMargin(formatPrice(profitMarginCalculate));
      }

      if (recipeTotalValue) {
        sellingCostCalculate =
          ((saleRateWithCards + salesFeePerMe) * recipeTotalValue) / 100;
      }

      setSellingCost(formatPrice(sellingCostCalculate));
    }

    return () => {
      setContribution(0);
      setProfitMargin(0);
      setSuggestedSellingPrice(0);
      setSuggestedSellingPriceApp(0);
      setSellingCost(0);
    };
  }, [
    salesAdjustment?.result,
    recipe.total,
    recipe?.contribution,
    recipe?.profit_margin,
    getRecipeSuggestedSellingPrice,
    getRecipeSuggestedSellingPriceApp,
    loading,
  ]);

  const handleDeleteRecipe = useCallback(async () => {
    try {
      await api.delete(`/recipes/${recipe?.id}`);

      msgSuccess('Ficha técnica deletada com sucesso!');

      history.push(`/technical-sheets/${params?.slug || ''}`);
    } catch (err: any) {
      msgError(err?.response?.data?.message);
    }
  }, [recipe.id, history, params?.slug, msgError, msgSuccess]);

  return (
    <PageContainer display="flex" flexDirection="column">
      <Box
        zIndex={1}
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box className={classes.details}>
          <img
            src={recipe?.image_url || noImage}
            alt="Imagem ficha técnica"
            className={classes.imagem}
          />
          <Box
            className={classes.imageTitleContainer}
            flexDirection={['column', 'row']}
          >
            <Box
              className={classes.title}
              onClick={() =>
                history.push(`/technical-sheets/${params?.slug || ''}`)
              }
            >
              <GoBackIconButton
                color="inherit"
                style={{ padding: 0 }}
                className={classes.hideOnPrint}
              />

              <Typography variant="h6">{recipe?.title}</Typography>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          width="100%"
          mt={2}
          className={classes.hideOnPrint}
        >
          <Iconify
            icon="dashicons:printer"
            onClickCapture={() => window.print()}
            color="#B54D54"
            fontSize="26px"
            marginRight="20px"
            className={classes.hideOnPrint}
            style={{ cursor: 'pointer' }}
          />
          <Button
            className={`${classes.button} ${classes.hideOnPrint}`}
            color="primary"
            size="small"
            variant="contained"
            style={{ border: 'none', height: '36px' }}
            endIcon={<MoreVertIcon />}
            onClick={() =>
              history.push(
                `/technical-sheets/edit/${params.id}/${params?.slug || ''}`
              )
            }
            autoFocus
          >
            Editar
          </Button>
        </Box>

        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          pt={2}
        >
          <table className={classes.table}>
            <thead>
              <tr>
                <th>Produto (Insumos Comuns)</th>
                <th>QTD.</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              {!!recipe?.recipeHasProducts?.length &&
                recipe.recipeHasProducts.map(
                  (recipeProduct) =>
                    recipeProduct.product.product_type_id !== 5 && (
                      <tr key={recipeProduct.product_id}>
                        <td style={{ paddingLeft: '24px' }}>
                          {recipeProduct.product.product_name}
                        </td>
                        <td>
                          {recipeProduct.quantity}{' '}
                          {recipeProduct.unitMeasurementType}
                        </td>
                        <td colSpan={2} className={classes.tablePrice}>
                          {!isPremium ? (
                            <Link to="/become-premium">
                              <Button
                                className={classes.showButtonPremium}
                                color="primary"
                                variant="contained"
                                style={{
                                  border: 'none',
                                }}
                                startIcon={
                                  <PremiumIcon
                                    className={classes.premiumColor}
                                  />
                                }
                              >
                                Mostrar
                              </Button>
                            </Link>
                          ) : (
                            <Box whiteSpace="nowrap" textAlign="right">
                              R$ {recipeProduct.totalPrice}
                            </Box>
                          )}
                        </td>
                      </tr>
                    )
                )}

              <tr style={{ background: '#DF7664', color: '#FFFFFF' }}>
                <td colSpan={3} style={{ color: '#FFFFFF' }}>
                  Produtos (Outros)
                </td>
              </tr>

              {!!recipe?.recipeHasProducts?.length &&
                recipe.recipeHasProducts.map(
                  (recipeProduct) =>
                    recipeProduct.product.product_type_id === 5 && (
                      <tr key={recipeProduct.product_id}>
                        <td style={{ paddingLeft: '24px' }}>
                          {recipeProduct.product.product_name}
                        </td>
                        <td>
                          {recipeProduct.quantity}{' '}
                          {recipeProduct.unitMeasurementType}
                        </td>
                        <td className={classes.tablePrice}>
                          {!isPremium
                            ? 'vff'
                            : `R$ ${recipeProduct.totalPrice}`}
                        </td>
                      </tr>
                    )
                )}
            </tbody>

            <tfoot style={{ background: '#DF7664' }}>
              <tr>
                <td>Custo total da Ficha Técnica</td>
                <td> </td>
                <td colSpan={2} className={classes.tablePrice}>
                  {!isPremium ? (
                    <Link to="/become-premium">
                      <Button
                        className={classes.showButtonPremium}
                        color="primary"
                        variant="contained"
                        style={{
                          border: 'none',
                        }}
                        startIcon={
                          <PremiumIcon className={classes.premiumColor} />
                        }
                      >
                        Mostrar
                      </Button>
                    </Link>
                  ) : (
                    <Box whiteSpace="nowrap" textAlign="right">
                      R$ {recipe?.totalPrice}
                    </Box>
                  )}
                </td>
              </tr>
            </tfoot>
          </table>
        </Box>

        <Box
          display="grid"
          alignItems="center"
          justifyContent="space-between"
          mt={3}
          gridGap="20px"
          width="100%"
          className={classes.cardContainerGridTemplateColumns}
          gridAutoRows="1fr"
        >
          <CardDetailTechnicalSheet
            title="Rendimento"
            value={`${recipe?.quantity} ${
              // eslint-disable-next-line no-nested-ternary
              recipe.unit
                ? recipe.unit
                : recipe.quantity === 1
                ? 'porção'
                : 'porções'
            }`}
            visible
            isLoading={loading}
          />

          <CardDetailTechnicalSheet
            title="Custos da Ficha Técnica"
            value={`R$ ${recipe?.totalPrice || ''}`}
            visible={isPremium}
            isLoading={loading}
          />

          <CardDetailTechnicalSheet
            title="Margem de Lucro"
            value={`R$  ${profitMargin}`}
            showPercentage
            percentage={`(${recipe?.profit_margin}%)`}
            visible={isPremium}
            link
            onEdit
            isLoading={loading}
          >
            <Button
              size="small"
              variant="outlined"
              color="primary"
              className={classes.hideOnPrint}
              onClick={() =>
                history.push(
                  `/technical-sheets/edit/${params.id}/${params?.slug || ''}`
                )
              }
              style={{ border: 'none' }}
            >
              <EditOutlined color="inherit" style={{ fontSize: '18px' }} />
              <Typography component="small" style={{ fontSize: '0.675rem' }}>
                Ficha Técnica
              </Typography>
            </Button>
          </CardDetailTechnicalSheet>

          <CardDetailTechnicalSheet
            title="Margem de Contribuição para os Custos Fixos"
            value={`R$ ${contribution}`}
            showPercentage
            percentage={`(${recipe?.contribution}%)`}
            visible={isPremium}
            link
            onEdit
            isLoading={loading}
          >
            <Button
              className={classes.hideOnPrint}
              size="small"
              variant="outlined"
              color="primary"
              onClick={() =>
                history.push(
                  `/technical-sheets/edit/${params.id}/${params?.slug || ''}`
                )
              }
              style={{ border: 'none' }}
            >
              <EditOutlined color="inherit" style={{ fontSize: '18px' }} />
              <Typography component="small" style={{ fontSize: '0.755rem' }}>
                Ficha Técnica
              </Typography>
            </Button>
          </CardDetailTechnicalSheet>

          <CardDetailTechnicalSheet
            title="Custos de venda por Cartão de Crédito"
            value={`R$ ${sellingCost}`}
            showPercentage
            percentage={`(${
              salesAdjustment?.result?.sale_rate_with_cards || 0
            }% +
            ${salesAdjustment?.result?.sales_fee_per_me || 0}%)`}
            visible={isPremium}
            link
            onEdit
            isLoading={loading}
          />
          <CardDetailTechnicalSheet
            title="Preço de Venda Sugerido"
            value={`R$ ${suggestedSellingPrice}`}
            visible={isPremium}
            link
            onEdit
            isLoading={loading}
          />

          {!!suggestedSellingPriceApp && (
            <CardDetailTechnicalSheet
              gridColumn="3/1"
              title="Preço de venda sugerido no APP de entrega"
              value={`R$ ${suggestedSellingPriceApp}`}
              visible={isPremium}
              link
              onEdit
              isLoading={loading}
            />
          )}
        </Box>
      </Box>

      <Box
        zIndex={1}
        mt={3}
        width="100%"
        display="flex"
        flexDirection="column"
        borderTop="2px solid #F4DDDD"
      >
        <h2 className={classes.cardContent}>Informações adicionais</h2>
        <p>{recipe?.description}</p>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        mt={3}
        pt={2}
        borderTop="2px solid #F4DDDD"
      >
        <Button
          variant="outlined"
          color="primary"
          style={{ width: '100%' }}
          onClick={() => setOpenModalDelete(true)}
          className={classes.hideOnPrint}
        >
          Deletar ficha técnica
        </Button>

        <SimpleDialog
          open={openModalDelete}
          title="Deletar Ficha Técnica ?"
          onClose={() => setOpenModalDelete(false)}
        >
          <Box display="flex" flexDirection="column" gridGap="16px" p={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDeleteRecipe}
              style={{ width: '100%' }}
              className={classes.hideOnPrint}
            >
              Deletar
            </Button>

            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpenModalDelete(false)}
              style={{ width: '100%' }}
              className={classes.hideOnPrint}
            >
              Cancelar
            </Button>
          </Box>
        </SimpleDialog>
      </Box>
    </PageContainer>
  );
}
