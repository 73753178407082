import {
  AppBar,
  Slide,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { ReactElement, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { logoPageAuth } from '../../assets/logoBase64';
import { Flex } from '../Flex';
import { IconButton } from '../IconButton';
import { Menu } from '../Menu';
import { MenuMessage } from './MenuMessage';
import { MenuProfile } from './MenuProfile';

interface Props {
  window?: () => Window;
  children: ReactElement;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  hideOnPrint: {
    '@media print': {
      display: 'none',
    },
  },
  appBar: {
    background: theme.background.gradient.radial,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.text.contrastText,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}));

interface TopNavigationProps {
  window?: () => Window;
  pageName?: string;
  signOut: () => void;
}

export function TopNavigation(props: TopNavigationProps) {
  const classes = useStyles();
  const history = useHistory();

  const [openMenu, setOpenMenu] = useState(false);

  const { pageName, signOut } = props;

  const toggleDrawer = useCallback((value) => {
    setOpenMenu(value);
  }, []);

  function handlePush(route: string) {
    history.push(route);
  }

  return (
    <HideOnScroll {...props}>
      <AppBar
        position="fixed"
        className={`${classes.appBar} ${classes.hideOnPrint}`}
      >
        <Toolbar className={classes.toolbar}>
          <Menu
            open={openMenu}
            onClose={() => toggleDrawer(false)}
            onOpen={() => toggleDrawer(true)}
            signOut={signOut}
          />

          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" className={classes.title} noWrap>
            <img
              src={logoPageAuth}
              alt="Logo"
              width="24px"
              style={{ marginRight: 8 }}
            />
            {pageName || 'Bolo no Bolso'}
          </Typography>

          <Flex flexGrow={0} justifyContent="flex-end">
            <MenuMessage handlePush={handlePush} />

            <MenuProfile signOut={signOut} handlePush={handlePush} />
          </Flex>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}
