import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Typography,
} from '@material-ui/core';
import { ReportProblemOutlined as Error } from '@material-ui/icons';
import { Controller } from 'react-hook-form';

type Props = RadioGroupProps & {
  label?: string;
  options: Array<{
    id: number | string;
    name: string;
  }>;
  control: any;
  name: string;
  error?: any;
  help?: string;
  required?: boolean;
  disabled?: boolean;
  getSelectValue?: (value: string) => void;
};

export function InputRadio({
  label,
  options = [],
  control,
  name,
  error = undefined,
  help,
  required,
  disabled,
  getSelectValue,
  ...rest
}: Props) {
  const handleSelectValue = (value: string) => {
    if (!getSelectValue) return;

    getSelectValue(value);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <>
            <FormControl component="fieldset">
              {label && <FormLabel component="legend">{label}</FormLabel>}
              <RadioGroup
                aria-label={name}
                name={name}
                value={field.value || '0'}
                defaultValue={field.value || '0'}
                onChange={(_, value) => {
                  field.onChange(value);
                  handleSelectValue(value);
                }}
                {...rest}
              >
                {options?.map((option) => (
                  <FormControlLabel
                    key={option.id}
                    value={`${option.id}`}
                    control={<Radio color="primary" />}
                    label={option.name}
                    disabled={disabled}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </>
        )}
      />
      {name && error?.[name]?.message && (
        <Typography
          component="p"
          variant="subtitle2"
          color="error"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Error color="error" style={{ width: 18, marginRight: 4 }} />
          {error[name]?.message}
        </Typography>
      )}
    </Box>
  );
}
