import { Box, BoxProps } from '@material-ui/core';
import { FC } from 'react';

type FlexProps = BoxProps;

export const Flex: FC<FlexProps> = ({ ...rest }) => {
  return (
    <Box
      flexGrow={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...rest}
    />
  );
};
