import { Table, TableBody, Box, useMediaQuery } from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Button } from '../../../../shared/components/Button';
import { Header } from '../../../../shared/components/Header';
import { Loading } from '../../../../shared/components/Loading';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { SimpleDialog } from '../../../../shared/components/SimpleDialog';
import { useScheduleDetail } from '../../../../shared/hooks/reactQuery/useScheduleDetail';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { ModalEditSchedule } from '../../components/ModalEditSchedule';
import { TableRowSchedule } from '../../components/TableRowSchedule';
import { TableItemSchedule } from '../../components/TableRowSchedule/TableItemSchedule';
import { TableRowItemSchedule } from '../../components/TableRowSchedule/TableRowItemSchedule';

export function ScheduleDetail() {
  const isNotMobile = useMediaQuery('(min-width:600px)');
  const history = useHistory();
  const { id, date } = useParams<{ id: string; date?: string }>();
  const { msgSuccess, msgError } = useSnackMessages();

  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [openModalEditSchedule, setOpenModalEditSchedule] = useState(false);

  const { data, error, refetch, status } = useScheduleDetail(id);
  console.log(data);
  if (error) {
    msgError(error?.message);
    history.push(`/sales/schedule/${date || ''}`);
  }

  const handleDeleteSchedule = async () => {
    setOpenModalDelete(false);

    try {
      await api.delete(`/sales/schedule/${id}`);

      msgSuccess('Agenda de vendas deletada com sucesso!');

      history.push(`/sales/schedule/${date || ''}`);
    } catch (err: any) {
      msgError(err?.response?.data?.message || err?.response?.data?.error);
    }
  };

  const handleUpdateStatusSchedule = async (
    status: 'UNDELIVERED' | 'PENDING_PAYMENT' | 'PAYMENT_MADE'
  ) => {
    setOpenModalDelete(false);

    try {
      await api.put(`/sales/schedule/${id}/status`, { status });

      msgSuccess('Status da agenda de vendas atualizada com sucesso!');

      history.push(`/sales/schedule/${date || ''}`);
    } catch (err: any) {
      msgError(err?.response?.data?.message || err?.response?.data?.error);
    }
  };

  const handleSuccessEditSchedule = useCallback(() => {
    refetch();
    history.push(`/sales/schedule/${date || ''}`);
  }, [history, refetch, date]);

  return (
    <PageContainer display="flex" flexDirection="column">
      <Header title="Voltar" goPush={`/sales/schedule/${date || ''}`}>
        <Button
          autoFocus
          size="medium"
          variant="contained"
          color="primary"
          startIcon={<EditOutlinedIcon />}
          style={{ borderRadius: 50 }}
          disabled={status === 'loading'}
          onClick={() => setOpenModalEditSchedule(true)}
        >
          Editar {isNotMobile && 'Agenda de Vendas'}
        </Button>
      </Header>

      {status === 'loading' && (
        <Box position="relative">
          <Loading />
        </Box>
      )}

      {status === 'success' && (
        <>
          <Table>
            <TableBody>
              <TableRowSchedule title="Cliente">
                <TableItemSchedule
                  title="Nome"
                  value={data?.result.client.name}
                />
                <TableItemSchedule
                  title="Telefone"
                  value={data?.result?.client.phone}
                />
              </TableRowSchedule>

              <TableRowSchedule title="Valores">
                <TableItemSchedule
                  title="Valor total"
                  value={`R$ ${data?.result?.formattedValue}`}
                />

                {!!data?.result?.amounts?.length && (
                  <TableRowSchedule title="Pagamentos">
                    {data?.result?.amounts?.map((amount) => (
                      <TableRowItemSchedule
                        key={amount.id}
                        title="Valor pago"
                        value={
                          amount.amount_paid
                            ? `R$ ${amount.formattedPaidValue}`
                            : undefined
                        }
                        title2="Data do Pagamento"
                        value2={amount.formattedPaidDate}
                      />
                    ))}
                  </TableRowSchedule>
                )}
              </TableRowSchedule>

              <TableRowSchedule title="Encomenda">
                <TableItemSchedule title="Sabor" value={data?.result?.flavor} />
                <TableItemSchedule
                  title="Sabor do recheio"
                  value={data?.result?.flavor_filling}
                />
                <TableItemSchedule
                  title={
                    data?.result?.recipeType.type === 'slices'
                      ? 'Fatias'
                      : 'Unidade'
                  }
                  value={data?.result?.quantity}
                />
              </TableRowSchedule>

              <TableRowSchedule title="Informações do evento">
                <TableItemSchedule
                  title="Nome do Evento"
                  value={data?.result?.name}
                />
                <TableItemSchedule title="Idade" value={data?.result?.age} />
                <TableItemSchedule
                  title="Tema"
                  value={data?.result?.party_theme}
                />
                <TableItemSchedule
                  title="Data"
                  value={data?.result?.formattedPartyDate}
                />
                <TableItemSchedule
                  title="Endereço"
                  value={data?.result?.party_address}
                />
                <TableItemSchedule
                  title="Informações adicionais"
                  value={data?.result?.additional_information}
                />
              </TableRowSchedule>
            </TableBody>
          </Table>

          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            mt={1}
            gridGap={8}
          >
            {data?.result?.status === 'UNDELIVERED' && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: '100%' }}
                  endIcon={<CheckOutlinedIcon />}
                  onClick={() => handleUpdateStatusSchedule('PENDING_PAYMENT')}
                >
                  ENTREGUE
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: '100%' }}
                  endIcon={<DoneAllIcon />}
                  onClick={() => handleUpdateStatusSchedule('PAYMENT_MADE')}
                >
                  ENTREGUE E PAGO
                </Button>
              </>
            )}

            {data?.result?.status === 'PENDING_PAYMENT' && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: '100%' }}
                  endIcon={<DoneAllIcon />}
                  onClick={() => handleUpdateStatusSchedule('PAYMENT_MADE')}
                >
                  PAGO
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: '100%' }}
                  disabled
                  endIcon={<DoneAllIcon />}
                >
                  JÁ ENTREGUE
                </Button>
              </>
            )}

            {data?.result?.status === 'PAYMENT_MADE' && (
              <Button
                variant="contained"
                color="primary"
                style={{ width: '100%' }}
                disabled
                endIcon={<DoneAllIcon />}
              >
                JÁ ENTREGUE E PAGO
              </Button>
            )}
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            mt={4}
            pt={4}
            borderTop="2px solid #F4DDDD"
          >
            <Button
              variant="outlined"
              color="primary"
              style={{ width: '100%' }}
              onClick={() => setOpenModalDelete(true)}
            >
              Deletar Agenda de Vendas
            </Button>

            <SimpleDialog
              open={openModalDelete}
              title="Deletar Agenda de Vendas?"
              onClose={() => setOpenModalDelete(false)}
            >
              <Box display="flex" flexDirection="column" gridGap="16px" p={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDeleteSchedule}
                  style={{ width: '100%', height: 56 }}
                >
                  Deletar
                </Button>

                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenModalDelete(false)}
                  style={{ width: '100%', height: 56 }}
                >
                  Cancelar
                </Button>
              </Box>
            </SimpleDialog>
          </Box>
        </>
      )}

      {status === 'success' && data?.result.id && (
        <ModalEditSchedule
          data={data.result}
          isOpen={openModalEditSchedule}
          onClose={() => setOpenModalEditSchedule(false)}
          onCloseSuccess={handleSuccessEditSchedule}
        />
      )}
    </PageContainer>
  );
}
