import { AddOutlined } from '@material-ui/icons';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ButtonLarge } from '../../../../shared/components/Button/ButtonLarge';
import { Header } from '../../../../shared/components/Header';
import { Loading } from '../../../../shared/components/Loading';
import { NoData } from '../../../../shared/components/NoData';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { SectionContainer } from '../../../../shared/components/SectionContainer';
import { useSchedule } from '../../../../shared/hooks/reactQuery/useSchedule';
import { formatPrice } from '../../../../shared/utils/formatPrice';
import { CardSalesSchedule } from '../../components/CardSalesSchedule';
import { CardsTopSalesSchedule } from '../../components/CardsTopSalesSchedule';
import { DateNavigation } from '../../components/DateNavigation';
import { ModalCreateSchedule } from '../../components/ModalCreateSchedule';
import { ScheduleStatusInformation } from '../../components/ScheduleStatusInformation';

type CardsTop = {
  received: string;
  toReceive: string;
  total: string;
};

export function Schedule() {
  const history = useHistory();
  const params = useParams<{ date?: string }>();

  const [selectedDate, setSelectedDate] = useState(moment(params?.date));

  const [selectedMonth, setSelectedMonth] = useState(
    moment(params?.date).format('M')
  );
  const [selectedYear, setSelectedYear] = useState(
    moment(params?.date).format('YYYY')
  );

  const [openModalCreate, setOpenModalCreate] = useState(false);

  const [cardsTop, setCardsTop] = useState<CardsTop>({} as CardsTop);

  const { data, error, refetch, status, isFetching } = useSchedule(
    selectedMonth,
    selectedYear
  );

  useEffect(() => {
    const toReceiveTotal =
      data?.results.reduce((accumulator, currentValue) => {
        if (currentValue?.status !== 'PAYMENT_MADE') {
          if (currentValue?.amounts?.length) {
            const totalPaid = currentValue?.amounts?.reduce((acc, item) => {
              return acc + Number(item.amount_paid);
            }, 0);

            return (
              accumulator + (Number(currentValue.amount) - Number(totalPaid))
            );
          }

          return accumulator + Number(currentValue.amount);
        }
        return accumulator;
      }, 0) ?? 0;

    const receivedTotal =
      data?.results.reduce((accumulator, currentValue) => {
        if (currentValue.status === 'PAYMENT_MADE') {
          return accumulator + Number(currentValue.amount);
        }

        if (currentValue?.amounts?.length) {
          const totalPaid = currentValue?.amounts?.reduce((acc, item) => {
            return acc + Number(item.amount_paid);
          }, 0);

          return accumulator + Number(totalPaid);
        }

        return accumulator;
      }, 0) ?? 0;

    setCardsTop({
      toReceive: formatPrice(toReceiveTotal),
      received: formatPrice(receivedTotal),
      total: formatPrice(toReceiveTotal + receivedTotal),
    });
  }, [data?.results]);

  const handleNewDate = useCallback((momentDate: Moment) => {
    setSelectedDate(momentDate);
    setSelectedMonth(momentDate.format('M'));
    setSelectedYear(momentDate.format('YYYY'));
  }, []);

  const handlePrevNewDate = useCallback((momentDate: Moment) => {
    const newPrevDate = momentDate.set('month', momentDate.month() - 1);
    setSelectedDate(newPrevDate);
    setSelectedMonth(newPrevDate.format('M'));
    setSelectedYear(newPrevDate.format('YYYY'));
  }, []);

  const handleNextNewDate = useCallback((momentDate: Moment) => {
    const newNextDate = momentDate.set('month', momentDate.month() + 1);
    setSelectedDate(newNextDate);
    setSelectedMonth(newNextDate.format('M'));
    setSelectedYear(newNextDate.format('YYYY'));
  }, []);

  const handleSuccessCreate = useCallback(() => {
    setOpenModalCreate(false);
    refetch();
  }, [refetch]);

  return (
    <PageContainer display="flex" flexDirection="column">
      <Header goPush="/" title="Agenda de Vendas" />
      <DateNavigation
        currentDate={selectedDate}
        newDate={(momentDate) => handleNewDate(momentDate)}
        prevDate={() => handlePrevNewDate(selectedDate)}
        nextDate={() => handleNextNewDate(selectedDate)}
      />

      <CardsTopSalesSchedule
        received={cardsTop.received || '0,00'}
        toReceive={cardsTop.toReceive || '0,00'}
        total={cardsTop.total || '0,00'}
      />

      {!!data?.results?.length && data?.results?.length >= 4 && (
        <ButtonLarge
          startIcon={<AddOutlined />}
          styleButton={{ textTransform: 'inherit' }}
          onClick={() => setOpenModalCreate(true)}
        >
          Adicionar Encomenda
        </ButtonLarge>
      )}

      <SectionContainer position="relative" minHeight={200}>
        {(status === 'loading' || isFetching) && <Loading />}

        {!!data?.results?.length &&
          data.results.map((item) => (
            <React.Fragment key={item.id}>
              <CardSalesSchedule
                day={item?.day}
                month={item?.month}
                name={item?.client?.name}
                eventName={item?.name}
                phone={item?.client?.phone}
                value={item?.formattedValue}
                status={item.status}
                onClick={() =>
                  history.push(
                    `/sales/schedule/${item.id}/detail/${selectedDate.format(
                      'YYYY-MM-DD'
                    )}`
                  )
                }
              />
            </React.Fragment>
          ))}

        <NoData
          visible={
            status !== 'loading' && status !== 'error' && !data?.results.length
          }
          title="Nenhuma encomenda cadastrada neste mês"
        />

        <NoData
          visible={status === 'error' && !data?.results.length}
          error={error?.message}
        />
      </SectionContainer>

      <ButtonLarge
        startIcon={<AddOutlined />}
        styleButton={{ textTransform: 'inherit' }}
        onClick={() => setOpenModalCreate(true)}
      >
        Adicionar Encomenda
      </ButtonLarge>

      <ScheduleStatusInformation />

      <ModalCreateSchedule
        isOpen={openModalCreate}
        onClose={() => setOpenModalCreate(false)}
        onCloseSuccess={handleSuccessCreate}
      />
    </PageContainer>
  );
}
