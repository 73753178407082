import { Box, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Fade from '@material-ui/core/Fade';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import moment from 'moment';
import { MouseEvent, useEffect, useState } from 'react';

import { api } from '../../../services/apiClient';
import { Button } from '../../Button';
import { IconButton } from '../../IconButton';
import { NoData } from '../../NoData';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '36ch',
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: 'inline',
      color: theme.palette.secondary.main,
    },
  })
);

interface MenuMessageProps {
  handlePush: (route: string) => void;
}
interface MessageProps {
  id: number;
  title: string;
  message: string;
  created_at: string;
  user: {
    id: number;
    name: string;
    avatar_url: string;
  };
}
export function MenuMessage({ handlePush }: MenuMessageProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [unreadMessages, setUnreadMessages] = useState<MessageProps[]>([]);
  const [loading, setLoading] = useState(false);

  const handleClickMenuMessage = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    queryData();
  };
  const handleCloseMenuMessage = () => {
    setAnchorEl(null);
  };

  const queryData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/message/all`);

      setUnreadMessages(response.data.messages.unreadMessages);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    queryData();
  }, []);

  return (
    <>
      <IconButton
        aria-controls="menu-message"
        aria-haspopup="true"
        color="inherit"
        onClick={handleClickMenuMessage}
      >
        <Box display="flex" component="span" position="relative" height="auto">
          {unreadMessages.length > 0 && (
            <Box
              component="span"
              display="block"
              position="absolute"
              width="8px"
              height="8px"
              borderRadius="8px"
              bgcolor="error.main"
              top={0}
              right={-2}
            />
          )}
          <MailOutlineIcon />
        </Box>
      </IconButton>
      <Menu
        id="menu-message"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenuMessage}
        TransitionComponent={Fade}
      >
        <Box
          display="flex"
          justifyContent="center"
          borderBottom="1px solid rgba(0, 0, 0, 0.1)"
          p={2}
          pb={1}
        >
          <Button
            size="small"
            variant="outlined"
            color="primary"
            style={{ border: 'none' }}
          >
            Mensagens
          </Button>
        </Box>
        <List className={classes.root}>
          {unreadMessages.map((message) => {
            return (
              <ListItem
                style={{ minWidth: '260px', cursor: 'pointer' }}
                alignItems="flex-start"
                key={message.id}
                onClick={() => {
                  handlePush('/messages');
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    alt={message.user.name}
                    src={message.user.avatar_url}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography noWrap style={{ maxWidth: '78%' }}>
                      {message.user.name}
                    </Typography>
                  }
                  secondary={
                    <Typography component="span" className={classes.inline}>
                      <Typography display="block">
                        {`${message.message.slice(0, 15)}...`}
                      </Typography>
                      <Typography
                        style={{
                          color: '#FF9988',
                          fontSize: '12px',
                          marginTop: '16px',
                          textAlign: 'right',
                        }}
                        display="block"
                      >
                        {moment(new Date(`${message.created_at}`)).format('ll')}
                      </Typography>
                    </Typography>
                  }
                />
              </ListItem>
            );
          })}

          {unreadMessages.length === 0 && (
            <ListItem alignItems="center" style={{ opacity: 0.5 }}>
              <ListItemText
                primary="Bolo no bolso"
                secondary={
                  <Typography component="span" className={classes.inline}>
                    <NoData title="Você não tem nenhuma mensagem pendente." />
                  </Typography>
                }
              />
            </ListItem>
          )}
        </List>
        <Box
          display="flex"
          justifyContent="center"
          borderTop="1px solid rgba(0, 0, 0, 0.1)"
          p={2}
          pb={1}
        >
          <Button
            size="small"
            variant="outlined"
            color="primary"
            style={{ border: 'none' }}
            onClick={() => handlePush('/messages')}
          >
            Ir para mensagens
          </Button>
        </Box>
      </Menu>
    </>
  );
}
