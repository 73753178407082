import MuiButton, {
  ButtonProps as MuiButtonProps,
} from '@material-ui/core/Button';
import {
  motion,
  MotionStyle,
  TargetAndTransition,
  VariantLabels,
} from 'framer-motion';
import { FC } from 'react';

import imgCarrinho from '../../assets/login-icon.svg';

type ButtonProps = MuiButtonProps & {
  loading?: boolean;
  whileTap?: VariantLabels | TargetAndTransition | undefined;
  styleMotion?: MotionStyle | undefined;
};

export const Button: FC<ButtonProps> = ({
  loading = false,
  whileTap,
  children,
  styleMotion,
  ...rest
}) => {
  return (
    <motion.div
      className="animatable"
      whileTap={!rest?.disabled ? whileTap ?? { scale: 0.95 } : undefined}
      style={{ display: 'flex', ...styleMotion }}
      transition={{
        duration: 1,
        type: 'spring',
        bounce: 2,
        damping: 5,
      }}
    >
      <MuiButton {...rest}>
        {loading && (
          <motion.div
            initial={{ opacity: 0.1, translateX: -100, scale: 0.2 }}
            animate={{
              translateX: 0,
              opacity: 1,
              scale: 1,
            }}
            transition={{
              duration: 0.8,
              ease: 'easeInOut',
              times: [0, 0.2, 0.5, 0.8, 1],
              loop: Infinity,
              repeatDelay: 1,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={imgCarrinho}
              alt="Bolo"
              style={{ maxWidth: '52px', width: '42px' }}
            />
          </motion.div>
        )}
        {!loading && children}
      </MuiButton>
    </motion.div>
  );
};
