const removeAcento = (item: any): string =>
  String(item)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

// const onlyNumbers = (text: string): string => text.replace(/\D/gi, '');
const onlyNumbers = (text: string) => text.replace(/[^0-9.]/gi, '');

const onlyNumbersInt = (text: string) => text.replace(/[^0-9]/gi, '');

export { onlyNumbers, onlyNumbersInt, removeAcento };
