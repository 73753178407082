import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  DialogActions,
  makeStyles,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import * as yup from 'yup';

import 'react-quill/dist/quill.snow.css';

import { Modal } from '../../../../shared/components/Modal';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';

interface CreateFofaModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function CreateFofaModal({ isOpen, onClose }: CreateFofaModalProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    field: {
      borderRadius: '4px',
      height: '200px',
      [theme.breakpoints.down(524)]: {
        height: '230px',
      },
    },
  }));
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { msgSuccess, msgError } = useSnackMessages();
  const isNotMobile = useMediaQuery('(min-width:600px)');
  const [strengths, setStrengths] = useState('');
  const [weaknesses, setWeaknesses] = useState('');
  const [opportunities, setOpportunitiess] = useState('');
  const [threats, setThreats] = useState('');
  const schema = yup.object().shape({
    // strengths: yup.string(),
    weaknesses: yup.string(),
    opportunities: yup.string(),
    threats: yup.string(),
  });

  const onSubmit = useCallback(async () => {
    setLoading(true);

    try {
      const formatData = {
        strengths,
        weaknesses,
        opportunities,
        threats,
      };

      await api.post('/fofa', formatData);
      msgSuccess('Fofa cadastrado com sucesso!');
      setStrengths('');
      setWeaknesses('');
      setOpportunitiess('');
      setThreats('');
      onClose();
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      msgError(err?.response?.data?.message);
    }
  }, [
    msgError,
    msgSuccess,
    onClose,
    strengths,
    weaknesses,
    opportunities,
    threats,
  ]);
  const { handleSubmit } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      strengths: '',
      weaknesses: '',
      opportunities: '',
      threats: '',
    },
    mode: 'onChange',
  });

  return (
    <Modal title="Cadastrar FOFA" open={isOpen} onClose={onClose}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDirection="column"
          gridGap={16}
          width="100%"
          style={{ padding: '10px' }}
        >
          <fieldset
            style={{
              borderRadius: '4px',
              backgroundColor: '#E5FFD1',
              borderColor: '#C8EBAD',
            }}
            className={classes.field}
          >
            <legend style={{ marginBottom: '10px' }}>Forças:</legend>
            <ReactQuill
              theme="snow"
              value={strengths}
              onChange={setStrengths}
              style={{
                minHeight: '100px',
                backgroundColor: 'none',
                marginLeft: '10px',
                marginRight: '10px',
                height: '100px',
              }}
            />
          </fieldset>
          <fieldset
            className={classes.field}
            style={{
              borderRadius: '4px',
              backgroundColor: '#FFF2D1',
              borderColor: '#EBD9AD',
            }}
          >
            <legend style={{ marginBottom: '10px' }}>Fraquezas:</legend>
            <ReactQuill
              theme="snow"
              value={weaknesses}
              onChange={setWeaknesses}
              style={{
                minHeight: '100px',
                backgroundColor: 'none',
                marginLeft: '10px',
                marginRight: '10px',
                height: '100px',
              }}
            />
          </fieldset>
          <fieldset
            className={classes.field}
            style={{
              borderRadius: '4px',
              backgroundColor: '#D1F7FF',
              borderColor: '#ADE0EB',
            }}
          >
            <legend style={{ marginBottom: '10px' }}>Oportunidades:</legend>
            <ReactQuill
              theme="snow"
              value={opportunities}
              onChange={setOpportunitiess}
              style={{
                minHeight: '100px',
                backgroundColor: 'none',
                marginLeft: '10px',
                marginRight: '10px',
                height: '100px',
              }}
            />
          </fieldset>
          <fieldset
            className={classes.field}
            style={{
              borderRadius: '4px',
              backgroundColor: '#FFD1D1',
              borderColor: '#EBADAD',
            }}
          >
            <legend style={{ marginBottom: '10px' }}>Ameaças:</legend>
            <ReactQuill
              theme="snow"
              value={threats}
              onChange={setThreats}
              style={{
                minHeight: '100px',
                backgroundColor: 'none',
                marginLeft: '10px',
                marginRight: '10px',
                height: '100px',
              }}
            />
          </fieldset>

          <DialogActions>
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              gridGap={8}
              mt={4}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                autoFocus
                color="primary"
                variant="outlined"
                size="large"
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                autoFocus
                size="large"
                color="primary"
                variant="contained"
                disabled={loading}
              >
                Cadastrar {isNotMobile && 'F.O.F.A'}
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Modal>
  );
}
