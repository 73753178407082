import {
  Box,
  Theme,
  Typography,
  makeStyles,
  BoxProps,
} from '@material-ui/core';
import { memo, ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    [theme.breakpoints.up(460)]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down(460)]: {
      justifyContent: 'space-between',
    },
  },
  header: {
    backgroundImage: theme.background.gradient.radial,
    color: theme.background.gradient.contrastText,
  },
  headerText: {
    color: theme.text.primary,
  },

  title: {
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    fontSize: '20px',
    marginBottom: '10px',
  },
  becomePremium: {
    cursor: 'pointer',
    border: 'none',
    backgroundColor: '#DF7664',
    boxShadow: theme.shadows[5],
    [theme.breakpoints.up(460)]: {
      maxWidth: '600px',
    },
    [theme.breakpoints.down(460)]: {
      maxWidth: '400px',
    },
  },
}));

interface CardButtonsProps extends BoxProps {
  title?: string;
  children: ReactNode;
}
function CardButtonsComponent({ title, children, ...rest }: CardButtonsProps) {
  const classes = useStyles();
  return (
    <>
      {title && (
        <Box
          display="flex"
          justifyContent={['center', 'flex-start']}
          alignItems="center"
        >
          <Typography className={classes.title}>{title}</Typography>
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
        flexWrap="wrap"
        gridGap={['8px', '50px']}
        className={classes.cardContainer}
        marginBottom="20px"
        {...rest}
      >
        {children}
      </Box>
    </>
  );
}

export const CardButtons = memo(CardButtonsComponent);
