import {
  Avatar,
  Box,
  List,
  ListItem,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useCallback, useState } from 'react';

import { Header } from '../../../shared/components/Header';
import Iconify from '../../../shared/components/Iconify';
import { NoData } from '../../../shared/components/NoData';
import { PageContainer } from '../../../shared/components/PageContainer';
import { api } from '../../../shared/services/apiClient';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginBottom: '30px',
    borderRadius: '5px',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
    color: theme.palette.secondary.main,
  },
}));

interface MessageProps {
  id: number;
  title: string;
  message: string;
  created_at: string;
  user: {
    id: number;
    name: string;
    avatar_url: string;
  };
}

export function Messages() {
  const [unreadMessages, setUnreadMessages] = useState<MessageProps[]>([]);
  const [readMessages, setReadMessages] = useState<MessageProps[]>([]);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [isExpanded, setIsExpanded] = useState(true);

  const handleAccordionToggle = () => {
    setIsExpanded((prevExpanded) => !prevExpanded);
  };

  const queryData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/message/all`);

      setUnreadMessages(response.data.messages.unreadMessages);
      setReadMessages(response.data.messages.readMessages);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const messageAsRead = async () => {
    try {
      await api.post(`/message/asread`, {
        messages: unreadMessages,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    queryData();
  }, []);

  useEffect(() => {
    if (unreadMessages.length !== 0) {
      messageAsRead();
    }
  }, [unreadMessages]);

  return (
    <PageContainer display="flex" flexDirection="column">
      <Header title="Suas mensagens" goPush="/" />
      <Accordion
        expanded={isExpanded}
        onChange={handleAccordionToggle}
        sx={{ boxShadow: 0, border: 'none' }}
      >
        <AccordionSummary expandIcon={<Iconify icon="eva:arrow-down-fill" />}>
          Mensagens Não lidas
        </AccordionSummary>
        <AccordionDetails>
          {unreadMessages.map((message) => {
            return (
              <Box key={message.id}>
                <List className={classes.root}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        alt={message.user.name}
                        src={message.user.avatar_url}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={message.user.name}
                      secondary={
                        <Typography component="span" className={classes.inline}>
                          <Typography
                            display="block"
                            style={{ marginTop: '20px' }}
                          >
                            {message.message}
                          </Typography>
                          <Typography
                            style={{
                              color: '#FF9988',
                              fontSize: '12px',
                              marginTop: '16px',
                              textAlign: 'right',
                            }}
                            display="block"
                            key={message.id}
                          >
                            {moment(new Date(`${message.created_at}`)).format(
                              'll'
                            )}
                          </Typography>
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            );
          })}

          {unreadMessages.length === 0 && (
            <ListItem alignItems="center" style={{ opacity: 0.5 }}>
              <ListItemText
                secondary={
                  <Typography
                    component="span"
                    variant="inherit"
                    className={classes.inline}
                  >
                    <NoData title="Você não tem nenhuma mensagem pendente.">
                      <Box mb={3} />
                    </NoData>
                  </Typography>
                }
              />
            </ListItem>
          )}
        </AccordionDetails>
      </Accordion>

      <Box style={{ margin: '20px 0' }} />
      <Accordion sx={{ boxShadow: 0 }}>
        <AccordionSummary expandIcon={<Iconify icon="eva:arrow-down-fill" />}>
          Mensagens lidas
        </AccordionSummary>
        <AccordionDetails>
          {readMessages.map((message) => {
            return (
              <Box key={message.id}>
                <List className={classes.root}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        alt={message.user.name}
                        src={message.user.avatar_url}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={message.user.name}
                      secondary={
                        <Typography component="span" className={classes.inline}>
                          <Typography
                            display="block"
                            style={{ marginTop: '20px' }}
                          >
                            {message.message}
                          </Typography>
                          <Typography
                            style={{
                              color: '#FF9988',
                              fontSize: '12px',
                              marginTop: '16px',
                              textAlign: 'right',
                            }}
                            display="block"
                            key={message.id}
                          >
                            {moment(new Date(`${message.created_at}`)).format(
                              'll'
                            )}
                          </Typography>
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            );
          })}

          {readMessages.length === 0 && (
            <ListItem alignItems="center" style={{ opacity: 0.5 }}>
              <ListItemText
                secondary={
                  <Typography
                    component="span"
                    variant="inherit"
                    className={classes.inline}
                  >
                    <NoData title="Ops! você não tem nenhuma mensagem.">
                      <Box mb={3} />
                    </NoData>
                  </Typography>
                }
              />
            </ListItem>
          )}
        </AccordionDetails>
      </Accordion>
    </PageContainer>
  );
}
