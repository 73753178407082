import {
  Box,
  useMediaQuery,
  CircularProgress,
  Button,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Header } from '../../../../shared/components/Header';
import { NoData } from '../../../../shared/components/NoData';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { AnnualPlanningTable } from '../../components/AnnualPlanningTable';
import { AnualPlanningData, IPlanningData } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.text.primary,
    fontSize: '18px',
  },
}));

export function AnnualPlanning() {
  const classes = useStyles();
  const history = useHistory();
  const isNotMobile = useMediaQuery('(min-width:600px)');
  const { msgError, msgSuccess } = useSnackMessages();
  const [loading, setLoading] = useState(false);

  const [anualPlanning, setAnualPlanning] = useState<IPlanningData[]>([]);

  const loadAnualPlanning = useCallback(() => {
    setLoading(true);
    api
      .get<{ anualPlanning: AnualPlanningData[] }>('/anual-planning')
      .then((response) => {
        const months: { month: string; date: string }[] = [];

        response.data?.anualPlanning.forEach((item) => {
          const currentMonth = moment(new Date(`${item.event_date}`)).format(
            'MMMM'
          );
          if (!months.find((month) => currentMonth === month.month)) {
            months.push({
              month: currentMonth,
              date: moment(new Date(`${item.event_date}`)).format('YYYY-MM-DD'),
            });
          }
        });

        const plannings: IPlanningData[] = months.map((month) => {
          const items: AnualPlanningData[] = [];

          response.data?.anualPlanning.forEach((planning) => {
            const parseDate = new Date(`${planning.event_date}`);

            if (
              moment(parseDate).format('YYYY-MM') ===
              moment(month.date).format('YYYY-MM')
            ) {
              items.push({
                ...planning,
                detailsAnualPlannings: planning.detailsAnualPlannings.map(
                  (item) => ({ ...item, value: Number(item.value) })
                ),
                formatedEventDate: moment(parseDate).format('DD/MM/YYYY'),
                month: moment(parseDate).format('MMMM'),
                formattedDay: moment(parseDate).format('DD'),
                formattedMonth: moment(parseDate).format('MMM'),
                formattedYear: moment(parseDate).format('YYYY'),
              });
            }
          });

          return {
            ...month,
            items,
          };
        });

        console.log(plannings);

        setAnualPlanning(plannings);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    loadAnualPlanning();
  }, [loadAnualPlanning]);

  const handleDeleteAnualPlanning = useCallback(
    async (id: number) => {
      try {
        await api.delete(`/anual-planning?id=${id}`);
        msgSuccess('Planejamento anual deletado com sucesso!');
        loadAnualPlanning();
      } catch (err: any) {
        msgError(err?.response?.data?.message);
      }
    },
    [msgError, msgSuccess, loadAnualPlanning]
  );

  const handleCreate = () => {
    history.push('/records/annual-planning/create');
  };

  const handleOpenView = useCallback(
    (anualPlanning: AnualPlanningData) => {
      history.push(`/records/annual-planning/details/${anualPlanning.id}`, {
        anualPlanning,
      });
    },
    [history]
  );

  const handleEdit = useCallback(
    (anualPlanning: AnualPlanningData) => {
      history.push(`/records/annual-planning/edit/${anualPlanning?.id}`, {
        anualPlanning,
      });
    },
    [history]
  );

  return (
    <PageContainer display="flex" flexDirection="column">
      <Header goPush="/" title="Planejamentos Anuais">
        <Button
          size="medium"
          variant="contained"
          color="primary"
          startIcon={<AddOutlined />}
          onClick={handleCreate}
        >
          Adicionar {isNotMobile && 'planejamento'}
        </Button>
      </Header>

      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        position="relative"
      >
        {loading && (
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            top={0}
            left={0}
            minHeight="30vh"
          >
            <CircularProgress />
          </Box>
        )}

        {anualPlanning?.map((planning) => {
          return (
            <Box key={planning.date} display="flex" flexDirection="column">
              <Box
                display="flex"
                // width="100%"
                ml={1}
                mr={1}
                mt={2}
                alignItems="center"
                justifyContent="space-between"
                style={{ borderTop: '2px solid grey' }}
              >
                <Typography
                  className={classes.title}
                  style={{
                    marginBottom: '10px',
                    marginTop: '5px',
                    textTransform: 'capitalize',
                  }}
                >
                  {planning.month}
                </Typography>
              </Box>

              <AnnualPlanningTable
                data={planning?.items}
                onDelete={(id) => handleDeleteAnualPlanning(id)}
                onView={(anualPlanning) => handleOpenView(anualPlanning)}
                onEdit={(anualPlanning) => handleEdit(anualPlanning)}
              />
            </Box>
          );
        })}
        {!loading && !anualPlanning.length && (
          <NoData
            title="Ops! Nenhum planejamento anual cadastrado."
            description="Seus planejamentos anuais aparecerão aqui. Adicione o seu primeiro planejamento anual clicando no botão abaixo:"
          >
            <Box mb={3} />
            <Button
              size="medium"
              variant="contained"
              color="primary"
              startIcon={<AddOutlined />}
              onClick={handleCreate}
            >
              Adicionar {isNotMobile && 'planejamento anual'}
            </Button>
          </NoData>
        )}
      </Box>
    </PageContainer>
  );
}
