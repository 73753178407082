import { useInfiniteQuery } from 'react-query';

import { api } from '../../../services/apiClient';
import { assertIsDataResponse } from '../assertIsDataResponse';
import { Client } from './types';

type IReactQueryResponse = {
  results: Client[];
  next: number | undefined;
};

async function getData({ pageParam = 1, searchParam = '' }) {
  const response = await api.get(
    `clients?page=${pageParam}&search=${searchParam}`
  );

  const data: IReactQueryResponse = {
    results: response.data.results,
    next:
      response.data.last_page === 0 ||
      response.data.results.length === 0 ||
      response.data.last_page === pageParam
        ? undefined
        : pageParam + 1,
  };
  return data;
}

function useClients(searchParam = '') {
  return useInfiniteQuery<IReactQueryResponse, Error>(
    ['clients', searchParam],
    (queryKey) => getData({ pageParam: queryKey.pageParam, searchParam }),
    {
      getNextPageParam: (nextPage) => nextPage.next,
    }
  );
}

export { useClients };
