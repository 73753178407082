import { SwipeableDrawer } from '@material-ui/core';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { usePremium } from '../../hooks/usePremium';
import { MenuContent } from './MenuContent';

interface MenuProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  signOut: () => void;
}

export function Menu({ open, onClose, onOpen, signOut }: MenuProps) {
  const { pathname } = useLocation();
  const isPremium = usePremium();
  const history = useHistory();

  const handlePush = useCallback(
    (link?: string) => {
      if (!link) return;

      history.push(link);
    },
    [history]
  );

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      <MenuContent
        onClose={onClose}
        onPush={handlePush}
        onSignOut={signOut}
        isPremium={isPremium}
        pathname={pathname}
      />
    </SwipeableDrawer>
  );
}
