import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { Button } from '../../../../shared/components/Button';
import { InputPassword } from '../../../../shared/components/Form/Input/InputPassword';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { PageAnimation } from '../../components/Animation/PageAnimation';
import { Header } from '../../components/Header';

const schema = yup.object().shape({
  password: yup
    .string()
    .required('A senha é obrigatória.')
    .min(6, 'No mínimo 6 caracteres.'),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Confirmação incorreta'),
});

type FormValues = {
  password: string;
  password_confirmation: string;
};

function ResetPassword() {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();

  const { msgSuccess, msgError } = useSnackMessages();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      password_confirmation: '',
    },
    mode: 'onChange',
  });

  const onSubmit = useCallback(
    async ({ password, password_confirmation }: FormValues) => {
      setLoading(true);

      try {
        await api.post(`/password/reset?token=${token}`, {
          password,
          password_confirmation,
        });

        msgSuccess('Sua senha foi redefinida com sucesso!');

        history.push('/login');
      } catch (err: any) {
        setLoading(false);
        msgError(err?.response?.data?.message);
      }
    },
    [msgError, msgSuccess, history, token]
  );

  return (
    <PageAnimation>
      <Header
        title="Redefinir senha"
        link="/sign-in"
        linkName="Fazer login"
        mb={4}
      />

      <Box
        component="form"
        display="flex"
        flexDirection="column"
        gridGap={8}
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputPassword
          name="password"
          control={control}
          error={errors}
          variant="outlined"
          label="Digite sua nova senha"
          required
          disabled={loading}
        />
        <InputPassword
          name="password_confirmation"
          control={control}
          error={errors}
          variant="outlined"
          label="Repita sua nova senha"
          required
          disabled={loading}
        />

        <Button
          type="submit"
          loading={loading}
          disabled={loading}
          style={{ width: '100%' }}
        >
          Redefinir
        </Button>
      </Box>
    </PageAnimation>
  );
}

export { ResetPassword };
