import { Box, CircularProgress, useMediaQuery } from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';

import { Button } from '../../../../shared/components/Button';
import ButtonLoadMore from '../../../../shared/components/Button/ButtonLoadMore/intex';
import { SearchForm } from '../../../../shared/components/Form/SearchForm';
import { Header } from '../../../../shared/components/Header';
import { NoData } from '../../../../shared/components/NoData';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { useClients } from '../../../../shared/hooks/reactQuery/useClients';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { ClientTable } from '../../components/ClientTable';
import { CreateClientModal } from '../../components/CreateClientModal';
import { EditClientModal } from '../../components/EditClientModal';
import { City, ClientData } from './types';

type IResponseData = {
  clients: ClientData[];
  last_page: number;
};

export function Client() {
  const isNotMobile = useMediaQuery('(min-width:600px)');
  const { msgError, msgSuccess } = useSnackMessages();

  const [searchValue, setSearchValue] = useState('');
  const [defaultSearchValue, setDefaultSearchValue] = useState('');

  const [cities, setCities] = useState<City[]>([]);
  const [openModalCreateClient, setOpenModalCreateClient] = useState(false);

  const [selectedClient, setSelectedClient] = useState<
    ClientData | undefined
  >();
  const [openModalEditClient, setOpenModalEditClient] = useState(false);
  const [openModalViewClient, setOpenModalViewClient] = useState(false);

  const {
    data,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    isFetching,
  } = useClients(searchValue);

  useEffect(() => {
    if (searchValue) {
      refetch();
    }
  }, [refetch, searchValue]);

  useEffect(() => {
    api
      .get<{ cities: City[] }>('/cities')
      .then((response) => setCities(response.data?.cities))
      .catch((err) => {
        msgError(err?.response?.data?.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      setSearchValue('');
      setDefaultSearchValue('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = useCallback(async (searchValue: string) => {
    setSearchValue(searchValue);
    setDefaultSearchValue(searchValue);
  }, []);

  const handleSearchClear = useCallback(() => {
    setSearchValue('');
    setDefaultSearchValue('');
    refetch();
  }, [refetch]);

  const handleDeleteClient = useCallback(
    async (id: number) => {
      try {
        await api.delete(`/clients/${id}`);

        refetch();

        msgSuccess('Cliente deletado com sucesso!');
      } catch (err: any) {
        msgError(err?.response?.data?.message);
      }
    },
    [msgError, msgSuccess, refetch]
  );

  const handleCloseCreateClientModal = useCallback(() => {
    setOpenModalCreateClient(false);
  }, []);

  const handleCloseSuccessCreateClientModal = useCallback(() => {
    setOpenModalCreateClient(false);
    setSearchValue('');
    setDefaultSearchValue('');
    refetch();
  }, [refetch]);

  const handleOpenEditClientModal = useCallback((client: ClientData) => {
    setSelectedClient(client);
    setOpenModalEditClient(true);
  }, []);

  const handleOpenViewClientModal = useCallback((client: ClientData) => {
    setOpenModalViewClient(true);
    setSelectedClient(client);
    setOpenModalEditClient(true);
  }, []);

  const handleCloseEditClientModal = useCallback(() => {
    setSelectedClient(undefined);
    setOpenModalViewClient(false);
    setOpenModalEditClient(false);
  }, []);

  const handleCloseSuccessEditClientModal = useCallback(() => {
    setSelectedClient(undefined);
    setOpenModalEditClient(false);
    setOpenModalViewClient(false);
    setSearchValue('');
    setDefaultSearchValue('');
    refetch();
  }, [refetch]);

  return (
    <PageContainer display="flex" flexDirection="column">
      <Header
        goPush="/"
        title="Lista de clientes"
        isLoading={isFetching}
        search={
          <SearchForm
            defaultValue={defaultSearchValue}
            loading={isFetching}
            searchValue={(value) => handleSearch(value)}
            searchClear={handleSearchClear}
            // disabled={loading || !clients.length}
          />
        }
      >
        <Button
          size="medium"
          variant="contained"
          color="primary"
          startIcon={<AddOutlined />}
          onClick={() => setOpenModalCreateClient(true)}
        >
          Adicionar {isNotMobile && 'Cliente'}
        </Button>
      </Header>

      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        position="relative"
      >
        {status === 'loading' && (
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            top={0}
            left={0}
            minHeight="30vh"
          >
            <CircularProgress />
          </Box>
        )}

        {data &&
          data.pages.map((group, index) => (
            <React.Fragment key={index}>
              <ClientTable
                data={group.results}
                onDelete={(id) => handleDeleteClient(id)}
                onEdit={(client) => handleOpenEditClientModal(client)}
                onView={(client) => handleOpenViewClientModal(client)}
              />
            </React.Fragment>
          ))}

        {status !== 'loading' &&
          !data?.pages[0].results.length &&
          searchValue && (
            <NoData
              title="Ops! Nenhum registro encontrado."
              description="Sua pesquisa não encontrou nenhum cliente. Limpe a busca ou tente uma nova pesquisa."
            />
          )}

        {status === 'error' && !data?.pages[0].results.length && (
          <NoData title="Ops! Ocorreu um erro." description={error?.message} />
        )}

        {status !== 'loading' &&
          !data?.pages[0].results.length &&
          !searchValue && (
            <NoData
              title="Ops! Nenhum cliente registrado."
              description="Seus clientes aparecerão aqui. Adicione o seu primeiro cliente clicando no botão abaixo:"
            >
              <Box mb={3} />
              <Button
                size="medium"
                variant="contained"
                color="primary"
                startIcon={<AddOutlined />}
                onClick={() => setOpenModalCreateClient(true)}
              >
                Adicionar {isNotMobile && 'Cliente'}
              </Button>
            </NoData>
          )}
      </Box>

      {hasNextPage && (
        <ButtonLoadMore
          disabled={!hasNextPage || isFetchingNextPage}
          loadMore={() => fetchNextPage()}
          loading={isFetchingNextPage}
        />
      )}

      <CreateClientModal
        cities={cities}
        isOpen={openModalCreateClient}
        onClose={handleCloseCreateClientModal}
        onCloseSuccess={handleCloseSuccessCreateClientModal}
      />

      {selectedClient && (
        <EditClientModal
          client={selectedClient}
          cities={cities}
          isOpen={openModalEditClient}
          onClose={handleCloseEditClientModal}
          onCloseSuccess={handleCloseSuccessEditClientModal}
          isView={openModalViewClient}
        />
      )}
    </PageContainer>
  );
}
