import { Box, BoxProps, makeStyles, Theme } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PriorityHighOutlinedIcon from '@material-ui/icons/PriorityHighOutlined';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    background: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    cursor: 'pointer',
    border: `1px solid #42424220`,
  },
  icon: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

const colors = {
  UNDELIVERED: '#B54D54',
  PENDING_PAYMENT: '#fbe20c',
  PAYMENT_MADE: '#22BC44',
};

interface CardSalesScheduleProps extends BoxProps {
  day?: string | number;
  month?: string;
  name?: string;
  eventName?: string;
  phone?: string;
  value?: number | string;
  status?: 'UNDELIVERED' | 'PENDING_PAYMENT' | 'PAYMENT_MADE';
}

export function CardSalesSchedule({
  day,
  month,
  name,
  eventName,
  phone,
  value,
  status = 'UNDELIVERED',
  ...rest
}: CardSalesScheduleProps) {
  const classes = useStyles();

  return (
    <Box className={classes.container} {...rest}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        color="secondary.main"
      >
        <Box className={classes.icon} color={colors[status]}>
          {status === 'UNDELIVERED' && <PriorityHighOutlinedIcon />}
          {status === 'PENDING_PAYMENT' && <DoneIcon />}
          {status === 'PAYMENT_MADE' && <DoneAllIcon />}
        </Box>

        <Box fontSize="1.3rem" fontWeight="bold">
          {day}
        </Box>
        <Box fontSize="0.88rem" borderTop="1px solid #eee">
          {month}
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        borderLeft={`1px solid ${colors[status]}`}
        color="secondary.main"
        ml={2}
        pl={1}
      >
        <Box display="flex" flexDirection="column" mb={2}>
          <Box
            component="strong"
            display="inline-flex"
            alignItems="center"
            fontSize="1rem"
          >
            <PersonOutlineOutlinedIcon
              fontSize="small"
              style={{ marginRight: 4 }}
            />
            <span>{name}</span>
          </Box>
          <Box component="small" fontSize="0.78rem" ml={3}>
            <strong>Telefone:</strong> {phone}
          </Box>
          <Box component="small" fontSize="0.78rem" ml={3}>
            {eventName && (
              <>
                <strong>Nome do Evento: </strong>
                {eventName}
              </>
            )}
          </Box>
        </Box>
        <Box fontSize="1.3rem" fontWeight="bold" ml={3}>
          R$ {value}
        </Box>
      </Box>
    </Box>
  );
}
