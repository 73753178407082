import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function ViviTvIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M21.4286 19.2901H2.57143C1.70714 19.2901 1 18.5828 1 17.7183V3.57183C1 2.70732 1.70714 2 2.57143 2H16.3214V2.78591H2.57143C2.13929 2.78591 1.78571 3.13958 1.78571 3.57183V17.7183C1.78571 18.1505 2.13929 18.5042 2.57143 18.5042H21.4286C21.8607 18.5042 22.2143 18.1505 22.2143 17.7183V3.57183C22.2143 3.13958 21.8607 2.78591 21.4286 2.78591H18.6786V2H21.4286C22.2929 2 23 2.70732 23 3.57183V17.7183C23 18.5828 22.2929 19.2901 21.4286 19.2901Z" />
      <path d="M7.67858 23.2197C6.81429 23.2197 6.10715 22.5124 6.10715 21.6479C6.10715 20.7834 6.81429 20.076 7.67858 20.076C8.54286 20.076 9.25 20.7834 9.25 21.6479C9.25 22.5124 8.54286 23.2197 7.67858 23.2197ZM7.67858 20.862C7.24643 20.862 6.89286 21.2156 6.89286 21.6479C6.89286 22.0801 7.24643 22.4338 7.67858 22.4338C8.11072 22.4338 8.46429 22.0801 8.46429 21.6479C8.46429 21.2156 8.11072 20.862 7.67858 20.862Z" />
      <path d="M6.49999 21.2549H1V22.0408H6.49999V21.2549Z" />
      <path d="M23 21.2549H11.6071V22.0408H23V21.2549Z" />
      <path d="M10.8214 21.2549H10.0357V22.0408H10.8214V21.2549Z" />
      <path d="M17.8928 2H17.1071V2.78591H17.8928V2Z" />
      <path d="M6.49999 8.28731C6.26428 8.28731 6.02856 8.16943 5.87142 7.97295L5.75357 7.77647C5.63571 7.81577 5.47857 7.85506 5.32142 7.85506C4.65357 7.85506 4.14285 7.34422 4.14285 6.67619C4.14285 5.22225 5.87142 3.53253 8.07142 3.53253C9.32856 3.53253 9.56428 4.31845 9.72142 4.90788C9.8 5.14366 9.83927 5.34014 9.95713 5.53661C10.0357 5.6545 10.0357 5.77239 10.0357 5.92957C10.0357 6.28323 9.79999 6.5976 9.44642 6.67619C8.03213 6.99056 7.12856 8.01224 7.08928 8.01224C6.93214 8.20872 6.73571 8.28731 6.49999 8.28731ZM5.91071 6.71549L6.49999 7.5014C6.53928 7.4621 7.56071 6.32253 9.24999 5.92957C9.09285 5.6545 9.01428 5.37943 8.93571 5.14366C8.77857 4.59352 8.69999 4.35774 8.07142 4.35774C6.38214 4.35774 4.92857 5.6545 4.92857 6.71549C4.92857 6.95126 5.08571 7.10844 5.32142 7.10844C5.39999 7.10844 5.51786 7.06915 5.59643 6.99056L5.91071 6.71549Z" />
      <path d="M9.44641 15.3605C9.17141 15.3605 8.89642 15.282 8.69999 15.1248L6.42142 13.5922L6.85356 12.9242L9.13213 14.4567C9.28927 14.5353 9.4857 14.5746 9.64284 14.5353L12.1571 13.7494C12.3143 13.7101 12.4321 13.5529 12.4321 13.3565C12.4321 13.1207 12.1964 12.9242 12 12.9635L9.64284 13.3172L7.48213 11.8632C7.40356 11.7846 7.28571 11.7846 7.16785 11.7846H6.89285V10.9987H7.08928C7.36428 10.9987 7.63927 11.0773 7.8357 11.2345L9.76069 12.492L11.8035 12.1776C11.8428 12.1776 11.9214 12.1776 12 12.1776C12.6678 12.1776 13.1785 12.6884 13.1785 13.3565C13.1785 13.8673 12.8643 14.3389 12.3535 14.496L9.83927 15.282C9.72141 15.3212 9.60355 15.3605 9.44641 15.3605Z" />
      <path d="M8.07143 13.9852H7.28571V17.7183H8.07143V13.9852Z" />
      <path d="M4.92856 16.9324H4.14285V17.7183H4.92856V16.9324Z" />
      <path d="M7.6889 9.73338L7.24615 11.4858L8.00789 11.6784L8.45063 9.92593L7.6889 9.73338Z" />
      <path d="M19.8571 17.7183H11.2143V14.3388H12V16.9324H19.0714V14.5746C19.0714 14.3388 18.9143 14.1817 18.6786 14.1817H13.9643V13.3958H18.6786C19.3464 13.3958 19.8571 13.9066 19.8571 14.5746V17.7183Z" />
      <path d="M18.6786 13.7887H17.8929V11.038C17.8929 10.8022 17.7357 10.645 17.5 10.645H13.5714C13.3357 10.645 13.1786 10.8022 13.1786 11.038V13.3957H12.3929V11.038C12.3929 10.37 12.9036 9.85913 13.5714 9.85913H17.5C18.1679 9.85913 18.6786 10.37 18.6786 11.038V13.7887Z" />
      <path d="M17.8928 10.2521H17.1071V8.28733C17.1071 8.05156 16.95 7.89437 16.7143 7.89437H14.3571C14.1214 7.89437 13.9643 8.05156 13.9643 8.28733V10.2521H13.1786V8.28733C13.1786 7.6193 13.6893 7.10846 14.3571 7.10846H16.7143C17.3821 7.10846 17.8928 7.6193 17.8928 8.28733V10.2521Z" />
      <path d="M17.1071 11.431H12.7857V12.2169H17.1071V11.431Z" />
      <path d="M20.6429 16.9324H19.4643V17.7183H20.6429V16.9324Z" />
      <path d="M11.6071 16.9324H10.4286V17.7183H11.6071V16.9324Z" />
      <path d="M18.2857 16.1464C17.775 16.1464 17.3036 15.9893 16.9107 15.7142C16.125 16.3036 14.9464 16.3036 14.1607 15.7142C13.3357 16.3036 12.1964 16.3036 11.3714 15.6749L11.8429 15.0462C12.4321 15.5177 13.3357 15.4391 13.8857 14.9283L14.1607 14.6532L14.4357 14.9283C15.025 15.5177 16.0464 15.5177 16.6357 14.9283L16.9107 14.6532L17.1857 14.9283C17.7357 15.4784 18.6393 15.5177 19.2286 15.0462L19.7 15.6749C19.3071 15.9893 18.7964 16.1464 18.2857 16.1464Z" />
      <path d="M14.75 3.57184H13.9643V5.53662H14.75V3.57184Z" />
      <path d="M17.6861 4.33376L16.9957 6.08905L17.7269 6.37677L18.4172 4.62149L17.6861 4.33376Z" />
      <path d="M20.9754 6.22052L19.7254 7.47089L20.281 8.02661L21.531 6.77624L20.9754 6.22052Z" />
      <path d="M8.07143 10.2521C6.97143 10.2521 6.10715 9.38759 6.10715 8.28731V7.89435H6.89286V8.28731C6.89286 8.95534 7.40357 9.46618 8.07143 9.46618C8.73929 9.46618 9.25 8.95534 9.25 8.28731V5.92957H10.0357V8.28731C10.0357 9.38759 9.17143 10.2521 8.07143 10.2521Z" />
      <path d="M4.92857 16.1465H4.14285V15.3605C4.14285 12.4134 5.20356 10.9987 5.6357 10.5272C5.75356 10.4093 5.83214 10.3307 5.87142 10.2128L6.42142 9.03394L7.12857 9.3483L6.57857 10.5272C6.5 10.7237 6.38214 10.8808 6.18571 11.0773C5.79286 11.4703 4.88928 12.7277 4.88928 15.3605V16.1465H4.92857Z" />
    </SvgIcon>
  );
}
