import { Box, Container, makeStyles, Theme } from '@material-ui/core';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { YourRegistration } from '../../../modules/profile/components/YourRegistration';
import { BottomNavigation } from '../../components/BottomNavigation';
import { RenderLottie } from '../../components/Lottie';
import { TopNavigation } from '../../components/TopNavigation';
import { useAuth } from '../../hooks/useAuth';
import { useLottieAlert } from '../../hooks/useLottieAlert';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(10),
    },
  },
}));

interface DefaultLayoutProps {
  pageName?: string;
  children?: ReactNode;
}

const DefaultLayout: FC<DefaultLayoutProps> = ({ pageName, children }) => {
  const classes = useStyles();
  const { signOutRedirect } = useAuth();
  const history = useHistory();
  const { pathname } = useLocation();
  const { isOpen, description, lottie, onLottieClose } = useLottieAlert();

  const handleNavigation = useCallback(
    (value: string) => {
      if (pathname === value) return;

      history.push(value);
    },
    [history, pathname]
  );

  const signOut = useCallback(() => {
    signOutRedirect();
  }, [signOutRedirect]);

  return (
    <>
      <Box
        width="100%"
        height="100%"
        minHeight="100vh"
        display="flex"
        flexDirection="column"
      >
        <TopNavigation pageName={pageName} signOut={signOut} />

        <Container maxWidth={false}>
          <Box className={classes.root}>{children}</Box>
        </Container>

        <BottomNavigation pathname={pathname} onNavigate={handleNavigation} />
      </Box>

      {/* Modal Your Registration | module/profile/YourRegistration/index.tsx */}
      <YourRegistration />

      {/* Animation in Lottie */}
      <RenderLottie
        lottie={lottie}
        description={description}
        isOpen={isOpen}
        onClose={onLottieClose}
      />
    </>
  );
};

export { DefaultLayout };
