/* eslint-disable no-restricted-globals */
import {
  Box,
  Divider,
  Fade,
  Link,
  Menu,
  MenuItem,
  useMediaQuery,
} from '@material-ui/core';
import {
  DeleteOutlined,
  MoreVertOutlined,
  CloudDownloadOutlined,
  EditOutlined,
  WhatsApp,
  Close as CloseIcon,
} from '@material-ui/icons';
import Axios from 'axios';
import fileDownload from 'js-file-download';
import { useState, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';

import pdfImg from '../../../../shared/assets/pdf-1.png';
import { IconButton } from '../../../../shared/components/IconButton';
import Iconify from '../../../../shared/components/Iconify';
import { Modal } from '../../../../shared/components/Modal';
import { IBudgetProps } from '../../../../shared/hooks/reactQuery/useBudgets/types';
import { useBudget } from '../../../../shared/hooks/useBudget';
import { BudgetCreate } from '../../pages/BudgetCreate';

interface BudgetListProps {
  data: IBudgetProps;
  onDelete?: (data: any) => void;
}

export function BudgetList({ data, onDelete }: BudgetListProps) {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectData, setSelectData] = useState<null | IBudgetProps>(null);

  const isDesktop = useMediaQuery('(min-width:600px)');
  const [url, setUrl] = useState('');
  const [idBudget, setIdBudget] = useState('');
  const [fileBudget, setFileBudget] = useState('');

  const { updateBudgetData } = useBudget();

  const [openEdit, setOpenEdit] = useState(false);

  const handleEdit = () => {
    if (selectData) {
      updateBudgetData(selectData);
    }
    history.push('/budget/edit');
  };
  const handleDuplicate = () => {
    if (selectData) {
      updateBudgetData(selectData);
    }
    history.push('/budgets/create');
  };

  const handleOnClose = () => {
    setOpenEdit(false);
  };

  const handleOpenOptions = (
    event: MouseEvent<HTMLButtonElement>,
    data: any
  ) => {
    setUrl(data.file_url);
    setIdBudget(data.id);
    setFileBudget(data.file);
    setSelectData(data);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    if (!selectData || !onDelete) return;
    setAnchorEl(null);

    onDelete(selectData);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  function download(url: string, filename: string) {
    Axios.get(url, {
      responseType: 'blob',
    }).then((res) => {
      fileDownload(res.data, filename);
    });
  }

  function sharedWhatsApp(phone: string) {
    const clearNumber = phone.replace(/[^0-9]/, '');
    const find55 = clearNumber.slice(0, 2) === '55' ? '55' : '';
    const parseNumber = clearNumber.replace(find55, '');

    const message = `https://wa.me/+55${parseNumber}/?text=Para%20visualizar%20o%20PDF%20clique%20no%20link:%20${process.env.REACT_APP_URL}/orcamento/${idBudget}/${fileBudget}`;

    window.open(message);
  }

  return (
    <>
      <Box
        bgcolor="background.paper"
        p={2}
        borderRadius={8}
        display="flex"
        alignItems="center"
        boxShadow="0px 0px 10px rgba(0, 0, 0, 0.15);"
      >
        <Link href={data?.file_url} target="_blank" color="inherit">
          <Box mr={2} style={{ cursor: 'pointer' }}>
            <img src={pdfImg} alt="PDF" width="60px" />
          </Box>
        </Link>
        <Box display="flex" flexDirection="column" flex={1}>
          <Box fontWeight="bold" fontSize="1rem" color="primary.main">
            {data.client.name}
          </Box>
          <Box mb={2} fontWeight="bold" color="primary.main">
            {data.value}
          </Box>

          <Box color="secondary.main">{data.created_at}</Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" color="secondary.main">
          <IconButton
            aria-controls="menu-options-table"
            aria-haspopup="true"
            color="inherit"
            onClick={(event) => handleOpenOptions(event, data)}
          >
            <MoreVertOutlined color="inherit" />
          </IconButton>
        </Box>
      </Box>

      <Menu
        id="menu-options-table"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseOptions}
        TransitionComponent={Fade}
      >
        {/* {isDesktop && (
          <MenuItem onClick={handleView}>
            <RemoveRedEyeOutlined fontSize="small" color="inherit" />
            <Box ml={1}>Visualizar</Box>
          </MenuItem>
        )} */}

        {isDesktop && selectData?.file_url && (
          <Link href={selectData?.file_url} target="_blank" color="inherit">
            <MenuItem>
              <CloudDownloadOutlined fontSize="small" color="inherit" />
              <Box ml={1}>Visualizar</Box>
            </MenuItem>
          </Link>
        )}

        <MenuItem
          onClick={() =>
            download(
              selectData?.file_url || '',
              `Orçamento-${selectData?.client.name}.pdf`
            )
          }
        >
          <CloudDownloadOutlined fontSize="small" color="inherit" />
          <Box ml={1}>Baixar</Box>
        </MenuItem>

        <MenuItem
          onClick={() => sharedWhatsApp(selectData?.client.phone || '')}
        >
          <WhatsApp fontSize="small" color="inherit" />
          <Box ml={1}>WhatsApp</Box>
        </MenuItem>

        <Divider style={{ marginBottom: 8 }} />

        <MenuItem onClick={() => handleEdit()}>
          <EditOutlined fontSize="small" color="inherit" />
          <Box ml={1}>Editar</Box>
        </MenuItem>

        <MenuItem onClick={() => handleDuplicate()}>
          <Iconify icon="ic:baseline-content-copy" />
          <Box ml={1}>Duplicar</Box>
        </MenuItem>

        <MenuItem onClick={handleDelete}>
          <DeleteOutlined fontSize="small" color="inherit" />
          <Box ml={1}>Deletar</Box>
        </MenuItem>
      </Menu>
    </>
  );
}
