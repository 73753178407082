import { Box } from '@material-ui/core';

type TableRowItemScheduleData = {
  title: string;
  value?: string | number | React.ReactNode;
  title2: string;
  value2?: string | number | React.ReactNode;
};

export function TableRowItemSchedule({
  title,
  value,
  title2,
  value2,
}: TableRowItemScheduleData) {
  if (!value) return <></>;

  return (
    <Box display="flex" flexDirection="row" alignItems="center" width="100%">
      <Box fontSize="0.88rem" fontWeight="bold" color="secondary.dark">
        {title}:
      </Box>
      <Box ml={1}>{value}</Box>
      <Box ml={4} fontSize="0.88rem" fontWeight="bold" color="secondary.dark">
        {title2}:
      </Box>
      <Box ml={1}>{value2}</Box>
    </Box>
  );
}
