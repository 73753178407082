import { useQuery } from 'react-query';

import { SalesAdjustment } from '../../../../modules/records/pages/SalesAdjustments/types';
import { api } from '../../../services/apiClient';
// import { assertIsSimplesDataResponse } from '../assertIsSimplesDataResponse';

type IReactQueryResponse = {
  result: SalesAdjustment;
};

async function getData() {
  const response = await api.get<IReactQueryResponse>('sales/sales-adjustment');

  // assertIsSimplesDataResponse(response);

  const data: IReactQueryResponse = {
    result: response.data?.result,
  };
  return data;
}

function useSalesAdjustments() {
  return useQuery<IReactQueryResponse, Error>('sales-adjustment', getData);
}

export { useSalesAdjustments };
