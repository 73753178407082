import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../shared/components/Button';

const useStyles = makeStyles((theme: Theme) => ({
  help: {
    borderRadius: theme.spacing(1),
    background: '#66666621',
    color: theme.palette.secondary.main,
  },
  button: {
    height: 56,
    marginTop: 16,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

interface CancelSubscriptionProps {
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onClick: () => void;
}

export function CancelSubscription({
  isOpen,
  isLoading = false,
  onClose,
  onClick,
}: CancelSubscriptionProps) {
  const history = useHistory();
  const classes = useStyles();

  const handleClose = () => {
    if (isLoading) return;

    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title-cancel-subscription"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title-cancel-subscription">
        Cancelar Assinatura!
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Box color="primary.main" textAlign="center" fontSize="1.2rem">
            ATENÇÃO !
          </Box>
          <Box textAlign="center">
            Faça Backup dos seus dados antes de cancelar sua assinatura para não
            perder os seus cadastros de Clientes, Produtos, Fichas Técnicas e
            Orçamentos !
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          width="100%"
        >
          <Button
            style={{ width: '100%' }}
            onClick={() => history.push('/utilities/backup')}
            disabled={isLoading}
          >
            Ir para a tela de Backups
          </Button>

          <Box mt={2} />
          <Button
            color="primary"
            className={classes.button}
            style={{ width: '100%' }}
            variant="outlined"
            onClick={onClick}
            disabled={isLoading}
          >
            {!isLoading
              ? 'Já salvei meus Backups, desejo cancelar minha assinatura'
              : 'Cancelando assinatura...'}
          </Button>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            autoFocus
            className={classes.button}
            style={{ width: '100%' }}
          >
            Continuar Premium
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
