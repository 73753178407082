import { Box, BoxProps, Typography, Link, Button } from '@material-ui/core';
import { motion } from 'framer-motion';
import React, { useContext } from 'react';

import { ThemeCustomContext } from '../../contexts/ThemeCustomContext';

interface FooterProps extends BoxProps {
  name?: string;
  version?: string;
}

export const Footer: React.FC<FooterProps> = ({ name, version, ...rest }) => {
  const { isDark } = useContext(ThemeCustomContext);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      {...rest}
    >
      <motion.div
        animate={{ opacity: [0, 1], translateY: [10, 0] }}
        transition={{ duration: 0.5 }}
      >
        <Box
          color="secondary.main"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          width={1}
        >
          <Typography component="div">
            {name && <Box fontSize="body2.fontSize">{name}</Box>}
            {version && (
              <Box fontSize="caption.fontSize" fontWeight="fontWeightMedium">
                Versão {version}
              </Box>
            )}
          </Typography>

          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => {
              window.location.reload();
            }}
          >
            Recarregar
          </Button>
        </Box>
      </motion.div>
    </Box>
  );
};
