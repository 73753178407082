import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function YourRegistrationIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M16.4268 10.6949C15.0103 10.5458 13.9292 9.35254 13.9292 7.8983C13.9292 7.45085 14.041 7.04068 14.2274 6.63051L15.383 4.31864C14.9357 3.98305 14.6747 3.42373 14.6747 2.86441C14.6747 1.82034 15.4949 1 16.5387 1C17.5825 1 18.4026 1.82034 18.4026 2.86441C18.4026 3.16271 18.328 3.42373 18.2162 3.68474L17.5452 3.34915C17.6198 3.2 17.657 3.01356 17.657 2.86441C17.657 2.23051 17.1724 1.74576 16.5387 1.74576C15.9049 1.74576 15.4203 2.23051 15.4203 2.86441C15.4203 3.27458 15.644 3.68474 16.0541 3.87118L16.3896 4.02034L14.8984 6.9661C14.7493 7.26441 14.6747 7.56271 14.6747 7.8983C14.6747 8.94237 15.4576 9.83729 16.5014 9.94915L16.4268 10.6949Z" />
      <path d="M20.6393 5.62375L20.1547 5.06443C20.4529 4.76613 20.6393 4.39324 20.6393 3.94579C20.6393 3.12545 19.9683 2.45426 19.1482 2.45426C18.8127 2.45426 18.4772 2.56612 18.2162 2.78985L17.7689 2.19324C18.1789 1.89494 18.6635 1.7085 19.1482 1.7085C20.3784 1.7085 21.3849 2.71528 21.3849 3.94579C21.3849 4.61697 21.1239 5.21358 20.6393 5.62375Z" />
      <path d="M18.8872 8.4576C18.8872 8.4576 17.9552 6.59319 15.1966 5.66099L15.4203 4.95251C18.5144 5.99658 19.5209 8.04743 19.5582 8.1593L18.8872 8.4576Z" />
      <path d="M22.5032 23H21.7576V15.6543C21.7576 14.1627 20.863 12.8204 19.4836 12.261L18.3653 11.7763C17.6943 11.5153 17.2842 10.8441 17.2842 10.1356V9.8746L17.5079 9.76274C17.9552 9.5763 18.328 9.2407 18.5517 8.79325L19.5582 6.77969L19.931 7.07799C20.1919 7.30172 20.5647 7.3763 20.9002 7.30172C21.273 7.22714 21.6085 6.89155 21.7204 6.51867C21.7949 6.18308 21.7576 5.84748 21.5713 5.58647C21.3849 5.32545 21.1239 5.13901 20.7884 5.10172L20.9002 4.35596C21.4221 4.43053 21.9068 4.72884 22.205 5.1763C22.5032 5.62375 22.5778 6.18308 22.4659 6.70511C22.3168 7.33901 21.7576 7.86104 21.1239 8.0102C20.7138 8.12206 20.2665 8.04748 19.8937 7.89833L19.26 9.12884C18.999 9.65087 18.589 10.061 18.1043 10.3221C18.1789 10.6577 18.4026 10.9187 18.7008 11.0305L19.8192 11.5153C21.4594 12.2238 22.5405 13.8271 22.5405 15.617V23H22.5032Z" />
      <path d="M20.2665 15.1695H19.5209V21.5085H20.2665V15.1695Z" />
      <path d="M13.9292 16.2881H13.1836V23H13.9292V16.2881Z" />
      <path d="M11.6924 19.2712C11.6179 19.2712 11.5433 19.2712 11.4688 19.2339L6.84621 18.4881C6.21248 18.3763 5.76514 17.8542 5.76514 17.1831C5.76514 16.4746 6.36159 15.878 7.06989 15.878C7.10717 15.878 7.14444 15.878 7.18172 15.878L10.7977 16.1763L12.1025 13.2678C12.5126 12.3729 13.2581 11.9254 13.9291 11.5525C14.6747 11.1424 15.0848 10.8814 15.0848 10.2475V9.94916H15.8304V10.2848C15.8304 11.3661 15.0102 11.8509 14.3019 12.261C13.7055 12.5966 13.109 12.9322 12.8108 13.6034L11.2824 16.9966L7.06989 16.661C6.77166 16.661 6.51071 16.922 6.51071 17.2203C6.51071 17.4814 6.6971 17.7424 6.99533 17.7797L11.6179 18.5254C11.8788 18.5627 12.177 18.4509 12.3634 18.1898L13.2954 16.8102L13.9291 17.2203L12.9972 18.6C12.6617 19.0102 12.1771 19.2712 11.6924 19.2712Z" />
      <path d="M16.4641 12.9322C16.054 12.9322 15.644 12.7458 15.383 12.4102L14.6747 11.5153L15.2712 11.0678L15.9795 11.9627C16.1659 12.1865 16.5386 12.261 16.7996 12.1119L18.2907 11.1424L18.7008 11.7763L17.2096 12.7458C16.986 12.8576 16.725 12.9322 16.4641 12.9322Z" />
      <path d="M16.5386 17.7797C15.4948 17.7797 14.6747 16.9593 14.6747 15.9152C14.6747 14.8712 15.4948 14.0508 16.5386 14.0508C17.5824 14.0508 18.4026 14.8712 18.4026 15.9152C18.4026 16.9593 17.5824 17.7797 16.5386 17.7797ZM16.5386 14.7966C15.9049 14.7966 15.4203 15.2814 15.4203 15.9152C15.4203 16.5491 15.9049 17.0339 16.5386 17.0339C17.1724 17.0339 17.657 16.5491 17.657 15.9152C17.657 15.2814 17.1724 14.7966 16.5386 14.7966Z" />
      <path d="M18.0298 20.539L16.5387 19.2339L15.0475 20.539V16.8848H15.7931V18.8983L16.5387 18.2644L17.2842 18.8983V16.8848H18.0298V20.539Z" />
      <path d="M7.31991 5.80961L7.22739 6.54962L7.89323 6.63292L7.98576 5.8929L7.31991 5.80961Z" />
      <path d="M10.5741 8.45766C10.5368 8.45766 10.4622 8.45766 10.425 8.45766L4.4604 7.7119C3.90122 7.63732 3.49115 7.15258 3.49115 6.59325C3.49115 5.95935 3.97577 5.47461 4.60951 5.47461C4.64679 5.47461 4.72134 5.47461 4.75862 5.47461L6.47344 5.69834L6.39888 6.4441L4.68407 6.22037H4.64679C4.42312 6.22037 4.274 6.36952 4.274 6.59325C4.274 6.77969 4.42311 6.92885 4.60951 6.96613L10.5741 7.7119H10.6114C10.835 7.7119 10.9841 7.56274 10.9841 7.33902C10.9841 7.15258 10.835 7.00342 10.6486 6.96613L8.74743 6.74241L8.82198 5.99664L10.7232 6.22037C11.2824 6.29495 11.6924 6.77969 11.6924 7.33902C11.6924 7.97291 11.2078 8.45766 10.5741 8.45766Z" />
      <path d="M7.03262 14.4237C5.09413 14.4237 3.49115 12.8203 3.49115 10.8814C3.49115 10.7322 3.49115 10.583 3.52843 10.4339L3.9385 7.22711L4.68407 7.30169L4.274 10.5085C4.274 10.6203 4.23672 10.7322 4.23672 10.8441C4.23672 12.3729 5.50419 13.6407 7.03262 13.6407C8.44921 13.6407 9.64212 12.5966 9.79123 11.1797L10.2013 7.97287L10.9469 8.04745L10.5368 11.2542C10.3131 13.0814 8.82199 14.4237 7.03262 14.4237Z" />
      <path d="M7.03263 12.9322C6.02611 12.9322 5.16871 12.1865 5.01959 11.1797L5.76516 11.0678C5.83972 11.7017 6.3989 12.1865 7.06991 12.1865C7.21903 12.1865 7.33087 12.1492 7.47998 12.1119L7.70364 12.8204C7.44269 12.8949 7.2563 12.9322 7.03263 12.9322Z" />
      <path d="M3.82665 11.6644C3.19291 11.5898 2 10.844 2 8.83048C2 8.42031 2.33551 8.08472 2.74557 8.08472C2.78285 8.08472 2.82013 8.08472 2.82013 8.08472L4.16215 8.23387L4.0876 8.97963L2.74557 8.83048C2.74557 10.7322 3.93849 10.9186 3.93849 10.9186L3.82665 11.6644Z" />
      <path d="M9.94034 12.4847V12.1118V11.739C9.97762 11.739 11.2451 11.7017 11.6924 10.0237L10.3504 9.83725L10.4622 9.09149L11.8043 9.27793C11.8415 9.27793 11.8788 9.27793 11.8788 9.27793C12.2889 9.3898 12.5126 9.79997 12.4007 10.1728C11.9161 12 10.6114 12.4847 9.94034 12.4847Z" />
      <path d="M8.33736 13.9017H7.5918V16.3627H8.33736V13.9017Z" />
      <path d="M6.13793 17.1831L4.87046 17.0339C4.49767 16.9966 4.19943 16.661 4.19943 16.2881C4.19943 16.2136 4.19943 16.1017 4.23671 16.0271L5.1314 13.5288L5.83969 13.7898L4.98229 16.2881L6.24976 16.4373L6.13793 17.1831Z" />
      <path d="M20.2665 22.2542H19.5209V23H20.2665V22.2542Z" />
    </SvgIcon>
  );
}
