import { IconButton, IconButtonProps } from '@material-ui/core';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import { motion } from 'framer-motion';

interface ButtonToggleThemeProps extends IconButtonProps {
  onClick: () => void;
  isThemeDark: boolean;
}

function ButtonToggleTheme({
  onClick,
  isThemeDark,
  ...rest
}: ButtonToggleThemeProps) {
  const icon = isThemeDark ? (
    <Brightness7Icon fontSize="small" />
  ) : (
    <Brightness3Icon fontSize="small" />
  );
  const currentTitle = isThemeDark
    ? 'Clique no ícone do Sol para mudar para o tema Light'
    : 'Clique no ícone da lua para mudar para o tema escuro ';
  return (
    <motion.div
      className="animatable"
      whileTap={{ scale: 0.85 }}
      style={{ display: 'flex' }}
    >
      <IconButton
        edge="end"
        aria-label="mode"
        onClick={onClick}
        title={currentTitle}
        {...rest}
      >
        {icon}
      </IconButton>
    </motion.div>
  );
}

export { ButtonToggleTheme };
