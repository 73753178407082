import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Edit } from '@material-ui/icons';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '../../../../shared/components/Button';
import { Input } from '../../../../shared/components/Form/Input';
import { InputDatePicker } from '../../../../shared/components/Form/Input/InputDatePicker';
import { Select } from '../../../../shared/components/Form/Select';
import { Modal } from '../../../../shared/components/Modal';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { City, ClientData } from '../../pages/Client/types';
import { FormValues } from './types';

const schema = yup.object().shape({
  phone: yup
    .string()
    .required('O telefone é obrigatório.')
    .min(10, 'No mínimo 10 digitos.')
    .max(190, 'No máximo 20 digitos.'),
  name: yup.string().required('O nome é obrigatório.'),
  email: yup.string().nullable(),
  complement: yup.string(),
  birthday: yup.string().nullable(),
  comments: yup.string().nullable().max(250, 'No máximo 250 caracteres.'),
});

interface EditClientModalProps {
  client: ClientData;
  cities: City[];
  onClose: () => void;
  onCloseSuccess: () => void;
  isOpen: boolean;
  isView?: boolean;
}

export function EditClientModal({
  client,
  cities,
  onClose,
  onCloseSuccess,
  isOpen,
  isView = false,
}: EditClientModalProps) {
  const { msgSuccess, msgError } = useSnackMessages();

  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(true);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      phone: client.phone,
      birthday: client?.birthday
        ? moment(new Date(`${client.birthday} 13:00:00`)).format('YYYY-MM-DD')
        : null,
      city: cities.find((city) => city.id === client?.city_id),
      complement: client.complement || '',
      comments: client.comments || '',
      email: client.email || '',
      name: client.name,
    },
    mode: 'onChange',
  });

  useEffect(() => {
    setIsEdit(!isView);
  }, [isView]);

  const onSubmit = useCallback(
    async ({
      name,
      email,
      phone,
      birthday,
      city,
      complement,
      comments,
    }: FormValues) => {
      setLoading(true);
      console.log(city);

      try {
        const formatData = {
          name,
          email: email || undefined,
          phone,
          birthday: birthday
            ? moment(new Date(birthday)).format('YYYY-MM-DD')
            : undefined,
          complement,
          city_id: city?.id || undefined,
          comments: comments || '',
        };

        await api.put(`/clients/${client.id}`, formatData);

        msgSuccess('Cliente atualizado com sucesso!');

        reset();
        setLoading(false);
        setIsEdit(true);
        onCloseSuccess();
      } catch (err: any) {
        setLoading(false);
        msgError(err?.response?.data?.message || err?.response?.data?.error);
      }
    },
    [msgError, msgSuccess, reset, onCloseSuccess, client.id]
  );

  const handleClose = () => {
    setIsEdit(true);
    onClose();
  };

  return (
    <Modal title="Editar Cliente" open={isOpen} onClose={handleClose}>
      {!isEdit && (
        <Box
          mb={3}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            startIcon={<Edit />}
            autoFocus
            size="large"
            color="primary"
            variant="outlined"
            onClick={() => setIsEdit(true)}
          >
            Clique aqui para editar
          </Button>
        </Box>
      )}
      <DialogContent dividers>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column" width="100%">
            <Input
              name="name"
              control={control}
              error={errors}
              variant="outlined"
              label="Nome do Cliente"
              fullWidth
              required
              disabled={loading || !isEdit}
            />
            <Box mb={2} />

            <Input
              name="phone"
              type="tel"
              control={control}
              error={errors}
              variant="outlined"
              label="Telefone (xx)nnnnn-nnnn"
              fullWidth
              required
              disabled={loading || !isEdit}
            />

            <Box mb={2} />

            <Input
              name="email"
              type="email"
              control={control}
              error={errors}
              variant="outlined"
              label="E-mail do Cliente"
              fullWidth
              disabled={loading || !isEdit}
            />
            <Box mb={2} />

            <InputDatePicker
              name="birthday"
              label="Data de aniversário"
              variant="outlined"
              control={control}
              error={errors}
              disabled={loading || !isEdit}
            />
            <Box mb={2} />

            {!!cities.length && (
              <Box display="flex" flexDirection="column" width="100%" mt={1}>
                <Box fontSize="0.85rem" color="secondary.light" mb={1}>
                  Endereço do Cliente
                </Box>

                <Input
                  name="complement"
                  type="text"
                  control={control}
                  error={errors}
                  variant="outlined"
                  label="Endereço"
                  fullWidth
                  disabled={loading || !isEdit}
                />

                <Box mb={2} />

                <Select
                  options={cities}
                  name="city"
                  control={control}
                  error={errors}
                  variant="outlined"
                  label="Cidade"
                  fullWidth
                  disabled={loading || !isEdit}
                  onClear={() => {
                    setValue('city', '');
                  }}
                />
                <Box mb={2} />
              </Box>
            )}

            <Box display="flex" flexDirection="column" width="100%" mt={1}>
              <Box fontSize="0.85rem" color="secondary.light" mb={1}>
                Outras informações
              </Box>
              <Input
                name="comments"
                type="text"
                control={control}
                error={errors}
                variant="outlined"
                label="Observações"
                fullWidth
                multiline
                minRows={5}
                disabled={loading || !isEdit}
              />
            </Box>
          </Box>

          <DialogActions style={{ padding: 0 }}>
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              gridGap={8}
              mt={4}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                onClick={handleClose}
                color="primary"
                variant="outlined"
                size="large"
              >
                {isEdit ? 'Cancelar' : 'Fechar'}
              </Button>

              {isEdit && (
                <Button
                  type="submit"
                  size="large"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Salvando...' : 'Salvar'}
                </Button>
              )}
            </Box>
          </DialogActions>
        </Box>
      </DialogContent>
    </Modal>
  );
}
