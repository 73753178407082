import { Box, Container, makeStyles, Theme } from '@material-ui/core';
import { FC, ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(8),
    },
  },
}));

interface CleanLayoutProps {
  pageName?: string;
  children?: ReactNode;
}

const CleanLayout: FC<CleanLayoutProps> = ({ pageName, children }) => {
  const classes = useStyles();

  return (
    <Box
      width="100%"
      height="100%"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      title={pageName}
    >
      <Container maxWidth="lg">
        <Box className={classes.root}>{children}</Box>
      </Container>
    </Box>
  );
};

export { CleanLayout };
