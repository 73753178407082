import { Avatar, Box, Input } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import { motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';

import { CircularProgressWithLabel } from '../../../../shared/components/CircularProgressWithLabel';
import { Flex } from '../../../../shared/components/Flex';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { useUserAuthenticated } from '../../../../shared/hooks/useUserAuthenticated';
import { api } from '../../../../shared/services/apiClient';
import resizeImage from '../../../../shared/utils/resize';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    container: {
      width: theme.spacing(14),
      height: theme.spacing(14),
      borderRadius: theme.spacing(1),
    },
    large: {
      width: theme.spacing(14),
      height: theme.spacing(14),
      borderRadius: theme.spacing(1),
      border: `2px solid ${theme.palette.background.paper}`,
    },
    button: {
      position: 'absolute',
      right: 0,
      bottom: 0,
      background: theme.background.gradient.radial,
      color: theme.background.gradient.contrastText,
      height: 24,
      borderRadius: 4,
    },
    label: {
      cursor: 'pointer',
      borderWidth: 0,
      borderRadius: theme.spacing(1),
      '&:focus': {
        outline: 0,
        boxShadow: 0,
      },

      '&::placeholder': {
        color: 'transparent',
      },
    },
    bgUpload: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(11, 11, 11, 0.4)',
      color: '#FFFFFF',
      borderRadius: theme.spacing(1),
      opacity: 0,
    },
    bgProgress: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(11, 11, 11, 0.4)',
      color: '#FFFFFF',
      borderRadius: theme.spacing(5),
    },
  })
);

type FileProps = {
  name: string;
  size: number;
  type: string;
  file: any;
};

interface AvatarUploadProps {
  isDisabled?: boolean;
}

export function AvatarUpload({ isDisabled }: AvatarUploadProps) {
  const classes = useStyles();
  const { msgSuccess, msgError } = useSnackMessages();
  const [fileProgress, setFileProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const { user, refreshUser } = useUserAuthenticated();
  const [avatarPreview, setAvatarPreview] = useState<string | null>(null);
  const [avatarProfile, setAvatarProfile] = useState('');

  // const avatarProfile = useMemo(() => {
  //   return user?.avatar_url || '';
  // }, [user?.avatar_url]);

  useEffect(() => {
    if (user?.avatar_url) {
      setAvatarProfile(user.avatar_url);
    }
  }, [user?.avatar_url]);

  const callback = (image: File) => {
    const formDataFile = new FormData();

    formDataFile.append('avatar', image);

    api
      .post('/profile/avatar', formDataFile, {
        onUploadProgress: (progressEvent) => {
          const progress: number = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          console.log(`${progress}% carregado... `);
          setFileProgress(progress);
        },
      })
      .then(() => {
        refreshUser();

        msgSuccess('Imagem de perfil atualizada com sucesso!');
      })
      .catch((err) => {
        setFileProgress(0);

        msgError(err.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
        setFileProgress(0);
      });
  };

  const handleUploadFile = async (event: any) => {
    const file = event?.target?.files[0] as FileProps;

    if (file.type.split('/')[0] !== 'image') {
      msgError('Selecione uma imagem PNG ou JPG.');
      return;
    }

    setLoading(true);

    setAvatarPreview(URL.createObjectURL(event?.target?.files[0]));

    // callback(event?.target?.files[0]);
    resizeImage(event?.target?.files[0], callback);
  };

  return (
    <Flex>
      <Box position="relative">
        <label className={classes.label}>
          <Input
            type="file"
            name="avatar"
            onChange={handleUploadFile}
            style={{ visibility: 'hidden', display: 'none' }}
            disabled={loading || isDisabled}
          />
          <Box position="relative" className={classes.container}>
            <Avatar
              alt={user?.name}
              src={avatarPreview || avatarProfile}
              className={classes.large}
            />
            <motion.div
              className={classes.bgUpload}
              whileHover={{ opacity: 1 }}
            >
              <CloudUploadOutlinedIcon />
            </motion.div>

            {loading && (
              <motion.div
                className={classes.bgProgress}
                whileHover={{ opacity: 1 }}
              >
                <CircularProgressWithLabel value={fileProgress} />
              </motion.div>
            )}
          </Box>
          <Box
            color="primary"
            className={classes.button}
            style={{ opacity: loading ? 0 : 1 }}
          >
            <PhotoCameraOutlinedIcon />
          </Box>
        </label>
      </Box>
    </Flex>
  );
}
