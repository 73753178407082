import {
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { useState } from 'react';

import { Header } from '../../../../shared/components/Header';
import {
  BudgetIcon,
  ClientIcon,
  ProductIcon,
  TechnicalSheetIcon,
} from '../../../../shared/components/Icons';
import { PageContainer } from '../../../../shared/components/PageContainer';
import { useSnackMessages } from '../../../../shared/hooks/useSnackMessages';
import { api } from '../../../../shared/services/apiClient';
import { BackupButton } from '../../components/BackupButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
);

const buttons = [
  {
    url: '/backup/clients',
    title: 'Clientes',
    filename: 'clientes.xlsx',
    icon: ClientIcon,
    disabled: false,
  },
  {
    url: '/backup/products',
    title: 'Produtos',
    filename: 'produtos.xlsx',
    icon: ProductIcon,

    disabled: false,
  },
  {
    url: '/backup/recipes',
    title: 'Fichas Técnicas',
    filename: 'fichas-tecnicas.xlsx',
    icon: TechnicalSheetIcon,
    disabled: false,
  },
  {
    url: '/backup/budgets',
    title: 'Orçamentos',
    filename: 'orcamentos.xlsx',
    icon: BudgetIcon,
    disabled: false,
  },
];

export function Backup() {
  const classes = useStyles();
  const { msgError } = useSnackMessages();
  const [lastBackup, setLastBackup] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  function download(url: string, filename: string) {
    setLoading(true);
    api
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        const fileName = `${moment().format(
          'DD-MM-YYYY HH[h]mm[m]'
        )} - ${filename}`;
        fileDownload(res.data, fileName);
        setLastBackup(fileName);
        setLoading(false);
      })
      .catch((err) => {
        msgError(
          err?.response?.data?.message ||
            'Error no servidor! Não foi possível exportar os dados selecionado.'
        );
        setLoading(false);
      });
  }

  return (
    <PageContainer display="flex" flexDirection="column">
      <Header goPush="/" title="Backups" />

      <Box textAlign="center" mb={2} color="primary.main">
        Para fazer o backup clique em uma das opções abaixo.
      </Box>

      {lastBackup && (
        <Box maxWidth={520} mx="auto">
          <Alert severity="info">Último backup realizado: {lastBackup}</Alert>
        </Box>
      )}

      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
      >
        {buttons.map((item) => {
          return (
            <BackupButton
              key={item.title}
              title={item.title}
              icon={<item.icon style={{ fontSize: 56 }} />}
              onClick={() => download(item.url, item.filename)}
              disabled={item?.disabled || loading}
            />
          );
        })}
      </Box>

      <Backdrop className={classes.backdrop} open={loading}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress color="inherit" />
          <Box>Gerando arquivo...</Box>
        </Box>
      </Backdrop>
    </PageContainer>
  );
}
