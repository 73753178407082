import moment from 'moment';
import { useQuery } from 'react-query';

import { api } from '../../../services/apiClient';
import { formatPrice } from '../../../utils/formatPrice';
import { IScheduleDetail, IResponse } from './types';
// import { assertIsSimplesDataResponse } from '../assertIsSimplesDataResponse';

type IReactQueryResponse = {
  result: IScheduleDetail;
};

async function getData(id: number | string) {
  const response = await api.get<IResponse>(`/sales/schedule/${id}`);

  // assertIsSimplesDataResponse(response);

  const data: IReactQueryResponse = {
    result: {
      ...response.data.result,
      formattedValue: formatPrice(Number(response.data.result.amount || 0)),
      amounts: response.data?.result?.amounts?.map((value) => ({
        ...value,
        amount_paid: Number(value?.amount_paid || 0),
        formattedPaidDate: moment(value.date_amount_paid).format('DD/MM/YYYY'),
        formattedPaidValue: formatPrice(Number(value.amount_paid || 0)),
      })),
      formattedPartyDate: moment(response.data.result.party_date).format(
        'DD/MM/YYYY'
      ),
    },
  };
  return data;
}

function useScheduleDetail(id: number | string) {
  return useQuery<IReactQueryResponse, Error>(['schedule-detail', id], () =>
    getData(id)
  );
}

export { useScheduleDetail };
