import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../shared/components/Button';
import NotFoundIcon from '../../../../shared/components/Icons/NotFoundIcon';

export function NotFound() {
  const history = useHistory();

  return (
    <Box
      minHeight="80vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="space-around"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        color="secondary.dark"
      >
        <NotFoundIcon style={{ fontSize: '5rem' }} />
        <Box
          mt={2}
          color="secondary.dark"
          fontWeight="medium"
          fontSize="1.2rem"
        >
          Ops! algo deu errado.
        </Box>
        <Box color="secondary.main" fontSize="0.85rem" style={{ opacity: 0.5 }}>
          Erro 404
        </Box>
      </Box>
      <Box
        mt={20}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={3}
      >
        <Button
          variant="outlined"
          size="small"
          color="primary"
          style={{ width: 122, borderRadius: 20 }}
          onClick={() => history.goBack()}
        >
          Voltar
        </Button>
      </Box>
    </Box>
  );
}
